import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { coolGrayColorGradients } from '../../constants/graph-colors';

export const AppHeaderWrp = styled.div`
  padding: 20px 1.75rem 0.5rem 1.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AppHeaderLeft = styled.div``;

export const AppHeaderRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  background: #ffffffe5;
  border-radius: 20px;
  padding: 5px 6px;
  font-family: Arial, sans-serif;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.2s ease;
  gap: 6px;

  &:hover {
    background: #e0e5ea;
  }
`;

export const Logo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  z-index: ${({ noIndex = false }) => noIndex && 0};
`;

export const LogoDefaultImageWrp = styled.div`
  width: ${({ width }) => width || '1.875rem'};
  height: ${({ width }) => width || '1.875rem'};
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
`;

export const LogoSvgWrp = styled.div`
  width: ${({ width }) => width || '1.875rem'};
  height: ${({ width }) => width || '1.875rem'};
`;

export const LogoDefaultIcon = styled.div`
  width: 100%;
  text-align: center;
  /* background: linear-gradient(180deg, #b5c1e5 0%, #a8afce 100%); */
  color: white;
  z-index: 2;
  margin-left: -100%;
  text-transform: uppercase;
  font-size: ${({ popup = false }) => (popup ? '1rem' : '0.8125rem')};
  font-weight: 600;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #${coolGrayColorGradients.coolGray80};
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds ellipsis (...) for overflowing text */
  max-width: 150px; /* Set a max-width to control when ellipsis applies */
`;

export const Arrow = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
    height: 6px;
  }

  path {
    fill: #585858;
  }
`;

export const AppLogo = styled(Link)`
  font-weight: bold;
  font-size: 1.15rem;
  color: ${({ theme }) => theme.logoText};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
`;

export const AppLogoSpan = styled.span`
  height: 2.3rem;
  width: 225px;
  background-size: cover;
  background-image: url(${({ theme }) => theme.logo});
`;

export const NavIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const NavUserProfile = styled.div`
  display: flex;
  align-items: center;
`;
export const NavUserProfileTitle = styled.div`
  padding: 0rem 0.75rem;
  font-size: 0.75rem;
  color: #343a3f;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  max-width: 100px;
  min-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
export const NavUserProfileImgWrp = styled.div`
  width: auto;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff4d;
  padding: 4px;
`;

export const NavUserProfileImg = styled.div`
  position: relative;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin-right: 0; /* Remove margin to avoid additional padding */
  background-size: contain;
  background-image: url(${({ profileImage }) => profileImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
`;

export const UserProfileCard = styled.div`
  width: 160px;
  border-radius: 1rem;
  position: absolute;
  margin-top: 2.5rem;
  z-index: 30;
  border: none;
  background: #fff;
  right: 0.2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
`;

export const ProfileCardDetails = styled.div`
  width: 100%;
  background: rgba(95, 57, 248, 0.09);
  height: 4rem;
  display: flex;
  gap: 0.625rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  padding: 1rem;
  align-items: center;
  /* justify-content: center; */
`;

// export const ProfileImage = styled.div`
//   width: 3.43rem;
//   height: 3.43rem;
//   background: url(${({ profileImage }) => profileImage});
//   background-size: cover;
//   border-radius: 50%;
// `;

export const ProfileText = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
`;

export const UserName = styled.h3`
  color: #343a3f;
  font-family: Inter;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 120% */
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: -0.01875rem;
  margin: 0;
`;

export const EmailId = styled.p`
  color: var(--grey-grey-3, #999);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 150% */
  letter-spacing: -0.015rem;
  margin: 0;
`;

export const ButtonsWrpr = styled.div`
  height: 88px;
  width: auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1rem;
  gap: 22px;
  border-radius: 0rem 0rem 1rem 1rem;
  margin: 0.1rem 0.1rem 0.5rem 0.1rem;
`;

export const Button = styled.div`
  color: #343a3f;
  font-family: Inter;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 120% */
  letter-spacing: -0.01875rem;
  &:hover {
    color: #5f39f8;
  }
`;

export const ButtonOne = styled.div`
  font-family: Inter;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 120% */
  letter-spacing: -0.01875rem;
  color: #343a3f;
  &:hover {
    color: #5f39f8;
  }
`;

export const CoachStartLabel = styled.div`
  color: #ffffff;
  font-size: 0.75rem;
  cursor: pointer;
`;

export const CoachMarksIntroWrp = styled.div`
  width: 23rem;
  /* height: 23rem; */
  flex-shrink: 0;
  /* border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2); */
`;
export const CoachMarksIntroImg = styled.img`
  width: 100%;
  height: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 0.625rem;
`;
export const CoachMarksIntroDesc = styled.div`
  width: 100%;
  /* height: 50%; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  .intro-label {
    color: #161a34;
    text-align: center;
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem; /* 121.739% */
    letter-spacing: -0.02875rem;
    > span {
      color: ${({ theme }) => theme.primary};
      font-size: 1.4375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.75rem;
      letter-spacing: -0.02875rem;
    }
  }
  .intro-description {
    color: #161a34;
    text-align: center;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 141.176% */
    letter-spacing: -0.02125rem;
  }
`;
export const CoachMarksImg = styled.img`
  width: 100%;
  height: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
`;
export const CoachMarksBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0 1rem;
`;

export const TutorialLink = styled.a`
  text-decoration-line: none;
`;

export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff4d;
  cursor: pointer;
`;

export const FloatingButton = styled.button`
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  z-index: 100000;

  background-color: var(--zsiqf-custom-bg-color);
  border: none;
  color: white;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
