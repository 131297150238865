import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  tabsData: [
    {
      campaign_id: uuidv4(),
      name: 'Brand 1',
      keywords: '',
      industryTypeId: null,
      competitorsTab: [],
    },
  ],
  activeTab: 0,
};

const brandSwitchDashboard = createSlice({
  name: 'brandSwitchDashboard',
  initialState,
  reducers: {
    // Set or update the main tab data
    setTabData: (state, action) => {
      const { tabIndex, data } = action.payload;
      state.tabsData[tabIndex] = { ...state.tabsData[tabIndex], ...data };
    },

    // Set or update the industry type id
    setIndustryTypeId: (state, action) => {
      const { tabIndex, data } = action.payload;
      state.tabsData[tabIndex].industryTypeId = data;
    },

    // Reset to initial state
    resetTabData: () => {
      return initialState;
    },
  },
});

export const { setTabData, resetTabData, setIndustryTypeId } =
  brandSwitchDashboard.actions;

export default brandSwitchDashboard.reducer;
