// index.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  TooltipOneContainer,
  TooltipTwoContainer,
  TooltipThreeContainer,
  TooltipFourContainer,
  TooltipFiveContainer,
  TooltipLabel,
  TooltipValue,
  TooltipDate,
  MarginBottom,
  SeasonalTooltipLabel,
  SeasonalTooltipValue,
  SeasonalTooltipWrp,
} from './index.sc';
import {
  blueColorGradients,
  coolGrayColorGradients,
  cyanColorGradients,
  greenColorGradients,
  magentaColorGradients,
  purpleColorGradients,
  redColorGradients,
} from '../../../../constants/graph-colors';

const SocialTooltip = ({ tooltipData, widget }) => {
  const renderTooltip = () => {
    switch (widget.customClassName) {
      case 'advanced_result_over_time':
        return (
          <TooltipOneContainer>
            <TooltipDate>{tooltipData?.data?.label}</TooltipDate>
            <TooltipValue>
              {Number(tooltipData?.data.doc_count).toLocaleString('en-US')}
            </TooltipValue>
          </TooltipOneContainer>
        );

      case 'brand_result_over_time':
        return (
          <TooltipOneContainer>
            <TooltipDate>{tooltipData?.data?.label}</TooltipDate>
            <MarginBottom />
            <TooltipLabel color={purpleColorGradients.purple70}>
              Results
            </TooltipLabel>
            <TooltipValue>
              {Number(tooltipData?.data.value).toLocaleString('en-US')}
            </TooltipValue>
            <MarginBottom />
            <TooltipLabel color={magentaColorGradients.magenta50}>
              {tooltipData?.data?.trendLabel}
            </TooltipLabel>
            <TooltipValue>
              {Number(tooltipData?.data.trend).toLocaleString('en-US')}
            </TooltipValue>
          </TooltipOneContainer>
        );

      case 'people_popular_topic_themes':
      case 'toptheme-graph-download':
      case 'advanced_top_themes':
      case 'advanced_top_themes_pre':
      case 'advanced_top_themes_during':
      case 'advanced_top_themes_post':
        return (
          <TooltipTwoContainer>
            <TooltipLabel color={tooltipData?.data?.labelColor}>
              {tooltipData?.data?.secondary_label || tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>
              {Number(tooltipData?.data?.thresholdValue).toLocaleString(
                'en-US'
              )}
            </TooltipValue>
          </TooltipTwoContainer>
        );

      case 'influencer_cluster_chart':
        return (
          <TooltipTwoContainer>
            <TooltipLabel color={tooltipData?.data?.color}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>
              {Number(tooltipData?.data?.value).toLocaleString('en-US')}
            </TooltipValue>
          </TooltipTwoContainer>
        );

      case 'advanced_campaign_sentiment_over_time':
        return (
          <TooltipThreeContainer>
            <div>
              <TooltipLabel color={greenColorGradients.green60}>
                Positive
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.POS}</TooltipValue>
            </div>
            <div>
              <TooltipLabel color={redColorGradients.red60}>
                Negative
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.NEG}</TooltipValue>
            </div>
            <div>
              <TooltipLabel color={coolGrayColorGradients.coolGray60}>
                Neutral
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.NEU}</TooltipValue>
            </div>
          </TooltipThreeContainer>
        );

      case 'advanced_campaign_engagement':
      case 'advanced_content_engagement':
        return (
          <TooltipFourContainer>
            <div>
              <TooltipLabel color={purpleColorGradients.purple70}>
                {tooltipData?.data?.label}
              </TooltipLabel>
              <TooltipLabel>{tooltipData?.data?.labelText}</TooltipLabel>
            </div>

            <TooltipValue>{tooltipData?.data.accValue}</TooltipValue>
          </TooltipFourContainer>
        );

      case 'advanced_campaign_content_type':
        return (
          <TooltipFiveContainer>
            <TooltipLabel color={blueColorGradients.blue60}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>{tooltipData?.data.accValue}</TooltipValue>
          </TooltipFiveContainer>
        );

      case 'brand_seasonal_trends':
        return (
          <TooltipFiveContainer>
            <TooltipLabel
              color={coolGrayColorGradients.coolGray100}
              fontSize={'14px'}
            >
              {tooltipData?.data?.label}
            </TooltipLabel>
            <SeasonalTooltipWrp>
              {widget?.data?.labels?.map((item, index) => {
                return (
                  <div key={index}>
                    <SeasonalTooltipLabel color={item.color}>
                      {item?.value}
                    </SeasonalTooltipLabel>
                    <SeasonalTooltipValue>
                      {tooltipData?.data[item.value]}
                    </SeasonalTooltipValue>
                  </div>
                );
              })}
            </SeasonalTooltipWrp>
          </TooltipFiveContainer>
        );

      case 'section_trends':
        return (
          <TooltipOneContainer>
            <TooltipDate>{tooltipData?.data?.label}</TooltipDate>
            <TooltipValue style={{ color: purpleColorGradients.purple60 }}>
              {Number(tooltipData?.data.value1).toLocaleString('en-US')}
            </TooltipValue>
            <TooltipValue style={{ color: magentaColorGradients.magenta60 }}>
              {Number(tooltipData?.data.value2).toLocaleString('en-US')}
            </TooltipValue>
            <TooltipValue style={{ color: cyanColorGradients.cyan50 }}>
              {Number(tooltipData?.data.value3).toLocaleString('en-US')}
            </TooltipValue>
          </TooltipOneContainer>
        );

      default:
        return null;
    }
  };

  return <div>{renderTooltip()}</div>;
};

SocialTooltip.propTypes = {
  tooltipData: PropTypes.object,
  widget: PropTypes.object,
};

export default SocialTooltip;
