import PropTypes, { bool } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import {
  networkClusterColors,
  networkClusterStroke,
} from '../utils/graphConst';

const NetworkCluster = ({ data, handleOnClick, config }) => {
  const svgRef = useRef();

  useEffect(() => {
    const width = config?.networkComponentWidth;
    console.log({ config });

    // config?.dashboardType !== 'overview'
    //   ? config?.dashboardType === 'newsletter'
    //     ? window?.innerWidth - window.innerWidth * 0.55
    //     : window?.innerWidth - window.innerWidth * 0.08
    //   : window.innerWidth - window.innerWidth * 0.3;
    const height =
      config?.networkComponentHeight > 525
        ? 525
        : config?.networkComponentHeight || 525;
    // Add padding to the simulation boundaries
    const padding = Math.max(...data.nodes.map((d) => d.size)) + 10; // Extra padding based on largest node

    const updateChartDimensions = () => {
      // Calculate the dimensions based on the window size
      const width = config?.networkComponentWidth;
      // config?.dashboardType !== 'overview'
      //   ? config?.dashboardType === 'newsletter'
      //     ? window?.innerWidth - window.innerWidth * 0.5
      //     : window?.innerWidth - window.innerWidth * 0.08
      // : window.innerWidth - window.innerWidth * 0.3;
      const height =
        config?.networkComponentHeight > 525
          ? 525
          : config?.networkComponentHeight || 525;

      // Update SVG width and height
      d3.select(svgRef.current).attr('width', width).attr('height', height);

      // Update simulation forces
      simulation
        .force('center', d3.forceCenter(width / 2, height / 2.5))
        .force('x', d3.forceX(width / 2).strength(0.5))
        .force('y', d3.forceY(height / 2).strength(0.5))
        .alpha(1)
        .restart();
    };

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height);
    // .style('background', '#f9f9f9')
    const simulation = d3
      .forceSimulation(data?.nodes)
      .force(
        'link',
        d3
          .forceLink(data.links)
          .id((d) => d.id)
          .distance(30) // Reduced link distance for tighter clustering
          .strength(1)
      )
      .force('charge', d3.forceManyBody().strength(-30)) // Weakened repulsion for denser layout
      .force('center', d3.forceCenter(width / 2, height / 2.5))
      .force(
        'collision',
        d3
          .forceCollide()
          .radius((d) => (data?.drillDownFlag ? d.size + 20 : d.size + 5)) // Reduced padding to make nodes closer
      )
      .force('x', d3.forceX(width / 2).strength(0.5)) // Stronger pull towards center (horizontal)
      .force('y', d3.forceY(height / 2).strength(0.5))
      .alphaDecay(0.0228); // Stronger pull towards center (vertical)

    // Function to generate curved path between nodes
    function linkPath(d) {
      // const dx = d.target.x - d.source.x;
      // const dy = d.target.y - d.source.y;
      // const dr = Math.sqrt(dx * dx + dy * dy);

      // // Calculate control point offset
      // const offset = dr * 0.35; // Adjust this value to control the curve amount

      // // Calculate control point coordinates
      // const midX = (d.source.x + d.target.x) / 2;
      // const midY = (d.source.y + d.target.y) / 2;

      // // Perpendicular offset for control point
      // const nx = (-dy / dr) * offset;
      // const ny = (dx / dr) * offset;

      // return `M${d.source.x},${d.source.y}
      //     Q${midX + nx},${midY + ny}
      //     ${d.target.x},${d.target.y}`;

      // Ensure source and target points are within bounds
      const sx = Math.max(
        d.source.size,
        Math.min(width - d.source.size, d.source.x)
      );
      const sy = Math.max(
        d.source.size,
        Math.min(height - d.source.size, d.source.y)
      );
      const tx = Math.max(
        d.target.size,
        Math.min(width - d.target.size, d.target.x)
      );
      const ty = Math.max(
        d.target.size,
        Math.min(height - d.target.size, d.target.y)
      );

      const dx = tx - sx;
      const dy = ty - sy;
      const dr = Math.sqrt(dx * dx + dy * dy);

      // Adjust control point to stay within bounds
      const offset = Math.min(dr * 0.35, Math.min(width, height) * 0.1); // Limit curve size

      const midX = (sx + tx) / 2;
      const midY = (sy + ty) / 2;

      // Calculate control point with boundary checks
      const nx = (-dy / dr) * offset;
      const ny = (dx / dr) * offset;

      // Ensure control point stays within bounds
      const cx = Math.max(0, Math.min(width, midX + nx));
      const cy = Math.max(0, Math.min(height, midY + ny));

      return `M${sx},${sy} Q${cx},${cy} ${tx},${ty}`;
    }

    // Draw links
    const link = svg
      .append('g')
      .attr('fill', 'none')
      .selectAll('path') // Changed from 'line' to 'path'
      .data(data.links)
      .join(
        (enter) =>
          enter
            .append('path')
            .attr('opacity', 0)
            .attr('stroke', '#ccc')
            .attr('stroke-width', 0.75)
            .transition()
            .duration(500)
            .attr('opacity', 1)
            .attr('stroke', (d) => {
              const sourceColor = networkClusterStroke[d.source.group];
              const targetColor = networkClusterStroke[d.target.group];
              return sourceColor === targetColor
                ? sourceColor
                : networkClusterStroke[d.group];
            }),
        (update) =>
          update
            .append('path')
            .attr('opacity', 0)
            .attr('stroke', '#ccc')
            .attr('stroke-width', 0.75)
            .transition()
            .duration(500)
            .attr('opacity', 1)
            .attr('stroke', (d) => {
              const sourceColor = networkClusterStroke[d.source.group];
              const targetColor = networkClusterStroke[d.target.group];
              return sourceColor === targetColor
                ? sourceColor
                : networkClusterStroke[d.group];
            }),
        (exit) => exit.transition().duration(500).attr('opacity', 0).remove()
      );
    // Draw nodes
    const node = svg
      .append('g')
      .selectAll('circle')
      .data(data.nodes)
      .join(
        (enter) =>
          enter
            .append('circle')
            .attr('r', (d) =>
              data?.drillDownFlag
                ? d?.orgColor
                  ? 12
                  : d?.authorColor
                  ? 10
                  : 8
                : d.id === d.organization
                ? d.size + 5
                : d.size - 5
            ) // Larger size for main nodes, smaller for connected bubbles
            .attr('fill', (d) => {
              return data?.drillDownFlag
                ? d.orgColor || d.authorColor || d.mentionColor
                : networkClusterColors[d.group];
            })
            .attr('stroke', (d) =>
              data?.drillDownFlag
                ? d.orgColor || d.authorColor || d.mentionColor
                : networkClusterStroke[d.group]
            )
            .style('cursor', 'pointer')
            .attr('stroke-width', 2),
        (update) =>
          update
            .append('circle')
            .attr('r', (d) =>
              data?.drillDownFlag
                ? d?.orgColor
                  ? 12
                  : d?.authorColor
                  ? 10
                  : 8
                : d.id === d.organization
                ? d.size + 5
                : d.size - 5
            ) // Larger size for main nodes, smaller for connected bubbles
            .attr('fill', (d) => {
              return data?.drillDownFlag
                ? d.orgColor || d.authorColor || d.mentionColor
                : networkClusterColors[d.group];
            })
            .attr('stroke', (d) =>
              data?.drillDownFlag
                ? d.orgColor || d.authorColor || d.mentionColor
                : networkClusterStroke[d.group]
            )
            .style('cursor', 'pointer')
            .attr('stroke-width', 2),
        (exit) => exit.transition().duration(500).attr('opacity', 0).remove()
      );
    // .call(
    //   d3
    //     .drag()
    //     .on('start', (event, d) => {
    //       if (!event.active) simulation.alphaTarget(0.3).restart();
    //       d.fx = d.x;
    //       d.fy = d.y;
    //     })
    //     .on('drag', (event, d) => {
    //       d.fx = event.x;
    //       d.fy = event.y;
    //     })
    //     .on('end', (event, d) => {
    //       if (!event.active) simulation.alphaTarget(0);
    //       d.fx = null;
    //       d.fy = null;
    //     })
    // );

    const textNode =
      data?.drillDownFlag &&
      svg
        .append('g')
        .selectAll('text')
        .data(data.nodes)
        .join(
          (enter) =>
            enter
              .append('text')
              .attr('class', 'label')
              .attr('text-anchor', 'middle')
              .attr('font-size', 11)
              .attr('fill', 'black')
              .text((d) => d?.label)
              .attr('opacity', 0)
              .transition()
              .duration(500)
              .attr('opacity', 1),
          (update) => update.text((d) => d?.label),
          (exit) => exit.transition().duration(500).attr('opacity', 0).remove()
        );
    // Highlight clusters on click
    node.on('click', function (event, d) {
      event.stopPropagation(); // Prevent other event listeners from interfering
      config?.dashboardType !== 'overview' &&
        config?.dashboardType !== 'newsletter' &&
        (d?.label !== d?.organization ||
          (!d?.id?.includes('category') && !data?.drillDownFlag)) &&
        handleOnClick({ ...d, networkPopupFlag: data?.drillDownFlag });
      config.handleMouseLeave(event, d, true);
      const clickedGroup = d.group;
      node.style('opacity', (nodeData) =>
        nodeData.group === clickedGroup ? 1 : 0.2
      );
    });
    node.on('mouseover', function (event, d) {
      const clickedGroup = d.group;
      node.style('opacity', (nodeData) =>
        nodeData.group === clickedGroup ? 1 : 0.2
      );
      link.style('opacity', (nodeData) =>
        nodeData?.source?.group === clickedGroup ? 1 : 0.2
      );
      config.handleMouseEnter(
        event,
        { ...d, networkSubClusterTitle: config?.networkSubClusterTitle },
        true
      );
    });
    node.on('mouseout', function (event, d) {
      //
      config.handleMouseLeave(event, d, true);
      const clickedGroup = d.group;

      node.style('opacity', (nodeData) =>
        nodeData.group !== clickedGroup ? 1 : 1
      );
      link.style('opacity', (nodeData) =>
        nodeData?.source?.group !== clickedGroup ? 1 : 1
      );
    });

    simulation.on('tick', () => {
      // link
      //   .attr('x1', (d) => Math.max(0, Math.min(width, d?.source?.x)))
      //   .attr('y1', (d) => Math.max(0, Math.min(height, d?.source?.y)))
      //   .attr('x2', (d) => Math.max(0, Math.min(width, d?.target?.x)))
      //   .attr('y2', (d) => Math.max(0, Math.min(height, d?.target?.y)));
      link.attr('d', linkPath); // Use path generator function
      node
        .attr(
          'cx',
          (d) => (d.x = Math.max(padding, Math.min(width - padding, d?.x)))
        )
        .attr(
          'cy',
          (d) => (d.y = Math.max(padding, Math.min(height - padding, d?.y)))
        );
      data?.drillDownFlag &&
        textNode
          .attr(
            'x',
            (d) => (d.x = Math.max(padding, Math.min(width - padding, d.x)))
          )
          .attr(
            'y',
            (d) =>
              (d.y = Math.max(padding, Math.min(height - padding, d.y)) + 24)
          );
    });

    // Attach resize event listener to update dimensions
    window.addEventListener('resize', updateChartDimensions);

    // Cleanup
    return () => {
      simulation.stop(); // Cleanup simulation
      svg.selectAll('*').remove();
      window.removeEventListener('resize', updateChartDimensions);
    };
  }, [config?.networkComponentWidth, data]);

  return <svg ref={svgRef}></svg>;
};

NetworkCluster.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        group: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
      })
    ).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        target: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
      })
    ).isRequired,
    drillDownFlag: bool,
  }).isRequired,
  handleOnClick: PropTypes.func,
  config: PropTypes.object,
};

export default NetworkCluster;
