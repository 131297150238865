/**
 * Helper function to check if a value is valid (not null, undefined, or empty)
 * @param {any} value - Value to check
 * @returns {boolean}
 */
export const isValidValue = (value) => {
  return value !== null && value !== undefined && value !== '';
};

/**
 * Validation functions for different chart types
 */

export function brandSwitchChartsQuery(payload) {
  return (
    isValidValue(payload?.media_types) &&
    isValidValue(payload?.simple_query) &&
    isValidValue(payload?.industry_type_id)
  );
}

export function influencerChartsQuery(payload) {
  return isValidValue(payload?.media_types) && isValidValue(payload?.dateTime);
}

export function contentChartsQuery(payload) {
  return isValidValue(payload?.dateTime) && isValidValue(payload?.media_types);
}

export function peopleChartsQuery(payload) {
  return (
    isValidValue(payload?.media_types) &&
    isValidValue(payload?.simple_query) &&
    isValidValue(payload?.person_name)
  );
}

export function socialCampaignChartsQuery(
  payload,
  isCampaignNamePresent = true
) {
  const baseValidation =
    isValidValue(payload?.dateTime) &&
    isValidValue(payload?.media_types) &&
    isValidValue(payload?.simple_query);
  const parseCampaignName = (campaign) => {
    try {
      return typeof campaign === 'string' ? JSON.parse(campaign) : campaign;
    } catch (error) {
      return null;
    }
  };

  const campaignData = parseCampaignName(payload?.campaign_name);
  const isValidCampaignName =
    Array.isArray(campaignData) && campaignData.length > 0;

  return isCampaignNamePresent
    ? baseValidation && isValidCampaignName
    : baseValidation;
}

export function prImpactChartQuery(payload) {
  return isValidValue(payload?.media_types);
}

export function storyAnalysisChartQuery(payload) {
  return payload?.article_ids?.length > 0;
}

export function campaignChartsQuery(payload) {
  return isValidValue(payload?.media_types) && isValidValue(payload?.keyword);
}

export function industryChartsQuery(payload) {
  return isValidValue(payload?.media_types);
}

export function campaignMonitorChartsQuery(payload) {
  return (
    isValidValue(payload?.dateTime) &&
    isValidValue(payload?.media_types) &&
    isValidValue(payload?.keyword)
  );
}

export function brandChartsQuery(payload) {
  return (
    isValidValue(payload?.media_types) &&
    isValidValue(payload?.simple_query) &&
    isValidValue(payload?.brand_keywords)
  );
}
