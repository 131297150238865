import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import similarwebIcon from '../../assets/img/similarWeb.svg';

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipContent = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background-color: ${({ bgColor }) => bgColor || '#ffffff'};
  color: ${({ bgColor }) => (bgColor ? '#FFFFFF' : '#343a3f')};
  text-align: start;
  border-radius: 10px;
  padding: 16px;
  position: absolute;
  z-index: 1000000;
  width: 15rem;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0px 4px 12px 0px #00000029;
  ${({ direction }) =>
    direction === 'top' &&
    css`
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 10px;
    `}
  ${({ direction }) =>
    direction === 'right' &&
    css`
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      margin-left: 10px;
    `}
  ${({ direction }) =>
    direction === 'bottom' &&
    css`
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 10px;
    `}
  ${({ direction }) =>
    direction === 'left' &&
    css`
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      margin-right: 10px;
    `}
`;

const SecondaryData = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  ${({ direction }) =>
    direction === 'top' &&
    css`
      border-width: 10px 10px 0 10px;
      border-color: ${({ bgColor }) =>
        bgColor
          ? '#000 transparent transparent  transparent'
          : '#fff transparent transparent transparent'};
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ direction }) =>
    direction === 'right' &&
    css`
      border-width: 10px 10px 10px 0;
      border-color: ${({ bgColor }) =>
        bgColor
          ? ' transparent #000 transparent  transparent'
          : 'transparent #fff transparent transparent'};
      top: 50%;
      left: -10px;
      transform: translateY(-50%);
    `}
  ${({ direction }) =>
    direction === 'bottom' &&
    css`
      border-width: 0 10px 10px 10px;
      border-color: ${({ bgColor }) =>
        bgColor
          ? 'transparent transparent #000 transparent'
          : 'transparent transparent #fff transparent'};
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ direction }) =>
    direction === 'left' &&
    css`
      border-width: 10px 0 10px 0px;
      border-color: ${({ bgColor }) =>
        bgColor
          ? ' transparent  transparent  transparent #000'
          : 'transparent transparent transparent #fff'};
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
    `}
`;

const ReachTooltip = ({
  content,
  direction = 'top',
  children,
  articleCount = true,
  hideTooltip = false,
  bgColor = '',
}) => {
  const [visible, setVisible] = useState(false);
  const [tooltipDirection, setTooltipDirection] = useState(direction);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      if (visible) {
        calculatePosition();
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [visible]);

  const calculatePosition = () => {
    const tooltipElement = tooltipRef.current;
    const parentElement = tooltipElement.parentNode;

    if (!tooltipElement || !parentElement) return;

    const tooltipRect = tooltipElement.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();

    const tooltipHeight = tooltipRect.height;
    const tooltipWidth = tooltipRect.width;

    const fitsAbove = parentRect.top - 265 - tooltipHeight > 0;
    const fitsBelow =
      window.innerHeight - (parentRect.bottom + tooltipHeight) > 0;
    const fitsLeft = parentRect.left - tooltipWidth > 0;
    const fitsRight = window.innerWidth - (parentRect.right + tooltipWidth) > 0;

    let newDirection = direction;
    if (articleCount) {
      if (direction === 'top' && !fitsAbove) {
        newDirection = fitsBelow ? 'bottom' : fitsRight ? 'right' : 'left';
      } else if (direction === 'bottom' && !fitsBelow) {
        newDirection = fitsAbove ? 'top' : fitsRight ? 'right' : 'left';
      } else if (direction === 'left' && !fitsLeft) {
        newDirection = fitsRight ? 'right' : fitsAbove ? 'top' : 'bottom';
      } else if (direction === 'right' && !fitsRight) {
        newDirection = fitsLeft ? 'left' : fitsAbove ? 'top' : 'bottom';
      } else if (direction === 'bottom' && fitsAbove) {
        newDirection = 'top';
      } else if (fitsAbove && fitsBelow) {
        newDirection = 'right';
      }
    } else {
      newDirection = 'top';
    }
    setTooltipDirection(newDirection);
  };

  const handleShowTooltip = () => {
    if (!hideTooltip) {
      calculatePosition();
      setVisible(true);
    }
  };

  const handleHideTooltip = () => {
    if (!hideTooltip) {
      setVisible(false);
    }
  };

  return (
    <TooltipWrapper
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
    >
      <div style={{ marginTop: '0.25rem' }}>{children}</div>
      {!hideTooltip && (
        <TooltipContent
          ref={tooltipRef}
          visible={visible}
          direction={tooltipDirection}
          bgColor={bgColor}
        >
          {content}
          {!bgColor && (
            <>
              <SecondaryData>Reach data by</SecondaryData>
              <img src={similarwebIcon} alt="icon" />
            </>
          )}
          <Arrow direction={tooltipDirection} bgColor={bgColor} />
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};

ReachTooltip.propTypes = {
  content: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  children: PropTypes.node.isRequired,
  articleCount: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  bgColor: PropTypes.string,
};

export default ReachTooltip;
