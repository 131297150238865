import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  // BackDrop,
  // DashboardInnerSection,
  DashboardSection,
  // articleWdth,
  // DropHeader,
  // FullSlot,
  // HalfSlot,
  // Iconwpr,
  // SlotWrp,
  // UberTextTitle,
  // UberTilesWrp,
  // UserTilesMainWrp,
} from '../index.sc';
// import OverviewDropdown from '../../overview-dropdown';
// import IconPop from '../../icon-popup';
// import DownloadIcon from '../../../assets/icons/DownloadIcon';
// import { AdvanceDashboard, Widgets } from '../../../constants/overview';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// import { useDashboardData } from '../../../hooks/useSearch';
// import { useParams } from 'react-router';
// import Spinner from '../../spinner';
import DashboardInnerContainer from './DashboardInnerContainer';
// import DashboardHeader from './dashboard-header';
// import { dashboardTabs } from '../../../constants/dashboard';

import DashboardHeaderV2 from './dashboard-header-v2/index.js';
import GraphIconBox from '../GraphIconBox';
import { useLocation } from 'react-router-dom';

// const downloadList = [
//   {
//     id: 0,
//     label: 'PDF',
//   },
//   {
//     id: 1,
//     label: 'Excel',
//   },
//   {
//     id: 2,
//     label: 'CSV',
//   },
// ];

const findIndexByTitle = (arr = [], title = '') =>
  [...arr].findIndex((obj) => obj?.title === title);

const updateState = (stateArray, newState, index) =>
  index === -1
    ? stateArray
    : [...stateArray.slice(0, index), newState, ...stateArray.slice(index + 1)];

const DashboardSectionComponent = ({
  handleSelectedChartDropdown,
  dashboardKeywords,
  selectedChartDropdown,
  resultsShown,
  setSyndicationClick,
  activeScreen,
  loader,
  setArticleType,
  articleType,
  tileDetails,
  dashboardDetails,
  accessTypeViewUser = false,
  overView = true,
  setClickedPosition,
  articlePosition,
  customWidgetDetails,
  setResetSelection,
  setSelected,
  selected,
  setArticleTypeClose,
  resetSelection,
  setArticlePosition,
  setSelectedGraph,
  selectGraph,
  dashboardType,
  customCanvas,
  editOption,
  volumeAnalysisWidgetDetails,
  brandEngagementMetrics,
  sentimentAnalysisWidgetDetails,
  sentimeOverTimeWidgetDetails,
  seasonalTrendsWidgetDetails,
  sentimeOverTimeSocialWidgetDetails,
  peopleTopThemesWidgetDetails,
  topJournalistSentimentAnalysisWidgetDetails,
  topJournalistSentimentAnalysisWidgetDetailsSocial,
  coverageOverTimeBrandWidgetDetails,
  coverageOverTimeBrandSocialWidgetDetails,
  reachOvertimeBrandWidgetDetails,
  topPerformingPostsBrandWidgetDetails,
  reachOvertimeBrandSocialWidgetDetails,
  SOVWidgetDetails,
  mediaContributorsCompWidgetDetails,
  coverageByWebsiteCompWidgetDetails,
  coverageOverTimeCompWidgetDetails,
  coverageOverTimeSocialCompWidgetDetails,
  mediaTypeWidgetDetails,
  compMentionedWidgetDetails,
  brandCompMentionedWidgetDetails,
  brandSocialWordCloudWidgetDetails,
  brandResultOverTimeWidgetDetails,
  peopleCoverageChartDataWidgetDetails,
  peopleCoverageSocialChartDataWidgetDetails,
  peopleThemeWidgetDetails,
  peopleMediaTypeWidgetDetails,
  peopleCoverageOnKeyTopicsChartDetails,
  peopleTopScoreDetails,
  peopleVolumeAnalysisWidgetDetails,
  peopleVolumeComparisonData,
  peopleImpactWidgetDetails,
  peopleReachOverTimeComparisonData,
  peopleSentimentAnalysisWidgetDetails,
  sentimentCompWidgetDetails,
  reachOvertimeCompWidgetDetails,
  reachOvertimeSocialCompWidgetDetails,
  mediaBreakdownWidgetDetails,
  journalistWidgetDetails,
  sourceCompWidgetDetails,
  placeholderGraphs,
  handleGraphTitleUpdate,
  industryVolumeAnalysisWidgetDetails,
  industrySentimentWidgetDetails,
  industryCoverageOverTimeWidgetDetails,
  industryCoverageSocialOverTimeWidgetDetails,
  industryCoverageBySourceWidgetDetails,
  industryCompaniesWidgetDetails,
  industryCoverageByJournalistsWidgetDetails,
  industryTopInfluencerWidgetData,
  industryPublicationsWidgetDetails,
  isSavePopup,
  onDownloadChartData,
  filters = {},
  showAIInsight = false,
  setShowAIInsight = () => {},
  summaryData = {},
  summaryLoading = false,
  isChartsFetching,
  showSaved = false,
  savedSelectedChart,
  handleOverallInsights = () => {},
}) => {
  // const [selectedTab, setSelectedTab] = useState('overview');
  // const createPdf = async () => {
  //   // eslint-disable-next-line new-cap
  //   const pdf = new jsPDF('portrait', 'pt', 'a4');
  //   const contentElement = document.querySelector('#download-content');
  //   const graphElements = contentElement.querySelectorAll('.graph-widget');

  //   const capturePromises = Array.from(graphElements).map(
  //     async (graphElement) => {
  //       contentElement.scrollTo(0, graphElement.offsetTop);
  //       const data = await html2canvas(graphElement);
  //       return data.toDataURL('image/png');
  //     }
  //   );

  //   const graphImages = await Promise.all(capturePromises);

  //   graphImages.forEach((img, index) => {
  //     const imgProperties = pdf.getImageProperties(img);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //     const x = (pdf.internal.pageSize.getWidth() - pdfWidth) / 2;
  //     const y = (pdf.internal.pageSize.getHeight() - pdfHeight) / 2;
  //     pdf.addImage(img, 'PNG', x, y, pdfWidth, pdfHeight);

  //     if (index < graphImages.length - 1) {
  //       pdf.addPage();
  //     }
  //   });

  //   // Save the PDF
  //   pdf.save('search_result.pdf');
  // };

  // const handleDownload = (id) => {
  //   const downloadOption = downloadList.find(
  //     (download) => download.id === id
  //   ).label;
  //   if (downloadOption.toLowerCase() === 'pdf') {
  //     createPdf();
  //   }
  // };

  // const handleTabChange = (index) => {
  //   setSelectedTab(dashboardTabs[index]?.type);
  // };

  // if (dashboardDataLoading) {
  //   return <Spinner />;
  // }

  const [showFeatures, SetShowFeature] = useState(false);

  const [dashboardState, setDashboardState] = useState([...dashboardDetails]);
  const location = useLocation();
  // const savedSelectedChart =
  //   location?.state?.savedDashboardData?.chart_field?.selectedChart || dashboard;

  const handleSaveDashboard = () => {
    console.log('save dashboard', [...dashboardState]);
  };

  const handleUpdatedChart = (data) => {
    const { chartType: updatedChartType, chartName: chartTitle } = data;

    let objIndex = -1;
    let updatedDashState = [];

    objIndex = findIndexByTitle(dashboardState, chartTitle);
    if (objIndex !== -1) {
      const updatedObj = {
        ...dashboardState[objIndex],
        graphType: updatedChartType,
      };
      updatedDashState = updateState(dashboardState, updatedObj, objIndex);
      setDashboardState(updatedDashState);
    }
  };
  const handleShowDownloadPopUp = () => {
    SetShowFeature((prev) => !prev);
  };

  return (
    <DashboardSection
      activeScreen={activeScreen}
      className={activeScreen === 'dashboard' ? 'active' : ''}
      articlePosition={articlePosition}
    >
      {overView && (
        <DashboardHeaderV2
          handleSaveDashboard={handleSaveDashboard}
        ></DashboardHeaderV2>
      )}
      {/* <DropHeader>
        <DashboardHeader
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          tabKeywords={tabKeywords}
        />
      </DropHeader> */}
      <DashboardInnerContainer
        handleSelectedChartDropdown={handleSelectedChartDropdown}
        dashboardKeywords={dashboardKeywords}
        selectedChartDropdown={selectedChartDropdown}
        resultsShown={resultsShown}
        filters={filters}
        resetData={() => {
          setSyndicationClick &&
            setSyndicationClick({
              widget: undefined,
              graphSelection: undefined,
              rawData: null,
            });
        }}
        onDownloadChartData={onDownloadChartData}
        accessTypeViewUser={accessTypeViewUser}
        isSavePopup={isSavePopup}
        handleGraphTitleUpdate={handleGraphTitleUpdate}
        dashboardDetails={dashboardState}
        tileDetails={tileDetails}
        customWidgetDetails={customWidgetDetails}
        loader={loader}
        articleType={articleType}
        setArticleType={setArticleType}
        overRideSlot={activeScreen !== 'dashboard'}
        setClickedPosition={setClickedPosition}
        articlePosition={''}
        handleUpdatedChart={handleUpdatedChart}
        setResetSelection={setResetSelection}
        setSelected={setSelected}
        selected={selected}
        setArticlePosition={setArticlePosition}
        resetSelection={resetSelection}
        setArticleTypeClose={setArticleTypeClose}
        selectGraph={selectGraph}
        setSelectedGraph={setSelectedGraph}
        handleShowDownloadPopUp={handleShowDownloadPopUp}
        dashboardType={dashboardType}
        customCanvas={customCanvas}
        editOption={editOption}
        volumeAnalysisWidgetDetails={volumeAnalysisWidgetDetails}
        brandEngagementMetrics={brandEngagementMetrics}
        sentimentAnalysisWidgetDetails={sentimentAnalysisWidgetDetails}
        sentimeOverTimeWidgetDetails={sentimeOverTimeWidgetDetails}
        seasonalTrendsWidgetDetails={seasonalTrendsWidgetDetails}
        sentimeOverTimeSocialWidgetDetails={sentimeOverTimeSocialWidgetDetails}
        peopleTopThemesWidgetDetails={peopleTopThemesWidgetDetails}
        topJournalistSentimentAnalysisWidgetDetails={
          topJournalistSentimentAnalysisWidgetDetails
        }
        topJournalistSentimentAnalysisWidgetDetailsSocial={
          topJournalistSentimentAnalysisWidgetDetailsSocial
        }
        coverageOverTimeBrandWidgetDetails={coverageOverTimeBrandWidgetDetails}
        coverageOverTimeBrandSocialWidgetDetails={
          coverageOverTimeBrandSocialWidgetDetails
        }
        reachOvertimeBrandWidgetDetails={reachOvertimeBrandWidgetDetails}
        topPerformingPostsBrandWidgetDetails={
          topPerformingPostsBrandWidgetDetails
        }
        reachOvertimeBrandSocialWidgetDetails={
          reachOvertimeBrandSocialWidgetDetails
        }
        SOVWidgetDetails={SOVWidgetDetails}
        mediaContributorsCompWidgetDetails={mediaContributorsCompWidgetDetails}
        coverageByWebsiteCompWidgetDetails={coverageByWebsiteCompWidgetDetails}
        coverageOverTimeCompWidgetDetails={coverageOverTimeCompWidgetDetails}
        coverageOverTimeSocialCompWidgetDetails={
          coverageOverTimeSocialCompWidgetDetails
        }
        mediaTypeLWidgetDetails={mediaTypeWidgetDetails}
        peopleCoverageChartDataWidgetDetails={
          peopleCoverageChartDataWidgetDetails
        }
        peopleCoverageSocialChartDataWidgetDetails={
          peopleCoverageSocialChartDataWidgetDetails
        }
        peopleThemeWidgetDetails={peopleThemeWidgetDetails}
        peopleMediaTypeWidgetDetails={peopleMediaTypeWidgetDetails}
        peopleCoverageOnKeyTopicsChartDetails={
          peopleCoverageOnKeyTopicsChartDetails
        }
        peopleTopScoreDetails={peopleTopScoreDetails}
        peopleVolumeAnalysisWidgetDetails={peopleVolumeAnalysisWidgetDetails}
        peopleVolumeComparisonData={peopleVolumeComparisonData}
        peopleReachOverTimeComparisonData={peopleReachOverTimeComparisonData}
        peopleImpactWidgetDetails={peopleImpactWidgetDetails}
        peopleSentimentAnalysisWidgetDetails={
          peopleSentimentAnalysisWidgetDetails
        }
        compMentionedWidgetDetails={compMentionedWidgetDetails}
        brandCompMentionedWidgetDetails={brandCompMentionedWidgetDetails}
        brandSocialWordCloudWidgetDetails={brandSocialWordCloudWidgetDetails}
        brandResultOverTimeWidgetDetails={brandResultOverTimeWidgetDetails}
        sentimentCompWidgetDetails={sentimentCompWidgetDetails}
        reachOvertimeCompWidgetDetails={reachOvertimeCompWidgetDetails}
        reachOvertimeSocialCompWidgetDetails={
          reachOvertimeSocialCompWidgetDetails
        }
        mediaBreakdownWidgetDetails={mediaBreakdownWidgetDetails}
        journalistWidgetDetails={journalistWidgetDetails}
        sourceCompWidgetDetails={sourceCompWidgetDetails}
        industryVolumeAnalysisWidgetDetails={
          industryVolumeAnalysisWidgetDetails
        }
        industrySentimentWidgetDetails={industrySentimentWidgetDetails}
        industryCoverageOverTimeWidgetDetails={
          industryCoverageOverTimeWidgetDetails
        }
        industryCoverageSocialOverTimeWidgetDetails={
          industryCoverageSocialOverTimeWidgetDetails
        }
        industryCoverageBySourceWidgetDetails={
          industryCoverageBySourceWidgetDetails
        }
        industryCompaniesWidgetDetails={industryCompaniesWidgetDetails}
        industryCoverageByJournalistsWidgetDetails={
          industryCoverageByJournalistsWidgetDetails
        }
        industryTopInfluencerWidgetData={industryTopInfluencerWidgetData}
        industryPublicationsWidgetDetails={industryPublicationsWidgetDetails}
        placeholderGraphs={placeholderGraphs}
        savedSelectedChart={savedSelectedChart}
        showAIInsights={showAIInsight}
        setShowAIInsights={setShowAIInsight}
        summaryData={summaryData}
        summaryLoading={summaryLoading}
        showSaved={showSaved}
        handleOverallInsights={handleOverallInsights}
        isChartsFetching={isChartsFetching}
      />
      {showFeatures && <GraphIconBox />}
    </DashboardSection>
  );
};

export default DashboardSectionComponent;

DashboardSectionComponent.propTypes = {
  activeScreen: PropTypes.string,
  loader: PropTypes.bool,
  setArticleType: PropTypes.func,
  articleType: PropTypes.object,
  tileDetails: PropTypes.array,
  dashboardDetails: PropTypes.array,
  customWidgetDetails: PropTypes.object,
  overView: PropTypes.bool,
  setClickedPosition: PropTypes.func,
  articlePosition: PropTypes.string,
  setResetSelection: PropTypes.func,
  setSelected: PropTypes.func,
  selected: PropTypes.any,
  setArticlePosition: PropTypes.func,
  resetSelection: PropTypes.bool,
  setArticleTypeClose: PropTypes.func,
  setSelectedGraph: PropTypes.func,
  selectGraph: PropTypes.object,
  dashboardType: PropTypes.string,
  customCanvas: PropTypes.object,
  editOption: PropTypes.bool,
  volumeAnalysisWidgetDetails: PropTypes.object,
  brandEngagementMetrics: PropTypes.object,
  sentimentAnalysisWidgetDetails: PropTypes.object,
  sentimeOverTimeWidgetDetails: PropTypes.object,
  seasonalTrendsWidgetDetails: PropTypes.object,
  sentimeOverTimeSocialWidgetDetails: PropTypes.object,
  peopleTopThemesWidgetDetails: PropTypes.object,
  topJournalistSentimentAnalysisWidgetDetails: PropTypes.object,
  topJournalistSentimentAnalysisWidgetDetailsSocial: PropTypes.object,
  coverageOverTimeBrandWidgetDetails: PropTypes.object,
  coverageOverTimeBrandSocialWidgetDetails: PropTypes.object,
  reachOvertimeBrandWidgetDetails: PropTypes.object,
  reachOvertimeBrandSocialWidgetDetails: PropTypes.object,
  SOVWidgetDetails: PropTypes.object,
  mediaContributorsCompWidgetDetails: PropTypes.object,
  coverageByWebsiteCompWidgetDetails: PropTypes.object,
  coverageOverTimeCompWidgetDetails: PropTypes.object,
  coverageOverTimeSocialCompWidgetDetails: PropTypes.object,
  mediaTypeWidgetDetails: PropTypes.object,
  peopleCoverageChartDataWidgetDetails: PropTypes.object,
  peopleCoverageSocialChartDataWidgetDetails: PropTypes.object,
  peopleThemeWidgetDetails: PropTypes.object,
  peopleCoverageOnKeyTopicsChartDetails: PropTypes.object,
  peopleMediaTypeWidgetDetails: PropTypes.object,
  peopleTopScoreDetails: PropTypes.object,
  peopleVolumeAnalysisWidgetDetails: PropTypes.object,
  peopleVolumeComparisonData: PropTypes.object,
  peopleImpactWidgetDetails: PropTypes.object,
  peopleReachOverTimeComparisonData: PropTypes.object,
  peopleSentimentAnalysisWidgetDetails: PropTypes.object,
  sentimentCompWidgetDetails: PropTypes.object,
  reachOvertimeCompWidgetDetails: PropTypes.object,
  topPerformingPostsBrandWidgetDetails: PropTypes.object,
  reachOvertimeSocialCompWidgetDetails: PropTypes.object,
  mediaBreakdownWidgetDetails: PropTypes.object,
  journalistWidgetDetails: PropTypes.object,
  sourceCompWidgetDetails: PropTypes.object,
  compMentionedWidgetDetails: PropTypes.object,
  brandCompMentionedWidgetDetails: PropTypes.object,
  brandSocialWordCloudWidgetDetails: PropTypes.object,
  brandResultOverTimeWidgetDetails: PropTypes.object,
  placeholderGraphs: PropTypes.bool,
  handleGraphTitleUpdate: PropTypes.func,
  industryVolumeAnalysisWidgetDetails: PropTypes.object,
  industrySentimentWidgetDetails: PropTypes.object,
  industryCoverageOverTimeWidgetDetails: PropTypes.object,
  industryCoverageSocialOverTimeWidgetDetails: PropTypes.object,
  industryCoverageBySourceWidgetDetails: PropTypes.object,
  industryCompaniesWidgetDetails: PropTypes.object,
  industryCoverageByJournalistsWidgetDetails: PropTypes.object,
  industryTopInfluencerWidgetData: PropTypes.object,
  industryPublicationsWidgetDetails: PropTypes.object,
  setSyndicationClick: PropTypes.func,
  isSavePopup: PropTypes.bool,
  onDownloadChartData: PropTypes.func,
  filters: PropTypes.object,
  showAIInsight: PropTypes.bool,
  setShowAIInsight: PropTypes.func,
  summaryData: PropTypes.object,
  summaryLoading: PropTypes.bool,
  showSaved: PropTypes.bool,
  accessTypeViewUser: PropTypes.bool,
  savedSelectedChart: PropTypes.array,
  resultsShown: PropTypes.string,
  handleSelectedChartDropdown: PropTypes.object,
  dashboardKeywords: PropTypes.array,
  selectedChartDropdown: PropTypes.object,
  handleOverallInsights: PropTypes.func,
  isChartsFetching: PropTypes.bool,
};
