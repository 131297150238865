export const brandSwitchSOVMapData = {
  title: 'SOV',
  subTitle: '',
  component: 'brand_switch_sov',
  graphType: 'pie',
  data: {
    title: '',
    subtitle: '2020-04-17',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const brandSwitchTopThemesMapData = {
  title: 'Top Themes Coverage',
  subTitle: 'Total Articles',
  component: 'brand_switch_top_themes_coverage',
  customClassName: 'brand_switch_top_themes_coverage',
  shouldShowGraph: true,
  graphType: 'radar',

  data: {
    summary: {
      subtext: '',
      value: '',
      widgetName: '',
    },
    data: [
      {
        label: 'A',
        low: '20',
        medium: '40',
        high: '60',
        blocker: '80',
        easy: '100',
        excellent: '100',
        color: 'red',
      },
    ],
    labels: [
      {
        label: 'Blocker',
        value: 'blocker',
      },
      {
        label: 'High',
        value: 'high',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Low',
        value: 'low',
      },
      {
        label: 'Easy',
        value: 'easy',
      },
      {
        label: 'Excellent',
        value: 'excellent',
      },
    ],
    footerLabels: [
      {
        label: 'Mike',
        value: 'Mike',
      },
      {
        label: 'Michael Jackson',
        value: 'michael jackson',
      },
      {
        label: 'Taylor Shift',
        value: 'taylor shift',
      },
    ],
  },

  info: [],
};

export const brandSwitchSentimentMapData = {
  title: 'Switch-in Switch-out Index',
  subTitle: '',
  component: 'brand_switch_sentiment_analysis_count',
  graphType: 'sentiment_gauge',
  data: {
    title: 'Switch-in Switch-out Index',
    subTitle: 'Total Articles',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: '',
    },
    data: [
      {
        label: 'Positive',
        value: 60,
        color: '#24A148',
      },
      {
        label: 'Negative',
        value: 20,
        color: '#FA4D56',
      },
      {
        label: 'Neutral',
        value: 20,
        color: '#A9ADBE',
      },
    ],
    legends: [
      {
        label: 'Positive',
        value: 'positive',
        color: '#24A148',
      },
      {
        label: 'Negative',
        value: 'negative',
        color: '#FA4D56',
      },
      {
        label: 'Neutral',
        value: 'neutral',
        color: '#A9ADBE',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
    brandName: '',
  },
};

export const brandSwitchSentimentReasonMapData = {
  title: 'Switch-in & Switch-out Reasons',
  subTitle: '',
  component: 'brand_switch_sentiment_analysis',
  graphType: 'stacked_bar',
  dataType: ['1d'],
  data: {
    title: 'Switch-in & Switch-out Reasons',
    subTitle: 'Total Articles',
    summary: {
      subtext: '',
      label: 'Total Articles',
      value: '1.8 Million',
    },
    data: [
      {
        label: 'Source 1',
        neg: '190000',
        pos: '360000',
        neu: '310000',
      },
      {
        label: 'Source 2',
        neg: '280000',
        pos: '290000',
        neu: '420000',
      },
      {
        label: 'Source 3',
        neg: '390000',
        pos: '350000',
        neu: '480000',
      },
      {
        label: 'Source 4',
        neg: '250000',
        pos: '280000',
        neu: '350000',
      },
      {
        label: 'Source 5',
        neg: '250000',
        pos: '150000',
        neu: '290000',
      },
    ],
    labels: [
      {
        label: 'Positive',
        value: 'pos',
        color: '#24A148',
      },
      {
        label: 'Negative',
        value: 'neg',
        color: '#FA4E56',
      },
      {
        label: 'Neutral',
        value: 'neu',
        color: '#FF832B',
      },
    ],
    info: [],
    percentage: 10,
    brandName: '',
  },
};
