import React, { useState } from 'react';
import { Button } from '../button';
import { useSelector } from 'react-redux';
import { theme } from '../../constants/theme';
import {
  SentimentModalButtonWrp,
  SentimentModalHeader,
  SentimentModalHeading,
  SentimentModalIconWrp,
  SentimentModalList,
  SentimentModalListWrp,
  SentimentModalTitle,
  SentimentModalWrp,
} from './index.sc';
import { sentimentDropDown } from '../../pages/news-letter/newsletter-add-section/add-item-component/article-item/articleinputbox/filterData';
import SentimentComponent from '../sentiment';
import PropTypes from 'prop-types';
import Close from '../../assets/icons/Close';
import CheckCircle from '../../assets/icons/CheckCircle';

const SentimentModal = ({
  selectedSentiment = '',
  buttonTxt,
  setSentimentPopup,
  setSelectedSentiment = () => {},
  onChange = () => {},
  handleSentimentUpdateInSearch = () => {},
}) => {
  const [localStore, setLocalStore] = useState(selectedSentiment);
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  return (
    <SentimentModalWrp>
      <SentimentModalHeader>
        <SentimentModalHeading>Sentiment</SentimentModalHeading>
        <SentimentModalIconWrp onClick={() => setSentimentPopup(false)}>
          <Close color="#000" width="1rem" height="1rem" />
        </SentimentModalIconWrp>
      </SentimentModalHeader>
      <SentimentModalListWrp>
        <SentimentModalTitle>Update Sentiment</SentimentModalTitle>
        {sentimentDropDown?.map((option, i) => (
          <SentimentModalList
            key={i}
            onClick={() => {
              //   const isSentimentExists = localStore?.includes(
              //     option?.label
              //   );
              //   if (!isSentimentExists) {
              //     setLocalStore([...localStore, option?.label]);
              //   } else {
              //     const popExistingSentiment = localStore?.filter(
              //       (selectedItem) => selectedItem !== option?.label
              //     );
              //     setLocalStore(popExistingSentiment);
              //   }
              setLocalStore(option?.value);
            }}
          >
            <SentimentComponent sentiment={option?.value} />
            {/* {localStore?.includes(option?.label) && ( */}
            {(localStore.toLowerCase() === option?.label?.toLowerCase() ||
              localStore?.toLowerCase() === option?.value?.toLowerCase()) && (
              <CheckCircle width="1.25rem" height="1.25rem" fill="#5F39F8" />
            )}
          </SentimentModalList>
        ))}
      </SentimentModalListWrp>
      <SentimentModalButtonWrp>
        <Button
          title={'Cancel'}
          color={theme[selectedTheme].primary}
          backgroundColor={theme[selectedTheme].background}
          onClick={() => setSentimentPopup(false)}
        />
        <Button
          title={buttonTxt}
          backgroundColor={theme[selectedTheme].primary}
          color={theme[selectedTheme].background}
          onClick={() => {
            setSelectedSentiment && setSelectedSentiment(localStore);
            setSentimentPopup(false);
            handleSentimentUpdateInSearch(localStore);
            onChange && onChange('sentiment', localStore);
          }}
        />
      </SentimentModalButtonWrp>
    </SentimentModalWrp>
  );
};

export default SentimentModal;

SentimentModal.propTypes = {
  selectedSentiment: PropTypes.array,
  buttonTxt: PropTypes.string,
  setSentimentPopup: PropTypes.func,
  setSelectedSentiment: PropTypes.func,
  onChange: PropTypes.func,
  handleSentimentUpdateInSearch: PropTypes.func,
};
