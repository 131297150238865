import styled from 'styled-components';

export const NetworkClusterWrp = styled.div`
  width: 100%;
  height: 85vh;
  border-radius: 1rem;
  overflow-y: auto;
  ${({ widgetComponent }) =>
    widgetComponent &&
    `
display: flex;
flex-direction: row;
  height: 100%;
  overflow-y: hidden;
gap: 2rem
`}
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NetworkWidgetComponent = styled.div`
  ${({ widgetComponent }) =>
    widgetComponent &&
    `      
  width: 50%;
    overflow-y: scroll;

    `}
`;

export const NetworkLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
`;

export const NetworkClusterHeaderWrp = styled.div`
  width: 100%;
  /* height: 100%; */
  padding: 1.25rem 1.25rem 6rem 1.25rem;
  border-radius: ${({ widgetComponent }) =>
    widgetComponent ? '1rem 1rem 0rem 0' : '0rem 0rem 0rem 0'};
  background-image: url(${({ bgImg }) => bgImg});
  background-repeat: round;
`;

export const NetWorkHeaderWrp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NetworkProfileWrp = styled.div`
  width: 100%;
  height: 100%;
  max-width: 7.5rem;
  max-height: 7.5rem;
  border: 2px solid #fff;
  border-radius: 50%;
  align-items: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const NetworkImg = styled.img`
  width: 100%;
  height: 100%;
  /* max-width: 7.5rem;
  max-height: 7.5rem; */
  border-radius: 50%;
  object-fit: fill;
`;

export const NetworkProfileDetailsWrp = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

export const NetworkProfileIconWrp = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  margin-top: -0.5rem;
`;

export const NetWorkTitleIconWrp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const NetWorkTitleWrp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

export const NetWorkTitle = styled.div`
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
`;

export const NetWorkSubDetails = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #121619;
`;

export const NetworkTabsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  height: 100%;
  gap: 0.125rem;
`;

export const NetworkTabs = styled.div`
  max-height: 1.25rem;
  padding: 0.125rem 0.625rem;
  gap: 0.625rem;
  border-radius: 0.3125rem;
  background-color: ${({ theme }) => theme?.background};
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
`;

export const WrapIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #dde1e6;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const NetworkResultWrp = styled.div`
  padding: 1.25rem;
  margin-top: -4.25rem;
`;

export const NetworkSentimentWrp = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const NetworkSentimentChartWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.125rem;
`;
export const NetworkSentiment = styled.div`
  height: 2rem;
  width: ${({ barWidth }) => `${barWidth}%`};
  background-color: ${({ color }) => color};
  border-radius: 0.25rem;
`;

export const NetworkSentimentTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const NetworkSentimentLegends = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const NetworkMediaWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  padding: 0rem 1.25rem;
  /* margin-top: 1.25rem; */
`;

export const NetworkMedia = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  gap: 10px;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme?.graphColors?.coolGray20};
  box-shadow: 0px 16px 12px 0px #0000000f;
  /* width: 100%; */
  width: auto;
`;

export const NetworkMediaImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
`;

export const NetworkMediaDetailsWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

export const NetworkMediaTitle = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const NetworkMediaHandle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray80};
`;

export const NetworkContentWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.25rem;
`;

export const NetworkDiscussionChartWrp = styled.div`
  width: auto;
  height: fit-content;
  background-color: #fff;
  margin: 1.25rem;
  padding: 1.25rem;
  border: 1px solid ${({ theme }) => theme?.borderGrey};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ widgetComponent }) =>
    widgetComponent &&
    `     
 border:none;
    `}
`;

export const NetworkDiscussionContentWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const NetworkDiscussionTitleWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
`;

export const NetworkChartTitle = styled.div`
  font-family: Inter;
  font-size: 17.59px;
  font-weight: 600;
  line-height: 21.99px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const NetworkChartResults = styled.div`
  font-family: Inter;
  font-size: 14.29px;
  font-weight: 700;
  line-height: 17.59px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray60};
`;

export const NetworkChartIconWrp = styled.div``;

export const NetworkMapTabs = styled.div`
  width: 100%;
  border-bottom: 1px solid #dde1e6;
  padding: 0.25rem 1.25rem 0 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* position: absolute;
  top: -2.2rem;
  background-color: #fff; */
`;

export const NetworkMapWTabWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const NetworkTabButton = styled.button`
  background-color: #fff;
  border: none;
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props?.theme?.primary}` : 'none'};
  color: ${(props) => (props.active ? props?.theme?.primary : '#343a3f')};
  margin-right: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: auto;
  height: 40px;
  padding: 12px 10px;
  transition: all 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme?.primary};
    background-color: '#f6f2ff';
  }
`;

export const NetworkCloseWrp = styled.div`
  cursor: pointer;
`;
