import React, { useEffect, useState } from 'react';
import { NetworkMapContainer } from './index.sc';
import { HalfSlot, SlotWrp } from '../campaign-monitor-2/index.sc';
import PropTypes from 'prop-types';
import SlotDetails from '../../search-result/slot-details';
import { FullSlot } from '../author-impact/index.sc';
import NetworkClusterPopup from '../../network-cluster-popup';
import { useSelector } from 'react-redux';
import DashboardPopup from '../../dasboard-popup';
import { useNetworkKeyInfluencerData } from '../../../hooks/useAdvanceNetworkMap';
import {
  blueColorGradients,
  greenColorGradients,
  yellowColorGradients,
} from '../../../constants/graph-colors';

const NetworkMapDashboard = ({
  widget,
  dashboardType,
  handleOnClick = () => {},
  resetSelection,
  setSelectedComponent = () => {},
  selectedComponent = '',
  onDownloadChartData,
  downloadFunction,
  savedSelectedChart,
  handleGraphTitleUpdate,
  overviewDashboard,
  setNetWorkDetails = () => {},
  setAuthorLoader = () => {},
  setErrorNoData = () => {},
  setNetWorkPopup = () => {},
  errorNoData = false,
  authorLoader = false,
  networkDetails = {},
  setDrilldownOrgNames = () => {},
  isNewsletter = false,
}) => {
  // const { networkPinData } = useSelector((state) => {
  //   if (dashboardType === 'networkMap' || overviewDashboard === 'networkMap') {
  //     return state.networkMap;
  //   }
  // });

  const [subClusterTitle, setSubClusterTitle] = useState('');
  const [selectedOrgOptions, setSelectedOrgOptions] = useState();
  const [selectedAuthorsOptions, setSelectedAuthorsOptions] = useState();
  const [clusterFilteredData, setClusterFilteredData] = useState([]);
  const [interestFilterNames, setInterestFilterNames] = useState([]);
  const [authorFilteredNames, setAuthorFilteredNames] = useState([]);

  const { mutateAsync: getNetworkUserDetails } = useNetworkKeyInfluencerData();

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  const allNodeData =
    widget.networkClusterDataWidgetDetails.data?.data?.modifiedData
      ?.allNodesData;
  const allLinksData =
    widget.networkClusterDataWidgetDetails.data?.data?.modifiedData
      ?.allLinksData;

  const handleSelectedInterestDropdown = (
    selectAll,
    options,
    widgetNodeData
  ) => {
    if (Array?.isArray(options) && selectAll) {
      setSelectedOrgOptions(options);
      setDrilldownOrgNames(options);
      // filtering organization widget data
      const newNodeData = widgetNodeData?.nodes?.filter((item) =>
        options?.includes(item?.organization)
      );

      // updating links for the nodes
      const newLinkData = widgetNodeData?.links?.filter(
        (item) =>
          options?.includes(item?.source?.organization) &&
          options?.includes(item?.target?.organization)
      );

      // Updating authors selected filters
      const newAuthorNamesList = [];
      newNodeData.forEach((items) => {
        items.authors?.map((item) => newAuthorNamesList?.push(item?.author));
      });
      setSelectedAuthorsOptions([...new Set(newAuthorNamesList)]);

      // Updating nodes and links widget data
      widget.networkClusterDataWidgetDetails.data.data.data = {
        nodes: newNodeData,
        links: newLinkData,
        drillDownFlag: true,
      };
    } else {
      if (selectAll) {
        setSelectedOrgOptions([options]);
        setDrilldownOrgNames([options]);
        // filtering organization widget data
        const newNodeData = widgetNodeData?.nodes?.filter(
          (item) => item?.organization === options
        );

        // updating links for the nodes
        const newLinkData = widgetNodeData?.links?.filter(
          (item) =>
            item?.source?.organization === options &&
            item?.target?.organization === options
        );
        // Updating authors selected filters
        setSelectedAuthorsOptions(
          newNodeData[0]?.authors?.map((item) => item?.author)
        );

        // Updating nodes and links widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newNodeData,
          links: newLinkData,
          drillDownFlag: true,
        };
      } else {
        setSelectedOrgOptions(options);
        setDrilldownOrgNames(options);

        // filtering organization widget data
        const newNodeData = widgetNodeData?.nodes?.filter((item) =>
          options?.includes(item?.organization)
        );

        // This function is to get the org items where the length is one
        const filterUniqueNames = (array) => {
          const nameCount = array.reduce((acc, obj) => {
            acc[obj.organization] = (acc[obj.organization] || 0) + 1;
            return acc;
          }, {});

          return array.filter(
            (obj) =>
              nameCount[obj.organization] === 1 &&
              !options?.includes(obj?.existingOrgAuthorMentions)
          );
        };

        // The 1st condition is to bring the missing items for the given unique org
        const newUpdateNodeData =
          filterUniqueNames(newNodeData)?.length > 0
            ? [
                ...(allNodeData?.filter(
                  (item) =>
                    !item?.id?.includes('org') &&
                    filterUniqueNames(newNodeData)?.some(
                      (nodeItem) =>
                        item?.organization === nodeItem?.organization &&
                        options?.includes(item?.organization)
                    )
                ) || []),
                ...newNodeData,
              ]
            : newNodeData;

        // updating links for the nodes
        const newLinkData = widgetNodeData?.links?.filter(
          (item) =>
            options?.includes(item?.source?.organization) &&
            options?.includes(item?.target?.organization)
        );

        const newUpdateLinkData =
          filterUniqueNames(newNodeData)?.length > 0
            ? [
                ...allLinksData?.filter(
                  (items) =>
                    newUpdateNodeData?.some(
                      (item) =>
                        item?.id === items?.source ||
                        item?.id === items?.source?.id
                    ) &&
                    newUpdateNodeData?.some(
                      (item) =>
                        item?.id === items?.target ||
                        item?.id === items?.target?.id
                    )
                ),
              ]
            : newLinkData?.every(
                (items) =>
                  newUpdateNodeData?.some((item) =>
                    item?.id?.includes(items?.source?.id)
                  ) &&
                  newUpdateNodeData?.some((item) =>
                    item?.id?.includes(items?.target?.id)
                  )
              )
            ? [
                ...widgetNodeData?.links?.filter(
                  (items) =>
                    newUpdateNodeData?.some(
                      (item) => item?.id === items?.source?.id
                    ) &&
                    newUpdateNodeData?.some(
                      (item) => item?.id === items?.target?.id
                    )
                ),
              ]
            : newLinkData;

        // Updating authors selected filters
        const newAuthorNamesList = [];
        newUpdateNodeData.forEach((items) => {
          items.authors?.map((item) => newAuthorNamesList?.push(item?.author));
        });
        setSelectedAuthorsOptions([...new Set(newAuthorNamesList)]);

        // Updating nodes and links widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newUpdateNodeData,
          links: newUpdateLinkData,
          drillDownFlag: true,
        };
      }
    }
  };

  const handleSelectedAuthorsDropdown = (
    selectAll,
    options,
    initialClusterData,
    widgetNodeData
  ) => {
    if (Array?.isArray(options) && selectAll) {
      setSelectedAuthorsOptions(options);
      // filtering author widget data
      const newNodeData = initialClusterData?.nodes?.filter(
        (item) =>
          options?.includes(item?.label) ||
          item?.authors?.some(
            (authItem) =>
              options?.includes(authItem?.author) &&
              (item?.author
                ? options?.includes(item?.author) &&
                  item?.label === item?.organization
                : item?.label === item?.organization)
          ) ||
          options?.includes(item?.author)
      );

      // updating links for the nodes
      const newLinkData = initialClusterData?.links?.filter(
        (item) =>
          (options?.includes(item?.source?.label) ||
            options?.includes(item?.target?.label)) &&
          (item?.source?.label === item?.source?.organization ||
            options?.includes(item?.target?.author))
      );

      // Updating organization selected filters
      setSelectedOrgOptions([
        ...new Set(
          initialClusterData?.nodes
            ?.filter(
              (item) =>
                item?.authors?.some(
                  (authItem) =>
                    options?.includes(authItem?.author) &&
                    (item?.author
                      ? options?.includes(item?.author) &&
                        item?.label === item?.organization
                      : item?.label === item?.organization)
                ) || options?.includes(item?.author)
            )
            ?.map((mapItem) => mapItem?.organization)
        ),
      ]);
      setDrilldownOrgNames([
        ...new Set(
          initialClusterData?.nodes
            ?.filter(
              (item) =>
                item?.authors?.some(
                  (authItem) =>
                    options?.includes(authItem?.author) &&
                    (item?.author
                      ? options?.includes(item?.author) &&
                        item?.label === item?.organization
                      : item?.label === item?.organization)
                ) || options?.includes(item?.author)
            )
            ?.map((mapItem) => mapItem?.organization)
        ),
      ]);

      // Updating author widget data
      widget.networkClusterDataWidgetDetails.data.data.data = {
        nodes: newNodeData,
        links: newLinkData,
        drillDownFlag: true,
      };
    } else {
      if (selectAll) {
        setSelectedAuthorsOptions([options]);

        // filtering author widget data
        const newNodeData = initialClusterData?.nodes?.filter(
          (item) =>
            item?.label === options ||
            item?.authors?.some(
              (authItem) =>
                authItem?.author === options &&
                (item?.author
                  ? item?.author === options &&
                    item?.label === item?.organization
                  : item?.label === item?.organization)
            ) ||
            item?.author === options
        );

        // updating links for the nodes
        const newLinkData = initialClusterData?.links?.filter(
          (item) =>
            (item?.source?.label === options ||
              item?.target?.label === options) &&
            (item?.source?.label === item?.source?.organization ||
              item?.target?.author === options)
        );

        // Updating organization selected filters
        setSelectedOrgOptions([
          ...new Set(
            initialClusterData?.nodes
              ?.filter(
                (item) =>
                  item?.authors?.some(
                    (authItem) =>
                      authItem?.author === options &&
                      (item?.author
                        ? item?.author === options &&
                          item?.label === item?.organization
                        : item?.label === item?.organization)
                  ) || item?.author === options
              )
              ?.map((mapItem) => mapItem?.organization)
          ),
        ]);
        setDrilldownOrgNames([
          ...new Set(
            initialClusterData?.nodes
              ?.filter(
                (item) =>
                  item?.authors?.some(
                    (authItem) =>
                      authItem?.author === options &&
                      (item?.author
                        ? item?.author === options &&
                          item?.label === item?.organization
                        : item?.label === item?.organization)
                  ) || item?.author === options
              )
              ?.map((mapItem) => mapItem?.organization)
          ),
        ]);

        // Updating author widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newNodeData,
          links: newLinkData,
          drillDownFlag: true,
        };
      } else {
        setSelectedAuthorsOptions(options);
        // filtering author widget data
        const newNodeData = initialClusterData?.nodes?.filter(
          (item) =>
            options?.includes(item?.label) ||
            item?.authors?.some(
              (authItem) =>
                options?.includes(authItem?.author) &&
                (item?.author
                  ? options?.includes(item?.author) &&
                    item?.label === item?.organization
                  : item?.label === item?.organization)
            ) ||
            options?.includes(item?.author)
        );

        // updating links for the nodes
        const newLinkData = initialClusterData?.links?.filter(
          (item) =>
            (options?.includes(item?.source?.label) ||
              options?.includes(item?.target?.label)) &&
            (item?.source?.label === item?.source?.organization ||
              options?.includes(item?.target?.author))
        );

        // Updating organization selected filters
        setSelectedOrgOptions([
          ...new Set(
            initialClusterData?.nodes
              ?.filter(
                (item) =>
                  item?.authors?.some(
                    (authItem) =>
                      options?.includes(authItem?.author) &&
                      (item?.author
                        ? options?.includes(item?.author) &&
                          item?.label === item?.organization
                        : item?.label === item?.organization)
                  ) || options?.includes(item?.author)
              )
              ?.map((mapItem) => mapItem?.organization)
          ),
        ]);
        setDrilldownOrgNames([
          ...new Set(
            initialClusterData?.nodes
              ?.filter(
                (item) =>
                  item?.authors?.some(
                    (authItem) =>
                      options?.includes(authItem?.author) &&
                      (item?.author
                        ? options?.includes(item?.author) &&
                          item?.label === item?.organization
                        : item?.label === item?.organization)
                  ) || options?.includes(item?.author)
              )
              ?.map((mapItem) => mapItem?.organization)
          ),
        ]);

        // Updating author widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newNodeData,
          links: newLinkData,
          drillDownFlag: true,
        };
      }
    }
    // setSelectedAuthorsOptions((prev) => {
    //   if (selectedOrgOptions?.includes(options)) {
    //     return selectedAuthorsOptions?.filter((item) => item !== options);
    //   } else {
    //     return [...prev, options];
    //   }
    // });
  };

  const clusterHandleClick = async (drillDownData) => {
    if (drillDownData?.networkPopupFlag) {
      try {
        setNetWorkPopup(true);
        setAuthorLoader(true);
        const networkResp = await getNetworkUserDetails({
          username: drillDownData?.username?.[0],
        });
        if (networkResp === 'No Data') {
          setErrorNoData(true);
          return;
        }

        setNetWorkDetails({
          ...networkResp?.data,
          author_name: drillDownData?.username?.[0],
          authorId: drillDownData?.author_id?.[0],
        });
      } catch (error) {
        console.log({ error });
        setAuthorLoader(false);
      } finally {
        setAuthorLoader(false);
      }

      // setSubClusterTitle();
    } else {
      const groupData = drillDownData?.group;
      // org Names
      setDrilldownOrgNames(
        drillDownData?.clusterOrg?.map((item) => item?.organization)
      );

      const newChildData = Object?.keys(
        widget.networkClusterDataWidgetDetails?.data?.data?.data
      )?.reduce((acc, item) => {
        const filteredData =
          widget.networkClusterDataWidgetDetails?.data?.data?.data[
            item
          ]?.filter((clusterData) => {
            return (
              (item === 'links' &&
                clusterData?.source?.group === groupData &&
                !clusterData?.source?.id?.includes('category') &&
                clusterData?.target?.group === groupData) ||
              (clusterData?.group === groupData &&
                !clusterData?.id?.includes('category'))
            );
          });

        if (filteredData.length > 0) {
          acc[item] = filteredData;
        }

        return acc;
      }, {});

      // Update state instead of pushing to array
      setClusterFilteredData(newChildData || []);

      // Filter Options for network map
      setInterestFilterNames(
        newChildData?.nodes?.reduce((acc, item) => {
          if (!acc.some((obj) => obj.label === item.organization)) {
            acc.push({
              label: item.organization,
              value: item.organization,
              count: item.author_count,
            });
          }
          return acc;
        }, [])
      );

      setSelectedOrgOptions([
        ...new Set(newChildData?.nodes?.map((item) => item?.organization)),
      ]);

      const authorFilterDetails = Object.values(
        newChildData?.nodes.reduce((acc, item) => {
          if (!acc[item.organization]) {
            acc[item.organization] = item;
          }
          return acc;
        }, {})
      );

      setAuthorFilteredNames(
        Array.from(
          authorFilterDetails
            ?.reduce((acc, item) => {
              item?.authors?.forEach((author) => {
                if (!acc.has(author.author)) {
                  acc.set(author.author, {
                    label: author.author,
                    value: author.author,
                    count: author.count,
                  });
                }
              });
              return acc;
            }, new Map())
            .values() // Convert Map values to an array
        )
      );
      setSelectedAuthorsOptions([
        ...new Set(
          authorFilterDetails?.flatMap((item) =>
            item.authors ? item.authors.map((author) => author.author) : []
          )
        ),
      ]);
      widget.networkClusterDataWidgetDetails.data.data.labels = [
        { label: 'Author', value: 'Author', color: blueColorGradients?.blue50 },
        {
          label: 'Tagged Entity',
          value: 'Tagged Entity',
          color: greenColorGradients?.green50,
        },
        {
          label: 'Industry',
          value: 'Industry',
          color: yellowColorGradients?.yellow40,
        },
      ];
      widget.networkClusterDataWidgetDetails.data.data.data = {
        ...newChildData,
        drillDownFlag: true,
      };
      setSubClusterTitle(newChildData?.nodes[0]?.category);
      widget.networkClusterDataWidgetDetails.data.data.subClusterTitle =
        newChildData?.nodes[0]?.label;
      // setDrillDownCluster({ ...newChildData, drillDownFlag: true });
    }
  };

  return (
    <NetworkMapContainer>
      <SlotWrp dashboard={true}>
        {widget &&
          Object?.keys(widget)?.map((networkKey, id) => {
            const SlotComponent =
              dashboardType !== 'newsletter' &&
              ((widget?.[networkKey]?.data?.slotType === 'half' &&
                dashboardType) ||
                (widget?.[networkKey]?.data?.isLoading &&
                  (widget?.[networkKey]?.data?.customClassName !==
                    'networkMap_sentiment_breakdown' ||
                    widget?.[networkKey]?.data?.customClassName !==
                      'networkMap_topics_discussion') &&
                  networkKey !== 'networkClusterDataWidgetDetails'))
                ? HalfSlot
                : FullSlot;
            let savedChartConfig = {};
            const chartData = savedSelectedChart?.find(
              (chart) =>
                chart?.chartId === widget?.[networkKey]?.data?.customClassName
            );

            if (chartData) {
              savedChartConfig = {
                chartName: chartData?.chartName,
                chartType: chartData?.chartType
                  ? chartData?.chartType
                  : widget?.[networkKey]?.data?.graphType,
              };
            } else {
              savedChartConfig = {
                chartName: widget?.[networkKey]?.data?.title,
                chartType: widget?.[networkKey]?.data?.graphType,
              };
            }

            return widget?.[networkKey]?.data?.show ? (
              <SlotComponent
                className="graph-widget"
                key={`widget-${id}`}
                dashboardType={networkKey !== 'topEngagingPosts'}
                networkCluster={
                  networkKey === 'networkClusterDataWidgetDetails' &&
                  SlotComponent === FullSlot
                }
                height={
                  dashboardType === 'newsletter' ||
                  networkKey === 'networkmap_cluster' ||
                  networkKey === 'networkClusterDataWidgetDetails'
                    ? '42rem'
                    : '26rem'
                }
              >
                {/* {networkKey !== 'networkPinData' && ( */}
                <SlotDetails
                  widget={widget?.[networkKey]?.data}
                  loader={widget?.[networkKey]?.data?.isLoading}
                  dashboardType={dashboardType}
                  legend={
                    widget?.[networkKey]?.data?.customClassName ===
                      'networkMap_sentiment_breakdown' ||
                    widget?.[networkKey]?.data?.customClassName ===
                      'networkmap_cluster'
                  }
                  handleOnClick={handleOnClick}
                  resetSelection={resetSelection}
                  actionOption={true}
                  editOption={true}
                  setSelectedComponent={setSelectedComponent}
                  selectedComponent={selectedComponent}
                  onDownloadChartData={onDownloadChartData}
                  downloadFunction={downloadFunction}
                  widgetClassName={widget?.[networkKey]?.data?.customClassName}
                  editChart={savedChartConfig}
                  handleGraphTitleUpdate={handleGraphTitleUpdate}
                  helperText={widget?.[networkKey]?.data?.text}
                  subClusterTitle={subClusterTitle}
                  clusterHandleClick={clusterHandleClick}
                  setSubClusterTitle={setSubClusterTitle}
                  footerMarginTop={
                    networkKey === 'networkClusterDataWidgetDetails' && '-2rem'
                  }
                  authorFilterNames={authorFilteredNames}
                  interestFilterNames={interestFilterNames}
                  handleSelectedInterestDropdown={
                    handleSelectedInterestDropdown
                  }
                  handleSelectedAuthorsDropdown={handleSelectedAuthorsDropdown}
                  selectedOrgOptions={selectedOrgOptions}
                  setSelectedOrgOptions={setSelectedOrgOptions}
                  selectedAuthorsOptions={selectedAuthorsOptions}
                  setSelectedAuthorsOptions={setSelectedAuthorsOptions}
                  clusterFilteredData={clusterFilteredData}
                  setDrilldownOrgNames={setDrilldownOrgNames}
                  isNewsletter={isNewsletter}
                />
                {/* )} */}
              </SlotComponent>
            ) : (
              <></>
            );
          })}
        {/* {networkPinData?.length > 0 &&
          networkPinData?.map((networkData, id) => (
            <FullSlot key={id} dashboardType={true} padding={false}>
              <NetworkClusterPopup
                popupData={networkData}
                widgetComponent={true}
                networkId={id}
                authorLoader={authorLoader}
              />
            </FullSlot>
          ))} */}
      </SlotWrp>
    </NetworkMapContainer>
  );
};

export default NetworkMapDashboard;

NetworkMapDashboard.propTypes = {
  widget: PropTypes.object,
  dashboardType: PropTypes?.string,
  overviewDashboard: PropTypes?.string,
  handleOnClick: PropTypes?.func,
  resetSelection: PropTypes?.bool,
  setSelectedComponent: PropTypes?.func,
  selectedComponent: PropTypes?.string,
  onDownloadChartData: PropTypes?.func,
  downloadFunction: PropTypes?.func,
  savedSelectedChart: PropTypes?.object,
  handleGraphTitleUpdate: PropTypes?.func,
  setNetWorkDetails: PropTypes.func,
  networkDetails: PropTypes.object,
  setNetWorkPopup: PropTypes.func,
  authorLoader: PropTypes.bool,
  errorNoData: PropTypes.bool,
  setAuthorLoader: PropTypes.func,
  setErrorNoData: PropTypes.func,
  setDrilldownOrgNames: PropTypes.func,
  isNewsletter: PropTypes.bool,
};
