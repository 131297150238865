import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AnalsisLeftContainers,
  AnalysisArticlesWrp,
  AnalysisBackgroundContainer,
  AnalysisDate,
  AnalysisGrpArticleWrp,
  AnalysisMarkDownText,
  AnalysisMatchingTitle,
  AnalysisMatchingWords,
  AnalysisMatchingWordWrp,
  AnalysisMatchingWrp,
  AnalysisPopupBtnsWrp,
  AnalysisPopupContainer,
  AnalysisTitle,
  AnalysisTitleWrp,
  AnalysisWidget,
  AnalysisWidgetWrp,
  CreateDashboardBtn,
  CreateDashboardTxt,
  IconAddWrp,
  SimilarAnalysisGrpArticleWrp,
  SimilarArticlesWrap,
  StoryLoaderWrap,
  NoDataText,
} from './index.sc';
import { Button } from '../button';
import Articles from '../articles';
import SlotDetails from '../search-result/slot-details';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../constants/theme';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../search-result/dashboard-section/dashboard-header-v2/index.sc';
import NewsletterAddCricle from '../../assets/icons/NewsletterAddCricle';
import AddContentCricle from '../../assets/icons/AddContentCricle';
import { format } from 'date-fns';
import { resetTabData as resetStoryAnalysisTabData } from '../../redux/slices/storyAnalysisDashboardSlice';

const StoryAnalysisPopup = ({
  setToggle,
  setSimilarStoriesData,
  setStoryAnalysisData,
  activeButton,
  setActiveButton,
  articles,
  similarAnalysisLength,
  isStoryLoading,
  articlesRecentSearchId,
  storyAnalysisData,
  similarStoriesData,
  similarStoriesIdsLength,
  errorMessage,
  closeArticlePopup,
  filters,
  networkMapArticle,
  storyAnalysisResultsOverTimeDataFetching,
  setErrorMessage,
  setSimilarStoriesIds,
  similarStoriesIdCaptured,
}) => {
  const [newOpen, setNewOpen] = useState(true);
  const [similarStoryData, setSimilarStoryData] = useState([]);
  const [similarLoader, setSimilarLoader] = useState(false);

  const { searchId, dashboardType, dashboardId } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleNavigate = (e) => {
    e.preventDefault();
    const storyAnalysisURL =
      storyAnalysisData &&
      storyAnalysisData?.articleData?.map((article) => article?.url);
    const storyAnalysisArticleIds =
      storyAnalysisData &&
      storyAnalysisData?.articleData?.map((article) => article?.articleId);
    const similarAnalysisURL =
      similarStoryData &&
      similarStoryData
        .map((similarStory) => {
          return similarStory?.articleData?.map((article) => article?.url);
        })
        ?.flat();
    const similarAnalysisArticleIds =
      similarStoryData &&
      similarStoryData
        .map((similarStory) => {
          return similarStory?.articleData?.map(
            (article) => article?.articleId
          );
        })
        ?.flat();
    dispatch(resetStoryAnalysisTabData());

    navigate(`/dashboard/${parseInt(searchId)}/storyAnalysis`, {
      state: {
        ...location?.state?.filters,
        filters: Array.isArray(location?.state?.filters)
          ? location?.state?.filters?.[0]?.dashboard_filters
          : location?.state?.filters?.filter ?? filters,
        recent_searchId:
          location?.state?.filters?.recent_search_id || articlesRecentSearchId,
        search_name: location?.state?.savedSearchData?.title,
        articleStoryLinks: [
          ...([...new Set(storyAnalysisURL)] || []),
          ...([...new Set(similarAnalysisURL)] || []),
        ],
        articleStoryIds: [
          ...([...new Set(storyAnalysisArticleIds)] || []),
          ...([...new Set(similarAnalysisArticleIds)] || []),
        ],
        // activeTab,
      },
    });
    if (dashboardType === 'storyAnalysis' && !dashboardId) {
      closeArticlePopup();
      setToggle(false);
      window.location.reload();
    }
  };

  const analysisPopRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      analysisPopRef.current &&
      !analysisPopRef.current.contains(event.target)
    ) {
      setToggle(false);
      setSimilarStoriesData(null);
      setStoryAnalysisData(null);
      setNewOpen(false);
      setErrorMessage('');
      setSimilarStoriesIds([]);
      setSimilarStoryData([]);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSimilarLoader(true);
    if (Array.isArray(articles)) {
      if (similarStoriesIdCaptured?.length > 0) {
        const fileredData = articles?.filter((similarData, id) => {
          return similarStoriesIdCaptured?.some(
            (item) => similarData?.articleData?.[0]?.articleId === item
          );
        });
        setSimilarLoader(false);

        setSimilarStoryData(fileredData);
      } else {
        setSimilarStoryData([]);
        !storyAnalysisResultsOverTimeDataFetching &&
          setErrorMessage('No Similar Stories');
        setSimilarLoader(false);
      }
    }
  }, [similarStoriesIdCaptured, articles]);

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const dateFormat = (date) => {
    const setDate = new Date(date);
    return format(setDate, 'MMM dd, yyyy');
  };

  return (
    <AnalysisBackgroundContainer
      isVisible={newOpen}
      netWork={networkMapArticle}
    >
      <AnalysisPopupContainer ref={analysisPopRef}>
        <AnalysisPopupBtnsWrp>
          <AnalsisLeftContainers>
            <Button
              title={'Story Analysis'}
              backgroundColor={
                activeButton === 'Story Analysis'
                  ? theme[selectedTheme]?.primary
                  : theme[selectedTheme]?.background
              }
              border={
                activeButton !== 'Story Analysis' &&
                theme[selectedTheme]?.primary
              }
              color={
                activeButton !== 'Story Analysis'
                  ? theme[selectedTheme]?.primary
                  : theme[selectedTheme]?.background
              }
              onClick={() => setActiveButton('Story Analysis')}
            />
            <Button
              title={'Similar Stories'}
              border={
                activeButton !== 'Similar Stories' &&
                theme[selectedTheme]?.primary
              }
              backgroundColor={
                activeButton === 'Similar Stories'
                  ? theme[selectedTheme]?.primary
                  : theme[selectedTheme]?.background
              }
              color={
                activeButton !== 'Similar Stories'
                  ? theme[selectedTheme]?.primary
                  : theme[selectedTheme]?.background
              }
              onClick={() => setActiveButton('Similar Stories')}
            />
          </AnalsisLeftContainers>

          <CreateDashboardBtn onClick={handleNavigate}>
            <IconAddWrp>
              <AddContentCricle white={theme[selectedTheme]?.primary} />
            </IconAddWrp>

            <CreateDashboardTxt>Create Story Dashboard</CreateDashboardTxt>
            {/* <Button
              title={'Create Story Dashboard'}
              border={theme[selectedTheme]?.primary}
              backgroundColor={theme[selectedTheme]?.background}
              color={theme[selectedTheme]?.primary}
              onClick={handleNavigate}
            /> */}
          </CreateDashboardBtn>
        </AnalysisPopupBtnsWrp>
        {activeButton !== 'Story Analysis' &&
          errorMessage === '' &&
          similarStoryData?.length === 0 &&
          (similarLoader || storyAnalysisResultsOverTimeDataFetching) && (
            <StoryLoaderWrap>
              <Loader size="0.25rem" width="1.4rem" height="1.4rem" />
            </StoryLoaderWrap>
          )}
        {activeButton !== 'Story Analysis' &&
          similarStoryData?.length === 0 &&
          errorMessage !== '' && (
            <StoryLoaderWrap>
              <NoDataText>{errorMessage}</NoDataText>
            </StoryLoaderWrap>
          )}
        {activeButton === 'Story Analysis' &&
          !articles?.articleData?.length && (
            <StoryLoaderWrap>
              {errorMessage && !storyAnalysisResultsOverTimeDataFetching ? (
                <NoDataText>{errorMessage}</NoDataText>
              ) : (
                <Loader size="0.25rem" width="1.4rem" height="1.4rem" />
              )}
            </StoryLoaderWrap>
          )}
        {Array.isArray(similarStoryData) &&
        similarStoryData?.length > 0 &&
        activeButton !== 'Story Analysis' ? (
          <SimilarArticlesWrap>
            {similarStoryData?.map((similarStory, id) => (
              <SimilarAnalysisGrpArticleWrp key={id}>
                <React.Fragment>
                  {similarStory?.articleData?.length > 0 &&
                    similarStory?.graphContent?.map((graphItem, grhId) => (
                      <AnalysisWidgetWrp key={grhId}>
                        <AnalysisTitleWrp>
                          <AnalysisTitle>{graphItem?.title}</AnalysisTitle>
                          <AnalysisDate>
                            {`${dateFormat(graphItem?.startDate)} -
                              ${dateFormat(graphItem?.endDate)}
                            `}
                          </AnalysisDate>
                        </AnalysisTitleWrp>

                        <AnalysisWidget>
                          <SlotDetails
                            widget={graphItem?.widgetData}
                            storyAnalysisChart={true}
                          />
                        </AnalysisWidget>
                        <AnalysisMarkDownText>
                          {graphItem?.markDownSummary}
                        </AnalysisMarkDownText>
                        <AnalysisMatchingWrp>
                          <AnalysisMatchingTitle>
                            {graphItem?.keywordsTitle}
                          </AnalysisMatchingTitle>
                          <AnalysisMatchingWordWrp>
                            {graphItem?.matchingKeywords?.map(
                              (keywords, id) => (
                                <AnalysisMatchingWords
                                  target="_blank"
                                  href={keywords?.domain}
                                  title={keywords?.domain}
                                  key={id}
                                >
                                  {keywords?.source}
                                </AnalysisMatchingWords>
                              )
                            )}
                          </AnalysisMatchingWordWrp>
                        </AnalysisMatchingWrp>
                      </AnalysisWidgetWrp>
                    ))}
                  {/* Articles */}
                  {similarStory?.articleData?.length > 0 && (
                    <AnalysisArticlesWrp>
                      <Articles
                        storyAnalysis={true}
                        articles={similarStory?.articleData}
                      />
                    </AnalysisArticlesWrp>
                  )}
                </React.Fragment>
              </SimilarAnalysisGrpArticleWrp>
            ))}
            {similarStoryData?.length !== similarStoriesIdsLength && (
              <StoryLoaderWrap>
                <Loader size="0.25rem" width="1.4rem" height="1.4rem" />
              </StoryLoaderWrap>
            )}
          </SimilarArticlesWrap>
        ) : (
          <AnalysisGrpArticleWrp>
            <React.Fragment>
              {articles?.graphContent?.map((graphItem, grhId) => (
                <AnalysisWidgetWrp key={grhId}>
                  <AnalysisTitleWrp>
                    <AnalysisTitle>{graphItem?.title}</AnalysisTitle>
                    <AnalysisDate>
                      {`${dateFormat(graphItem?.startDate)} -
                              ${dateFormat(graphItem?.endDate)}
                            `}
                    </AnalysisDate>
                  </AnalysisTitleWrp>

                  <AnalysisWidget>
                    <SlotDetails
                      widget={graphItem?.widgetData}
                      storyAnalysisChart={true}
                    />
                  </AnalysisWidget>
                  <AnalysisMarkDownText>
                    {graphItem?.markDownSummary}
                  </AnalysisMarkDownText>
                  <AnalysisMatchingWrp>
                    <AnalysisMatchingTitle>
                      {graphItem?.keywordsTitle}
                    </AnalysisMatchingTitle>
                    <AnalysisMatchingWordWrp>
                      {graphItem?.matchingKeywords?.map((keywords, id) => (
                        <AnalysisMatchingWords
                          target="_blank"
                          href={keywords?.domain}
                          title={keywords?.domain}
                          key={id}
                        >
                          {keywords?.source}
                        </AnalysisMatchingWords>
                      ))}
                    </AnalysisMatchingWordWrp>
                  </AnalysisMatchingWrp>
                </AnalysisWidgetWrp>
              ))}
              {/* Articles */}
              <AnalysisArticlesWrp>
                <Articles
                  articles={articles?.articleData}
                  storyAnalysisArticle={true}
                />
              </AnalysisArticlesWrp>
            </React.Fragment>
          </AnalysisGrpArticleWrp>
        )}
      </AnalysisPopupContainer>
    </AnalysisBackgroundContainer>
  );
};

export default StoryAnalysisPopup;

StoryAnalysisPopup.propTypes = {
  setToggle: PropTypes.func,
  setSimilarStoriesData: PropTypes.func,
  setStoryAnalysisData: PropTypes.func,
  articles: PropTypes.func,
  setActiveButton: PropTypes.func,
  activeButton: PropTypes.func,
  similarAnalysisLength: PropTypes.number,
  isStoryLoading: PropTypes.bool,
  articlesRecentSearchId: PropTypes.number,
  similarStoriesData: PropTypes.array,
  storyAnalysisData: PropTypes.object,
  similarStoriesIdsLength: PropTypes.number,
  errorMessage: PropTypes.string,
  closeArticlePopup: PropTypes.func,
  setErrorMessage: PropTypes.func,
  filters: PropTypes.object,
  networkMapArticle: PropTypes.bool,
  storyAnalysisResultsOverTimeDataFetching: PropTypes.bool,
  setSimilarStoriesIds: PropTypes.func,
  similarStoriesIdCaptured: PropTypes.array,
};
