import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { actualOneD } from '../utils/mockData';
import ResizeHandlerHOC from '../utils/resizeHandlerHOC';
import BestBarGraph from './bestBar.graph';
import { GraphContainer, GraphWrp } from '../../styles/index.sc';

const BestBar = (props) => {
  const refElement = useRef(null);
  const graphRef = useRef(null);

  useEffect(() => {
    let graph = graphRef.current;

    if (props.data) {
      if (!graph) {
        graphRef.current = new BestBarGraph(refElement.current);
        graph = graphRef.current;
      }
      graph.setConfig({ ...props.config });
      graph.setData(props.data || actualOneD);
      graph.drawGraph();
    }
  }, [props.config, props.data, props.rerender]);

  useEffect(() => {
    let graph = graphRef.current;

    if (!graph) {
      graphRef.current = new BestBarGraph(refElement.current);
      graph = graphRef.current;
    }
    if (props.resetSelection) {
      graph.onResetFunc();
    }
  }, [props.resetSelection]);

  return (
    <GraphContainer
      isBrandSwitchSentimentAnalysisChart={
        props?.config?.component == 'brand_switch_sentiment_analysis'
      }
      authorDataLength={props?.config?.authorDataLength}
      authorCharts={
        props?.config?.component === 'top_author' ||
        props?.config?.component === 'industry_coverage_by_journalist' ||
        props?.config?.component === 'author_impact' ||
        props?.config?.component === 'people_top_author'
      }
      className="best-bar graph-container"
    >
      <GraphWrp
        authorDataLength={props?.config?.authorDataLength}
        authorCharts={
          props?.config?.component === 'top_author' ||
          props?.config?.component === 'industry_coverage_by_journalist' ||
          props?.config?.component === 'author_impact' ||
          props?.config?.component === 'people_top_author'
        }
        className="graph-wrp"
        ref={refElement}
      />
    </GraphContainer>
  );
};

BestBar.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  rerender: PropTypes.bool,
  resetSelection: PropTypes.bool,
};
export default ResizeHandlerHOC(BestBar);
