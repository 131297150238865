import { useMutation, useQuery } from '@tanstack/react-query';
import { get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import { addCountPrefix, calculatePercentage } from '../constants/utils';
import { networkClusterMapData } from './data/advancedDashboardData';
import { sentimentChartMapData as tempSentimentChartMapData } from './data/chartData';

import { topThemeChartMapData as TempTopThemeChartMapData } from './data/advancedSocialCampaign';
import {
  blueColorGradients,
  greenColorGradients,
  yellowColorGradients,
} from '../constants/graph-colors';
import { networkClusterColors } from '../graphs/utils/graphConst';

const getNetworkClusterData = async (payload) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;

  try {
    const {
      data: response,
      isSuccessful,
      // message,
    } = await get(
      `${API}/dashboard-influencer/network-map-chart?${queryString}`,
      {}
    );

    if (!isSuccessful) {
      return {
        data: {
          title: 'NetworkMap',
          shouldShowGraph: false,
          summary: { value: '0' },
        },
      };
    }
    let allNodesData = [];
    const allLinksData = [];

    const nodesData = response?.data
      // ?.slice(0, 5)
      ?.flatMap((categoryData, categoryIndex) => {
        const clusterName = Object.keys(categoryData)[0];
        const items = categoryData[clusterName];
        const orgNodesData = [];

        const clusterSize =
          Math.floor(
            5 *
              (items?.length > 10
                ? items?.length + 0.1 * items?.length
                : items?.length)
          ) <= 40
            ? Math.floor(
                5 *
                  (items?.length > 10
                    ? items?.length + 0.1 * items?.length
                    : items?.length)
              ) + 35
            : Math.floor(
                5 *
                  (items?.length > 10
                    ? items?.length + 0.1 * items?.length
                    : items?.length)
              );

        orgNodesData.push({
          ...items,
          id: `category_${clusterName}_${categoryIndex}`,
          group: categoryIndex,
          category: clusterName,
          size: clusterSize,
          label: clusterName,
          totalAuthorCount: items?.reduce(
            (acc, cur) => (acc += cur?.author_count),
            0
          ),
          clusterOrg: items,
        });

        items.forEach((item, id) => {
          // Add organization node
          const existingOrgAuthorMentions = () => {
            const existingNode = orgNodesData.find((node) => {
              return (
                JSON.stringify(item?.authors) ===
                  JSON.stringify(node?.orgAuthors) && items?.length > 1
              );
            });
            return existingNode ? existingNode?.organization : '';
          };

          orgNodesData.push({
            ...item,
            id: `org_${item?.organization}_${clusterName}_${categoryIndex}_${id}`,
            group: categoryIndex,
            category: clusterName,
            size: orgNodesData?.[0]?.size / 3,
            label: item?.organization,
            orgColor: yellowColorGradients?.yellow40,
            totalAuthorCount: item?.author_count,
            clusterOrg: items,
            orgAuthors: item?.authors,
            existingOrgAuthorMentions: existingOrgAuthorMentions(),
          });

          // Add author nodes
          if (item?.authors) {
            item.authors.forEach((authorItem, authorIndex) => {
              // Check if authorItem already exists in orgNodesData

              const existingAuthorNodeID = () => {
                const existingNode = orgNodesData.find((node) => {
                  return (
                    node?.label === authorItem?.author &&
                    JSON.stringify(item?.authors) ===
                      JSON.stringify(node?.authors) &&
                    JSON.stringify(node?.mentioned) ===
                      JSON.stringify(authorItem?.mentions) &&
                    items?.length > 1
                  );
                });

                return existingNode
                  ? { authId: existingNode.id, exists: false }
                  : {
                      authId: `author_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`,
                      exists: true,
                    };
              };

              orgNodesData.push({
                ...item,
                mentioned: authorItem?.mentions,
                id: existingAuthorNodeID()?.authId,
                username: authorItem?.username,
                author_id: authorItem?.author_id,
                group: categoryIndex,
                category: clusterName,
                parentOrg: existingAuthorNodeID()?.exists,
                orgId: existingAuthorNodeID()?.authId,
                size:
                  authorItem?.mentions?.length > 0
                    ? Math.floor(
                        Math.random() *
                          5 *
                          (authorItem?.mentions?.length / 2 || 1)
                      ) + 12
                    : Math.floor(Math.random() * (15 - 10 + 1)) + 10,
                label: authorItem?.author,
                authorColor: blueColorGradients?.blue50,
                clusterOrg: items,
              });

              if (authorItem?.mentions) {
                authorItem.mentions.forEach((authorMention, mentionId) => {
                  const existingMentionNodeId = () => {
                    const existingNode = orgNodesData.find(
                      (node) =>
                        node?.label === authorMention &&
                        node?.author === authorItem?.author &&
                        JSON.stringify(item?.authors) ===
                          JSON.stringify(node?.authors) &&
                        items?.length > 1
                    );
                    return existingNode
                      ? { mentId: existingNode.id, exists: false }
                      : {
                          mentId: `mentions_${authorMention}_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`,
                          exists: true,
                        };
                  };
                  orgNodesData.push({
                    ...item,
                    ...authorItem,
                    id: existingMentionNodeId()?.mentId,
                    group: categoryIndex,
                    parentOrg: existingMentionNodeId()?.exists,
                    orgId: existingMentionNodeId()?.mentId,
                    category: clusterName,
                    size:
                      authorItem?.mentions?.length > 0
                        ? Math.floor(
                            Math.random() *
                              5 *
                              (authorItem?.mentions?.length / 3 || 1)
                          ) + 10
                        : Math.floor(Math.random() * (15 - 10 + 1)) + 10,
                    label: authorMention,
                    mentionColor: greenColorGradients?.green50,
                    clusterOrg: items,
                  });
                });
              }
            });
          }
        });

        const idMap = {};
        const uniqueOrgNodesData = orgNodesData.filter((orgItem) => {
          if (!orgItem?.id || idMap[orgItem.id]) return false;
          idMap[orgItem.id] = true;
          return true;
        });
        allNodesData = [...allNodesData, ...orgNodesData];

        return uniqueOrgNodesData;
      });

    const linkData = response?.data
      // ?.slice(0, 5)
      ?.flatMap((categoryData, categoryIndex) => {
        const linkNodes = [];

        const clusterName = Object.keys(categoryData)[0];
        const items = categoryData[clusterName];
        const categoryNodeId = `category_${clusterName}_${categoryIndex}`;

        items.forEach((item, id) => {
          linkNodes.push({
            source: categoryNodeId,
            target: `org_${item?.organization}_${clusterName}_${categoryIndex}_${id}`,
          });

          // Add links from the organization to authors
          if (item?.authors) {
            item.authors.forEach((authorItem, authorIndex) => {
              const existingAuthorNodeID = () => {
                const existingNode = nodesData.find(
                  (node) =>
                    node?.label === authorItem?.author &&
                    JSON.stringify(node?.mentioned) ===
                      JSON.stringify(authorItem?.mentions) &&
                    JSON.stringify(item?.authors) ===
                      JSON.stringify(node?.authors) &&
                    items?.length > 1
                );

                return existingNode
                  ? existingNode.id
                  : `author_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`;
              };

              const existingAllAuthorNodeData = () => {
                const exisitingNode = allNodesData.find(
                  (node) =>
                    node?.label === authorItem?.author &&
                    JSON.stringify(node?.mentioned) ===
                      JSON.stringify(authorItem?.mentions) &&
                    JSON.stringify(item?.authors) ===
                      JSON.stringify(node?.authors) &&
                    items?.length > 1
                );
                return exisitingNode
                  ? exisitingNode.id
                  : `author_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`;
              };
              allLinksData.push({
                source: `org_${item?.organization}_${clusterName}_${categoryIndex}_${id}`,
                target: existingAllAuthorNodeData(),
              });

              linkNodes.push({
                source: `org_${item?.organization}_${clusterName}_${categoryIndex}_${id}`,
                target: existingAuthorNodeID(),
              });
              if (authorItem?.mentions) {
                authorItem.mentions.forEach((authorMention, mentionId) => {
                  const existingMentionNodeId = () => {
                    const existingNode = nodesData.find(
                      (node) =>
                        node?.label === authorMention &&
                        node?.author === authorItem?.author &&
                        JSON.stringify(item?.authors) ===
                          JSON.stringify(node?.authors) &&
                        items?.length > 1
                    );

                    return existingNode
                      ? existingNode.id
                      : `mentions_${authorMention}_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`;
                  };

                  const existingAllMentionsNodeData = () => {
                    const exisitingNode = allNodesData.find(
                      (node) =>
                        node?.label === authorMention &&
                        node?.author === authorItem?.author &&
                        JSON.stringify(item?.authors) ===
                          JSON.stringify(node?.authors) &&
                        items?.length > 1
                    );
                    return exisitingNode
                      ? exisitingNode.id
                      : `author_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`;
                  };

                  allLinksData.push({
                    source: existingAllAuthorNodeData(),
                    target: existingAllMentionsNodeData(),
                  });

                  linkNodes.push({
                    source: existingAuthorNodeID(),
                    target: existingMentionNodeId(),
                  });
                });
              }
            });
          }
        });

        return linkNodes;
      });

    const legends = response?.data?.map((item, id) => {
      const clusterName = Object.keys(item)[0];
      return {
        label: clusterName,
        value: clusterName,
        color: networkClusterColors[id],
      };
    });
    const networkClusterData = JSON.parse(
      JSON.stringify(networkClusterMapData)
    );

    networkClusterData.data.resultsInFig =
      networkClusterData?.data?.resultsInFig?.map((item) => {
        const authorsInNetwork = response?.data?.flatMap(
          (categoryData, categoryIndex) => {
            const clusterName = Object.keys(categoryData)[0];
            const items = categoryData[clusterName];
            let totalCount = 0;
            items?.reduce((acc, item) => {
              totalCount += item?.author_count || 0;
            }, 0);
            return totalCount;
          }
        );

        return {
          ...item,
          value:
            item?.label === 'Total Authors in the Network'
              ? authorsInNetwork?.reduce((acc, item) => acc + item, 0)
              : response?.data?.length,
        };
      });

    networkClusterData.data.labels = legends;
    networkClusterData.data.data = {
      nodes: nodesData,
      links: linkData,
    };
    //   if (response) {
    //     data.originalData = response;
    //   }
    networkClusterData.slotType = 'full';
    networkClusterData.data.modifiedData = { allNodesData, allLinksData };
    networkClusterData.shouldShowGraph = true;
    networkClusterData.resetNetworkMapData = {
      nodes: nodesData,
      links: linkData,
    };
    networkClusterData.resetLegends = legends;
    networkClusterData.data.originalData = response;

    return networkClusterData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title: 'NetworkMap',
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useNetworkClusterData = (filters, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-advance-network-cluster', filters],
    queryFn: () => getNetworkClusterData(filters),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

const getNetworkKeyInfluencerData = async (payload) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  try {
    const { data: response } = await get(
      `${API}/dashboard-influencer/influencer-info?${queryString}`,
      {}
    );

    if (!response) {
      return 'No Data';
    }

    return response;
  } catch (error) {
    console.error('Network Info Error occurred:', error);

    return 'No Data';
  }
  // const data = JSON.parse(JSON.stringify(networkClusterMapData));
  // // console.log({ response });
  // //   if (response) {
  // //     data.originalData = response;
  // //   }
  // data.slotType = 'full';
  // data.shouldShowGraph = true;

  // return data;
};

export const useNetworkKeyInfluencerData = () => {
  return useMutation({
    mutationFn: getNetworkKeyInfluencerData,
  });
};

export const getNetworkSentimentChartData = async (payload) => {
  const title = 'Sentiment Breakdown';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-influencer/sentiment-breakdown?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const sentimentChartMapData = JSON.parse(
      JSON.stringify(tempSentimentChartMapData)
    );
    // // Set Percentage here
    const percentage = calculatePercentage(response);
    const sentimeMentMapData = sentimentChartMapData?.data.data;

    const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
      return {
        ...x,
        value: percentage[x.label],
        labelColor: x?.color,
      };
    });

    const totalArticlesMapData = sentimentChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    sentimentChartMapData.data.title = title;
    sentimentChartMapData.data.summary = totalArticlesMapData;
    sentimentChartMapData.data.data = updatedSentimentMapData || [];
    sentimentChartMapData.shouldShowGraph = response?.total_count > 0;
    if (response) {
      sentimentChartMapData.originalData = response;
    }
    sentimentChartMapData.slotType = 'half';
    return sentimentChartMapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useNetworkSentimentBreakdownChartData = (filters, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-network-sentiment-analysis', filters],
    queryFn: () => getNetworkSentimentChartData(filters),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getNetworkTopThemeChartData = async (payload) => {
  payload.dashboard_name = 'networkMap';
  payload.chart_name = 'networkMap_topics_discussion';
  try {
    let { data: response } = await get(
      `${API}/dashboard-influencer/topic-of-discussion?${objectToQueryString(
        payload
      )}`,
      {}
    );

    if (!response) {
      response = {};
    }
    const topThemeChartMapData = JSON.parse(
      JSON.stringify(TempTopThemeChartMapData)
    );

    topThemeChartMapData.title = 'Topics of Discussion';

    const totalArticlesMapData = topThemeChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topThemeChartMapData.data.summary = totalArticlesMapData;

    const topThemeRes = response?.data;

    const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
      return {
        ...x,
        value: x?.article_count,
        secondaryValue: x?.secondary_theme,
        label: x?.primary_theme,
        thresholdValue: x?.article_count,
        keyword: x?.keyword,
      };
    });
    topThemeChartMapData.data.title = 'Topics of Discussion';
    topThemeChartMapData.data.data = updatedTopSourcedata || [];
    topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
    topThemeChartMapData.slotType = 'half';
    topThemeChartMapData.originalData = response;
    return topThemeChartMapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title: 'Top Themes',
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useNetworkTopicsDiscussionChartData = (filters, editMode) => {
  return useQuery({
    queryKey: ['network-top-theme-charts', filters],
    queryFn: () => getNetworkTopThemeChartData(filters),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};
