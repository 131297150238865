import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ResizeHandlerHOC from '../utils/resizeHandlerHOC';
import LineBarGraph from './barGraph.graph';
import { GraphContainer, GraphWrp } from '../../styles/index.sc';

const GroupedColumnBar = (props) => {
  const refElement = useRef(null);
  const graphRef = useRef(null);

  useEffect(() => {
    if (props?.data?.data?.length > 0) {
      let graph = graphRef.current;

      // Initialize graph if it doesn't exist
      if (!graph) {
        graphRef.current = new LineBarGraph(refElement.current);
        graph = graphRef.current;
      }

      graph.setConfig({ ...props.config });
      graph.setData(props.data);
      graph.drawGraph();

      // Cleanup
      return () => {
        if (graph && typeof graph.clear === 'function') {
          graph.clear();
        }
      };
    }
  }, [props.data, props.rerender]);

  useEffect(() => {
    const graph = graphRef.current;
    if (graph && props.resetSelection) {
      graph.onResetFunc();
    }
  }, [props.resetSelection]);

  return (
    <GraphContainer className="line-bar graph-container">
      <GraphWrp className="graph-wrp" ref={refElement} />
    </GraphContainer>
  );
};

GroupedColumnBar.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  rerender: PropTypes.bool,
  resetSelection: PropTypes.bool,
};

export default ResizeHandlerHOC(GroupedColumnBar);
