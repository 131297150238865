import styled from 'styled-components';

export const NetworkMapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const SlotResultsFiltersWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
`;

export const SlotResultsInFigureWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme?.graphColors?.coolGray20};
`;

export const SlotResultsInFigure = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  gap: 0.75rem;
  width: 100%;
  height: 100%;
  max-height: 5.125rem;
  border-right: 1px solid ${({ theme }) => theme?.graphColors?.coolGray20};
`;

export const SlotResultInFigureCount = styled.div`
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.63px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const SlotResultInFigureCountDescriptionWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const SlotResultInFigureCountDescription = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray60};
`;

export const SlotNetworkBreadCrumbsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;
`;

export const SlotBreadCrumbsText = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const SlotNetworkFiltersWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5rem;
`;
