import React from 'react';
import NetworkCluster from './graphWrapper';
import PropTypes from 'prop-types';

export const dummyNetworkData = {
  nodes: [
    // Clusters 1 to 10
    ...Array.from({ length: 10 }, (_, clusterIndex) => {
      const clusterId = clusterIndex + 1;
      return [
        {
          id: `cluster${clusterId}_main`,
          group: clusterId,
          size: Math.floor(Math.random() * (25 - 24 + 1)) + 24, // Random size between 20-25
        }, // Main node
        ...Array.from({ length: 45 }, (_, childIndex) => ({
          id: `cluster${clusterId}_child${childIndex + 1}`,
          group: clusterId,
          size: Math.floor(Math.random() * (15 - 10 + 1)) + 10, // Random size between 8-1
        })),
      ];
    }).flat(),
  ],
  links: [
    // Internal links for Clusters 1 to 10
    ...Array.from({ length: 10 }, (_, clusterIndex) => {
      const clusterId = clusterIndex + 1;
      return [
        // Links from main node to child nodes
        ...Array.from({ length: 45 }, (_, childIndex) => ({
          source: `cluster${clusterId}_main`,
          target: `cluster${clusterId}_child${childIndex + 1}`,
        })),
        // Links between child nodes
        ...Array.from({ length: 45 }, (_, childIndex) => ({
          source: `cluster${clusterId}_child${childIndex + 1}`,
          target: `cluster${clusterId}_child${((childIndex + 1) % 45) + 1}`, // Connect to the next child in the cluster
        })),
      ];
    }).flat(),

    // Inter-cluster links (main nodes)
    ...Array.from({ length: 10 }, (_, clusterIndex) => {
      const cluster1 = clusterIndex + 1;
      const cluster2 = ((clusterIndex + 1) % 10) + 1;
      return {
        source: `cluster${cluster1}_main`,
        target: `cluster${cluster2}_main`,
      };
    }),

    // Inter-cluster links (child nodes between clusters)
    ...[
      { source: 'cluster1_child1', target: 'cluster3_child5' },
      { source: 'cluster2_child2', target: 'cluster4_child7' },
      { source: 'cluster3_child3', target: 'cluster5_child2' },
      { source: 'cluster4_child4', target: 'cluster1_child8' },
      { source: 'cluster5_child5', target: 'cluster2_child9' },
      { source: 'cluster6_child1', target: 'cluster8_child4' },
      { source: 'cluster7_child2', target: 'cluster9_child6' },
      { source: 'cluster8_child3', target: 'cluster10_child9' },
      { source: 'cluster9_child5', target: 'cluster1_child3' },
      { source: 'cluster10_child7', target: 'cluster2_child4' },
    ],
  ],
};

const NetworkClusterWrapper = ({ handleOnClick = () => {}, data, config }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <NetworkCluster
        data={data}
        handleOnClick={handleOnClick}
        config={config}
      />
      {/* <NestedTree data={nestedTreeData} config={{}} rerender={true} /> */}
    </div>
  );
};

export default NetworkClusterWrapper;

NetworkClusterWrapper.propTypes = {
  handleOnClick: PropTypes.func,
  data: PropTypes.array,
  config: PropTypes.object,
};
