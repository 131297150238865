import {
  sentimentOverTime,
  resultOveTime,
  topAuthorChartMapData as tempTopAuthorChartMapData,
  topThemeChartMapData as TempTopThemeChartMapData,
  topSourceChartMapData as TempTopSourceChartMapData,
  wordCloudChartMapData as TempWordCloudChartMapData,
  reachByCampaign,
  compaignMapData,
  volumeDistByChannel,
  campaignSentiment,
} from '../../hooks/data/advancedCampaign';
import { getFormattedDate } from '../../hooks/useCharts';
import { updateArrayToObject } from '../../hooks/useAdvancedCampaign';
import { addCountPrefix, colorCodeObjects } from '../../constants/utils';
import {
  blueColorGradients,
  purpleColorGradients,
  magentaColorGradients,
  tealColorGradients,
  orangeColorGradients,
  yellowColorGradients,
  cyanColorGradients,
} from '../../constants/graph-colors';
import { topThemeColors, CampaignMonitor } from '../../graphs/utils/graphConst';
import { WordCloudColors } from '../../hooks/data/colors';

export function campaign_mapping(response, graphType, chartName) {
  if (graphType === 'advanced_campaign_monitor_sentiment_over_time') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(sentimentOverTime));
    const dataFromRes = response?.data;
    const labels = mapData.data.labels;
    const udpatedDataFromRes = dataFromRes?.map((x) => {
      const formattedDate = getFormattedDate(x?.date ?? x.label);
      const data = updateArrayToObject(
        x?.sentiment_count,
        'key',
        'doc_count',
        labels
      );

      return {
        label: formattedDate,
        ...data,
        date: x?.date ?? x.label,
        timeline: x.label,
      };
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.data.summary = totalArticlesMapData;
    mapData.title = chartName;
    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;

    return mapData;
  } else if (graphType === 'advanced_campaign_monitor_reach') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(resultOveTime));

    const totalArticlesMapData = mapData.data.summary;

    totalArticlesMapData.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    mapData.title = chartName;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = response?.campaigns[0]?.campaign1?.data?.map((x) => {
      const formattedDate = getFormattedDate(x?.label);
      return {
        ...x,
        label: formattedDate,
        gucci: x.sum_reach ? parseFloat(x.sum_reach) : 0,
        date: x?.label,
        color: purpleColorGradients?.purple60,
      };
    });
    mapData.data.summary.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    return mapData;
  } else if (graphType === 'advanced_campaign_monitor_top_author') {
    if (response?.error) {
      return {};
    }
    const topAuthorChartMapData = JSON.parse(
      JSON.stringify(tempTopAuthorChartMapData)
    );

    const totalArticlesMapData = topAuthorChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topAuthorChartMapData.data.summary = totalArticlesMapData;

    const topAuthorRes = response?.authors;

    const updatedTopSourcedata = topAuthorRes
      ?.slice(0, 10)
      ?.filter((x) => Number(x?.count) !== 0)
      ?.map((x, i) => {
        return {
          author_id: x?.author_id,
          value: x?.count,
          label: x?.author,
          thresholdValue: x?.count,
          color: '#22B9FF',
          labelColor: blueColorGradients.blue60,
        };
      });
    topAuthorChartMapData.title = chartName;
    topAuthorChartMapData.data.data = updatedTopSourcedata || [];
    topAuthorChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
    return topAuthorChartMapData;
  } else if (graphType === 'advanced_campaign_monitor_themes') {
    if (response?.error) {
      return {};
    }
    const topThemeChartMapData = JSON.parse(
      JSON.stringify(TempTopThemeChartMapData)
    );

    const totalArticlesMapData = topThemeChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topThemeChartMapData.data.summary = totalArticlesMapData;

    const topThemeRes = response?.data;

    const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
      const colorIndex = i % topThemeColors.length;
      return {
        ...topThemeColors[colorIndex],
        value: x?.article_count,
        secondaryValue: x.secondary_theme,
        label: x?.primary_theme,
        thresholdValue: x?.article_count,
        keyword: x?.keyword,
        labelColor: topThemeColors[colorIndex],
      };
    });
    topThemeChartMapData.title = chartName;
    topThemeChartMapData.data.data = updatedTopSourcedata || [];
    topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
    topThemeChartMapData.originalData = response;
    // topThemeChartMapData.shouldShowGraph = true;

    return topThemeChartMapData;
  } else if (graphType === 'advanced_campaign_monitor_wordcloud') {
    if (response?.error) {
      return {};
    }
    const wordCloudChartMapData = JSON.parse(
      JSON.stringify(TempWordCloudChartMapData)
    );

    const totalArticlesMapData = wordCloudChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    wordCloudChartMapData.data.summary = totalArticlesMapData;

    const worldCloudFromRes = response?.data;
    const counts = {
      PERSONS: 0,
      COMPANIES: 0,
      LOCATIONS: 0,
      HASHTAGS: 0,
      OTHERS: 0,
    };
    const legendsConfig = {
      PERSONS: { label: 'People', color: magentaColorGradients.magenta60 },
      COMPANIES: {
        label: 'Organizations',
        color: purpleColorGradients.purple60,
      },
      LOCATIONS: { label: 'Locations', color: orangeColorGradients.orange60 },
      HASHTAGS: { label: 'Hashtags', color: cyanColorGradients.cyan50 },
      OTHERS: { label: 'Others', color: tealColorGradients.teal50 },
    };
    const updatedWordCloudData = worldCloudFromRes
      ?.filter((x) => {
        return x?.type !== 'LOCATIONS';
      })
      ?.map((x, i) => {
        if (x?.type in counts) {
          counts[x.type] += 1;
        }
        return {
          value: x?.article_count,
          thresholdValue: x?.count,
          label: x?.label,
          labelColor: WordCloudColors[x?.type],
          article_count: x?.article_count,
          type: x?.type,
          color: WordCloudColors[x?.type],
        };
      });
    const legends = Object.keys(counts)
      .filter((key) => counts[key] > 0)
      .map((key) => legendsConfig[key]);

    wordCloudChartMapData.title = chartName;
    wordCloudChartMapData.legends = legends;
    wordCloudChartMapData.data.data = updatedWordCloudData || [];
    wordCloudChartMapData.shouldShowGraph = response?.data?.length > 0;

    return wordCloudChartMapData;
  } else if (graphType === 'advanced_campaign_monitor_outlet_breakdown') {
    if (response?.error) {
      return {};
    }
    const topSourceChartMapData = JSON.parse(
      JSON.stringify(TempTopSourceChartMapData)
    );

    const totalArticlesMapData = topSourceChartMapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    topSourceChartMapData.data.summary = totalArticlesMapData;
    topSourceChartMapData.title = chartName;

    const topSourceRes = response?.campaigns[0]?.campaign1?.data;

    const updatedTopSourceData = topSourceRes?.slice(0, 10)?.map((x, i) => {
      return {
        value: x?.doc_count,
        label: x?.media_type,
        color: purpleColorGradients.purple60,
        labelColor: purpleColorGradients.purple60,
      };
    });

    // Apply the gradient colors after sorting
    const gradientColors = [
      purpleColorGradients.purple70,
      purpleColorGradients.purple60,
      purpleColorGradients.purple50,
      purpleColorGradients.purple40,
    ];

    const maxGradientIndex = gradientColors.length - 1;

    const colorCodedData = colorCodeObjects(updatedTopSourceData)?.map(
      (item, index) => {
        // Use gradient based on the index, default to purple40 for remaining items
        const gradientColor =
          index <= maxGradientIndex
            ? gradientColors[index]
            : purpleColorGradients.purple40;
        return {
          ...item,
          color: gradientColor,
        };
      }
    );

    topSourceChartMapData.data.data = colorCodedData || [];
    topSourceChartMapData.shouldShowGraph = topSourceRes?.length > 0;
    return topSourceChartMapData;
  } else if (graphType === 'advanced_campaign_comparison_reach_by_campaign') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(reachByCampaign));

    const dataFromRes = response?.campaigns;
    const labels = mapData.data.labels;
    const resdata = [];
    let coloridx = 0;
    const mergedCampaigns = dataFromRes.reduce((acc, campaignObj) => {
      const campaignKey = Object.keys(campaignObj)[0];
      const campaignData = campaignObj[campaignKey].data;
      mapData?.data?.labels.push({
        label: campaignKey,
        value: String(campaignKey),
        color: CampaignMonitor[coloridx++],
      });
      campaignData.forEach(({ label, sum_reach }) => {
        if (!acc[label]) {
          acc[label] = { label };
        }
        acc[label][campaignKey] = sum_reach;
      });

      return acc;
    }, {});

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    mapData.title = chartName;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = Object.values(mergedCampaigns) || [];
    mapData.shouldShowGraph = response?.total_count;
    return mapData;
  } else if (graphType === 'advanced_campaign_comparison_campaign_analysis') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(compaignMapData));
    const newArray = [];
    const campaigns = response?.campaigns;
    const total_count = response?.overall_total_count;
    let idx = 0;
    campaigns.forEach((campaignObj, camp_idx) => {
      const campaignName = Object.keys(campaignObj)[0];
      const campaignData = campaignObj[campaignName].data;

      const totalCountCampaign = campaignData.reduce(
        (a, c) => a + c.campaign_count,
        0
      );

      const colorIndex = idx % CampaignMonitor.length;

      campaignData.forEach((dataPoint, index) => {
        const xValue = dataPoint.net_sentiment;
        const yValue = dataPoint.campaign_reach;
        const radius = Number(
          (dataPoint.campaign_count / total_count).toFixed(2)
        );
        const articleCount = dataPoint.campaign_count;

        const newObject = {
          label: dataPoint.label,
          xValue: parseFloat(xValue.toFixed(2)),
          yValue,
          radius: (radius / 2).toFixed(2).toString(),
          color: CampaignMonitor[colorIndex],
          keyword: dataPoint.keyword,
          articleCount,
          //   recentSearchId: filters[camp_idx]?.recent_search_id,
        };

        newArray.push(newObject);
      });
      idx++;
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    mapData.title = chartName;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = newArray || [];

    return mapData;
  } else if (
    graphType === 'advanced_campaign_monitor_vol_distribution_by_channel'
  ) {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(volumeDistByChannel));

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    mapData.data.summary = totalArticlesMapData;
    const processed_arr = [];
    const sumArr = [];
    let totalSum = 0;
    response?.campaigns.forEach((campaignObj, camp_idx) => {
      const campaignName = Object.keys(campaignObj)[0];
      const campaignData = campaignObj[campaignName].data;
      let valueSum = 0;
      const updatedCampaignData = campaignData?.slice(0, 10)?.map((x, i) => {
        valueSum += x?.doc_count;
        return {
          value: x?.doc_count,
          label: x?.media_type,
          color: purpleColorGradients.purple60,
          labelColor: purpleColorGradients.purple60,
          //   recentSearchId: filters[camp_idx]?.recent_search_id,
        };
      });

      processed_arr.push({
        [campaignName]: updatedCampaignData,
      });
      sumArr.push(valueSum);
      totalSum += valueSum;
    });

    // Apply the gradient colors after sorting
    const gradientColors = [
      [
        purpleColorGradients.purple70,
        purpleColorGradients.purple60,
        purpleColorGradients.purple50,
        purpleColorGradients.purple40,
      ],
      [
        magentaColorGradients.magenta70,
        magentaColorGradients.magenta60,
        magentaColorGradients.magenta50,
        magentaColorGradients.magenta40,
      ],

      [
        tealColorGradients.teal70,
        tealColorGradients.teal60,
        tealColorGradients.teal50,
        tealColorGradients.teal40,
      ],

      [
        yellowColorGradients.yellow70,
        yellowColorGradients.yellow60,
        yellowColorGradients.yellow50,
        yellowColorGradients.yellow40,
      ],

      [
        orangeColorGradients.orange70,
        orangeColorGradients.orange60,
        orangeColorGradients.orange50,
        orangeColorGradients.orange40,
      ],
    ];
    let clrIdx = 0;
    const colorProcessedArr = [];
    const maxGradientIndex = gradientColors[0].length - 1;
    processed_arr.map((camp, idx) => {
      const campaignName = Object.keys(camp)[0];
      const arr = camp[campaignName];

      const colorProccedData = colorCodeObjects(arr)?.map((item, index) => {
        // Use gradient based on the index, default to purple40 for remaining items
        const gradientColor =
          index <= maxGradientIndex
            ? gradientColors[clrIdx][index]
            : gradientColors[clrIdx][maxGradientIndex];
        return {
          ...item,
          color: gradientColor,
          labelColor: gradientColor,
        };
      });

      colorProcessedArr.push({
        [campaignName]: colorProccedData,
      });
      clrIdx++;
    });
    mapData.title = chartName;
    mapData.data.data = colorProcessedArr || [];
    mapData.data.sumArr = sumArr;
    mapData.data.total_articles = totalSum;
    mapData.shouldShowGraph = colorProcessedArr?.length > 0;
    return mapData;
  } else if (graphType === 'advanced_campaign_monitor_sentiment') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(campaignSentiment));
    const newArr = [];
    response?.campaigns.forEach((campaignObj, camp_idx) => {
      const campaignName = Object.keys(campaignObj)[0];
      const item = campaignObj[campaignName];

      const obj = {
        label: campaignName,
        neg: item.sentiment_negative.toString(),
        pos: item.sentiment_positive.toString(),
        neu: item.sentiment_neutral.toString(),
        // recentSearchId: filters[camp_idx]?.recent_search_id,
      };

      newArr.push(obj);
    });
    mapData.title = chartName;

    mapData.data.summary.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    mapData.data.data = newArr || [];

    return mapData;
  }
}
