import React from 'react';
import Proptypes from 'prop-types';
import {
  LegendColor,
  LegendLabel,
  LegendValue,
  TooltipBody,
  TooltipBodyLabel,
  TooltipBodyWrp,
  TooltipLegendContainer,
  TooltipLegendWrp,
  TooltipSubTitle,
  // TooltipSubTitleValue,
  TooltipTitle,
  TooltipTitleValue,
  TooltipWrapper,
  Separator,
  ArticlesCount,
} from './index.sc';
import { TooltipBodyValue } from '../search-result/index.sc';
import { colorBox, sovColorBox } from '../../graphs/utils/graphConst';
import {
  capitalizeFirstLetter,
  capitalizeFirstWord,
  formatNumber,
} from '../../utils';
import {
  ChartLabelLargeWrp,
  ChartLabelSmallWrp,
} from '../../constants/graph-colors/index.sc';
import {
  blueColorGradients,
  coolGrayColorGradients,
  cyanColorGradients,
  greenColorGradients,
  magentaColorGradients,
  purpleColorGradients,
  redColorGradients,
  tealColorGradients,
  yellowColorGradients,
} from '../../constants/graph-colors';

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (filters?.mediaType !== null) {
    const mediaTypes = filters?.mediaType?.split(',');
    const isSocial = mediaTypes?.some((x) => social?.includes(x));
    const isTraditional = mediaTypes?.some((x) => traditional?.includes(x));
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const GraphTooltip = ({ tooltipData, widget, storyAnalysisChart }) => {
  const type = () => {
    if (
      widget?.component === 'breakdown_by_media_type' ||
      widget?.component === 'coverage_by_source' ||
      widget?.component === 'coverage_by_journalist'
    ) {
      return 'one-d';
    } else {
      return widget?.data?.labels?.length > 1 ? 'two-d' : 'one-d';
    }
  };

  const filteredLabels = (widget) => {
    let labels = widget?.data?.labels || [];

    if (
      (widget.component === 'result_over_time' ||
        widget.component === 'media_type' ||
        widget.component === 'industry_coverage_by_source' ||
        widget.component === 'people_media_type') &&
      widget.data.summary.mediaType
    ) {
      const mediaTypes = widget.data.summary.mediaType;
      const filteredLabels = [];

      if (mediaTypes.includes('Print')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'Print')
        );
      }
      if (mediaTypes.includes('Online')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'Online')
        );
      }
      if (mediaTypes.includes('Blogs')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'Blogs')
        );
      }
      if (mediaTypes.includes('Forums')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'Forums')
        );
      }
      if (mediaTypes.includes('Reviews')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'Reviews')
        );
      }
      if (mediaTypes.includes('Twitter')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'X (Twitter)')
        );
      }

      if (mediaTypes.includes('Reddit')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'Reddit')
        );
      }
      if (mediaTypes.includes('YouTube')) {
        filteredLabels.push(
          ...labels.filter((label) => label.label === 'YouTube')
        );
      }

      // If any filters were applied, update labels
      if (filteredLabels.length > 0) {
        labels = filteredLabels;
      }
    }

    return labels;
  };
  const labels = filteredLabels(widget);

  const { data, rawData, labelColor } = tooltipData;
  const totalArticleCountForSentiment =
    data?.positive + data?.neutral + data?.negative;

  let tooltip1d = {};
  let tooltip2d = {};

  if (type() === 'two-d') {
    tooltip2d = {
      title:
        Object.keys(data)?.includes('networkSubClusterTitle') &&
        !data?.networkSubClusterTitle
          ? data?.category
          : data?.label,
      titleValue:
        rawData?.label || (rawData?.brand && `Brand: ${rawData?.brand}`),
      subTitle: widget?.data?.subtitle,
      positiveValue: data?.positive || 0,
      negativeValue: data?.negative || 0,
      subTitleValue: '',
      legendData: labels?.map((ele) => ({
        label: ele?.label,
        value: rawData
          ? rawData[
              ele?.value === 'message_congruence'
                ? 'keyword_doc_count'
                : ele?.value || ele?.doc_count
            ] ?? '0'
          : '',
        color: ele?.color,
      })),
      labelColor: !data?.mediaTypeChart && data?.color,
      positiveFlag: data?.positiveFlag,
      component: widget?.component !== 'top_journalist_by_sentiment',
    };
  } else {
    tooltip1d = {
      title: widget?.title === 'Message Congruence' ? data?.brand : '',
      label:
        widget?.component === 'breakdown_by_media_type' ||
        widget?.component === 'coverage_by_source' ||
        widget?.component === 'coverage_by_journalist' ||
        widget?.component === 'outlet_breakdown'
          ? data?.labelText
          : Object.keys(data)?.includes('networkSubClusterTitle') &&
            !data?.networkSubClusterTitle
          ? data?.category
          : data?.label || data?.properties?.NAME || data?.properties?.brk_name,
      value:
        widget?.component === 'influencer_geographical'
          ? tooltipData?.articleCount
          : data?.value ||
            data?.properties?.value ||
            totalArticleCountForSentiment ||
            data?.doc_count ||
            '',
      docCount:
        data?.labelText === 'Online'
          ? data?.OnlineDocCount
          : data?.labelText === 'Print'
          ? data?.PrintDocCount
          : data?.labelText === 'Reviews'
          ? data?.ReviewsDocCount
          : data?.labelText === 'Forums'
          ? data?.ForumsDocCount
          : data?.labelText === 'Blogs'
          ? data?.BlogsDocCount
          : data?.labelText === 'Twitter'
          ? data?.TwitterDocCount
          : data?.labelText === 'X (Twitter)'
          ? data?.TwitterDocCount
          : data?.labelText === 'Reddit'
          ? data?.RedditDocCount
          : data?.labelText === 'YouTube'
          ? data?.YouTubeDocCount
          : data?.labelText === 'All Media'
          ? data?.keyword_doc_count
          : 0,

      percentAge:
        data?.xValue ||
        data?.net_sentiment ||
        ((widget.title === 'Campaign Analysis' ||
          widget.title === 'People Impact') &&
        !data?.net_sentiment
          ? '0'
          : undefined),
      reach:
        data?.yValue ||
        ((widget.title === 'Campaign Analysis' ||
          widget.title === 'People Impact') &&
        !data?.yValue
          ? '0'
          : undefined),
      totalArticleAVEREACH: data?.rawData?.articleCount,
      labelColor: labelColor || data?.labelColor || data?.color,
      mediaTypes: data?.media_types || data?.media_type || [],
    };
  }

  const traditional = {
    type: 'Traditional',
    color: purpleColorGradients.purple60,
    dataKey: 'traditionalCount',
  };

  const social = {
    type: 'Social',
    color: magentaColorGradients.magenta50,
    dataKey: 'socialCount',
  };

  const mediaMappings = {
    social: [
      {
        ...social,
        type: 'Results',
      },
    ],
    traditional: [
      {
        ...traditional,
        type: 'Results',
      },
    ],
    all: [social, traditional],
  };

  return (
    <>
      {type() === 'one-d' && widget.component === 'result_over_time' && (
        <TooltipWrapper className="one-d" padding="0.6rem 0.6rem 0.3rem 0.6rem">
          <ChartLabelSmallWrp
            minWidth="3.5rem"
            padding="0px 0px 4px 0px"
            style={{
              color: coolGrayColorGradients.coolGray70,
            }}
          >
            {tooltip1d.label}
          </ChartLabelSmallWrp>
          {mediaMappings[getSelectedTypes(widget?.data?.summary)].map(
            ({ type }, index) => {
              const types =
                mediaMappings[getSelectedTypes(widget?.data?.summary)];

              const mediaType = types?.find(
                (mt) =>
                  mt.type === type ||
                  (type === 'X (Twitter)' && mt?.type === 'twitter')
              ) || {
                color: 'gray',
                dataKey: 'value',
              };
              return (
                <React.Fragment key={type}>
                  <ChartLabelSmallWrp
                    minWidth="3.5rem"
                    style={{
                      color: mediaType?.color,
                      marginTop: index !== 0 ? '1rem' : '0',
                    }}
                  >
                    {type}
                  </ChartLabelSmallWrp>
                  <TooltipBodyWrp>
                    <TooltipBody>
                      <ChartLabelLargeWrp>
                        <div style={{ marginTop: '3px' }}>
                          {(
                            tooltipData?.data?.[mediaType?.dataKey] || 0
                          ).toLocaleString('en-US')}
                        </div>
                      </ChartLabelLargeWrp>
                    </TooltipBody>
                  </TooltipBodyWrp>
                </React.Fragment>
              );
            }
          )}
        </TooltipWrapper>
      )}
      {type() === 'one-d' ? (
        <TooltipWrapper
          className={widget.component === 'result_over_time' ? '' : 'one-d'}
          totalArticleAVEREACH={
            tooltip1d?.totalArticleAVEREACH || tooltip1d?.percentAge
          }
          padding={tooltip1d?.totalArticleAVEREACH && '0.625rem '}
        >
          {widget?.title === 'Message Congruence' && (
            <TooltipBodyLabel marginBottom="0.65rem">
              {capitalizeFirstLetter(tooltip1d?.title)}
            </TooltipBodyLabel>
          )}
          <ChartLabelSmallWrp
            style={{
              color:
                tooltip1d?.labelColor || tooltipData?.data?.color || 'black',
            }}
          >
            {widget.component === 'result_over_time'
              ? ''
              : capitalizeFirstLetter(tooltip1d?.label)}
          </ChartLabelSmallWrp>
          <TooltipBodyWrp
            totalArticleAVEREACH={
              tooltip1d?.totalArticleAVEREACH || tooltip1d?.percentAge
            }
          >
            {(tooltip1d?.totalArticleAVEREACH || tooltip1d?.percentAge) && (
              <TooltipBody>
                <TooltipBodyLabel>Total Results</TooltipBodyLabel>
                <TooltipBodyValue>
                  {tooltip1d?.totalArticleAVEREACH?.toLocaleString('en-US') ||
                    tooltip1d?.value?.toLocaleString('en-US')}
                </TooltipBodyValue>
              </TooltipBody>
            )}
            {!tooltip1d?.percentAge && (
              <TooltipBody>
                <TooltipBodyLabel>
                  {data?.key === 'total_ave'
                    ? 'Total AVE'
                    : data?.key === 'total_reach'
                    ? 'Traditional Reach'
                    : ''}
                </TooltipBodyLabel>
                <ChartLabelLargeWrp>
                  {data?.key === 'total_ave' || data?.key === 'total_reach' ? (
                    `~$ ${formatNumber(tooltip1d?.value)}`
                  ) : widget.component === 'result_over_time' ? (
                    ''
                  ) : widget?.title === 'Message Congruence' ? (
                    <>
                      {tooltip1d?.value?.toLocaleString('en-US')}%{' '}
                      <Separator>|&nbsp;</Separator>
                      <ArticlesCount>
                        {tooltip1d?.docCount?.toLocaleString('en-US')}
                        &nbsp;Results
                      </ArticlesCount>
                    </>
                  ) : (
                    tooltip1d?.value &&
                    parseInt(tooltip1d?.value)?.toLocaleString('en-US')
                  )}
                </ChartLabelLargeWrp>
                {(widget?.component?.includes('coverage_over_time') ||
                  widget?.component?.includes('reach_over_time')) &&
                  tooltip1d?.mediaTypes &&
                  !storyAnalysisChart &&
                  widget?.mediaTypes?.map((mediaName) => {
                    const media = tooltip1d?.mediaTypes?.find(
                      (mdt) => mdt?.key === mediaName
                    );
                    return (
                      <React.Fragment key={mediaName}>
                        <ChartLabelSmallWrp minWidth="3.5rem">
                          {mediaName} :{' '}
                          {(media?.doc_count || 0).toLocaleString('en-US')}
                        </ChartLabelSmallWrp>
                      </React.Fragment>
                    );
                  })}
              </TooltipBody>
            )}
            {tooltip1d?.percentAge && (
              <TooltipBody>
                <TooltipBodyLabel>Net Sentiment</TooltipBodyLabel>
                <TooltipBodyValue>
                  {parseFloat(tooltip1d?.percentAge)
                    .toFixed(2)
                    .toLocaleString('en-US')}
                  %
                </TooltipBodyValue>
              </TooltipBody>
            )}
            {tooltip1d?.reach && (
              <TooltipBody>
                <TooltipBodyLabel>Reach</TooltipBodyLabel>
                <TooltipBodyValue>
                  {formatNumber(tooltip1d?.reach)}
                </TooltipBodyValue>
              </TooltipBody>
            )}
          </TooltipBodyWrp>
        </TooltipWrapper>
      ) : (
        <TooltipWrapper className="two-d">
          <TooltipTitle
            labelColor={
              widget.component === 'result_over_time'
                ? coolGrayColorGradients.coolGray70
                : tooltip2d?.labelColor
            }
            className="two-d"
          >
            {/* {tooltip2d?.title || 'title'} */}
            {tooltip2d?.title}
          </TooltipTitle>
          {tooltip2d?.titleValue?.toLowerCase() !==
          tooltip2d?.title?.toLowerCase() ? (
            <TooltipTitleValue>{tooltip2d?.titleValue}</TooltipTitleValue>
          ) : (
            ''
          )}
          {/* <TooltipSubTitle>{tooltip2d?.subTitle}</TooltipSubTitle> */}
          {/* <TooltipSubTitleValue>75002</TooltipSubTitleValue> */}

          <TooltipLegendWrp>
            {tooltip2d?.component &&
              tooltip2d?.legendData?.map((ele, i) => (
                <TooltipLegendContainer key={i}>
                  {/* <LegendColor
                  bgColor={ele?.color || sovColorBox[i]}
                ></LegendColor> */}
                  <LegendLabel bgColor={ele?.color || sovColorBox[i]}>
                    {ele?.label}
                  </LegendLabel>
                  <LegendValue>
                    {ele?.value && formatNumber(ele?.value)}
                  </LegendValue>
                </TooltipLegendContainer>
              ))}
            {widget?.component === 'top_journalist_by_sentiment' && (
              <TooltipLegendContainer>
                {tooltip2d?.positiveFlag && (
                  <>
                    <LegendLabel bgColor={greenColorGradients?.green50}>
                      Positive Results
                    </LegendLabel>
                    <LegendValue>{tooltip2d?.positiveValue}</LegendValue>
                  </>
                )}
                {!tooltip2d?.positiveFlag && (
                  <>
                    <LegendLabel bgColor={redColorGradients?.red50}>
                      Negative Results
                    </LegendLabel>
                    <LegendValue>{tooltip2d?.negativeValue}</LegendValue>
                  </>
                )}
              </TooltipLegendContainer>
            )}
          </TooltipLegendWrp>
        </TooltipWrapper>
      )}
    </>
  );
};

export default GraphTooltip;

GraphTooltip.propTypes = {
  type: Proptypes.string,
  tooltipData: Proptypes.object.isRequired,
  widget: Proptypes.object,
  storyAnalysisChart: Proptypes.bool,
};
