import * as d3 from 'd3';
import { colorBox, initialValues } from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';
import geoJSONData from './geoJson.json';
import {
  coolGrayColorGradients,
  purpleColorGradients,
} from '../../constants/graph-colors';
import { formatNumber } from '../utils/graphGrid';

export const worldMap = function worldMap() {
  let config = {
    ...initialValues,
    interpolateColors: [
      purpleColorGradients.purple40,
      purpleColorGradients.purple50,
      purpleColorGradients.purple60,
      purpleColorGradients.purple70,
      purpleColorGradients.purple80,
    ],
    strokeWidth: 1,
    strokeColor: '#fff',
    defaultColor: coolGrayColorGradients.coolGray20,
  };

  // Draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      // Find the country in geoJSON and update the value
      geoJSONData.features.forEach((feature) => {
        const country = data.find(
          (item) => item.label === feature.properties.iso_a2
        );
        feature.properties.value = country?.value;
      });

      const projection = d3
        .geoEquirectangular()
        .fitSize([config.width, config.height], geoJSONData);

      const pathGenerator = d3.geoPath().projection(projection);

      const sampleMap = geoJSONData.features.map((item) => {
        return Number(item.properties.value);
      });

      const minValue = d3.min(sampleMap);
      const maxValue = d3.max(sampleMap);

      const colorScale = d3
        .scaleQuantize()
        .domain([minValue, maxValue])
        .range(config.interpolateColors);

      // Paths rendering
      selected
        .selectAll('.worldmap-path')
        .data(geoJSONData.features)
        .join(
          (enter) => {
            enter
              .append('path')
              .attr('class', 'worldmap-path')
              .attr('d', (d) => pathGenerator(d))
              .attr('stroke-width', config.strokeWidth)
              .attr('stroke', config.strokeColor)
              .style('fill', function (d, i) {
                const uRate = d.properties.value;
                const color = uRate ? colorScale(uRate) : config.defaultColor;
                return color;
              });
          },
          (update) =>
            update
              .attr('d', (d) => pathGenerator(d))
              .attr('stroke-width', config.strokeWidth)
              .attr('stroke', config.strokeColor)
              .style('fill', function (d, i) {
                const uRate = d.properties.value;
                const color = uRate ? colorScale(uRate) : config.defaultColor;
                return color;
              }),

          (exit) => {
            exit.remove();
          }
        );

      // Circles rendering
      if (config.showCount) {
        selected
          .selectAll('.worldmap-circle-group')
          .data(geoJSONData.features.filter((d) => d.properties.value > 0))
          .join(
            (enter) => {
              const group = enter
                .append('g')
                .attr('class', 'worldmap-circle-group');

              group
                .append('ellipse') // Change circle to ellipse to elongate
                .attr('cx', (d) => pathGenerator.centroid(d)[0])
                .attr('cy', (d) => pathGenerator.centroid(d)[1])
                .attr('rx', 16) // Horizontal radius
                .attr('ry', 14) // Vertical radius
                .attr('fill', 'white') // Circle background
                .attr('stroke-width', 1);

              group
                .append('text')
                .attr('x', (d) => pathGenerator.centroid(d)[0])
                .attr('y', (d) => pathGenerator.centroid(d)[1] + 4) // Text position
                .attr('text-anchor', 'middle')
                .attr('font-size', '11px')
                .attr('fill', 'black')
                .style('font-weight', 'bold') // Make text bold
                .text((d) => formatNumber(d.properties.value));
            },
            (update) => {
              update
                .select('circle') // Update ellipse instead of circle
                .attr('cx', (d) => pathGenerator.centroid(d)[0])
                .attr('cy', (d) => pathGenerator.centroid(d)[1])
                .attr('rx', 16) // Horizontal radius
                .attr('ry', 14); // Vertical radius

              update
                .select('text')
                .attr('x', (d) => pathGenerator.centroid(d)[0])
                .attr('y', (d) => pathGenerator.centroid(d)[1] + 4)
                .style('font-weight', 'bold') // Keep text bold
                .text((d) => formatNumber(d.properties.value));
            },
            (exit) => {
              exit.remove();
            }
          );

        // Pass circle data in the same format as the path data to setUpEvents
        setUpEvents(config, selected, 'worldmap-circle-group');
      }

      // Pass path data to setUpEvents
      setUpEvents(config, selected, 'worldmap-path');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
