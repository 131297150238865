import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { coolGrayColorGradients } from '../../constants/graph-colors';

// Styled Components
const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const SentimentCircle = styled(Circle)`
  border: 2px solid ${(props) => props.borderColor};
`;

const Label = styled.span`
  font-size: 11px;
  color: ${coolGrayColorGradients.coolGray60};
`;

// Component
const BarGraphLegend = ({ legends, sentimentLegends }) => {
  return (
    <LegendContainer>
      {legends.map((legend, index) => (
        <LegendItem key={index}>
          <Circle color={legend.color} />
          <Label>{legend.label}</Label>
        </LegendItem>
      ))}
      <Label>| Net Sentiment :</Label>
      {sentimentLegends.map((sentiment, index) => (
        <LegendItem key={index}>
          <SentimentCircle
            color={sentiment.circleColor}
            borderColor={sentiment.borderColor}
          />
          <Label>{sentiment.label}</Label>
        </LegendItem>
      ))}
    </LegendContainer>
  );
};

BarGraphLegend.propTypes = {
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  sentimentLegends: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      circleColor: PropTypes.string.isRequired,
      borderColor: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BarGraphLegend;
