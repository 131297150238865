import {
  peopleCoverage,
  topJournalistBySentiment,
  topSourceBySentiment,
  peoplePopularTopics,
  mediaType,
  peopleCoverageOnKeyTopics,
  peopleReachOverTimeColors,
  topInfluencerChartMapData as TempTopInfluencerChartMapData,
  peopleReachOverTimeLightColors,
} from '../../hooks/data/peopleData';
import {
  addCountPrefix,
  capitalizeWords,
  toCamelCase,
} from '../../constants/utils';
import {
  blueColorGradients,
  purpleColorGradients,
  tealColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  orangeColorGradients,
} from '../../constants/graph-colors';
import { getFormattedDate } from '../../hooks/useCharts';
import { traditionalMediaTypes, socialMediaTypes } from '../../constants/index';
import { WordCloudColors } from '../../hooks/data/colors';
import { compaignMapData } from '../../hooks/data/advancedDashboardData';
import { CampaignMonitor } from '../../graphs/utils/graphConst';
import { topThemeChartMapData as TempTopThemeChartMapData } from '../../hooks/data/advancedSocialCampaign';
import { topAuthorChartMapData as tempTopAuthorChartMapData } from '../../hooks/data/chartData';
import { hasNonZeroValues } from '../../hooks/usePeopleCharts';

export function people_mapping(resData, graphType, chartName) {
  if (graphType === 'people_coverage_over_time') {
    if (resData?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(peopleCoverage));

    //   const selectedMedia = getSelectedTypes(payload);
    //   if (selectedMedia === 'all') {
    mapData.title = chartName || 'Results Over Time - Traditional';
    //   }

    // mapData.mediaTypes = payload?.media_types
    //   ? payload?.media_types?.filter((x) => traditional?.includes(x))
    //   : [...traditional];

    mapData.mediaTypes = [...traditionalMediaTypes];

    const totalArticlesMapData = mapData.data.summary;

    totalArticlesMapData.value = String(addCountPrefix(resData?.total_count));
    totalArticlesMapData.originalCount = resData?.total_count;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = resData?.data?.map((x) => {
      const formattedDate = getFormattedDate(x?.label ?? x?.date);
      return {
        ...x,
        label: formattedDate,
        gucci: String(x?.value),
        date: x?.label ?? x?.date,
        color: purpleColorGradients?.purple60,
      };
    });
    mapData.data.summary.value = String(addCountPrefix(resData?.total_count));
    mapData.shouldShowGraph = resData?.data?.some((items) => items.value !== 0);
    return mapData;
  } else if (graphType === 'people_coverage_over_time_social') {
    if (resData?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(peopleCoverage));

    //   const selectedMedia = getSelectedTypes(payload);
    //   if (selectedMedia === 'all') {
    mapData.title = chartName || 'Results Over Time - Social';
    //   }

    mapData.mediaTypes = [...socialMediaTypes];

    const totalArticlesMapData = mapData.data.summary;

    totalArticlesMapData.value = String(addCountPrefix(resData?.total_count));
    mapData.originalCount = resData?.total_count;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = resData?.data?.map((x) => {
      const formattedDate = getFormattedDate(x?.label ?? x?.date);
      return {
        ...x,
        label: formattedDate,
        gucci: String(x?.value),
        date: x?.label ?? x?.date,
        color: purpleColorGradients?.purple60,
      };
    });
    mapData.data.summary.value = String(addCountPrefix(resData?.total_count));
    mapData.shouldShowGraph = resData?.data?.some((items) => items.value !== 0);
    return mapData;
  } else if (graphType === 'people_top_journalist_by_sentiment') {
    if (resData?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(topJournalistBySentiment));
    //   if (selectedMedia === 'all') {
    mapData.title =
      chartName || 'Media Contributors by Sentiment - Traditional';
    //   }

    mapData.data.data = resData?.data
      .filter(
        (filterItem) =>
          !(
            filterItem?.positive === 0 &&
            filterItem?.negative === 0 &&
            filterItem?.neutral === 0
          )
      )
      .map((item) => {
        const label = toCamelCase(item?.label);
        return {
          author_id: item.author_id,
          label,
          positive: item.positive,
          negative: item.negative,
          neutral: item.neutral,
        };
      });
    mapData.data.summary.value = String(addCountPrefix(resData?.total_count));
    mapData.originalCount = resData?.total_count;
    mapData.shouldShowGraph = resData?.data?.length > 0;
    return mapData;
  } else if (graphType === 'people_top_source_by_sentiment') {
    if (resData?.error) {
      return {};
    }
    const sentimentChartMapData = JSON.parse(
      JSON.stringify(topSourceBySentiment)
    );

    sentimentChartMapData.data.data = resData?.data?.map((item) => ({
      label: capitalizeWords(item.source_name),
      neg: item.negative.toString(),
      pos: item.positive.toString(),
      neu: item.neutral.toString(),
    }));
    sentimentChartMapData.data.summary.value = String(
      addCountPrefix(resData?.total_count)
    );
    sentimentChartMapData.title = chartName;
    sentimentChartMapData.originalCount = resData?.total_count;
    sentimentChartMapData.shouldShowGraph = resData?.data?.length > 0;
    return sentimentChartMapData;
  } else if (graphType === 'people_popular_topics') {
    if (resData?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(peoplePopularTopics));
    const counts = {
      PERSONS: 0,
      COMPANIES: 0,
      LOCATIONS: 0,
      HASHTAGS: 0,
      OTHERS: 0,
    };
    const legendsConfig = {
      PERSONS: { label: 'People', color: magentaColorGradients.magenta60 },
      COMPANIES: {
        label: 'Organizations',
        color: purpleColorGradients.purple60,
      },
      LOCATIONS: { label: 'Locations', color: orangeColorGradients.orange60 },
      HASHTAGS: { label: 'Hashtags', color: cyanColorGradients.cyan50 },
      OTHERS: { label: 'Others', color: tealColorGradients.teal50 },
    };
    mapData.data.data = resData?.data
      ?.filter((x) => {
        return x?.type !== 'LOCATIONS';
      })
      ?.map((item, i) => {
        if (item?.type in counts) {
          counts[item.type] += 1;
        }
        return {
          label: item.label,
          value: item.article_count,
          type: item?.type,
          labelColor: WordCloudColors[item?.type],
          color: WordCloudColors[item?.type],
        };
      });
    const legends = Object.keys(counts)
      .filter((key) => counts[key] > 0)
      .map((key) => legendsConfig[key]);
    mapData.data.legends = legends;
    mapData.title = chartName;
    mapData.data.summary.value = String(addCountPrefix(resData?.total_count));
    mapData.originalCount = resData?.total_count;
    mapData.shouldShowGraph = resData?.data?.length > 0;
    return mapData;
  } else if (graphType === 'people_media_type') {
    if (resData?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(mediaType));

    let totalOnlineCount = 0;
    let totalPrintCount = 0;
    let totalBlogsCount = 0;
    let totalForumsCount = 0;
    let totalReviewsCount = 0;
    let totalTwitterCount = 0;
    let totalRedditCount = 0;
    let totalYouTubeCount = 0;
    resData?.data?.forEach((x) => {
      x.media_type_count = Array.isArray(x?.media_type_count)
        ? x?.media_type_count
        : [x?.media_type_count];

      const onlineMedia = x?.media_type_count?.find(
        (media) => media.label === 'Online'
      );
      const PrintMedia = x?.media_type_count?.find(
        (media) => media.label === 'Print'
      );
      const forumMedia = x?.media_type_count?.find(
        (media) => media.label === 'Forums'
      );
      const blogMedia = x?.media_type_count?.find(
        (media) => media.label === 'Blogs'
      );
      const reviewMedia = x?.media_type_count?.find(
        (media) => media.label === 'Reviews'
      );
      const twitterMedia = x?.media_type_count?.find(
        (media) => media.label === 'X (Twitter)'
      );
      const redditMedia = x?.media_type_count?.find(
        (media) => media.label === 'Reddit'
      );
      const YouTubeMedia = x?.media_type_count?.find(
        (media) => media.label === 'YouTube'
      );
      const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
      const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
      const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
      const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
      const twitterDocCount = twitterMedia ? twitterMedia?.doc_count : 0;
      const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
      const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
      const YouTubeDocCount = YouTubeMedia ? YouTubeMedia.doc_count : 0;
      totalOnlineCount += onlineDocCount;
      totalPrintCount += printDocCount;
      totalBlogsCount += blogDocCount;
      totalReviewsCount += reviewDocCount;
      totalForumsCount += forumDocCount;
      totalTwitterCount += twitterDocCount;
      totalRedditCount += redditDocCount;
      totalYouTubeCount += YouTubeDocCount;
    });

    mapData.data.data = resData?.data?.map((item) => {
      const formattedDate = getFormattedDate(item?.label || item?.date);
      item.media_type_count = Array.isArray(item?.media_type_count)
        ? item?.media_type_count
        : [item?.media_type_count];
      return {
        date: item?.label || item?.date,
        label: formattedDate,
        print:
          item.media_type_count.find((type) => type.label === 'Print')?.value ||
          0,
        online:
          item.media_type_count.find((type) => type.label === 'Online')
            ?.value || 0,
        forum:
          item.media_type_count.find((type) => type.label === 'Forums')
            ?.value || 0,
        blog:
          item.media_type_count.find((type) => type.label === 'Blogs')?.value ||
          0,
        review:
          item.media_type_count.find((type) => type.label === 'Reviews')
            ?.value || 0,
        twitter:
          item.media_type_count.find((type) => type.label === 'X (Twitter)')
            ?.value || 0,
        reddit:
          item.media_type_count.find((type) => type.label === 'Reddit')
            ?.value || 0,
        YouTube:
          item.media_type_count.find((type) => type.label === 'YouTube')
            ?.value || 0,
      };
    });
    mapData.data.summary.value = String(addCountPrefix(resData?.total_count));
    mapData.originalCount = resData?.total_count;
    mapData.data.summary = {
      ...mapData.data.summary,
      totalOnlineCount,
      totalPrintCount,
      totalBlogsCount,
      totalForumsCount,
      totalReviewsCount,
      totalTwitterCount,
      totalRedditCount,
      totalYouTubeCount,
      mediaType: resData?.media_filter ?? null,
    };
    mapData.title = chartName;
    mapData.shouldShowGraph = resData?.data?.length > 0;
    return mapData;
  } else if (graphType === 'people_coverage_on_key_topics') {
    if (resData?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(peopleCoverageOnKeyTopics));

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(resData?.overall_total_count)
    );
    mapData.originalCount = resData?.overall_total_count;
    mapData.title = chartName || 'Coverage on Key Topics';

    mapData.data.footerLabels = resData?.persons?.map((person, index) => {
      return {
        label: person?.name,
        value: person?.name,
        color: peopleReachOverTimeColors[index],
      };
    });

    mapData.data.labels = Object.keys(resData?.common_labels)?.map(
      (label, index) => {
        return {
          label,
          value: `value${index}`,
        };
      }
    );

    let largestValue = -Infinity;

    function createObjectByPersonName(data, personName) {
      const result = {};

      Object.entries(data).forEach(([key, value], index) => {
        if (value[personName] !== undefined) {
          result[`keywords${index}`] = value[personName]?.keyword;
          result[`value${index}`] =
            largestValue === 0
              ? 0
              : parseInt((value[personName]?.count / largestValue) * 100);
        } else {
          result[`value${index}`] = '0';
        }
      });
      return result;
    }

    const processDataForLargerValue = (data, personName) => {
      Object.entries(data).forEach(([key, value], index) => {
        if (
          value[personName] !== undefined &&
          parseInt(value[personName]?.count) > largestValue
        ) {
          largestValue = parseInt(value[personName]?.count);
        }
      });
    };

    resData?.persons?.forEach((person, index) => {
      processDataForLargerValue(resData?.common_labels, person?.name);
    });

    mapData.data.data = resData?.persons?.map((person, index) => {
      const remainingData = createObjectByPersonName(
        resData?.common_labels,
        person?.name
      );
      return {
        label: person?.name,
        ...remainingData,
        color: peopleReachOverTimeColors[index],
        recentSearchId: person?.recent_search_id,
      };
    });

    mapData.shouldShowGraph = resData?.overall_total_count > 0;

    return mapData;
  } else if (graphType === 'people-impact') {
    if (resData?.error) {
      return {};
    }
    const data = JSON.parse(JSON.stringify(compaignMapData));
    data.title = chartName || 'People Impact';
    function flattenData(inputData) {
      return inputData.map((personObj) => {
        const personName = Object.keys(personObj)[0];
        const personData = personObj[personName];
        const recentSearchId = personObj.recent_search_id;

        return {
          label: personName,
          total_count: personData.total_count,
          reach: personData.reach,
          positive_sentiment: personData.positive_sentiment,
          negative_sentiment: personData.negative_sentiment,
          neutral_sentiment: personData.neutral_sentiment,
          net_sentiment: personData.net_sentiment,
          recent_search_id: recentSearchId,
          recentSearchId,
        };
      });
    }

    const personData = flattenData(resData?.persons);

    const newArray = [];

    const totalCount = personData.reduce((a, c) => a + c.total_count, 0);
    personData.forEach((dataPoint, index) => {
      const xValue = dataPoint.net_sentiment;

      const yValue = dataPoint.reach;
      const radius = Number((dataPoint.total_count / totalCount).toFixed(2));
      const articleCount = dataPoint.total_count;
      const colorIndex = index % CampaignMonitor.length;
      const newObject = {
        label: dataPoint.label,
        keyword: dataPoint.keyword,
        xValue: parseFloat(xValue.toFixed(2)),
        yValue,
        radius: (radius / 2).toFixed(2).toString(),
        color: peopleReachOverTimeColors[colorIndex],
        articleCount,
        recentSearchId: dataPoint?.recentSearchId,
      };

      newArray.push(newObject);
    });

    const totalArticlesMapData = data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(resData?.overall_total_count)
    );
    data.originalCount = resData?.overall_total_count;
    data.summary = totalArticlesMapData;
    data.data = newArray || [];
    data.shouldShowGraph = personData.every(
      (item, i) =>
        item?.net_sentiment === 0 &&
        item?.reach === 0 &&
        item?.total_count === 0
    );
    return data;
  } else if (graphType === 'people_top_score_comparison') {
    if (resData?.error) {
      return {};
    }
    const topInfluencerChartMapData = JSON.parse(
      JSON.stringify(TempTopInfluencerChartMapData)
    );

    topInfluencerChartMapData.title = chartName || 'Score Card';
    topInfluencerChartMapData.data.title = 'Score Card';

    const totalArticlesMapData = topInfluencerChartMapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(resData?.overall_total_count)
    );
    topInfluencerChartMapData.data.summary = totalArticlesMapData;
    topInfluencerChartMapData.originalCount = resData?.overall_total_count;
    const topAuthorRes = resData?.data;
    function transformData(data, personsData) {
      return data.map((item, index) => {
        const [name, details] = Object.entries(item).find(
          ([key]) => key !== 'recent_search_id'
        );
        return {
          name: personsData[index]?.profile_name || name,
          designation: personsData[index]?.profile_designation,
          personInfo: {
            name,
            designation: personsData[index]?.profile_designation,
            profile_url: personsData[index]?.profile_url,
            profile_name: personsData[index]?.profile_name,
            profile_designation: personsData[index]?.profile_designation,
          },
          results: details.total_count,
          reach: details.reach,
          netSentiment: details.net_sentiment,
          recentSearchId: item.recent_search_id,
          label: name,
        };
      });
    }

    const updatedTopInfluencerData = transformData(
      resData?.persons,
      resData?.persons_information
    );
    topInfluencerChartMapData.data.data = updatedTopInfluencerData || [];
    topInfluencerChartMapData.shouldShowGraph =
      updatedTopInfluencerData?.length > 0;

    return topInfluencerChartMapData;
  } else if (graphType === 'people_popular_topic_themes') {
    if (resData?.error) {
      return {};
    }
    const topThemeChartMapData = JSON.parse(
      JSON.stringify(TempTopThemeChartMapData)
    );
    const totalArticlesMapData = topThemeChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(resData?.total_count));
    topThemeChartMapData.data.summary = totalArticlesMapData;

    const topThemeRes = resData?.data;

    const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
      return {
        ...x,
        value: x?.article_count,
        secondaryValue: x.secondary_theme,
        label: x?.primary_theme,
        thresholdValue: x?.article_count,
        keyword: x?.keyword,
      };
    });
    topThemeChartMapData.title = chartName;

    topThemeChartMapData.data.data = updatedTopSourcedata || [];
    topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
    return topThemeChartMapData;
  } else if (graphType === 'people_volume_comparison_analysis') {
    if (resData?.error) {
      return {};
    }
    const topAuthorChartMapData = JSON.parse(
      JSON.stringify(tempTopAuthorChartMapData)
    );

    topAuthorChartMapData.title = chartName || 'Volume';
    topAuthorChartMapData.data.title = 'Volume';

    const totalArticlesMapData = topAuthorChartMapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(resData?.overall_total_count)
    );
    topAuthorChartMapData.data.summary = totalArticlesMapData;
    topAuthorChartMapData.originalCount = resData?.overall_total_count;
    const topAuthorRes = resData?.persons;

    const updatedTopSourcedata = topAuthorRes?.slice(0, 10)?.map((x, i) => {
      const personKey = Object.keys(x)[0]; // Get the "Person 1" or "Person 2"

      return {
        label: personKey,
        value: x[personKey].total_count,
        thresholdValue: x[personKey]?.total_count,
        color: peopleReachOverTimeColors[i],
        lightColor: peopleReachOverTimeLightColors[i],
        labelColor: blueColorGradients.blue60,
        recentSearchId: x?.recent_search_id,
      };
    });

    topAuthorChartMapData.data.data = updatedTopSourcedata || [];
    topAuthorChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
    return topAuthorChartMapData;
  } else if (graphType === 'people-reach-over-time-comparison') {
    if (resData?.error) {
      return {};
    }
    function transformData(inputData) {
      // Step 1: Create total count object
      const totalCounts = inputData.reduce((acc, personObj) => {
        const personName = Object.keys(personObj)[0];
        const totalCount = personObj[personName].total_count;
        acc[personName] = totalCount;
        return acc;
      }, {});

      // Step 2: Create date-wise values array
      const dates = inputData[0][Object.keys(inputData[0])[0]].data.map(
        (entry) => entry.label
      );

      const dateWiseData = dates.map((date) => {
        const values = inputData.map((personObj) => {
          const personName = Object.keys(personObj)[0];
          const personData = personObj[personName].data.find(
            (d) => d.label === date
          );
          return {
            label: personName,
            value: personData ? parseFloat(personData.sum_reach) : 0,
            recentSearchId: personObj?.recent_search_id,
          };
        });
        return {
          date,
          values,
        };
      });

      return { totalCounts, dateWiseData };
    }

    // Example usage:

    // Call the function and get results
    const { totalCounts, dateWiseData } = transformData(resData?.persons);

    const labels = Object.keys(totalCounts)?.map((key, index) => ({
      label: key,
      value: key?.split(' ').join('_'),
      color: peopleReachOverTimeColors[index],
    }));

    const mapData = JSON.parse(JSON.stringify(peopleCoverage));

    mapData.title = chartName || 'Reach Over Time';
    mapData.customClassName = 'people-reach-over-time-comparison';

    const totalArticlesMapData = mapData.data.summary;

    totalArticlesMapData.value = String(
      addCountPrefix(resData?.overall_total_count)
    );
    mapData.originalCount = resData?.overall_total_count;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = dateWiseData?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);
      function arrayToObject(arr) {
        return arr.reduce((acc, { label, value }) => {
          const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
          acc[formattedLabel] = value;
          return acc;
        }, {});
      }
      return {
        ...x,
        label: formattedDate,
        date: x?.date,
        ...arrayToObject(x?.values),
      };
    });
    mapData.data.labels = labels;
    mapData.data.summary.value = String(
      addCountPrefix(resData?.overall_total_count)
    );
    mapData.shouldShowGraph = hasNonZeroValues(dateWiseData);
    return mapData;
  } else if (graphType === 'people_top_journalist_by_sentiment_social') {
    if (resData?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(topJournalistBySentiment));
    // if (selectedMedia === 'all') {
    mapData.title = chartName || 'Media Contributors by Sentiment - Social';
    // }
    mapData.data.data = resData.data
      .filter(
        (filterItem) =>
          !(
            filterItem?.positive === 0 &&
            filterItem?.negative === 0 &&
            filterItem?.neutral === 0
          )
      )
      .map((item) => {
        const label = toCamelCase(item?.label);
        return {
          author_id: item.author_id,
          label,
          positive: item.positive,
          negative: item.negative,
          neutral: item.neutral,
        };
      });
    mapData.data.summary.value = String(addCountPrefix(resData?.total_count));
    mapData.originalCount = resData?.total_count;
    mapData.shouldShowGraph = resData?.data?.length > 0;

    return mapData;
  }
}
