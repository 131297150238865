import {
  contentDashboardEngagementData,
  topEngagingPostsChartMapData,
} from '../../hooks/data/advancedDashboardData';
import {
  topAuthorChartMapData as tempTopAuthorChartMapData,
  sentimentChartMapData as tempSentimentChartMapData,
} from '../../hooks/data/chartData';
import {
  addCountPrefix,
  calculatePercentage,
  capitalize,
} from '../../constants/utils';
import { capitalizeFirstLetter } from '../../utils';
import {
  blueColorGradients,
  cyanColorGradients,
  greenColorGradients,
  magentaColorGradients,
  purpleColorGradients,
  redColorGradients,
} from '../../constants/graph-colors';

import { transformDocCountData } from '../../hooks/useAdvancedSocialCampaign';
import twitterLogo from '../../assets/img/twitterLogo.png';
import redditLogo from '../../assets/img/reddit.png';

export function content_dashboard_mapping(response, graphType, chartName) {
  if (graphType === 'advanced_content_type') {
    if (response?.error) {
      return {};
    }
    const contentTypeChartMapData = JSON.parse(
      JSON.stringify(tempTopAuthorChartMapData)
    );

    const totalArticlesMapData = contentTypeChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    contentTypeChartMapData.data.summary = totalArticlesMapData;

    const topAuthorRes = response?.data || {};

    const updatedTopSourcedata = Object.keys(topAuthorRes)?.map(
      (contentLabel, i) => {
        return {
          author_id: i,
          value: topAuthorRes?.[contentLabel],
          label: capitalizeFirstLetter(contentLabel),
          // thresholdValue: x?.count,
          color: '#22B9FF',
          labelColor: blueColorGradients.blue60,
        };
      }
    );

    contentTypeChartMapData.data.data = updatedTopSourcedata || [];
    contentTypeChartMapData.title = chartName || 'Content Type';
    contentTypeChartMapData.data.title = 'Content Type';
    contentTypeChartMapData.component = 'top_author';
    contentTypeChartMapData.slotType = 'full';
    contentTypeChartMapData.shouldShowGraph = updatedTopSourcedata?.length > 0;

    return contentTypeChartMapData;
  } else if (graphType === 'advanced_content_promoted_organic') {
    if (response?.error) {
      return {};
    }
    const promotedOrganicChartMapData = JSON.parse(
      JSON.stringify(tempSentimentChartMapData)
    );

    // Set Percentage here
    const percentage = calculatePercentage(response);
    const sentimeMentMapData = promotedOrganicChartMapData?.data.data;
    const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
      return {
        ...x,
        value: percentage[x.label],
        labelColor: x?.color,
      };
    });

    const totalArticlesMapData = promotedOrganicChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    promotedOrganicChartMapData.data.summary = totalArticlesMapData;
    promotedOrganicChartMapData.data.data = updatedSentimentMapData || [];
    promotedOrganicChartMapData.title = chartName || 'Promoted/Organic';
    promotedOrganicChartMapData.slotType = 'half';
    promotedOrganicChartMapData.shouldShowGraph = true;

    if (response) {
      promotedOrganicChartMapData.originalData = response;
    }

    return promotedOrganicChartMapData;
  } else if (graphType === 'advanced_content_engagement') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(contentDashboardEngagementData));
    const transformedData = transformDocCountData(response?.data);

    mapData.data.data.data = transformedData?.data;
    mapData.data.data.summary.value = String(
      addCountPrefix(response?.total_count)
    );
    mapData.title = chartName;
    mapData.data.data.labels = transformedData?.labels;
    mapData.info = transformedData?.info;
    mapData.data.shouldShowGraph = response?.total_count > 0;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } else if (graphType === 'advanced_content_top_posts') {
    if (response?.error) {
      return {};
    }
    const topEngagingPostsChartData = JSON.parse(
      JSON.stringify(topEngagingPostsChartMapData)
    );

    const totalArticlesMapData = topEngagingPostsChartData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topEngagingPostsChartData.data.summary = totalArticlesMapData;

    const topEngageRes = response?.data;

    const updatedTopInfluencerData = topEngageRes?.slice(0, 10)?.map((x, i) => {
      return {
        postTitle: x?.title,
        postContributor: x?.author,
        postType: x?.post_type,
        author_id: x?.author_id,
        post_id: x?.id,
        channel: x?.Channel,
        impressions: x?.impressions,
        engagements: x?.engagements,
        potentialReach: x?.reach,
        logoUrl: '',
      };
    });
    topEngagingPostsChartData.title = chartName;
    topEngagingPostsChartData.data.data = updatedTopInfluencerData || [];

    topEngagingPostsChartData.slotType = 'full';
    topEngagingPostsChartData.shouldShowGraph = topEngageRes?.length > 0;

    return topEngagingPostsChartData;
  }
}
