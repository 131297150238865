import styled, { keyframes } from 'styled-components';

const loadKeyframes = keyframes`
   0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
`;

export const Loader = styled.div`
  &.loader,
  &.loader:before,
  &.loader:after {
    background: #ffffff;
    -webkit-animation: ${loadKeyframes} 1s infinite ease-in-out;
    animation: ${loadKeyframes} 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  &.loader {
    color: #ffffff;
    text-indent: -9999em;
    margin: 8rem auto;
    position: relative;
    font-size: ${({ size }) => size};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  &.loader:before,
  &.loader:after {
    position: absolute;
    top: 0;
    content: '';
  }
  &.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &.loader:after {
    left: 1.5em;
  }
`;
