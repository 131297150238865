import {
  purpleColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  redColorGradients,
  greenColorGradients,
  coolGrayColorGradients,
  yellowColorGradients,
  tealColorGradients,
  blueColorGradients,
} from '../../constants/graph-colors';
import { dummyNetworkData } from '../../graphs/NetworkCluster/networkCluster';

export const peoplePopularTopics = {
  title: 'Word Cloud',
  subTitle: '',
  component: 'influencer_wordcloud',
  graphType: 'word_cloud',
  slotType: 'half',
  data: {
    title: 'Word Cloud',
    subtitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: 'Total',
    },
    data: [
      {
        label: 'Theme 1',
        value: 222000,
        color: '#695DFB',
      },
      {
        label: 'Theme 2',
        value: 150000,
        color: '#FF737D',
      },
      {
        label: 'Theme 3',
        value: 125000,
        color: '#FFB673',
      },
      {
        label: 'Theme 4',
        value: 100000,
        color: '#AB5BEA',
      },
      {
        label: 'Theme 5',
        value: 75000,
        color: '#E3D022',
      },
      {
        label: 'Theme 6',
        value: 60000,
        color: '#73CDFF',
      },
      {
        label: 'Theme 7',
        value: 55000,
        color: '#C3C8DC',
      },
      {
        label: 'Theme 8',
        value: 50000,
        color: '#92E385',
      },
      {
        label: 'Theme 9',
        value: 40000,
        color: '#FFCF73',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const keyInfluencerChartMapData = {
  title: 'Key Influencer',
  subTitle: '',
  component: 'key_influencer',
  graphType: 'top_author_bar',
  data: {
    title: 'Key Influencer',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Post', value: 'postTitle' },
      { label: 'Post Contributor', value: 'postContributor' },
      { label: 'Channel', value: 'channel' },
      { label: 'Post Type', value: 'postType' },
      { label: 'Impression', value: 'impressions' },
      { label: 'Engagement', value: 'engagements' },
      { label: 'Potential Reach', value: 'potentialReach' },
    ],
    data: [
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const geographicalWorldMapData = {
  title: 'Geographical Breakdown',
  subTitle: '',
  component: 'influencer_geographical',
  graphType: 'world_map',
  slotType: 'half',
  data: {
    title: 'Defect Distribution',
    subtitle: '2020-04-17',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [
      { label: 'IND', value: 2213 },
      { label: 'USA', value: 24 },
      { label: 'AUS', value: 53 },
      { label: 'NZL', value: 53 },
      { label: 'PAK', value: 5523 },
      { label: 'RUS', value: 1523 },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const sectionInfluencersChartMapData = {
  title: 'Section Influencers',
  subTitle: '',
  component: 'key_influencer',
  graphType: 'top_author_bar',
  slotType: 'half',
  data: {
    title: 'Section Influencers',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Rank', value: 'rank' },
      { label: 'Influencer Name', value: 'postContributor' },
      { label: 'Geography', value: 'country' },
      { label: 'Followers', value: 'followers', rightAlign: true },
      { label: 'Post Related To Topic', value: 'totalPost', rightAlign: true },
      { label: 'Total Reach', value: 'reach', rightAlign: true },
      { label: 'Total Impressions', value: 'impressions', rightAlign: true },
      { label: 'Total Engagement', value: 'engagements', rightAlign: true },
      { label: 'Net Sentiment', value: 'net_sentiments', rightAlign: true },
    ],
    data: [
      {
        rank: 1,
        country: 'US',
        postContributor: 'Name I/D',
        followers: '656877',
        totalPost: '67',
        reach: '7218',
        engagements: '3192',
        impressions: '4345',
        net_sentiments: '4345',
      },
      {
        rank: 2,
        country: 'UK',
        postContributor: 'Name I/D',
        followers: '500000',
        totalPost: '50',
        reach: '6000',
        engagements: '2500',
        impressions: '4100',
        net_sentiments: '3100',
      },
      {
        rank: 3,
        country: 'CA',
        postContributor: 'Name I/D',
        followers: '450000',
        totalPost: '45',
        reach: '5900',
        engagements: '2400',
        impressions: '4000',
        net_sentiments: '3000',
      },
      {
        rank: 4,
        country: 'AU',
        postContributor: 'Name I/D',
        followers: '420000',
        totalPost: '42',
        reach: '5800',
        engagements: '2300',
        impressions: '3900',
        net_sentiments: '2900',
      },
      {
        rank: 5,
        country: 'IN',
        postContributor: 'Name I/D',
        followers: '400000',
        totalPost: '40',
        reach: '5700',
        engagements: '2200',
        impressions: '3800',
        net_sentiments: '2800',
      },
      {
        rank: 6,
        country: 'DE',
        postContributor: 'Name I/D',
        followers: '380000',
        totalPost: '38',
        reach: '5600',
        engagements: '2100',
        impressions: '3700',
        net_sentiments: '2700',
      },
      {
        rank: 7,
        country: 'FR',
        postContributor: 'Name I/D',
        followers: '360000',
        totalPost: '36',
        reach: '5500',
        engagements: '2000',
        impressions: '3600',
        net_sentiments: '2600',
      },
      {
        rank: 8,
        country: 'JP',
        postContributor: 'Name I/D',
        followers: '340000',
        totalPost: '34',
        reach: '5400',
        engagements: '1900',
        impressions: '3500',
        net_sentiments: '2500',
      },
      {
        rank: 9,
        country: 'BR',
        postContributor: 'Name I/D',
        followers: '320000',
        totalPost: '32',
        reach: '5300',
        engagements: '1800',
        impressions: '3400',
        net_sentiments: '2400',
      },
      {
        rank: 10,
        country: 'IT',
        postContributor: 'Name I/D',
        followers: '300000',
        totalPost: '30',
        reach: '5200',
        engagements: '1700',
        impressions: '3300',
        net_sentiments: '2300',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const resultOverTimeBrandMapData = {
  title: 'Result Over Time',
  subTitle: 'Total Result',
  component: 'section_trends',
  customClassName: 'section_trends',
  graphType: 'grouped_column_bar',
  slotType: 'full',
  dataType: ['2d'],
  data: {
    title: '',
    subtitle: '',
    summary: {
      subtext: '',
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: 'Compliance Categories',
    },
    data: [
      {
        label: 'Jan',
        gucci: '10000',
        chanel: '350000',
        sephora: '170000',
      },
    ],
    labels: [],
    info: [],
  },
};

export const influencerSentimentClusterChartMapData = {
  title: 'Overall Themes – Bubble chart',
  subTitle: '',
  component: 'influencer_cluster_chart',
  graphType: 'bubble_cluster',
  customClassName: 'influencer_cluster_chart',
  slotType: 'full',
  shouldShowGraph: true,
  data: {
    title: 'Overall Themes – Bubble chart',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '0',
      subLabel: '132/237',
    },
    resultsInFig: [
      { label: 'Total Authors in the Network', value: 5000, description: '' },
      { label: 'Distinct Clusters', value: 5000, description: '' },
    ],
    data: dummyNetworkData,
    labels: [
      {
        label: 'Cluster 1',
        value: 'Cluster 1',
      },
      {
        label: 'Cluster 2',
        value: 'Cluster 2',
      },
      {
        label: 'Cluster 3',
        value: 'Cluster 3',
      },
      {
        label: 'Cluster 4',
        value: 'Cluster 4',
      },
      {
        label: 'Cluster 5',
        value: 'Cluster 5',
      },
    ],
    info: [],
  },
};
