import React from 'react';
import Articles from '../articles';
import PropTypes from 'prop-types';
import { NoDataText, SummaryMarkdown, TopTrendingContainer } from './index.sc';

const TopTrendingChart = ({ data = [], setPage }) => {
  const topTrendingData = data;

  return (
    <TopTrendingContainer>
      {topTrendingData?.title === 'Summary' ? (
        topTrendingData?.data !== null &&
        topTrendingData?.data?.summary !== '' ? (
          <SummaryMarkdown className="markdown-styles">
            {topTrendingData?.data?.summary}
          </SummaryMarkdown>
        ) : (
          <NoDataText>No Data</NoDataText>
        )
      ) : topTrendingData?.data?.length > 0 ? (
        <Articles
          topTrendingChart={true}
          articles={topTrendingData?.data}
          setInsearchPageNum={setPage}
          name="INSEARCH"
        />
      ) : (
        <NoDataText>No Data</NoDataText>
      )}
    </TopTrendingContainer>
  );
};

export default TopTrendingChart;

TopTrendingChart.propTypes = {
  data: PropTypes?.array,
  setPage: PropTypes.func,
};
