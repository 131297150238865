import React, { useRef, useEffect } from 'react';
import { createGaugeChart } from './gauge.graph';
import { GraphContainer } from '../../styles/index.sc';
import PropTypes from 'prop-types';
import ResizeHandlerHOC from '../utils/resizeHandlerHOC';

const GaugeChart = ({ data, config }) => {
  const netSentiment = data?.netSentiment;
  const svgRef = useRef(null);

  useEffect(() => {
    if (!svgRef.current || typeof netSentiment !== 'number') {
      return;
    }

    createGaugeChart(svgRef.current, netSentiment, config);
  }, [netSentiment, config]);

  return (
    <GraphContainer className="gauge-container">
      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        style={{ minHeight: '200px' }}
      />
    </GraphContainer>
  );
};

GaugeChart.propTypes = {
  data: PropTypes.shape({
    netSentiment: PropTypes.number,
  }).isRequired,
  config: PropTypes.object,
};

export default ResizeHandlerHOC(GaugeChart);
