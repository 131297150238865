import React from 'react';
import SearchAlphaAIInsights from '../alpha-ai-insights';
import PropTypes from 'prop-types';
import {
  DisclaimerContainer,
  DisclaimerText,
  ErrorText,
  PopupStyledMarkdown,
  PopupTxtWpr,
} from './index.sc';
import AiLoader from '../ai-loader';
import { formatDecimalNumbers } from '../../constants/utils';
import ResultsAIIinsightsDisclaimerIcon from '../../assets/icons/ResultsAIInsightsDisclaimerIcon';

const SearchAIInsightsPopup = ({
  popup,
  setInsightType,
  insightType,
  data,
  togglePopup,
  setShowAIInsight,
  isLoading = false,
  isSuccessful = true,
  handleOverallInsights = () => {},
}) => {
  return (
    <div>
      <SearchAlphaAIInsights
        popup={true}
        setInsightType={setInsightType}
        setShowAIInsight={setShowAIInsight}
        handleOverallInsights={handleOverallInsights}
      />
      <PopupTxtWpr>
        {isLoading ? (
          <AiLoader />
        ) : isSuccessful ? (
          <PopupStyledMarkdown>
            {data && formatDecimalNumbers(data)}
          </PopupStyledMarkdown>
        ) : (
          <ErrorText>
            An error occurred while processing the data. Please try again later
          </ErrorText>
        )}
      </PopupTxtWpr>
      <DisclaimerContainer>
        <div>
          <ResultsAIIinsightsDisclaimerIcon />
        </div>
        <DisclaimerText>
          This Platform uses AI-powered analytics to generate insights that are
          provided strictly for informational purposes only. Please review
          findings before making critical decisions.
        </DisclaimerText>
      </DisclaimerContainer>
    </div>
  );
};

export default SearchAIInsightsPopup;

SearchAIInsightsPopup.propTypes = {
  popup: PropTypes.bool,
  setInsightType: PropTypes.func,
  insightType: PropTypes.string,
  data: PropTypes.func,
  togglePopup: PropTypes.func,
  setShowAIInsight: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccessful: PropTypes.bool,
  handleOverallInsights: PropTypes.func,
};
