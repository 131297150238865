import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.04);
  backdrop-filter: transparent;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 32px;
  position: absolute;
  width: 480px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: auto;
  height: 46px;
`;

export const Title = styled.h1`
  font-size: 20px;
  line-height: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0px;
`;

export const SubTitle = styled.h2`
  line-height: 18px;
  font-weight: 500;
  height: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #343a3f;
  margin: 4px 0px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
`;

export const SwitchTxt = styled.h1`
  width: 70%;
  height: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #343a3f;
  align-item: center;
`;

export const ToggleSwitchWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 24px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 44px;
`;

export const ButtonSecondary = styled.button`
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  gap: 10px;
  width: 75px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 7px;
  cursor: pointer;
`;

export const ButtonPrimary = styled.button`
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  gap: 16px;
  width: 65px;
  background: #5f39f8;
  border-radius: 8px;
  border: 1px solid #5f39f8;
  cursor: pointer;
  color: white;
`;
