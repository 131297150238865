// index.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  TooltipOneContainer,
  TooltipTwoContainer,
  TooltipThreeContainer,
  TooltipFourContainer,
  TooltipFiveContainer,
  TooltipLabel,
  TooltipValue,
  TooltipDate,
} from './index.sc';
import {
  blueColorGradients,
  coolGrayColorGradients,
  greenColorGradients,
  purpleColorGradients,
  redColorGradients,
} from '../../../../constants/graph-colors';
import { formatNumber } from '../../../../utils';
import { addCountPrefix } from '../../../../constants/utils';

const SocialTooltip = ({ tooltipData, widget }) => {
  const renderTooltip = () => {
    switch (widget.customClassName) {
      case 'brand_switch_sov':
        return (
          <TooltipOneContainer>
            <TooltipDate color={tooltipData?.data?.color}>
              {tooltipData?.data?.label}
            </TooltipDate>
            <TooltipValue>
              {formatNumber(tooltipData?.data.doc_count)}
            </TooltipValue>
          </TooltipOneContainer>
        );
      case 'brand_switch_sentiment_analysis':
        return (
          <TooltipOneContainer>
            <TooltipDate color={tooltipData?.data?.color}>
              {tooltipData?.data?.label}
            </TooltipDate>
            <TooltipValue>
              {formatNumber(tooltipData?.data.accValue)}
            </TooltipValue>
          </TooltipOneContainer>
        );
      default:
        return null;
    }
  };

  return <div>{renderTooltip()}</div>;
};

SocialTooltip.propTypes = {
  tooltipData: PropTypes.object,
  widget: PropTypes.object,
};

export default SocialTooltip;
