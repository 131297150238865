import { useQuery } from '@tanstack/react-query';
import { axiosPost, get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import {
  brandSwitchSentimentMapData,
  brandSwitchSOVMapData,
  brandSwitchTopThemesMapData,
  brandSwitchSentimentReasonMapData,
} from './data/advancedBrandSwitch';
import {
  addCountPrefix,
  calculatePercentage,
  sentiment,
} from '../constants/utils';
import { sovColorBox } from '../graphs/utils/graphConst';
import { peopleReachOverTimeColors } from './data/peopleData';
import { brandSwitchChartsQuery } from '../utils/apiQueryFunctionChecker';

const sovProperties = {
  volume: 'doc_count',
  impression: 'impressions',
  traditional_reach: 'reach',
  social_reach: 'reach',
  engagement: 'engagement',
};

export const getSentimentCountMappedData = (data) => {
  let response = data;

  const sentimentChartMapData = JSON.parse(
    JSON.stringify(brandSwitchSentimentMapData)
  );
  response = response?.sentiment_count;

  // Set Percentage here
  const percentage = calculatePercentage(response);
  const sentimentMapData = sentimentChartMapData?.data.data;
  const updatedSentimentMapData = sentimentMapData?.map((x) => {
    return {
      ...x,
      value: percentage[x.label],
      sentiment_negative: 123,
    };
  });

  const totalArticlesMapData = sentimentChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  sentimentChartMapData.originalCount = response?.total_count;
  sentimentChartMapData.data.summary = totalArticlesMapData;
  sentimentChartMapData.data.data = updatedSentimentMapData || [];
  sentimentChartMapData.data.netSentiment = response?.net_sentiment || 0;
  sentimentChartMapData.shouldShowGraph = response?.total_count > 0;
  sentimentChartMapData.switchedTo = response?.brand?.[0];
  sentimentChartMapData.customClassName =
    'brand_switch_sentiment_analysis_count';

  if (response) {
    sentimentChartMapData.originalData = response;
  }

  return sentimentChartMapData;
};

export const getSentimentReasonsMappedData = (data) => {
  let response = data;
  response = response?.data;

  if (
    response &&
    typeof response === 'object' &&
    Object.keys(response).length > 0
  ) {
    const [brandName, sentimentAnalysisData] = Object.entries(response)[0];

    const sentimentPositiveChartMapData = JSON.parse(
      JSON.stringify(brandSwitchSentimentReasonMapData)
    );
    const sentimentNegativeChartMapData = JSON.parse(
      JSON.stringify(brandSwitchSentimentReasonMapData)
    );

    const positiveData = [];
    const negativeData = [];
    let total_count = 0;
    let total_negative_count = 0;
    let total_positive_count = 0;

    // Process each key and create separate objects for positive, neutral, and negative
    for (const key in sentimentAnalysisData) {
      const {
        positive,
        neutral,
        negative,
        pos_article_ids,
        neg_article_ids,
        neu_article_ids,
      } = sentimentAnalysisData[key];

      // Calculate totals
      total_positive_count += positive + neutral;
      total_negative_count += negative;
      total_count = total_positive_count + total_negative_count;

      // Create separate objects for positive and neutral sentiments
      if (positive > 0) {
        positiveData.push({
          label: key,
          pos: positive,
          neu: 0,
          neg: 0,
          pos_article_ids,
        });
      }

      if (neutral > 0) {
        positiveData.push({
          label: `  ${key}`, // Add two spaces before the label for neutral sentiment
          pos: 0,
          neu: 0, // keeping it zero dont show neutral sentiment
          neg: 0,
          neu_article_ids,
        });
      }

      // Create object for negative sentiment
      if (negative > 0) {
        negativeData.push({
          label: key,
          pos: 0,
          neu: 0,
          neg: negative,
          neg_article_ids,
        });
      }
    }

    // Calculate percentages
    const positivePercentage = (
      (total_positive_count / total_count) *
      100
    ).toFixed(2);
    const negativePercentage = (
      (total_negative_count / total_count) *
      100
    ).toFixed(2);

    // Sort the data arrays
    positiveData.sort((a, b) => b.pos + b.neu - (a.pos + a.neu));
    negativeData.sort((a, b) => b.neg - a.neg);

    // Get top 10 entries for each
    const topPositiveData = positiveData.slice(0, 10);
    const topNegativeData = negativeData.slice(0, 10);

    // Populate positive chart data
    sentimentPositiveChartMapData.data.data = topPositiveData;
    sentimentPositiveChartMapData.data.percentage = positivePercentage;
    sentimentPositiveChartMapData.data.brandName = brandName;
    sentimentPositiveChartMapData.data.summary.value = String(
      addCountPrefix(total_count)
    );
    sentimentPositiveChartMapData.originalCount = total_count;
    sentimentPositiveChartMapData.shouldShowGraph = total_count > 0;
    sentimentPositiveChartMapData.originalData = response;

    // Populate negative chart data
    sentimentNegativeChartMapData.data.data = topNegativeData;
    sentimentNegativeChartMapData.data.percentage = negativePercentage;
    sentimentNegativeChartMapData.data.brandName = brandName;
    sentimentNegativeChartMapData.data.summary.value = String(
      addCountPrefix(total_negative_count)
    );
    sentimentNegativeChartMapData.originalCount = total_negative_count;
    sentimentNegativeChartMapData.shouldShowGraph = total_negative_count > 0;
    sentimentNegativeChartMapData.originalData = response;

    return {
      sentimentPositiveChartMapData,
      sentimentNegativeChartMapData,
    };
  }
};

const getIndustryTypes = async () => {
  const { data: response } = await get(
    `${API}/dashboard-brand-switch/switching-parameters`,
    {}
  );

  if (!response) {
    response = {};
  }

  const data = JSON.parse(JSON.stringify(response));
  return data;
};

const getSOVChartData = async (filter, payload) => {
  const queryString = `${objectToQueryString({
    ...filter,
  })}`;
  const { data: response } = await axiosPost(
    `${API}/dashboard-brand-switch/sov?${queryString}`,
    payload
  );
  if (!response) {
    response = {};
  }
  const payloadBrandKeys = payload
    ?.map((item) => item.brand_name)
    ?.flat(Infinity);

  const mapData = JSON.parse(JSON.stringify(brandSwitchSOVMapData));

  const totalArticlesMapData = mapData.data.summary;

  const totalCount = response?.overall_total_count;

  totalArticlesMapData.value = String(addCountPrefix(totalCount));

  mapData.data.summary = totalArticlesMapData;

  mapData.originalCount = totalCount;
  mapData.data.data =
    payloadBrandKeys
      ?.map((keyName) => {
        const sovData = response?.brands
          ?.map((brandLabel, i) => {
            const brandKeyData = brandLabel?.[keyName]?.data?.[0];

            if (!brandKeyData) return null;

            return {
              ...brandKeyData,

              value: brandKeyData?.[sovProperties[filter?.value ?? 'volume']],

              label: brandKeyData?.label.replace(/^"(.*)"$/, '$1'),
              filter: 'volume' || filter?.value,
              graph: 'brand_switch_sov',
            };
          })
          ?.filter(Boolean);

        return sovData;
      })
      ?.flat() || [];

  mapData.data.legends = [...payloadBrandKeys]?.map((x) => {
    return {
      label: x,
      value: String(x).toLowerCase(),
    };
  });

  // Create a map to store the index of each legend
  const legendIndexMap = {};

  mapData?.data?.legends?.forEach((legend, index) => {
    legendIndexMap[legend?.label] = index;
  });

  // Sort the list data using the custom comparison
  mapData?.data?.data?.sort(
    (a, b) => (legendIndexMap[a?.label] || 0) - (legendIndexMap[b?.label] || 0)
  );

  mapData.data.data = mapData.data.data.map((item, i) => {
    return {
      ...item,
      color: sovColorBox[i],
    };
  });

  mapData.shouldShowGraph =
    response?.data?.some((item) => item?.doc_count !== 0) ||
    (response?.overall_total_count && response?.overall_total_count > 0);

  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

const getTopThemesCoverageChartData = async (filters, payload) => {
  const queryString = `${objectToQueryString({
    ...filters,
    dashboard_name: 'brandSwitch',
    chart_name: 'brand_switch_top_themes_coverage',
  })}`;
  const { data: response } = await axiosPost(
    `${API}/dashboard-brand-switch/top-themes-coverage?${queryString}`,
    payload
  );
  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(brandSwitchTopThemesMapData));

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.originalCount = response?.overall_total_count;

  mapData.data.footerLabels = response?.brand_keywords?.map((brand, index) => {
    return {
      label: brand?.name,
      value: brand?.name,
      color: peopleReachOverTimeColors[index],
    };
  });

  mapData.data.labels = Object.keys(response?.common_labels)?.map(
    (label, index) => {
      return {
        label,
        value: `value${index}`,
      };
    }
  );

  let largestValue = -Infinity;

  function createObjectByBrandName(data, brandName) {
    const result = {};
    Object.entries(data).forEach(([key, value], index) => {
      if (value[brandName] !== undefined) {
        result[`keywords${index}`] = value[brandName]?.keyword;
        result[`value${index}`] =
          largestValue === 0
            ? 0
            : parseInt((value[brandName]?.count / largestValue) * 100);
      } else {
        result[`value${index}`] = '0';
      }
    });
    return result;
  }

  const processDataForLargerValue = (data, brandName) => {
    Object.entries(data).forEach(([key, value], index) => {
      if (
        value[brandName] !== undefined &&
        parseInt(value[brandName]?.count) > largestValue
      ) {
        largestValue = parseInt(value[brandName]?.count);
      }
    });
  };

  response?.brand_keywords?.forEach((brand, index) => {
    processDataForLargerValue(response?.common_labels, brand?.name);
  });

  mapData.data.data = response?.brand_keywords?.map((brand, index) => {
    const remainingData = createObjectByBrandName(
      response?.common_labels,
      brand?.name
    );
    return {
      label: brand?.name,
      ...remainingData,
      color: peopleReachOverTimeColors[index],
      recentSearchId: brand?.recent_search_id,
    };
  });

  mapData.shouldShowGraph = response?.overall_total_count > 0;

  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

const getSentimentAnalysisChartData = async (filters, payload) => {
  const title = 'Switch-in Switch-out Index';
  try {
    const queryString = `${objectToQueryString({
      ...filters,
    })}`;
    let { data: response } = await axiosPost(
      `${API}/dashboard-brand-switch/sentiment-analysis?${queryString}`,
      payload
    );
    if (!response) {
      response = [];
    }

    const sentimentCountChartMapData = getSentimentCountMappedData(response);
    const sentimentReasonsChartMapData =
      getSentimentReasonsMappedData(response);

    return { sentimentCountChartMapData, sentimentReasonsChartMapData };
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useIndustryTypes = () => {
  return useQuery({
    queryKey: ['dashboard_advanced_brand_switch_industry_types'],
    queryFn: () => getIndustryTypes(),
    refetchOnWindowFocus: false,
  });
};

export const useBrandSwitchSOVChartData = (filters, payload, isEnabled) => {
  const { name, keywords, competitorsTab = [], industryTypeId } = payload[0];
  const body = [
    {
      brand_name: name,
      brand_keywords: keywords,
    },
    ...competitorsTab,
  ];
  if (industryTypeId) {
    filters = { ...filters, industry_type_id: industryTypeId.id };
  }

  return useQuery({
    queryKey: ['dashboard_advanced_brand_switch_sov_chart', filters, body],
    queryFn: () => getSOVChartData(filters, body),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandSwitchChartsQuery(filters),
  });
};

export const useBrandSwitchTopThemesCoverageChartData = (
  filters,
  payload,
  isEnabled
) => {
  const { name, keywords, competitorsTab = [], industryTypeId } = payload[0];
  const body = [
    {
      brand_name: name,
      brand_keywords: keywords,
    },
    ...competitorsTab,
  ];
  if (industryTypeId) {
    filters = { ...filters, industry_type_id: industryTypeId.id };
  }
  return useQuery({
    queryKey: [
      'dashboard_advanced_brand_switch_top_themes_chart',
      filters,
      body,
    ],
    queryFn: () => getTopThemesCoverageChartData(filters, body),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandSwitchChartsQuery(filters),
  });
};

export const useBrandSwitchSentimentAnalysisChartData = (
  filters,
  payload,
  isEnabled
) => {
  const { name, keywords, competitorsTab = [], industryTypeId } = payload[0];
  const body = [
    {
      brand_name: name,
      brand_keywords: keywords,
    },
    ...competitorsTab,
  ];
  if (industryTypeId) {
    filters = { ...filters, industry_type_id: industryTypeId.id };
  }
  return useQuery({
    queryKey: [
      'dashboard_advanced_brand_switch_sentiment_analysis_chart',
      filters,
      body,
    ],
    queryFn: () => getSentimentAnalysisChartData(filters, body),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandSwitchChartsQuery(filters),
  });
};
