import { useQuery } from '@tanstack/react-query';
import { axiosPost, get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import {
  volumeAnalysis,
  topJournalistBySentiment,
  topSourceBySentiment,
  peopleCoverage,
  peoplePopularTopics,
  mediaType,
  peopleCoverageOnKeyTopics,
  peopleReachOverTimeColors,
  peopleReachOverTimeLightColors,
  topInfluencerChartMapData as TempTopInfluencerChartMapData,
} from './data/peopleData';
import {
  addCountPrefix,
  calculatePercentageIncrease,
  capitalizeWords,
  toCamelCase,
} from '../constants/utils';
import { getFormattedDate } from './useCharts';
import { colors, WordCloudColors } from './data/colors';
import {
  blueColorGradients,
  purpleColorGradients,
  tealColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  orangeColorGradients,
} from '../constants/graph-colors';
import { topThemeChartMapData as TempTopThemeChartMapData } from './data/advancedSocialCampaign';
import { CampaignMonitor, concentricPie } from '../graphs/utils/graphConst';
import { topAuthorChartMapData as tempTopAuthorChartMapData } from './data/chartData';
import { mediaTypeBrandMapData } from './data/dashboardData';
import { compaignMapData } from './data/advancedDashboardData';
import { peopleChartsQuery } from '../utils/apiQueryFunctionChecker';

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    const isSocial = filters?.media_types?.some((x) => social?.includes(x));
    const isTraditional = filters?.media_types?.some((x) =>
      traditional?.includes(x)
    );
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const socialCardMapping = [
  'Social Mentions',
  'Social Reach',
  // 'Total Engagement',
  'Social Unique Users',
];

const traditionalMapping = [
  'Total Results',
  'Total Reach',
  'Total AVE',
  'Traditional Reach',
];
const all = [
  'Total Results',
  'Traditional Reach',
  'Social Mentions',
  'Social Reach',
  'Total AVE',
  // 'Total Engagement',
  'Social Unique Users',
];

const getTitle = (tile, selectedMedia) => {
  if (tile?.label === 'Social Unique Users') {
    return 'Unique Users';
  } else if (
    (tile?.label === 'Total Articles' || tile?.label === 'Social Mentions') &&
    selectedMedia === 'all'
  ) {
    return tile?.label;
  } else if (
    (tile?.label === 'Total Articles' || tile?.label === 'Social Mentions') &&
    selectedMedia !== 'all'
  ) {
    return 'Social Mentions';
  } else if (
    tile?.label === 'Traditional Reach' &&
    selectedMedia === 'traditional'
  ) {
    return 'Total Reach';
  }
  return tile?.label;
};

const mediaMappings = {
  social: socialCardMapping,
  all,
  traditional: traditionalMapping,
};

// people dashboard volume analysis
const getDashboardVolumeAnalysisData = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-people/volume-analysis?${queryString}`,
    {}
  );

  const volumAnalysisMapData = JSON.parse(JSON.stringify(volumeAnalysis));
  const totalArticlesMapData = volumAnalysisMapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.[0] ? response?.[0]?.current_count : 0)
  );

  volumAnalysisMapData.data.summary.label = String(
    addCountPrefix(response?.[0]?.current_count)
  );
  const colorData = volumAnalysisMapData?.data?.data;
  const selectedMedia = getSelectedTypes(payload);

  const filteredData = response?.filter((tile) => {
    const cards = mediaMappings[selectedMedia];
    if (!cards?.includes(tile?.label)) {
      return false;
    }
    return true;
  });

  const data = filteredData
    ?.map((x, i) => {
      const { isIncreased, percentageIncrease: change } =
        calculatePercentageIncrease(x?.current_count, x?.previous_count);
      const colorIndex = i % colorData?.length;
      const cards = mediaMappings[selectedMedia];
      const order = cards.findIndex((card) => card === x?.label);
      return {
        label: getTitle(x, selectedMedia),
        value: x?.current_count,
        change,
        isIncreased,
        color: colorData[colorIndex]?.color,
        previousCount: x?.previous_count,
        order,
      };
    })
    .sort((a, b) => a.order - b.order);

  volumAnalysisMapData.data.data = data;
  volumAnalysisMapData.shouldShowGraph = response?.length > 0;
  if (response) {
    volumAnalysisMapData.originalData = response;
  }
  return volumAnalysisMapData;
};

export const useDashboardPeopleAnalysisData = (filters, people, isEnabled) => {
  filters = { ...filters, person_name: people };
  return useQuery({
    queryKey: ['dashboard_people-volume-analysis', filters, people],
    queryFn: () => getDashboardVolumeAnalysisData(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};
// people sentiments
export const getPeopleSentimentChartData = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/source-sentiment-analysis?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const sentimentChartMapData = JSON.parse(
    JSON.stringify(topSourceBySentiment)
  );

  sentimentChartMapData.data.data = response.data.map((item) => ({
    label: capitalizeWords(item.source_name),
    source: item.label,
    neg: item.negative.toString(),
    pos: item.positive.toString(),
    neu: item.neutral.toString(),
  }));
  sentimentChartMapData.data.summary.value = String(
    addCountPrefix(response?.total_count)
  );
  sentimentChartMapData.originalCount = response?.total_count;
  sentimentChartMapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    sentimentChartMapData.originalData = response;
  }
  return sentimentChartMapData;
};

export const usePeopleDashboardSentimentChartData = (
  filters,
  people,
  isEnabled
) => {
  filters = { ...filters, person_name: people };
  return useQuery({
    queryKey: ['dashboard-people_sentiment-analysis', filters, people],
    queryFn: () => getPeopleSentimentChartData(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};

export const encloseWordsInDoubleQuotes = (data) => {
  const regex = /^"(.*)"$/;
  if (Array.isArray(data)) {
    return data.map((item) => {
      if (regex.test(item)) {
        return item;
      }
      return `"${item}"`;
    });
  }
  if (regex.test(data)) {
    return data;
  }
  return `"${data}"`;
};
// get journalist data
export const getPeopleJournalistSentimetChart = async (payload, people) => {
  const selectedMedia = getSelectedTypes(payload);
  const mediaFilter = () => {
    if (selectedMedia === 'all') {
      return {
        ...payload,
        media_types: traditional,
      };
    }
    return payload;
  };

  const queryString = `${objectToQueryString({
    ...mediaFilter(),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/journalist-sentiment-analysis?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(topJournalistBySentiment));
  if (selectedMedia === 'all') {
    mapData.title = 'Media Contributors by Sentiment - Traditional';
  }

  mapData.data.data = response.data
    .filter(
      (filterItem) =>
        !(
          filterItem?.positive === 0 &&
          filterItem?.negative === 0 &&
          filterItem?.neutral === 0
        )
    )
    .map((item) => {
      const label = toCamelCase(item?.label);
      return {
        author_id: item.author_id,
        label,
        positive: item.positive,
        negative: item.negative,
        neutral: item.neutral,
      };
    });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.originalCount = response?.total_count;
  mapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleDashboardJournalistSentimetChart = (
  filters,
  people,
  isEnabled
) => {
  filters = { ...filters, person_name: people };
  return useQuery({
    queryKey: ['dashboard-people_journalist_sentiment', filters, people],
    queryFn: () => getPeopleJournalistSentimetChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};
export const getSocialPeopleJournalistSentimetChart = async (
  payload,
  people
) => {
  const selectedMedia = getSelectedTypes(payload);
  const mediaFilter = () => {
    if (selectedMedia === 'all') {
      return {
        ...payload,
        media_types: social,
      };
    }
    return payload;
  };

  const queryString = `${objectToQueryString({
    ...mediaFilter(),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/journalist-sentiment-analysis?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(topJournalistBySentiment));
  if (selectedMedia === 'all') {
    mapData.title = 'Media Contributors by Sentiment - Social';
  }

  mapData.data.data = response.data
    .filter(
      (filterItem) =>
        !(
          filterItem?.positive === 0 &&
          filterItem?.negative === 0 &&
          filterItem?.neutral === 0
        )
    )
    .map((item) => {
      const label = toCamelCase(item?.label);
      return {
        author_id: item.author_id,
        label,
        positive: item.positive,
        negative: item.negative,
        neutral: item.neutral,
      };
    });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.originalCount = response?.total_count;
  mapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useSocailPeopleDashboardJournalistSentimetChart = (
  filters,
  people,
  isEnabled
) => {
  filters = { ...filters, person_name: people };
  return useQuery({
    queryKey: ['dashboard-people_journalist_sentiment_social', filters, people],
    queryFn: () => getSocialPeopleJournalistSentimetChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};
// get coverage
export const getPeopleCoverageChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/coverage-over-time?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(peopleCoverage));

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Traditional';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => traditional?.includes(x))
    : [...traditional];

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  totalArticlesMapData.originalCount = response?.total_count;
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = response?.data?.map((x) => {
    const formattedDate = getFormattedDate(x?.label ?? x?.date);
    return {
      ...x,
      label: formattedDate,
      gucci: String(x?.value),
      date: x?.label ?? x?.date,
      color: purpleColorGradients?.purple60,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.some((items) => items.value !== 0);
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleCoverageChart = (filters, people, isEnabled) => {
  filters = { ...filters, person_name: people };
  return useQuery({
    queryKey: ['dashboard-people_coverage', filters, people],
    queryFn: () => getPeopleCoverageChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};

export const getPeopleCoverageSocialChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/social-coverage-over-time?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(peopleCoverage));

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Social';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => social.includes(x))
    : [...social];

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.originalCount = response?.total_count;
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = response?.data?.map((x) => {
    const formattedDate = getFormattedDate(x?.label ?? x?.date);
    return {
      ...x,
      label: formattedDate,
      gucci: String(x?.value),
      date: x?.label ?? x?.date,
      color: purpleColorGradients?.purple60,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.some((items) => items.value !== 0);
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleCoverageSocialChart = (filters, people, isEnabled) => {
  filters = { ...filters, person_name: people };
  return useQuery({
    queryKey: ['dashboard-people_coverage_social', filters, people],
    queryFn: () => getPeopleCoverageSocialChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};

// get popular topics
export const getPeoplePopularTopicsChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/wordcloud-count?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(peoplePopularTopics));
  const counts = {
    PERSONS: 0,
    COMPANIES: 0,
    LOCATIONS: 0,
    HASHTAGS: 0,
    OTHERS: 0,
  };
  const legendsConfig = {
    PERSONS: { label: 'People', color: magentaColorGradients.magenta60 },
    COMPANIES: { label: 'Organizations', color: purpleColorGradients.purple60 },
    LOCATIONS: { label: 'Locations', color: orangeColorGradients.orange60 },
    HASHTAGS: { label: 'Hashtags', color: cyanColorGradients.cyan50 },
    OTHERS: { label: 'Others', color: tealColorGradients.teal50 },
  };
  mapData.data.data = response?.data
    ?.filter((x) => {
      return x?.type !== 'LOCATIONS';
    })
    ?.map((item, i) => {
      if (item?.type in counts) {
        counts[item.type] += 1;
      }
      return {
        label: item.label,
        value: item.article_count,
        type: item?.type,
        labelColor: WordCloudColors[item?.type],
        color: WordCloudColors[item?.type],
      };
    });
  const legends = Object.keys(counts)
    .filter((key) => counts[key] > 0)
    .map((key) => legendsConfig[key]);
  mapData.data.legends = legends;
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.originalCount = response?.total_count;
  mapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeoplePopularTopicsChart = (filters, people, isEnabled) => {
  filters = {
    ...filters,
    person_name: people,
    dashboard_name: 'people',
  };
  return useQuery({
    queryKey: ['dashboard-people_popular_topics', filters, people],
    queryFn: () => getPeoplePopularTopicsChart(filters, people),
    enabled: !!isEnabled && peopleChartsQuery(filters),
    refetchOnWindowFocus: false,
  });
};

// Function to generate random colors
function getRandomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

// get media type
export const getPeopleMediaTypeChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/media-type?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(mediaType));

  let totalOnlineCount = 0;
  let totalPrintCount = 0;
  let totalBlogsCount = 0;
  let totalForumsCount = 0;
  let totalReviewsCount = 0;
  let totalTwitterCount = 0;
  let totalRedditCount = 0;
  let totalYouTubeCount = 0;
  response?.data?.forEach((x) => {
    x.media_type_count = Array.isArray(x?.media_type_count)
      ? x?.media_type_count
      : [x?.media_type_count];

    const onlineMedia = x?.media_type_count?.find(
      (media) => media.label === 'Online'
    );
    const PrintMedia = x?.media_type_count?.find(
      (media) => media.label === 'Print'
    );
    const forumMedia = x?.media_type_count?.find(
      (media) => media.label === 'Forums'
    );
    const blogMedia = x?.media_type_count?.find(
      (media) => media.label === 'Blogs'
    );
    const reviewMedia = x?.media_type_count?.find(
      (media) => media.label === 'Reviews'
    );
    const twitterMedia = x?.media_type_count?.find(
      (media) => media.label === 'X (Twitter)'
    );
    const redditMedia = x?.media_type_count?.find(
      (media) => media.label === 'Reddit'
    );
    const YouTubeMedia = x?.media_type_count?.find(
      (media) => media.label === 'YouTube'
    );
    const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
    const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
    const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
    const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
    const twitterDocCount = twitterMedia ? twitterMedia?.doc_count : 0;
    const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
    const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
    const YouTubeDocCount = YouTubeMedia ? YouTubeMedia.doc_count : 0;
    totalOnlineCount += onlineDocCount;
    totalPrintCount += printDocCount;
    totalBlogsCount += blogDocCount;
    totalReviewsCount += reviewDocCount;
    totalForumsCount += forumDocCount;
    totalTwitterCount += twitterDocCount;
    totalRedditCount += redditDocCount;
    totalYouTubeCount += YouTubeDocCount;
  });

  mapData.data.data = response.data.map((item) => {
    const formattedDate = getFormattedDate(item?.label || item?.date);
    item.media_type_count = Array.isArray(item?.media_type_count)
      ? item?.media_type_count
      : [item?.media_type_count];
    return {
      date: item?.label || item?.date,
      label: formattedDate,
      print:
        item.media_type_count.find((type) => type.label === 'Print')?.value ||
        0,
      online:
        item.media_type_count.find((type) => type.label === 'Online')?.value ||
        0,
      forum:
        item.media_type_count.find((type) => type.label === 'Forums')?.value ||
        0,
      blog:
        item.media_type_count.find((type) => type.label === 'Blogs')?.value ||
        0,
      review:
        item.media_type_count.find((type) => type.label === 'Reviews')?.value ||
        0,
      twitter:
        item.media_type_count.find((type) => type.label === 'X (Twitter)')
          ?.value || 0,
      reddit:
        item.media_type_count.find((type) => type.label === 'Reddit')?.value ||
        0,
      YouTube:
        item.media_type_count.find((type) => type.label === 'YouTube')?.value ||
        0,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.originalCount = response?.total_count;
  mapData.data.summary = {
    ...mapData.data.summary,
    totalOnlineCount,
    totalPrintCount,
    totalBlogsCount,
    totalForumsCount,
    totalReviewsCount,
    totalTwitterCount,
    totalRedditCount,
    totalYouTubeCount,
    mediaType: response?.media_filter ?? null,
  };

  mapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleMediaTypeChart = (filters, people, isEnabled) => {
  filters = { ...filters, person_name: people };
  return useQuery({
    queryKey: ['dashboard-people_media_type', filters, people],
    queryFn: () => getPeopleMediaTypeChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};

const getCoverageOnKeyTopicsChart = async (payload) => {
  payload = payload.map((item) => ({
    ...item,
    dashboard_name: 'peopleComparison',
    chart_name: 'people_coverage_on_key_topics',
  }));
  let { data: response } = await axiosPost(
    `${API}/dashboard-people/coverage-key-topics`,
    payload
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(peopleCoverageOnKeyTopics));

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.originalCount = response?.overall_total_count;

  mapData.data.footerLabels = response?.persons?.map((person, index) => {
    return {
      label: person?.name,
      value: person?.name,
      color: peopleReachOverTimeColors[index],
    };
  });

  mapData.data.labels = Object.keys(response?.common_labels)?.map(
    (label, index) => {
      return {
        label,
        value: `value${index}`,
      };
    }
  );

  let largestValue = -Infinity;

  function createObjectByPersonName(data, personName) {
    const result = {};

    Object.entries(data).forEach(([key, value], index) => {
      if (value[personName] !== undefined) {
        result[`keywords${index}`] = value[personName]?.keyword;
        result[`value${index}`] =
          largestValue === 0
            ? 0
            : parseInt((value[personName]?.count / largestValue) * 100);
      } else {
        result[`value${index}`] = '0';
      }
    });
    return result;
  }

  const processDataForLargerValue = (data, personName) => {
    Object.entries(data).forEach(([key, value], index) => {
      if (
        value[personName] !== undefined &&
        parseInt(value[personName]?.count) > largestValue
      ) {
        largestValue = parseInt(value[personName]?.count);
      }
    });
  };

  response?.persons?.forEach((person, index) => {
    processDataForLargerValue(response?.common_labels, person?.name);
  });

  mapData.data.data = response?.persons?.map((person, index) => {
    const remainingData = createObjectByPersonName(
      response?.common_labels,
      person?.name
    );
    return {
      label: person?.name,
      ...remainingData,
      color: peopleReachOverTimeColors[index],
      recentSearchId: person?.recent_search_id,
    };
  });

  mapData.shouldShowGraph = response?.overall_total_count > 0;

  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useCoverageOnKeyTopicsChart = (filters, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-people_coverage_on_key_topics', filters],
    queryFn: () => getCoverageOnKeyTopicsChart(filters),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};
const getTopThemes = async (filters, person) => {
  const queryString = `${objectToQueryString({
    ...filters,
    dashboard_name: 'people',
    chart_name: 'people_popular_topics',
  })}`;
  let { data: response } = await get(
    `${API}/search-visuals/top-themes-count?${queryString}`,
    {}
  );

  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
    return {
      ...x,
      value: x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count,
      keyword: x?.keyword,
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  // topThemeChartMapData.shouldShowGraph = true;
  return topThemeChartMapData;
};

export const useAdvancedPopularTopics = (filters, person, isEnabled) => {
  filters = { ...filters, person_name: person };
  return useQuery({
    queryKey: ['dashboard-search-visuals-top-theme-count', filters, person],
    queryFn: () => getTopThemes(filters, person),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && peopleChartsQuery(filters),
  });
};

const getVolumeComparison = async (payload) => {
  let { data: response } = await axiosPost(
    `${API}/dashboard-people/volume-comparison`,
    payload
  );

  if (!response) {
    response = {};
  }

  const topAuthorChartMapData = JSON.parse(
    JSON.stringify(tempTopAuthorChartMapData)
  );

  topAuthorChartMapData.title = 'Volume';
  topAuthorChartMapData.data.title = 'Volume';

  const totalArticlesMapData = topAuthorChartMapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  topAuthorChartMapData.data.summary = totalArticlesMapData;
  topAuthorChartMapData.originalCount = response?.overall_total_count;
  const topAuthorRes = response?.persons;

  const updatedTopSourcedata = topAuthorRes?.slice(0, 10)?.map((x, i) => {
    const personKey = Object.keys(x)[0]; // Get the "Person 1" or "Person 2"

    return {
      label: personKey,
      value: x[personKey].total_count,
      thresholdValue: x[personKey]?.total_count,
      color: peopleReachOverTimeColors[i],
      lightColor: peopleReachOverTimeLightColors[i],
      labelColor: blueColorGradients.blue60,
      recentSearchId: x?.recent_search_id,
    };
  });

  topAuthorChartMapData.data.data = updatedTopSourcedata || [];
  topAuthorChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
  if (response) {
    topAuthorChartMapData.originalData = response;
  }
  return topAuthorChartMapData;
};

export const useGetPeopleVolumeComparison = (filters, editMode) => {
  return useQuery({
    queryKey: ['top-volume-charts-people-comparison', filters],
    queryFn: () => getVolumeComparison(filters),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

const getReachOverTimeComparison = async (payload) => {
  let { data: response } = await axiosPost(
    `${API}/dashboard-people/reach-over-time-comparison`,

    payload
  );

  if (!response) {
    response = {};
  }

  function transformData(inputData) {
    // Step 1: Create total count object
    const totalCounts = inputData.reduce((acc, personObj) => {
      const personName = Object.keys(personObj)[0];
      const totalCount = personObj[personName].total_count;
      acc[personName] = totalCount;
      return acc;
    }, {});

    // Step 2: Create date-wise values array
    const dates = inputData[0][Object.keys(inputData[0])[0]].data.map(
      (entry) => entry.label
    );

    const dateWiseData = dates.map((date) => {
      const values = inputData.map((personObj) => {
        const personName = Object.keys(personObj)[0];
        const personData = personObj[personName].data.find(
          (d) => d.label === date
        );
        return {
          label: personName,
          value: personData ? parseFloat(personData.sum_reach) : 0,
          recentSearchId: personObj?.recent_search_id,
        };
      });
      return {
        date,
        values,
      };
    });

    return { totalCounts, dateWiseData };
  }

  // Example usage:

  // Call the function and get results
  const { totalCounts, dateWiseData } = transformData(response?.persons);

  const labels = Object.keys(totalCounts)?.map((key, index) => ({
    label: key,
    value: key?.split(' ').join('_'),
    color: peopleReachOverTimeColors[index],
  }));

  const mapData = JSON.parse(JSON.stringify(peopleCoverage));

  mapData.title = 'Reach Over Time';
  mapData.customClassName = 'people-reach-over-time-comparison';

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.originalCount = response?.overall_total_count;
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = dateWiseData?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);
    function arrayToObject(arr) {
      return arr.reduce((acc, { label, value }) => {
        const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
        acc[formattedLabel] = value;
        return acc;
      }, {});
    }
    return {
      ...x,
      label: formattedDate,
      date: x?.date,
      ...arrayToObject(x?.values),
    };
  });
  mapData.data.labels = labels;
  mapData.data.summary.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.shouldShowGraph = hasNonZeroValues(dateWiseData);
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export function hasNonZeroValues(data) {
  return data.some((item) =>
    item.values.some((valueObj) => valueObj.value > 0)
  );
}

export const useGetReachOverTimeComparison = (filters, editMode) => {
  return useQuery({
    queryKey: ['reach-overtime-people-comparison', filters],
    queryFn: () => getReachOverTimeComparison(filters),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

const getCampaignAnalysisData = async (payload) => {
  const { data: response } = await axiosPost(
    `${API}/dashboard-people/people-impact`,
    payload
  );

  const data = JSON.parse(JSON.stringify(compaignMapData));
  data.title = 'People Impact';
  function flattenData(inputData) {
    return inputData.map((personObj) => {
      const personName = Object.keys(personObj)[0];
      const personData = personObj[personName];
      const recentSearchId = personObj.recent_search_id;

      return {
        label: personName,
        total_count: personData.total_count,
        reach: personData.reach,
        positive_sentiment: personData.positive_sentiment,
        negative_sentiment: personData.negative_sentiment,
        neutral_sentiment: personData.neutral_sentiment,
        net_sentiment: personData.net_sentiment,
        recent_search_id: recentSearchId,
        recentSearchId,
      };
    });
  }

  const personData = flattenData(response?.persons);

  const newArray = [];

  const totalCount = personData.reduce((a, c) => a + c.total_count, 0);
  personData.forEach((dataPoint, index) => {
    const xValue = dataPoint.net_sentiment;

    const yValue = dataPoint.reach;
    const radius = Number((dataPoint.total_count / totalCount).toFixed(2));
    const articleCount = dataPoint.total_count;
    const colorIndex = index % CampaignMonitor.length;
    const newObject = {
      label: dataPoint.label,
      keyword: dataPoint.keyword,
      xValue: parseFloat(xValue.toFixed(2)),
      yValue,
      radius: (radius / 2).toFixed(2).toString(),
      color: peopleReachOverTimeColors[colorIndex],
      articleCount,
      recentSearchId: dataPoint?.recentSearchId,
    };

    newArray.push(newObject);
  });

  const totalArticlesMapData = data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  data.originalCount = response?.overall_total_count;
  data.summary = totalArticlesMapData;
  data.data = newArray || [];
  data.shouldShowGraph = personData.every(
    (item, i) =>
      item?.net_sentiment === 0 && item?.reach === 0 && item?.total_count === 0
  );
  if (response) {
    data.originalData = response;
  }
  return data;
};

export const usePeopleImpactComparison = (filters, editMode) => {
  return useQuery({
    queryKey: ['people-impact-comparison', filters],
    queryFn: () => getCampaignAnalysisData(filters),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

const getPeopleSummary = async (payload) => {
  payload = payload.map((item) => ({
    ...item,
    dashboard_name: 'peopleComparison',
    chart_name: 'person bio',
  }));
  const { data: response } = await axiosPost(
    `${API}/dashboard-people/get-people-summary`,
    payload
  );

  return response;
};

export const usePeopleSummaryCharts = (payload, editMode) => {
  return useQuery({
    queryKey: ['people-summary-charts', payload],
    queryFn: () => getPeopleSummary(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getTopScoreData = async (payload) => {
  let { data: response } = await axiosPost(
    `${API}/dashboard-people/score-card`,
    payload
  );

  if (!response) {
    response = {};
  }

  const topInfluencerChartMapData = JSON.parse(
    JSON.stringify(TempTopInfluencerChartMapData)
  );

  topInfluencerChartMapData.title = 'Score Card';
  topInfluencerChartMapData.data.title = 'Score Card';

  const totalArticlesMapData = topInfluencerChartMapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  topInfluencerChartMapData.data.summary = totalArticlesMapData;
  topInfluencerChartMapData.originalCount = response?.overall_total_count;
  const topAuthorRes = response?.data;

  function transformData(data) {
    return data.map((item) => {
      const [name, details] = Object.entries(item).find(
        ([key]) => key !== 'recent_search_id'
      );
      return {
        name,
        results: details.total_count,
        reach: details.reach,
        netSentiment: details.net_sentiment,
        recentSearchId: item.recent_search_id,
        label: name,
      };
    });
  }

  const updatedTopInfluencerData = transformData(response?.persons);
  topInfluencerChartMapData.data.data = updatedTopInfluencerData || [];
  topInfluencerChartMapData.shouldShowGraph =
    updatedTopInfluencerData?.length > 0;

  if (response) {
    topInfluencerChartMapData.originalData = response;
  }

  return topInfluencerChartMapData;
};

export const usePeopleTopScoresData = (filters, isEnabled) => {
  return useQuery({
    queryKey: ['people_top-score-charts', filters],
    queryFn: () => getTopScoreData(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};
