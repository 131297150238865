import React from 'react';
import Proptypes from 'prop-types';
import { LegendBox, LegendContainer, LegendLabel, LegendWrp } from './index.sc';
import {
  colorBox,
  networkClusterColors,
  sovColorBox,
} from '../../graphs/utils/graphConst';

const GraphLegend = ({ legendData = [], networkType = false }) => {
  return (
    <LegendWrp>
      {legendData.map((legend, i) => (
        <LegendContainer key={i}>
          {/* <LegendBox bgColor={legend?.color || colorBox[i]}></LegendBox> */}
          <LegendBox
            bgColor={
              legend?.color
                ? legend?.color
                : networkType
                ? networkClusterColors[i]
                : sovColorBox[i]
            }
          ></LegendBox>
          <LegendLabel title={legend?.label}>{legend?.label}</LegendLabel>
        </LegendContainer>
      ))}
    </LegendWrp>
  );
};

export default GraphLegend;

GraphLegend.propTypes = {
  legendData: Proptypes.arrayOf(Proptypes.object),
  networkType: Proptypes.bool,
};
