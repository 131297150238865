import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CampNoDatatxtWrp,
  FullSlot,
  // IconBox,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  SlotBody,
  SlotBodyHeader,
  SlotBodyHeaderRight,
  SlotBodyHeaderLeft,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  // SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  SlotOverviewWrapper,
  HalfSlot,
  SlotWrp,
  StageContainer,
  StageItem,
  StageDot,
  Label,
  Divider,
  SubTitleSmallWrp,
  SlotSubTitle,
  TopThemeToggleWrp,
  Iconwpr,
  SlotHeaderRight,
  SlotLeftWrp,
  LegendSection,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
import {
  BestBar,
  LinearLine,
  WordCloud,
  BigBubble,
  ScatterPlot3D,
  CoolColumn,
  ConcentricPieChart,
} from '../../../graphs';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import ChartToolTip from '../../chart-tool-tip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import { theme } from '../../../constants/theme';
import SocialTooltip from './tooltip';
import {
  coolGrayColorGradients,
  greenColorGradients,
  redColorGradients,
} from '../../../constants/graph-colors';
import { useSelector } from 'react-redux';
import ToggleSwitch from '../../toggle-switch';
import TreemapCampaign from '../../../graphs/TreeGraph/campaign-tree';

import SimpleReusableDropDown from '../../simple-dropdown';
import { VerticleDots } from '../../../assets/icons/VerticleDots';

// Edit icon imports
import DashboardPopup from '../../dasboard-popup';
import Edit2 from '../../../assets/icons/Edit2';
import EditGraphPopup from '../../edit-graph-popup';
import GraphLegend from '../../graph-legend';
import { AiButton } from '../../nav-section/index.sc';
import { DashboardType } from '../../new-dashboard-pdf/index.sc';
import { useLocation } from 'react-router-dom';

const sentiment = [
  {
    label: 'Positive',
    color: greenColorGradients.green50,
  },
  {
    label: 'Negative',
    color: redColorGradients.red50,
  },
  {
    label: 'Neutral',
    color: coolGrayColorGradients.coolGray40,
  },
];

// ###########################################################################################
// WARNING: this component is also used in Newsletter and hidden routes so please be careful #
// ###########################################################################################

const CampaignMonitor = ({
  widget,
  type = 'dashboard',
  resetSelection = false,
  handleOnClick = () => {},
  helperText = '',
  selectedComponent,
  setSelectedComponent,
  graphDownloading,
  onDownloadChartData,
  downloadFunction,
  savedSelectedChart,
  handleGraphTitleUpdate,
  isNewsletter = false,
  dashboardType,
}) => {
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const { tabsData, activeTab: activeCampaignTab } = useSelector(
    (state) => state.campaignMonitor
  );

  const legendData = [
    {
      label: 'X-Axis : Net Sentiment',
      color: '#fff',
    },
    {
      label: 'Y-Axis : Reach',
      color: '#fff',
    },
    {
      label: 'Size of Bubbles : Number of Results',
      color: '#fff',
    },
  ];

  function getWidgetData(widget, isNewsletter) {
    const transformWidget = (widgetPart) =>
      isNewsletter
        ? { ...widget, data: { data: widget?.data }, show: true }
        : { ...widgetPart };

    return {
      sentimentOverTime: transformWidget(
        widget.advanced_campaign_monitor_sentiment_over_time
      ),
      topAuthors: transformWidget(widget.advanced_campaign_monitor_top_author),
      reachOverTime: transformWidget(widget.advanced_campaign_monitor_reach),
      topThemesChart: transformWidget(widget.advanced_campaign_monitor_themes),
      wordCloud: transformWidget(widget.advanced_campaign_monitor_wordcloud),
      outletBreakDown: transformWidget(
        widget.advanced_campaign_monitor_outlet_breakdown
      ),
      reachByCampaign: transformWidget(
        widget.advanced_campaign_comparison_reach_by_campaign
      ),
      campaignAnalysis: transformWidget(
        widget.advanced_campaign_comparison_campaign_analysis
      ),
      volumeDistByChannel: transformWidget(
        widget.advanced_campaign_monitor_vol_distribution_by_channel
      ),
      compSentiment: transformWidget(
        widget.advanced_campaign_monitor_sentiment
      ),
    };
  }

  const {
    sentimentOverTime,
    topAuthors,
    reachOverTime,
    topThemesChart,
    wordCloud,
    outletBreakDown,
    reachByCampaign,
    campaignAnalysis,
    volumeDistByChannel,
    compSentiment,
  } = getWidgetData(widget, isNewsletter);

  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const [showAllSubCircles, setShowAllSubCircles] = useState(false);

  const [iconPosition, setIconPosition] = useState({});
  const [activeChart, setActiveChart] = useState(null);
  const [labels, setLabels] = useState([]);

  const [selectedChartId, setSelectedChartId] = useState('');
  const [selectedGraphEdit, setSelectedGraphEdit] = useState('');
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: '',
    chartType: widget?.graphType || '',
  });

  const location = useLocation();
  const newsletterDashboard =
    location?.pathname?.includes('news-letter') ||
    location?.pathname?.includes('sent-news-letter') ||
    location?.pathname?.includes('create-news-letter');

  const titleRefs = useRef({});
  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (reachByCampaign?.data?.data?.labels) {
      setLabels(reachByCampaign?.data?.data?.labels);
    }
  }, [reachByCampaign]);

  const calculateIconPosition = (id) => {
    if (titleRefs.current) {
      const titleWidth = titleRefs?.current[id]?.offsetWidth;

      setIconPosition((prevPositions) => ({
        ...prevPositions,
        [id]: titleWidth + 24, // Calculate icon position based on width
      }));
    }
  };

  const setTitleRef = (id, el) => {
    if (el) {
      titleRefs.current[id] = el;
    }
  };

  useEffect(() => {
    if (chartToolTip) {
      calculateIconPosition(chartToolTip); // Update width for active tooltip item
    }
  }, [chartToolTip, editChart2]);

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev === componentName ? false : componentName
    );
  };

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: {
          ...tData,
          value: tData?.articleCount || tData?.value,
        },
        rawData: d?.rawData,
      });
      setActiveChart(chartId);
    }
  };

  const handleMouseMove = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };

  const handleMouseLeave = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
      setActiveChart(null);
    }
  };

  const defaultConfig = (chartId) => {
    return {
      handleMouseEnter: (event, d, i) => handleMouseEnter(event, d, i, chartId),
      handleMouseMove: (event, d, i) => handleMouseMove(event, d, i, chartId),
      handleMouseLeave: (event, d, i) => handleMouseLeave(event, d, i, chartId),
      handleOnClick: (event, d) => handleOnClick(event, d, chartId),
    };
  };

  const handleGraphEditClick = (widget) => {
    // e.preventDefault();
    setSelectedGraphEdit(widget?.data?.title);
    setSelectedChartId(widget?.customClassName);
    setPopupIsOpen(!popupIsOpen);
  };

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  const handleEditChart = (data) => {
    setEditChart2({ ...editChart2, [selectedChartId]: { ...data } });
  };

  const renderEditIcon = (widget) => {
    if (widget?.show) {
      return (
        // role !== reader &&
        <Iconwpr
          width="1.5rem"
          height="1.5rem"
          onClick={() => handleGraphEditClick(widget)}
          className="hide-downloading"
        >
          <Edit2 />
        </Iconwpr>
      );
    }
    return null;
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();
    // handleUpdatedChart(data);
    const chartData = savedSelectedChart?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      selectedChartId,
      data?.chartType || ''
    );
    handleGraphEditClick(e);
  };

  const renderDownloadOptions = (widget) => {
    if (isNewsletter) {
      return null;
    }
    return (
      <SlotHeaderRight>
        {renderEditIcon(widget)}
        <Iconwpr
          width={'1.5rem'}
          height={'1.5rem'}
          onClick={(e) => {
            handleOptionIcon(e, widget.customClassName);
          }}
          ref={downloadRef}
          className="hide-downloading"
        >
          <VerticleDots
            color={
              openActionDropdown === widget?.customClassName
                ? '#675ef2'
                : '#5C5E60'
            }
          />
          <SimpleReusableDropDown
            isOpen={openActionDropdown === widget.customClassName}
            options={actionDropDownOptions}
            graphDownloading={graphDownloading}
            setIsOpen={setOpenActionDropDown}
          />
        </Iconwpr>
      </SlotHeaderRight>
    );
  };

  const graphData = widget;

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(
          option,
          containerRef,
          graphData[selectedComponent],
          graphData[selectedComponent]?.data?.title
        );
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(
          option,
          containerRef,
          graphData[selectedComponent],
          graphData[selectedComponent]?.data?.title
        );
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData[selectedComponent]);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData[selectedComponent]);
      },
    },
  ];

  function hasNonZeroSentiments(data = []) {
    return data?.some(
      (item) => item.neg !== '0' || item.pos !== '0' || item.neu !== '0'
    );
  }

  function hasNonEmptyCampaigns(data = []) {
    return data?.some((item) => {
      const values = Object.values(item);
      return values.some((array) => array.length > 0);
    });
  }

  function hasNonZeroValues(data = []) {
    return data?.some(
      (item) =>
        item.xValue !== 0 || item.yValue !== 0 || item.articleCount !== 0
    );
  }

  const ChartSlot =
    dashboardType === 'campaign' || dashboardType === 'newsletter'
      ? HalfSlot
      : FullSlot;

  return (
    <>
      {(activeCampaignTab !== 999 || isNewsletter) && (
        <>
          {reachOverTime?.customClassName ===
            'advanced_campaign_monitor_reach' && (
            <FullSlot className="graph-widget override-padding">
              <SlotDetailsMainWrp
                className={reachOverTime.customClassName}
                ref={
                  selectedComponent === reachOverTime.customClassName
                    ? containerRef
                    : null
                }
              >
                <SlotDetailsWrp>
                  <SlotHeader className="hide-download">
                    <SlotHeaderLeft>
                      <SlotLeftWrp>
                        <SlotTitle
                          ref={(el) =>
                            setTitleRef(reachOverTime?.customClassName, el)
                          }
                        >
                          {savedSelectedChart?.find(
                            (chart) =>
                              chart.chartId === reachOverTime?.customClassName
                          )?.chartName ||
                            editChart2[reachOverTime?.customClassName]
                              ?.chartName ||
                            'Reach Over Time'}
                        </SlotTitle>
                        <span
                          style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                          onMouseEnter={() => {
                            setChartToolTip(reachOverTime?.customClassName);
                          }}
                          onMouseLeave={() => {
                            setChartToolTip(false);
                          }}
                        >
                          {!isNewsletter && <HelpIcon />}
                        </span>
                      </SlotLeftWrp>

                      <SlotOverviewWrapper>
                        {reachOverTime.show ? (
                          <SlotOverview
                            summary={reachOverTime?.data?.data?.summary}
                          />
                        ) : (
                          <SubTitleSmallWrp>--</SubTitleSmallWrp>
                        )}
                      </SlotOverviewWrapper>
                    </SlotHeaderLeft>
                    {renderDownloadOptions(reachOverTime)}

                    {chartToolTip === reachOverTime?.customClassName && (
                      <ChartToolTip
                        text={reachOverTime?.text}
                        iconTop={-9}
                        componentTop={9}
                        componentLeft={iconPosition?.[chartToolTip]}
                      />
                    )}
                  </SlotHeader>
                  {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                  <SlotBody type={type} className="commentary">
                    <SlotBodyMain>
                      {reachOverTime.isLoading ? (
                        <Loader />
                      ) : !reachOverTime.show ? null : reachOverTime?.data?.data
                          ?.data?.length > 0 ? (
                        <LinearLine
                          data={reachOverTime?.data?.data}
                          config={{
                            graphType: 'area',
                            yAxisType: 'number',
                            yDomainMultiplayer: 0.001,
                            gridYTicks: 5,
                            yLabelAlignment: 40,
                            yAxisTicksFormat: true,
                            graphAreaWMultiplayer: 1,
                            enableTooltipTrendPointer: true,
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: 11,
                            fontColor: '#697077',
                            enableGradient: true,
                            enableAreaLine: true,
                            enableGridXLine: false,
                            enableGridYLine: true,
                            gridLineStrokeWidth: 1,
                            gridLineXStroke: '#d9dbde',
                            type: 'dashboard',
                            summaryContainerHeight: 0,
                            singleLineWrp: false,
                            showAllLabels: true,
                            ...defaultConfig(reachOverTime.customClassName),
                          }}
                          resetSelection={resetSelection}
                        />
                      ) : (
                        <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                      )}
                      {enableTooltip &&
                        activeChart === reachOverTime.customClassName && (
                          <PortalTooltip
                            isOpen={true}
                            pos={toolTipPos}
                            align={
                              toolTipPos.left > window.innerWidth / 2
                                ? 'left'
                                : 'right'
                            }
                            vAlign={
                              toolTipPos.top > window.innerHeight / 2
                                ? 'top'
                                : 'bottom'
                            }
                          >
                            <SocialTooltip
                              tooltipData={tooltipData}
                              widget={reachOverTime}
                            />
                          </PortalTooltip>
                        )}
                    </SlotBodyMain>
                  </SlotBody>
                  <SlotFooter>
                    <LegendCon></LegendCon>

                    <SlotBodyHeaderRight>
                      {' '}
                      {/* <LegendCon>
                      {widget?.data?.map((ele, i) => {
                        return (
                          <LegendItem key={i}>
                            <LegendBox legendColor={ele.color}></LegendBox>
                            <LegendLabel>{ele?.label}</LegendLabel>
                          </LegendItem>
                        );
                      })}
                    </LegendCon> */}
                    </SlotBodyHeaderRight>
                  </SlotFooter>
                </SlotDetailsWrp>
              </SlotDetailsMainWrp>
            </FullSlot>
          )}

          <SlotWrp dashboard={dashboardType === 'campaign'}>
            {topThemesChart?.customClassName ===
              'advanced_campaign_monitor_themes' && (
              <ChartSlot
                isNewsletter={isNewsletter}
                className="graph-widget override-padding"
              >
                <SlotDetailsMainWrp
                  className={topThemesChart.customClassName}
                  ref={
                    selectedComponent === topThemesChart.customClassName
                      ? containerRef
                      : null
                  }
                >
                  <SlotDetailsWrp>
                    <SlotHeader className="hide-download">
                      <SlotHeaderLeft>
                        <SlotLeftWrp>
                          <SlotTitle
                            ref={(el) =>
                              setTitleRef(topThemesChart?.customClassName, el)
                            }
                          >
                            {savedSelectedChart?.find(
                              (chart) =>
                                chart.chartId ===
                                topThemesChart?.customClassName
                            )?.chartName ||
                              editChart2[topThemesChart?.customClassName]
                                ?.chartName ||
                              'Top Themes'}
                          </SlotTitle>
                          <span
                            style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                            onMouseEnter={() => {
                              setChartToolTip(topThemesChart?.customClassName);
                            }}
                            onMouseLeave={() => {
                              setChartToolTip(false);
                            }}
                          >
                            {!isNewsletter && <HelpIcon />}
                          </span>
                        </SlotLeftWrp>

                        <SlotOverviewWrapper>
                          {topThemesChart.show ? (
                            <SlotOverview
                              summary={topThemesChart?.data?.data?.summary}
                            />
                          ) : (
                            <SubTitleSmallWrp>--</SubTitleSmallWrp>
                          )}
                        </SlotOverviewWrapper>
                      </SlotHeaderLeft>
                      {renderDownloadOptions(topThemesChart)}
                      {chartToolTip === topThemesChart?.customClassName && (
                        <ChartToolTip
                          text={topThemesChart?.text}
                          iconTop={-9}
                          componentTop={9}
                          componentLeft={iconPosition?.[chartToolTip]}
                        />
                      )}
                    </SlotHeader>
                    {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                    <SlotBody type={type} className="commentary">
                      <SlotBodyMain>
                        {topThemesChart.isLoading ? (
                          <Loader />
                        ) : !topThemesChart.show ? null : topThemesChart?.data
                            ?.data?.data?.length > 0 ? (
                          <ConcentricPieChart
                            data={topThemesChart?.data?.data?.data}
                            config={{
                              ...defaultConfig(topThemesChart.customClassName),
                              renderKeyword: 3,
                              dashboardType: 'people',
                            }}
                            resetSelection={resetSelection}
                            isFullSlot={false}
                          />
                        ) : (
                          <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                        )}
                        {enableTooltip &&
                          activeChart === topThemesChart.customClassName && (
                            <PortalTooltip
                              isOpen={true}
                              pos={toolTipPos}
                              align={
                                toolTipPos.left > window.innerWidth / 2
                                  ? 'left'
                                  : 'right'
                              }
                              vAlign={
                                toolTipPos.top > window.innerHeight / 2
                                  ? 'top'
                                  : 'bottom'
                              }
                            >
                              <SocialTooltip
                                tooltipData={tooltipData}
                                widget={topThemesChart}
                              />
                            </PortalTooltip>
                          )}
                      </SlotBodyMain>
                    </SlotBody>
                  </SlotDetailsWrp>
                </SlotDetailsMainWrp>
              </ChartSlot>
            )}
            {sentimentOverTime?.customClassName ===
              'advanced_campaign_monitor_sentiment_over_time' && (
              <ChartSlot
                isNewsletter={isNewsletter}
                className="graph-widget override-padding"
              >
                <SlotDetailsMainWrp
                  className={sentimentOverTime.customClassName}
                  ref={
                    selectedComponent === sentimentOverTime.customClassName
                      ? containerRef
                      : null
                  }
                >
                  <SlotDetailsWrp>
                    <SlotHeader className="hide-download">
                      <SlotHeaderLeft>
                        <SlotLeftWrp>
                          <SlotTitle
                            ref={(el) =>
                              setTitleRef(
                                sentimentOverTime?.customClassName,
                                el
                              )
                            }
                          >
                            {savedSelectedChart?.find(
                              (chart) =>
                                chart.chartId ===
                                sentimentOverTime?.customClassName
                            )?.chartName ||
                              editChart2[sentimentOverTime?.customClassName]
                                ?.chartName ||
                              'Sentiment'}
                          </SlotTitle>
                          <span
                            style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                            onMouseEnter={() => {
                              setChartToolTip(
                                sentimentOverTime?.customClassName
                              );
                            }}
                            onMouseLeave={() => {
                              setChartToolTip(false);
                            }}
                          >
                            {!isNewsletter && <HelpIcon />}
                          </span>
                        </SlotLeftWrp>

                        <SlotOverviewWrapper>
                          {sentimentOverTime.show ? (
                            <SlotOverview
                              summary={sentimentOverTime?.data?.data?.summary}
                            />
                          ) : (
                            <SubTitleSmallWrp>--</SubTitleSmallWrp>
                          )}
                        </SlotOverviewWrapper>
                      </SlotHeaderLeft>
                      {renderDownloadOptions(sentimentOverTime)}

                      {chartToolTip === sentimentOverTime?.customClassName && (
                        <ChartToolTip
                          text={sentimentOverTime?.text}
                          iconTop={-9}
                          componentTop={9}
                          componentLeft={iconPosition?.[chartToolTip]}
                        />
                      )}
                    </SlotHeader>
                    {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                    <SlotBody type={type} className="commentary">
                      <SlotBodyMain>
                        {sentimentOverTime.isLoading ? (
                          <Loader />
                        ) : !sentimentOverTime.show ? null : sentimentOverTime
                            ?.data?.data?.data?.length > 0 ? (
                          <LinearLine
                            data={sentimentOverTime?.data?.data}
                            config={{
                              yAxisType: 'number',
                              yDomainMultiplayer: 0.001,
                              gridYTicks: 5,
                              yLabelAlignment: 40,
                              yAxisTicksFormat: true,
                              graphAreaWMultiplayer: 1,
                              enableTooltipTrendPointer: true,
                              fontFamily: 'Inter',
                              fontWeight: 500,
                              fontSize: 11,
                              fontColor: '#697077',
                              enableGridXLine: false,
                              enableGridYLine: true,
                              gridLineStrokeWidth: 1,
                              gridLineXStroke: '#d9dbde',
                              type: 'dashboard',
                              summaryContainerHeight: 0,
                              singleLineWrp: false,
                              showAllLabels: true,
                              ...defaultConfig(
                                sentimentOverTime.customClassName
                              ),
                            }}
                            resetSelection={resetSelection}
                          />
                        ) : (
                          <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                        )}
                        {enableTooltip &&
                          activeChart === sentimentOverTime.customClassName && (
                            <PortalTooltip
                              isOpen={true}
                              pos={toolTipPos}
                              align={
                                toolTipPos.left > window.innerWidth / 2
                                  ? 'left'
                                  : 'right'
                              }
                              vAlign={
                                toolTipPos.top > window.innerHeight / 2
                                  ? 'top'
                                  : 'bottom'
                              }
                            >
                              <SocialTooltip
                                tooltipData={tooltipData}
                                widget={sentimentOverTime}
                              />
                            </PortalTooltip>
                          )}
                      </SlotBodyMain>
                    </SlotBody>
                    <SlotFooter>
                      <LegendCon></LegendCon>

                      <SlotBodyHeaderRight>
                        {' '}
                        {sentimentOverTime.show && (
                          <StageContainer>
                            {sentiment.map((sentiment, index) => (
                              <StageItem key={sentiment.label}>
                                <StageDot color={sentiment.color} />
                                <Label>{sentiment.label}</Label>
                                {index < sentiment.length - 1 && (
                                  <Divider>|</Divider>
                                )}
                              </StageItem>
                            ))}
                          </StageContainer>
                        )}
                      </SlotBodyHeaderRight>
                    </SlotFooter>
                  </SlotDetailsWrp>
                </SlotDetailsMainWrp>
              </ChartSlot>
            )}
            {wordCloud?.customClassName ===
              'advanced_campaign_monitor_wordcloud' && (
              <ChartSlot
                isNewsletter={isNewsletter}
                className="graph-widget override-padding"
              >
                <SlotDetailsMainWrp
                  className={wordCloud.customClassName}
                  ref={
                    selectedComponent === wordCloud.customClassName
                      ? containerRef
                      : null
                  }
                >
                  <SlotDetailsWrp>
                    <SlotHeader className="hide-download">
                      <SlotHeaderLeft>
                        <SlotLeftWrp>
                          <SlotTitle
                            ref={(el) =>
                              setTitleRef(wordCloud?.customClassName, el)
                            }
                          >
                            {savedSelectedChart?.find(
                              (chart) =>
                                chart.chartId === wordCloud?.customClassName
                            )?.chartName ||
                              editChart2[wordCloud?.customClassName]
                                ?.chartName ||
                              'Wordcloud'}
                          </SlotTitle>
                          <span
                            style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                            onMouseEnter={() => {
                              setChartToolTip(wordCloud?.customClassName);
                            }}
                            onMouseLeave={() => {
                              setChartToolTip(false);
                            }}
                          >
                            {!isNewsletter && <HelpIcon />}
                          </span>
                        </SlotLeftWrp>

                        <SlotOverviewWrapper>
                          {wordCloud.show ? (
                            <SlotOverview
                              summary={wordCloud?.data?.data?.summary}
                            />
                          ) : (
                            <SubTitleSmallWrp>--</SubTitleSmallWrp>
                          )}
                        </SlotOverviewWrapper>
                      </SlotHeaderLeft>
                      {renderDownloadOptions(wordCloud)}
                      {chartToolTip === wordCloud?.customClassName && (
                        <ChartToolTip
                          text={wordCloud?.text}
                          iconTop={-9}
                          componentTop={9}
                          componentLeft={iconPosition?.[chartToolTip]}
                        />
                      )}
                    </SlotHeader>
                    {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                    <SlotBody type={type} className="commentary">
                      <SlotBodyMain>
                        {wordCloud.isLoading ? (
                          <Loader />
                        ) : !wordCloud.show ? null : wordCloud?.data?.data?.data
                            ?.length > 0 ? (
                          <>
                            <WordCloud
                              data={wordCloud?.data?.data}
                              config={{
                                yLabelAlignment: 5,
                                maxData: 5,
                                minFontSize: 10,
                                maxFontSize: 30,
                                padding: {
                                  left: 0,
                                  top: 0,
                                  bottom: 0,
                                  right: 10,
                                },
                                ...defaultConfig(wordCloud.customClassName),
                              }}
                              resetSelection={resetSelection}
                            />
                            <LegendSection>
                              <GraphLegend
                                legendData={
                                  wordCloud?.data?.legends || wordCloud?.legends
                                }
                              />
                            </LegendSection>
                          </>
                        ) : (
                          <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                        )}
                        {enableTooltip &&
                          activeChart === wordCloud.customClassName && (
                            <PortalTooltip
                              isOpen={true}
                              pos={toolTipPos}
                              align={
                                toolTipPos.left > window.innerWidth / 2
                                  ? 'left'
                                  : 'right'
                              }
                              vAlign={
                                toolTipPos.top > window.innerHeight / 2
                                  ? 'top'
                                  : 'bottom'
                              }
                            >
                              <SocialTooltip
                                tooltipData={tooltipData}
                                widget={wordCloud}
                              />
                            </PortalTooltip>
                          )}
                      </SlotBodyMain>
                    </SlotBody>
                    <SlotFooter>
                      <LegendCon></LegendCon>

                      <SlotBodyHeaderRight> </SlotBodyHeaderRight>
                    </SlotFooter>
                  </SlotDetailsWrp>
                </SlotDetailsMainWrp>
              </ChartSlot>
            )}
            {topAuthors?.customClassName ===
              'advanced_campaign_monitor_top_author' && (
              <ChartSlot
                isNewsletter={isNewsletter}
                className="graph-widget override-padding"
              >
                <SlotDetailsMainWrp
                  className={topAuthors?.customClassName}
                  ref={
                    selectedComponent === topAuthors.customClassName
                      ? containerRef
                      : null
                  }
                >
                  <SlotDetailsWrp>
                    <SlotHeader className="hide-download">
                      <SlotHeaderLeft>
                        <SlotLeftWrp>
                          <SlotTitle
                            ref={(el) =>
                              setTitleRef(topAuthors?.customClassName, el)
                            }
                          >
                            {savedSelectedChart?.find(
                              (chart) =>
                                chart.chartId === topAuthors?.customClassName
                            )?.chartName ||
                              editChart2[topAuthors?.customClassName]
                                ?.chartName ||
                              'Top Authors'}
                          </SlotTitle>
                          <span
                            style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                            onMouseEnter={() => {
                              setChartToolTip(topAuthors?.customClassName);
                            }}
                            onMouseLeave={() => {
                              setChartToolTip(false);
                            }}
                          >
                            {!isNewsletter && <HelpIcon />}
                          </span>
                        </SlotLeftWrp>

                        <SlotOverviewWrapper>
                          {topAuthors.show ? (
                            <SlotOverview
                              summary={topAuthors?.data?.data?.summary}
                            />
                          ) : (
                            <SubTitleSmallWrp>--</SubTitleSmallWrp>
                          )}
                        </SlotOverviewWrapper>
                      </SlotHeaderLeft>
                      {renderDownloadOptions(topAuthors)}
                      {chartToolTip === topAuthors?.customClassName && (
                        <ChartToolTip
                          text={topAuthors?.text}
                          iconTop={-9}
                          componentTop={9}
                          componentLeft={iconPosition?.[chartToolTip]}
                        />
                      )}
                    </SlotHeader>
                    {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                    <SlotBody type={type} className="commentary">
                      <SlotBodyMain>
                        {topAuthors.isLoading ? (
                          <Loader />
                        ) : !topAuthors.show ? null : topAuthors?.data?.data
                            ?.data?.length > 0 ? (
                          <BestBar
                            data={topAuthors?.data?.data}
                            config={{
                              yLabelAlignment: 130,
                              yAxisTicksFormat: true,
                              yAxisType: 'text',
                              xAxisType: 'number',
                              xAxisTicksFormat: true,
                              wrapLength: newsletterDashboard ? 15 : 20,
                              gridLineXStroke: '#d9dbde',
                              gridLineStrokeWidth: 1,
                              enableGridXLine: true,
                              enableGridYLine: false,
                              graphXYLabelFontSize: 11,
                              fontFamily: 'Inter',
                              fontWeight: 500,
                              colorGradientDark:
                                theme?.light?.graphColors?.blue50,
                              colorGradientLight:
                                theme?.light?.graphColors?.cyan30,
                              dropShadowColor:
                                theme?.light?.graphColors?.cyan40,
                              authorDataLength:
                                topAuthors?.data?.data?.length * 10,
                              graphTopPadding: 0,
                              ...defaultConfig(topAuthors.customClassName),
                            }}
                            resetSelection={resetSelection}
                          />
                        ) : (
                          <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                        )}
                        {enableTooltip &&
                          activeChart === topAuthors.customClassName && (
                            <PortalTooltip
                              isOpen={true}
                              pos={toolTipPos}
                              align={
                                toolTipPos.left > window.innerWidth / 2
                                  ? 'left'
                                  : 'right'
                              }
                              vAlign={
                                toolTipPos.top > window.innerHeight / 2
                                  ? 'top'
                                  : 'bottom'
                              }
                            >
                              <SocialTooltip
                                tooltipData={tooltipData}
                                widget={topAuthors}
                              />
                            </PortalTooltip>
                          )}
                      </SlotBodyMain>
                    </SlotBody>
                    <SlotFooter></SlotFooter>
                  </SlotDetailsWrp>
                </SlotDetailsMainWrp>
              </ChartSlot>
            )}
            {outletBreakDown?.customClassName ===
              'advanced_campaign_monitor_outlet_breakdown' && (
              <ChartSlot
                isNewsletter={isNewsletter}
                className="graph-widget override-padding"
              >
                <SlotDetailsMainWrp
                  className={outletBreakDown?.customClassName}
                  ref={
                    selectedComponent === outletBreakDown.customClassName
                      ? containerRef
                      : null
                  }
                >
                  <SlotDetailsWrp>
                    <SlotHeader className="hide-download">
                      <SlotHeaderLeft>
                        <SlotLeftWrp>
                          <SlotTitle
                            ref={(el) =>
                              setTitleRef(outletBreakDown?.customClassName, el)
                            }
                          >
                            {savedSelectedChart?.find(
                              (chart) =>
                                chart.chartId ===
                                outletBreakDown?.customClassName
                            )?.chartName ||
                              editChart2[outletBreakDown?.customClassName]
                                ?.chartName ||
                              'Outlet Breakdown'}
                          </SlotTitle>
                          <span
                            style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                            onMouseEnter={() => {
                              setChartToolTip(outletBreakDown?.customClassName);
                            }}
                            onMouseLeave={() => {
                              setChartToolTip(false);
                            }}
                          >
                            {!isNewsletter && <HelpIcon />}
                          </span>
                        </SlotLeftWrp>
                        <SlotOverviewWrapper>
                          {outletBreakDown.show ? (
                            <SlotOverview
                              summary={outletBreakDown?.data?.data?.summary}
                            />
                          ) : (
                            <SubTitleSmallWrp>--</SubTitleSmallWrp>
                          )}
                        </SlotOverviewWrapper>
                      </SlotHeaderLeft>
                      {renderDownloadOptions(outletBreakDown)}

                      {chartToolTip === outletBreakDown?.customClassName && (
                        <ChartToolTip
                          text={outletBreakDown?.text}
                          iconTop={-9}
                          componentTop={9}
                          componentLeft={iconPosition?.[chartToolTip]}
                        />
                      )}
                    </SlotHeader>
                    {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                    <SlotBody type={type} className="commentary">
                      <SlotBodyMain>
                        {outletBreakDown.isLoading ? (
                          <Loader />
                        ) : !outletBreakDown.show ? null : outletBreakDown?.data
                            ?.data?.data?.length > 0 ? (
                          <TreemapCampaign
                            data={outletBreakDown?.data?.data}
                            handleOnClick={handleOnClick}
                            resetSelection={resetSelection}
                            chartId={outletBreakDown?.customClassName}
                          />
                        ) : (
                          <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                        )}
                        {enableTooltip &&
                          activeChart === outletBreakDown.customClassName && (
                            <PortalTooltip
                              isOpen={true}
                              pos={toolTipPos}
                              align={
                                toolTipPos.left > window.innerWidth / 2
                                  ? 'left'
                                  : 'right'
                              }
                              vAlign={
                                toolTipPos.top > window.innerHeight / 2
                                  ? 'top'
                                  : 'bottom'
                              }
                            >
                              <SocialTooltip
                                tooltipData={tooltipData}
                                widget={outletBreakDown}
                              />
                            </PortalTooltip>
                          )}
                      </SlotBodyMain>
                    </SlotBody>
                    <SlotFooter></SlotFooter>
                  </SlotDetailsWrp>
                </SlotDetailsMainWrp>
              </ChartSlot>
            )}
          </SlotWrp>
        </>
      )}

      {(activeCampaignTab === 999 || isNewsletter) && (
        <>
          {reachByCampaign?.customClassName ===
            'advanced_campaign_comparison_reach_by_campaign' && (
            <FullSlot className="graph-widget override-padding">
              <SlotDetailsMainWrp
                className={reachByCampaign.customClassName}
                ref={
                  selectedComponent === reachByCampaign.customClassName
                    ? containerRef
                    : null
                }
              >
                <SlotDetailsWrp>
                  <SlotHeader className="hide-download">
                    <SlotHeaderLeft>
                      <SlotLeftWrp>
                        <SlotTitle
                          ref={(el) =>
                            setTitleRef(reachByCampaign?.customClassName, el)
                          }
                        >
                          {savedSelectedChart?.find(
                            (chart) =>
                              chart.chartId === reachByCampaign?.customClassName
                          )?.chartName ||
                            editChart2[reachByCampaign?.customClassName]
                              ?.chartName ||
                            'Reach By Campaign'}
                        </SlotTitle>
                        {/* <span
                        style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                        onMouseEnter={() => {
                          setChartToolTip(reachByCampaign?.customClassName);
                        }}
                        onMouseLeave={() => {
                          setChartToolTip(false);
                        }}
                      >
                        <HelpIcon />
                      </span> */}
                      </SlotLeftWrp>

                      <SlotOverviewWrapper>
                        {reachByCampaign.show ? (
                          <SlotOverview
                            summary={reachByCampaign?.data?.data?.summary}
                          />
                        ) : (
                          <SubTitleSmallWrp>--</SubTitleSmallWrp>
                        )}
                      </SlotOverviewWrapper>
                    </SlotHeaderLeft>
                    {renderDownloadOptions(reachByCampaign)}
                    {chartToolTip === reachByCampaign?.customClassName && (
                      <ChartToolTip
                        text={reachByCampaign?.text}
                        iconTop={-9}
                        componentTop={9}
                        componentLeft={iconPosition?.[chartToolTip]}
                      />
                    )}
                  </SlotHeader>
                  {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                  <SlotBody type={type} className="commentary">
                    <SlotBodyMain>
                      {reachByCampaign.isLoading ? (
                        <Loader />
                      ) : !reachByCampaign.show ? null : reachByCampaign?.data
                          ?.data?.data?.length > 0 ? (
                        <LinearLine
                          data={reachByCampaign?.data?.data}
                          config={{
                            yAxisType: 'number',
                            yDomainMultiplayer: 0.001,
                            gridYTicks: 5,
                            yLabelAlignment: 40,
                            yAxisTicksFormat: true,
                            graphAreaWMultiplayer: 1,
                            enableTooltipTrendPointer: true,
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: 11,
                            fontColor: '#697077',
                            enableGridXLine: false,
                            enableGridYLine: true,
                            gridLineStrokeWidth: 1,
                            gridLineXStroke: '#d9dbde',
                            type: 'dashboard',
                            summaryContainerHeight: 0,
                            singleLineWrp: false,
                            showAllLabels: true,
                            multiLineDrillDown: true,
                            ...defaultConfig(reachByCampaign.customClassName),
                          }}
                          resetSelection={resetSelection}
                        />
                      ) : (
                        <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                      )}
                      {enableTooltip &&
                        activeChart === reachByCampaign.customClassName && (
                          <PortalTooltip
                            isOpen={true}
                            pos={toolTipPos}
                            align={
                              toolTipPos.left > window.innerWidth / 2
                                ? 'left'
                                : 'right'
                            }
                            vAlign={
                              toolTipPos.top > window.innerHeight / 2
                                ? 'top'
                                : 'bottom'
                            }
                          >
                            <SocialTooltip
                              tooltipData={tooltipData}
                              widget={reachByCampaign}
                            />
                          </PortalTooltip>
                        )}
                    </SlotBodyMain>
                  </SlotBody>
                  <SlotFooter>
                    {/* <LegendCon></LegendCon> */}

                    <SlotBodyHeaderRight>
                      {' '}
                      <LegendCon>
                        {labels?.map((ele, i) => {
                          return (
                            <LegendItem key={i}>
                              <LegendBox legendColor={ele?.color}></LegendBox>
                              <LegendLabel title={ele?.label}>
                                {ele?.label}
                              </LegendLabel>
                            </LegendItem>
                          );
                        })}
                      </LegendCon>
                    </SlotBodyHeaderRight>
                  </SlotFooter>
                </SlotDetailsWrp>
              </SlotDetailsMainWrp>
            </FullSlot>
          )}

          {campaignAnalysis?.customClassName ===
            'advanced_campaign_comparison_campaign_analysis' && (
            <FullSlot className="graph-widget override-padding">
              <SlotDetailsMainWrp
                className={campaignAnalysis?.customClassName}
                ref={
                  selectedComponent === campaignAnalysis?.customClassName
                    ? containerRef
                    : null
                }
              >
                <SlotDetailsWrp>
                  <SlotHeader className="hide-download">
                    <SlotHeaderLeft>
                      <SlotLeftWrp>
                        <SlotTitle
                          ref={(el) =>
                            setTitleRef(campaignAnalysis?.customClassName, el)
                          }
                        >
                          {savedSelectedChart?.find(
                            (chart) =>
                              chart.chartId ===
                              campaignAnalysis?.customClassName
                          )?.chartName ||
                            editChart2[campaignAnalysis?.customClassName]
                              ?.chartName ||
                            'Campaign Analysis'}
                        </SlotTitle>
                        {/* <span
                        style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                        onMouseEnter={() => {
                          setChartToolTip(campaignAnalysis?.customClassName);
                        }}
                        onMouseLeave={() => {
                          setChartToolTip(false);
                        }}
                      >
                        <HelpIcon />
                      </span> */}
                      </SlotLeftWrp>
                      <SlotOverviewWrapper>
                        <SlotOverview
                          summary={campaignAnalysis?.data?.data?.summary}
                        />
                      </SlotOverviewWrapper>
                    </SlotHeaderLeft>
                    {renderDownloadOptions(campaignAnalysis)}

                    {chartToolTip === campaignAnalysis?.customClassName && (
                      <ChartToolTip
                        text={campaignAnalysis?.text}
                        iconTop={-9}
                        componentTop={9}
                        componentLeft={iconPosition?.[chartToolTip]}
                      />
                    )}
                  </SlotHeader>
                  {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                  <SlotBody type={type} className="commentary">
                    <SlotBodyMain>
                      {campaignAnalysis.isLoading ? (
                        <Loader />
                      ) : !campaignAnalysis.show ? null : hasNonZeroValues(
                          campaignAnalysis?.data?.data?.data
                        ) ? (
                        <ScatterPlot3D
                          data={campaignAnalysis?.data?.data}
                          config={{
                            xAxisType: 'number',
                            yLabelAlignment: 30,
                            gridXTicks: 12,
                            gridYTicks: 6,
                            overTimeChart: true,
                            hideYAxisLine: true,
                            valueInPercent: true,
                            enableGridXLine: false,
                            enableGridYLine: true,
                            gridLineStrokeWidth: 1,
                            gridLineXStroke: '#d9dbde',
                            ...defaultConfig(campaignAnalysis?.customClassName),
                          }}
                          resetSelection={resetSelection}
                        />
                      ) : (
                        <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                      )}
                      {enableTooltip &&
                        activeChart === campaignAnalysis.customClassName && (
                          <PortalTooltip
                            isOpen={true}
                            pos={toolTipPos}
                            align={
                              toolTipPos.left > window.innerWidth / 2
                                ? 'left'
                                : 'right'
                            }
                            vAlign={
                              toolTipPos.top > window.innerHeight / 2
                                ? 'top'
                                : 'bottom'
                            }
                          >
                            <SocialTooltip
                              tooltipData={tooltipData}
                              widget={campaignAnalysis}
                            />
                          </PortalTooltip>
                        )}
                    </SlotBodyMain>
                  </SlotBody>
                  <SlotFooter>
                    <LegendCon>
                      {legendData.map((ele, i) => {
                        return (
                          <LegendItem key={i}>
                            {/* <LegendBox legendColor={ele?.color}></LegendBox> */}
                            <LegendLabel textAlign={false}>
                              {ele?.label}
                            </LegendLabel>
                          </LegendItem>
                        );
                      })}
                    </LegendCon>

                    <SlotBodyHeaderRight>
                      {' '}
                      <LegendCon>
                        {campaignAnalysis?.data?.data?.data?.map((ele, i) => {
                          return (
                            <LegendItem key={i}>
                              <LegendBox legendColor={ele.color}></LegendBox>
                              <LegendLabel title={ele?.label}>
                                {ele?.label}
                              </LegendLabel>
                            </LegendItem>
                          );
                        })}
                      </LegendCon>
                    </SlotBodyHeaderRight>
                  </SlotFooter>
                </SlotDetailsWrp>
              </SlotDetailsMainWrp>
            </FullSlot>
          )}

          {volumeDistByChannel?.customClassName ===
            'advanced_campaign_monitor_vol_distribution_by_channel' && (
            <FullSlot className="graph-widget override-padding">
              <SlotDetailsMainWrp
                className={volumeDistByChannel?.customClassName}
                ref={
                  selectedComponent === volumeDistByChannel?.customClassName
                    ? containerRef
                    : null
                }
              >
                <SlotDetailsWrp>
                  <SlotHeader className="hide-download">
                    <SlotHeaderLeft>
                      <SlotLeftWrp>
                        <SlotTitle
                          ref={(el) =>
                            setTitleRef(
                              volumeDistByChannel?.customClassName,
                              el
                            )
                          }
                        >
                          {savedSelectedChart?.find(
                            (chart) =>
                              chart.chartId ===
                              volumeDistByChannel?.customClassName
                          )?.chartName ||
                            editChart2[volumeDistByChannel?.customClassName]
                              ?.chartName ||
                            'Media Breakout'}
                        </SlotTitle>
                        {/* <span
                        style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                        onMouseEnter={() => {
                          setChartToolTip(volumeDistByChannel?.customClassName);
                        }}
                        onMouseLeave={() => {
                          setChartToolTip(false);
                        }}
                      >
                        <HelpIcon />
                      </span> */}
                      </SlotLeftWrp>
                      <SlotOverviewWrapper>
                        {volumeDistByChannel.show ? (
                          <SlotOverview
                            summary={volumeDistByChannel?.data?.data?.summary}
                          />
                        ) : (
                          <SubTitleSmallWrp>--</SubTitleSmallWrp>
                        )}
                      </SlotOverviewWrapper>
                    </SlotHeaderLeft>
                    {renderDownloadOptions(volumeDistByChannel)}
                    {chartToolTip === volumeDistByChannel?.customClassName && (
                      <ChartToolTip
                        text={volumeDistByChannel?.text}
                        iconTop={-9}
                        componentTop={9}
                        componentLeft={iconPosition?.[chartToolTip]}
                      />
                    )}
                  </SlotHeader>
                  {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                  <SlotBody type={type} className="commentary">
                    <SlotBodyMain>
                      {volumeDistByChannel.isLoading ? (
                        <Loader />
                      ) : !volumeDistByChannel.show ? null : hasNonEmptyCampaigns(
                          volumeDistByChannel?.data?.data?.data
                        ) ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {volumeDistByChannel?.data?.data?.data?.map(
                            (item, index) => (
                              <div
                                key={index}
                                style={{
                                  width: `${
                                    (volumeDistByChannel?.data?.data?.sumArr[
                                      index
                                    ] *
                                      100) /
                                    volumeDistByChannel?.data?.data
                                      ?.total_articles
                                  }%`,
                                  marginRight: '-0.7%',
                                }}
                              >
                                <div
                                  style={{
                                    paddingRight: '10px',
                                    paddingLeft: '10px',
                                    paddingBottom: '5px',
                                    fontFamily: 'Inter',
                                    fontWeight: 600,
                                    fontSize: 13,
                                    maxWidth: '15rem',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  title={Object.keys(item)[0]}
                                >
                                  {Object.keys(item)[0].value > 0
                                    ? Object.keys(item)[0]
                                    : null}
                                </div>
                                {item[Object.keys(item)[0]].length > 0 && (
                                  <TreemapCampaign
                                    data={item[Object.keys(item)[0]]}
                                    handleOnClick={handleOnClick}
                                    resetSelection={resetSelection}
                                    chartId={
                                      volumeDistByChannel?.customClassName +
                                      '-' +
                                      index
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                      )}
                      {enableTooltip &&
                        activeChart === volumeDistByChannel.customClassName && (
                          <PortalTooltip
                            isOpen={true}
                            pos={toolTipPos}
                            align={
                              toolTipPos.left > window.innerWidth / 2
                                ? 'left'
                                : 'right'
                            }
                            vAlign={
                              toolTipPos.top > window.innerHeight / 2
                                ? 'top'
                                : 'bottom'
                            }
                          >
                            <SocialTooltip
                              tooltipData={tooltipData}
                              widget={volumeDistByChannel}
                            />
                          </PortalTooltip>
                        )}
                    </SlotBodyMain>
                  </SlotBody>
                  <SlotFooter></SlotFooter>
                </SlotDetailsWrp>
              </SlotDetailsMainWrp>
            </FullSlot>
          )}

          <SlotWrp>
            {compSentiment?.customClassName ===
              'advanced_campaign_monitor_sentiment' && (
              <HalfSlot
                className="graph-widget override-padding"
                isNewsletter={isNewsletter}
              >
                <SlotDetailsMainWrp
                  className={compSentiment?.customClassName}
                  ref={
                    selectedComponent === compSentiment?.customClassName
                      ? containerRef
                      : null
                  }
                >
                  <SlotDetailsWrp>
                    <SlotHeader className="hide-download">
                      <SlotHeaderLeft>
                        <SlotLeftWrp>
                          <SlotTitle
                            ref={(el) =>
                              setTitleRef(compSentiment?.customClassName, el)
                            }
                          >
                            {savedSelectedChart?.find(
                              (chart) =>
                                chart.chartId === compSentiment?.customClassName
                            )?.chartName ||
                              editChart2[compSentiment?.customClassName]
                                ?.chartName ||
                              'Sentiment'}
                          </SlotTitle>
                          {/* <span
                          style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                          onMouseEnter={() => {
                            setChartToolTip(compSentiment?.customClassName);
                          }}
                          onMouseLeave={() => {
                            setChartToolTip(false);
                          }}
                        >
                          <HelpIcon />
                        </span> */}
                        </SlotLeftWrp>
                        <SlotOverviewWrapper>
                          {compSentiment.show ? (
                            <SlotOverview
                              summary={compSentiment?.data?.data?.summary}
                            />
                          ) : (
                            <SubTitleSmallWrp>--</SubTitleSmallWrp>
                          )}
                        </SlotOverviewWrapper>
                      </SlotHeaderLeft>
                      {renderDownloadOptions(compSentiment)}

                      {chartToolTip === compSentiment?.customClassName && (
                        <ChartToolTip
                          text={compSentiment?.text}
                          iconTop={-9}
                          componentTop={9}
                          componentLeft={iconPosition?.[chartToolTip]}
                        />
                      )}
                    </SlotHeader>
                    {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
                    <SlotBody type={type} className="commentary">
                      <SlotBodyMain>
                        {compSentiment.isLoading ? (
                          <Loader />
                        ) : !compSentiment.show ? null : hasNonZeroSentiments(
                            compSentiment?.data?.data?.data
                          ) ? (
                          <BestBar
                            data={compSentiment?.data?.data}
                            resetSelection={resetSelection}
                            config={{
                              graphType: 'stack',
                              yAxisType: 'text',
                              xAxisType: 'number',
                              xAxisTicksFormat: true,
                              gridXTicks: 5,
                              gutterSpace: 40,
                              graphXYLabelFontSize: 11,
                              fontFamily: 'Inter',
                              fontWeight: 500,
                              fontColor: theme?.light?.graphColors?.coolGray60,
                              gridXTicks: 5,
                              yLabelAlignment: 130,
                              wrapLength: newsletterDashboard ? 25 : 20,
                              hideYAxis: false,
                              gridLineXStroke:
                                theme.light.graphColors.coolGray30,
                              gridLineStrokeWidth: 1,
                              enableGridXLine: true,
                              enableGridYLine: false,
                              barHeight: 24,
                              greenGradientLight:
                                theme?.light?.graphColors?.green40,
                              greenGradientDark:
                                theme?.light?.graphColors?.green50,
                              redGradientLight:
                                theme?.light?.graphColors?.red40,
                              redGradientDark: theme?.light?.graphColors?.red50,
                              grayGradientLight:
                                theme?.light?.graphColors?.coolGray30,
                              grayGradientDark:
                                theme?.light?.graphColors?.coolGray40,
                              greenDropShadowColor:
                                theme?.light?.graphColors?.green50,
                              redDropShadowColor:
                                theme?.light?.graphColors?.red50,
                              grayDropShadowColor: '#A8ADBD',
                              articleSentiment: true,
                              ...defaultConfig(compSentiment?.customClassName),
                            }}
                          />
                        ) : (
                          <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                        )}
                        {enableTooltip &&
                          activeChart === compSentiment.customClassName && (
                            <PortalTooltip
                              isOpen={true}
                              pos={toolTipPos}
                              align={
                                toolTipPos.left > window.innerWidth / 2
                                  ? 'left'
                                  : 'right'
                              }
                              vAlign={
                                toolTipPos.top > window.innerHeight / 2
                                  ? 'top'
                                  : 'bottom'
                              }
                            >
                              <SocialTooltip
                                tooltipData={tooltipData}
                                widget={compSentiment}
                              />
                            </PortalTooltip>
                          )}
                      </SlotBodyMain>
                    </SlotBody>
                    <SlotFooter>
                      {/* <LegendCon></LegendCon> */}

                      <SlotBodyHeaderLeft>
                        {' '}
                        {compSentiment.show && (
                          <StageContainer>
                            {sentiment.map((sentiment, index) => (
                              <StageItem key={sentiment.label}>
                                <StageDot color={sentiment.color} />
                                <Label>{sentiment.label}</Label>
                                {index < sentiment.length - 1 && (
                                  <Divider>|</Divider>
                                )}
                              </StageItem>
                            ))}
                          </StageContainer>
                        )}
                      </SlotBodyHeaderLeft>
                    </SlotFooter>
                  </SlotDetailsWrp>
                </SlotDetailsMainWrp>
              </HalfSlot>
            )}
          </SlotWrp>
        </>
      )}

      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={
              savedSelectedChart?.find(
                (chart) => chart.chartId === selectedChartId
              )?.chartName || selectedGraphEdit
            }
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
    </>
  );
};

export default CampaignMonitor;

CampaignMonitor.defaultProps = {
  type: 'dashboard',
};

CampaignMonitor.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
  setArticleType: Proptypes.object,
  setClickedPosition: Proptypes.object,
  setSelectedComponent: Proptypes.func,
  selectedComponent: Proptypes.string,
  graphDownloading: Proptypes.bool,
  onDownloadChartData: Proptypes.func,
  downloadFunction: Proptypes.func,
  savedSelectedChart: Proptypes.object,
  handleGraphTitleUpdate: Proptypes.func,
  isNewsletter: Proptypes.bool,
  dashboardType: Proptypes.string,
};
