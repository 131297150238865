import * as d3 from 'd3';
import { setUpEvents } from '../utils/graphEvents';
import { hexToRgba } from '../utils/hexToRgba';

export const lineBarMultipleRects = function lineBarMultipleRects() {
  let config = {
    barSpacing: 4, // Default spacing between bars
  };

  function graph(selected) {
    selected.each(function (data) {
      if (!data) return;

      const t = d3
        .transition()
        .delay((d, i) => i * 3)
        .duration(config.duration);

      // Add gradient defs
      selected
        .append('defs')
        .append('linearGradient')
        .attr('id', 'gradient')
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '80%')
        .attr('y2', '0%')
        .selectAll('stop')
        .data([
          { offset: '0%', color: '#BD96FE' },
          { offset: '100%', color: '#893FFC' },
        ])
        .enter()
        .append('stop')
        .attr('offset', function (d) {
          return d.offset;
        })
        .attr('stop-color', function (d) {
          return d.color;
        });

      selected
        .selectAll('.bar-group')
        .data(data)
        .join(
          (enter) => {
            const barGroup = enter
              .append('g')
              .attr('class', 'bar-group')
              .attr(
                'transform',
                (d) => `translate(${config.xScale(d.label)}, 0)`
              );

            // Render bars and circles
            barGroup.each(function (d) {
              const numBars = d.bars.length;
              const totalSpacing = (numBars - 1) * config.barSpacing;
              const barWidth =
                (config.xScale.bandwidth() - totalSpacing) / numBars;

              // Find the maximum height of the bars in the group
              const maxBarValue = d3.max(d.bars, (bar) => bar.value);
              const maxBarY = config.yScale(maxBarValue);

              // Render the bars
              const rects = d3
                .select(this)
                .selectAll('.bar-rect')
                .data(d.bars)
                .join('rect')
                .attr('class', 'bar-rect')
                .attr('x', (bar, i) => i * (barWidth + config.barSpacing))
                .attr('y', (bar) => config.yScale(bar.value))
                .attr('width', barWidth)
                .attr(
                  'height',
                  (bar) => config.graphAreaH - config.yScale(bar.value)
                )
                // Create a unique gradient ID for each bar and apply it
                .attr('fill', (bar, i) => {
                  const gradientId = `barGradient-${i}`; // Create a unique ID for each bar

                  // Create the gradient definition
                  const defs =
                    d3.select(this.parentNode).select('defs') ||
                    d3.select(this.parentNode).append('defs');
                  const gradient = defs
                    .append('linearGradient')
                    .attr('id', gradientId)
                    .attr('x1', '0%')
                    .attr('y1', '0%')
                    .attr('x2', '100%')
                    .attr('y2', '0%');

                  // Add gradient stops using the bar's colors
                  gradient
                    .append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', bar.color); // Start color from the bar
                  gradient
                    .append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', bar.color); // End color from the bar

                  return `url(#${gradientId})`; // Apply the unique gradient
                })
                .attr('filter', (bar) => {
                  const color = bar.color;
                  const rgbaColor = hexToRgba(color, 0.6);
                  return `drop-shadow(0px 10px 12px ${rgbaColor})`;
                })
                .attr('rx', 4);

              // Add a circle for every bar at the maximum height
              rects.each(function (bar, i) {
                if (bar.value <= 0) return;

                // Create a unique gradient for the circle (if needed)
                const gradientId = `circleGradient-${i}`;
                const defs =
                  d3.select(this.parentNode).select('defs') ||
                  d3.select(this.parentNode).append('defs');
                const gradient = defs
                  .append('linearGradient')
                  .attr('id', gradientId)
                  .attr('x1', '0%')
                  .attr('y1', '0%')
                  .attr('x2', '100%')
                  .attr('y2', '0%');

                gradient
                  .append('stop')
                  .attr('offset', '0%')
                  .attr('stop-color', bar.startColor);
                gradient
                  .append('stop')
                  .attr('offset', '100%')
                  .attr('stop-color', bar.endColor);

                // Calculate the center x position of the bar
                const barXCenter =
                  i * (barWidth + config.barSpacing) + barWidth / 2;

                // Set the radius of the circle based on the width of the bar
                const circleRadius = barWidth > 20 ? 10 : barWidth / 2;

                // Append the circle and bind data (bar data)
                const circle = d3
                  .select(this.parentNode)
                  .append('circle')
                  .attr('class', 'bar-circle')
                  .attr('cx', barXCenter)
                  .attr('cy', maxBarY - 20)
                  .attr('r', circleRadius)
                  .attr('fill', bar.circleColor)
                  .attr('stroke', bar.borderColor)
                  .attr('stroke-width', circleRadius / 6)
                  .datum(bar); // Bind the bar data to the circle element

                // Now, set up events for the circle and the bar
                setUpEvents(config, d3.select(this.parentNode), 'bar-rect'); // Events for bars
                setUpEvents(config, d3.select(this.parentNode), 'bar-circle'); // Events for circles
              });
            });
          },
          (update) => update,
          (exit) => exit.remove()
        );
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) return config;
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
