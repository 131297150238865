import { addCountPrefix } from '../../constants/utils';
import { sovColorBox } from '../../graphs/utils/graphConst';
import {
  brandSwitchSOVMapData,
  brandSwitchTopThemesMapData,
} from '../../hooks/data/advancedBrandSwitch';
import { peopleReachOverTimeColors } from '../../hooks/data/peopleData';
import {
  getSentimentCountMappedData,
  getSentimentReasonsMappedData,
} from '../../hooks/useAdvanceBrandSwitch';

export function brand_switch_mapping_dashboard(response, graphType, chartName) {
  if (graphType === 'brand_switch_top_themes_coverage') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = [];
    }

    const mapData = JSON.parse(JSON.stringify(brandSwitchTopThemesMapData));

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    mapData.originalCount = response?.overall_total_count;

    mapData.data.footerLabels = response?.brand_keywords?.map(
      (brand, index) => {
        return {
          label: brand?.name,
          value: brand?.name,
          color: peopleReachOverTimeColors[index],
        };
      }
    );

    mapData.data.labels = Object.keys(response?.common_labels)?.map(
      (label, index) => {
        return {
          label,
          value: `value${index}`,
        };
      }
    );

    let largestValue = -Infinity;

    function createObjectByBrandName(data, brandName) {
      const result = {};
      Object.entries(data).forEach(([key, value], index) => {
        if (value[brandName] !== undefined) {
          result[`keywords${index}`] = value[brandName]?.keyword;
          result[`value${index}`] =
            largestValue === 0
              ? 0
              : parseInt((value[brandName]?.count / largestValue) * 100);
        } else {
          result[`value${index}`] = '0';
        }
      });
      return result;
    }

    const processDataForLargerValue = (data, brandName) => {
      Object.entries(data).forEach(([key, value], index) => {
        if (
          value[brandName] !== undefined &&
          parseInt(value[brandName]?.count) > largestValue
        ) {
          largestValue = parseInt(value[brandName]?.count);
        }
      });
    };

    response?.brand_keywords?.forEach((brand, index) => {
      processDataForLargerValue(response?.common_labels, brand?.name);
    });
    mapData.title = chartName;

    mapData.data.data = response?.brand_keywords?.map((brand, index) => {
      const remainingData = createObjectByBrandName(
        response?.common_labels,
        brand?.name
      );
      return {
        label: brand?.name,
        ...remainingData,
        color: peopleReachOverTimeColors[index],
        recentSearchId: brand?.recent_search_id,
      };
    });

    mapData.shouldShowGraph = response?.overall_total_count > 0;

    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } else if (graphType === 'brand_switch_sov') {
    if (response?.error) {
      return {};
    }
    const sovProperties = {
      volume: 'doc_count',
      impression: 'impressions',
      traditional_reach: 'reach',
      social_reach: 'reach',
      engagement: 'engagement',
    };
    if (!response) {
      response = {};
    }

    const payloadBrandKeys = response?.brands
      ?.map((item, id) => {
        return Object.keys(response?.brands?.[id])?.map(
          (keyName) => keyName
        )?.[0];
      })
      ?.flat(Infinity);

    const mapData = JSON.parse(JSON.stringify(brandSwitchSOVMapData));

    const totalArticlesMapData = mapData.data.summary;

    const totalCount = response?.overall_total_count;

    totalArticlesMapData.value = String(addCountPrefix(totalCount));

    mapData.data.summary = totalArticlesMapData;
    mapData.title = chartName;
    mapData.originalCount = totalCount;
    mapData.data.data =
      payloadBrandKeys
        ?.map((keyName) => {
          const sovData = response?.brands
            ?.map((brandLabel, i) => {
              const brandKeyData = brandLabel?.[keyName]?.data?.[0];

              if (!brandKeyData) return null;

              return {
                ...brandKeyData,

                value: brandKeyData?.[sovProperties.volume],

                label: brandKeyData?.label.replace(/^"(.*)"$/, '$1'),
                filter: 'volume',
                graph: 'brand_switch_sov',
              };
            })
            ?.filter(Boolean);

          return sovData;
        })
        ?.flat() || [];

    mapData.data.legends = [...payloadBrandKeys]?.map((x) => {
      return {
        label: x,
        value: String(x).toLowerCase(),
      };
    });

    // Create a map to store the index of each legend
    const legendIndexMap = {};

    mapData?.data?.legends?.forEach((legend, index) => {
      legendIndexMap[legend?.label] = index;
    });

    // Sort the list data using the custom comparison
    mapData?.data?.data?.sort(
      (a, b) =>
        (legendIndexMap[a?.label] || 0) - (legendIndexMap[b?.label] || 0)
    );

    mapData.data.data = mapData.data.data.map((item, i) => {
      return {
        ...item,
        color: sovColorBox[i],
      };
    });

    mapData.shouldShowGraph =
      response?.data?.some((item) => item?.doc_count !== 0) ||
      (response?.overall_total_count && response?.overall_total_count > 0);

    if (response) {
      mapData.originalData = response;
    }

    return mapData;
  } else {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = [];
    }

    const sentimentCountChartMapData = getSentimentCountMappedData(response);
    const sentimentReasonsChartMapData =
      getSentimentReasonsMappedData(response);
    return {
      sentimentCountChartMapData: {
        ...sentimentCountChartMapData,
        title: chartName,
      },
      sentimentReasonsChartMapData: {
        ...sentimentReasonsChartMapData,
        title: chartName,
      },
    };
  }
}
