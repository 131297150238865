/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import {
  blueColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  purpleColorGradients,
  tealColorGradients,
  orangeColorGradients,
  greenColorGradients,
  redColorGradients,
  yellowColorGradients,
} from '../../constants/graph-colors';
import styled from 'styled-components';

// Styled component for the chart wrapper
const BubbleChartWrapper = styled.div`
  isolation: isolate;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }

  .bubble-node {
    transition: all 0.3s ease;
  }

  .link {
    transition: opacity 0.3s ease;
  }

  .node-label {
    pointer-events: none;
    user-select: none;
  }
`;

const BubbleChart = ({ data, config, resetSelection }) => {
  const containerRef = useRef(null);
  const activeNodeRef = useRef(null);
  const simulationRef = useRef(null);
  const MIN_RADIUS_FOR_LABEL = 20;

  // Color definitions for clusters
  const NODE_COLORS = {
    cluster0: {
      primary: magentaColorGradients.magenta60,
      keyword: magentaColorGradients.magenta60,
    },
    cluster1: {
      primary: purpleColorGradients.purple60,
      keyword: purpleColorGradients.purple60,
    },
    cluster2: {
      primary: blueColorGradients.blue60,
      keyword: blueColorGradients.blue60,
    },
    cluster3: {
      primary: cyanColorGradients.cyan60,
      keyword: cyanColorGradients.cyan60,
    },
    cluster4: {
      primary: tealColorGradients.teal60,
      keyword: tealColorGradients.teal60,
    },
    cluster5: {
      primary: orangeColorGradients?.orange60,
      keyword: orangeColorGradients?.orange60,
    },
    cluster6: {
      primary: greenColorGradients?.green60,
      keyword: greenColorGradients?.green60,
    },
    cluster7: {
      primary: redColorGradients?.red60,
      keyword: redColorGradients?.red60,
    },
    cluster8: {
      primary: yellowColorGradients?.yellow60,
      keyword: yellowColorGradients?.yellow60,
    },
    getClusterColor: (index) => {
      const clusterKey = `cluster${index % 8}`;
      return (
        NODE_COLORS[clusterKey] || {
          primary: `hsl(${(index * 40) % 360}, 70%, 50%)`,
          keyword: `hsl(${(index * 40) % 360}, 70%, 65%)`,
        }
      );
    },
  };

  // Interaction styles
  const INTERACTION_STYLES = {
    ACTIVE_OPACITY: 1,
    INACTIVE_OPACITY: 0.2,
    HOVER_STROKE: '#ffffff',
    HOVER_STROKE_WIDTH: 2,
  };

  // Shadow configuration
  const SHADOW_CONFIG = {
    PRIMARY: {
      BLUR: 8,
      OPACITY: 0.6,
      OFFSET: 6,
    },
    KEYWORD: {
      BLUR: 7,
      OPACITY: 0.6,
      OFFSET: 6,
    },
  };

  useEffect(() => {
    if (!data.data || data.data.length === 0) return;
    const processedData = data.data.slice(0, config?.fromNewsletter ? 7 : 8);

    // Clear previous content
    d3.select(containerRef.current).selectAll('*').remove();

    // Set chart dimensions
    const width = config?.networkComponentWidth || 800;
    const height = config?.networkComponentHeight
      ? config.networkComponentHeight - 30
      : 600;

    const svg = d3
      .select(containerRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    // Define shadow filters
    const defs = svg.append('defs');

    for (let i = 0; i < Math.max(7, processedData.length); i++) {
      const clusterColors = NODE_COLORS.getClusterColor(i);
      const primaryFilter = defs
        .append('filter')
        .attr('id', `shadow-cluster${i}-primary`)
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '300%')
        .attr('height', '300%')
        .attr('x', '-100%')
        .attr('y', '-100%');
      primaryFilter
        .append('feDropShadow')
        .attr('dx', SHADOW_CONFIG.PRIMARY.OFFSET)
        .attr('dy', SHADOW_CONFIG.PRIMARY.OFFSET)
        .attr('stdDeviation', SHADOW_CONFIG.PRIMARY.BLUR)
        .attr('flood-color', clusterColors.primary)
        .attr('flood-opacity', SHADOW_CONFIG.PRIMARY.OPACITY);

      const keywordFilter = defs
        .append('filter')
        .attr('id', `shadow-cluster${i}-keyword`)
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '200%')
        .attr('height', '200%')
        .attr('x', '-50%')
        .attr('y', '-50%');
      keywordFilter
        .append('feDropShadow')
        .attr('dx', SHADOW_CONFIG.KEYWORD.OFFSET)
        .attr('dy', SHADOW_CONFIG.KEYWORD.OFFSET)
        .attr('stdDeviation', SHADOW_CONFIG.KEYWORD.BLUR)
        .attr('flood-color', clusterColors.keyword)
        .attr('flood-opacity', SHADOW_CONFIG.KEYWORD.OPACITY);
    }

    // Calculate global min and max article counts for scaling
    const allArticleCounts = processedData
      .flatMap((theme) => [
        theme.article_count,
        ...theme.keyword.map((kw) => kw.article_count),
      ])
      .filter((count) => count > 0);
    const globalMaxArticleCount = Math.max(...allArticleCounts);
    const globalMinArticleCount = Math.min(...allArticleCounts);

    // Define scales for bubble sizes
    const primaryScale = d3
      .scaleSqrt()
      .domain([globalMinArticleCount, globalMaxArticleCount])
      .range([20, config?.fromNewsletter ? 70 : 100]);
    const keywordScale = d3
      .scaleSqrt()
      .domain([globalMinArticleCount, globalMaxArticleCount])
      .range([15, config?.fromNewsletter ? 55 : 75]);

    // Prepare nodes data
    const nodes = processedData.flatMap((theme, index) => {
      if (theme.article_count <= 0) return [];
      const keywordsWithCounts = theme.keyword.filter(
        (kw) => kw.article_count > 0
      );
      const clusterColors = NODE_COLORS.getClusterColor(index);

      const primaryNode = {
        id: theme.primary_theme,
        radius: primaryScale(theme.article_count),
        group: theme.primary_theme,
        label: theme.primary_theme,
        value: theme.article_count,
        color: clusterColors.primary,
        type: 'primary',
        clusterIndex: index,
        primary_theme: theme.primary_theme,
        article_count: theme.article_count,
      };

      const keywordNodes = keywordsWithCounts.map((kw) => ({
        id: kw.formated_keyword,
        radius: keywordScale(kw.article_count),
        group: theme.primary_theme,
        label: kw.formated_keyword,
        type: 'keyword',
        value: kw.article_count,
        color: clusterColors.primary,
        clusterIndex: index,
        isKeyword: true,
        nonformatedKeyword: kw.keyword,
        primary_theme: theme.primary_theme,
        article_count: kw.article_count,
      }));

      primaryNode.nodeKeywords = keywordNodes.map(
        (kw) => kw.nonformatedKeyword
      );
      primaryNode.nodeLabels = keywordNodes.map((kw) => kw.label);
      return [primaryNode, ...keywordNodes];
    });

    if (nodes.length === 0) return;

    // Prepare links data
    const links = [];
    processedData.forEach((theme) => {
      if (theme.article_count <= 0) return;
      theme.keyword.forEach((kw) => {
        if (kw.article_count > 0) {
          links.push({
            source: theme.primary_theme,
            target: kw.formated_keyword,
          });
        }
      });
    });

    // Custom force for clustering
    function forceCluster() {
      const strength = 0.8;
      let nodes;

      function force(alpha) {
        const centroids = {};
        const l = alpha * strength;
        nodes.forEach((d) => {
          if (d.type === 'primary') {
            centroids[d.id] = { x: d.x, y: d.y, count: 1 };
          }
        });
        nodes.forEach((d) => {
          if (d.type !== 'primary') {
            const centroid = centroids[d.group];
            if (centroid) {
              d.vx -= (d.x - centroid.x) * l;
              d.vy -= (d.y - centroid.y) * l;
            }
          }
        });
      }

      force.initialize = function (_) {
        nodes = _;
      };

      return force;
    }

    // Enhanced collision force
    function enhancedCollideForce() {
      const padding = 5;
      const strength = 1;
      let nodes;
      let iterations = 3;

      function force() {
        for (let i = 0; i < iterations; i++) {
          nodes.forEach((nodeA) => {
            nodes.forEach((nodeB) => {
              if (nodeA !== nodeB) {
                const dx = nodeB.x - nodeA.x;
                const dy = nodeB.y - nodeA.y;
                const distance = Math.sqrt(dx * dx + dy * dy);
                const minDistance = nodeA.radius + nodeB.radius + padding;

                if (distance < minDistance) {
                  const distRatio =
                    ((minDistance - distance) / distance) * strength;
                  const moveX = dx * distRatio * 0.5;
                  const moveY = dy * distRatio * 0.5;

                  if (
                    nodeA.type === 'primary' &&
                    nodeB.type === 'keyword' &&
                    nodeB.group === nodeA.id
                  ) {
                    nodeB.x += moveX * 2;
                    nodeB.y += moveY * 2;
                  } else if (
                    nodeB.type === 'primary' &&
                    nodeA.type === 'keyword' &&
                    nodeA.group === nodeB.id
                  ) {
                    nodeA.x -= moveX * 2;
                    nodeA.y -= moveY * 2;
                  } else {
                    nodeA.x -= moveX;
                    nodeA.y -= moveY;
                    nodeB.x += moveX;
                    nodeB.y += moveY;
                  }
                }
              }
            });
          });
        }
      }

      force.initialize = function (_) {
        nodes = _;
      };

      force.iterations = function (_) {
        if (!arguments.length) return iterations;
        iterations = _;
        return force;
      };

      return force;
    }

    // Set up D3 force simulation
    const simulation = d3
      .forceSimulation()
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('charge', d3.forceManyBody().strength(-400))
      .force('cluster', forceCluster())
      .force(
        'collide',
        d3
          .forceCollide()
          .radius((d) => d.radius + (d.type === 'primary' ? 40 : 10))
          .strength(1)
          .iterations(5)
      )
      .force(
        'x',
        d3
          .forceX()
          .strength(0.1)
          .x(width / 2)
      )
      .force(
        'y',
        d3
          .forceY()
          .strength(0.1)
          .y(height / 2)
      );
    simulationRef.current = simulation;
    simulation.force('enhancedCollide', enhancedCollideForce());

    // Draw links
    const link = svg
      .selectAll('line')
      .data(links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .attr('stroke', (d) => {
        const sourceNode = nodes.find((n) => n.id === d.source);
        const clusterColors = NODE_COLORS.getClusterColor(
          sourceNode.clusterIndex
        );
        return clusterColors.primary;
      })
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 0.6);

    // Draw node groups
    const nodeGroups = svg
      .selectAll('.node-group')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node-group')
      .style('cursor', 'pointer');

    // Draw bubbles
    const node = nodeGroups
      .append('circle')
      .attr('class', 'bubble-node')
      .attr('r', (d) => d.radius)
      .attr('fill', (d) => {
        const clusterColors = NODE_COLORS.getClusterColor(d.clusterIndex);
        return d.type === 'primary'
          ? clusterColors.primary
          : clusterColors.keyword;
      })
      .style('filter', (d) => `url(#shadow-cluster${d.clusterIndex}-${d.type})`)
      .style('transform', 'translateZ(0)');

    // Draw labels with text wrapping
    const label = nodeGroups
      .append('text')
      .attr('class', 'node-label')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('fill', '#fff')
      .style('pointer-events', 'none')
      .each(function (d) {
        if (d.radius < MIN_RADIUS_FOR_LABEL) {
          return;
        }
        const text = d3.select(this);
        const words = d.label.split(/\s+/);
        text.text('');

        const padding = 5;
        const fontSize = Math.max(
          8,
          Math.min(14, d.radius * (d.type === 'primary' ? 0.25 : 0.2))
        );
        text.attr('font-size', `${fontSize}px`);

        const charWidth = fontSize * 0.6;
        const availableWidth = d.radius * 2 - 2 * padding;
        const maxCharsPerLine = Math.max(
          6,
          Math.floor(availableWidth / charWidth)
        );
        const lineHeight = fontSize * 1.2;
        const availableHeight = d.radius * 2 - 2 * padding;
        const maxLines = Math.max(1, Math.floor(availableHeight / lineHeight));

        let lines = [];
        let currentLine = [];
        let currentLength = 0;
        let isTruncated = false;
        let i;
        for (i = 0; i < words.length && lines.length < maxLines; i++) {
          const word = words[i];
          const wordLength = word.length;

          if (
            currentLength + wordLength + (currentLine.length > 0 ? 1 : 0) <=
            maxCharsPerLine
          ) {
            currentLine.push(word);
            currentLength += wordLength + (currentLine.length > 1 ? 1 : 0);
          } else {
            if (currentLine.length > 0) {
              lines.push(currentLine.join(' '));
              currentLine = [];
              currentLength = 0;
            }
            if (wordLength > maxCharsPerLine) {
              lines.push(word.substring(0, maxCharsPerLine - 3) + '...');
              isTruncated = true;
              break;
            } else {
              currentLine.push(word);
              currentLength = wordLength;
            }
          }
        }

        if (currentLine.length > 0 && lines.length < maxLines) {
          lines.push(currentLine.join(' '));
        }

        if (i < words.length) {
          isTruncated = true;
        }

        if (isTruncated && lines.length > 0) {
          let lastLine = lines[lines.length - 1];
          if (lastLine.length <= maxCharsPerLine - 3) {
            lastLine += '...';
          } else {
            lastLine = lastLine.substring(0, maxCharsPerLine - 3) + '...';
          }
          lines[lines.length - 1] = lastLine;
        }

        lines.forEach((line, i) => {
          text
            .append('tspan')
            .attr('x', 0)
            .attr('dy', i === 0 ? `-${(lines.length - 1) * 0.5}em` : '1em')
            .text(line);
        });
      });

    // Interaction handlers
    let selectedNode = null;
    nodeGroups.on('click', function (event, d) {
      event.stopPropagation();
      selectedNode = d;
      activeNodeRef.current = d.id;
      config.handleOnClick && config.handleOnClick(event, d);
      updateVisualState(d, true);
      config.handleMouseLeave(event, d, true);
    });

    nodeGroups.on('mouseover', function (event, d) {
      if (!selectedNode) {
        config.handleMouseEnter && config.handleMouseEnter(event, d, true);
        updateVisualState(d, false);
      }
    });

    nodeGroups.on('mouseout', function (event, d) {
      if (!selectedNode) {
        config.handleMouseLeave && config.handleMouseLeave(event, d, true);
        resetVisualState();
      }
    });

    svg.on('click', function (event) {
      if (event.target === this) {
        selectedNode = null;
        activeNodeRef.current = null;
        resetVisualState();
      }
    });

    // Update visual state on interaction
    function updateVisualState(d, isClick) {
      node.style('opacity', (n) => {
        if (n.id === d.id) return INTERACTION_STYLES.ACTIVE_OPACITY;
        if (n.group === d.group || (d.type === 'keyword' && n.id === d.group))
          return 0.7;
        return INTERACTION_STYLES.INACTIVE_OPACITY;
      });

      link.style('opacity', (l) => {
        if (l.source.id === d.id || l.target.id === d.id)
          return INTERACTION_STYLES.ACTIVE_OPACITY;
        if (l.source.id === d.group || l.target.id === d.group) return 0.5;
        return INTERACTION_STYLES.INACTIVE_OPACITY;
      });

      label.style('opacity', (n) => {
        if (n.id === d.id) return INTERACTION_STYLES.ACTIVE_OPACITY;
        if (n.group === d.group || (d.type === 'keyword' && n.id === d.group))
          return 0.7;
        return INTERACTION_STYLES.INACTIVE_OPACITY;
      });
    }

    // Reset visual state
    function resetVisualState() {
      node.style('opacity', 1).style('stroke', 'none').style('stroke-width', 0);
      link.style('opacity', 0.6);
      label.style('opacity', 1);
    }

    // Set initial node positions
    function setInitialPositions() {
      const primaryNodes = nodes.filter((n) => n.type === 'primary');
      if (primaryNodes.length === 0) return;
      const radius = Math.min(width, height) * 0.35;
      primaryNodes.forEach((node, i) => {
        const angle = (2 * Math.PI * i) / primaryNodes.length;
        node.x = width / 2 + radius * Math.cos(angle);
        node.y = height / 2 + radius * Math.sin(angle);
      });

      nodes.forEach((node) => {
        if (node.type === 'keyword') {
          const parent = nodes.find((n) => n.id === node.group);
          if (parent) {
            const angle = Math.random() * 2 * Math.PI;
            const distance = parent.radius * 2 + node.radius;
            node.x = parent.x + distance * Math.cos(angle);
            node.y = parent.y + distance * Math.sin(angle);
          }
        }
      });
    }

    setInitialPositions();

    // Configure simulation with nodes and links
    simulation.nodes(nodes).force(
      'link',
      d3
        .forceLink(links)
        .id((d) => d.id)
        .distance((d) => {
          const sourceNode = nodes.find((n) => n.id === d.source.id);
          const targetNode = nodes.find((n) => n.id === d.target.id);
          return ((sourceNode?.radius || 0) + (targetNode?.radius || 0)) * 1.5;
        })
        .strength(0.7)
    );

    simulation.alpha(1).restart();

    // Update positions on each tick
    simulation.on('tick', () => {
      nodes.forEach((d) => {
        d.x = Math.max(d.radius, Math.min(width - d.radius, d.x));
        d.y = Math.max(d.radius, Math.min(height - d.radius, d.y));
      });

      link
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y);

      nodeGroups.attr('transform', (d) => `translate(${d.x},${d.y})`);
    });

    // Dampen and stop simulation
    setTimeout(() => {
      simulation.alphaTarget(0).alpha(0.1);
    }, 1000);

    setTimeout(() => {
      simulation.stop();
    }, 2000);

    // Cleanup
    return () => {
      if (simulationRef.current) {
        simulationRef.current.stop();
        simulationRef.current = null;
      }
    };
  }, [
    data,
    config?.networkComponentWidth,
    config?.networkComponentHeight,
    resetSelection,
  ]);

  return <BubbleChartWrapper ref={containerRef} />;
};

// PropTypes for type checking
BubbleChart.propTypes = {
  data: PropTypes.object.isRequired,
  config: PropTypes.object,
  resetSelection: PropTypes.bool,
};

export default BubbleChart;
