import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  FullSlot,
  // IconBox,
  // Iconwpr,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  SlotBody,
  SlotBodyHeader,
  SlotBodyHeaderRight,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
// import PortalTooltip from '../../portal-tooltip';
// import GraphTooltip from '../../graph-tooltip';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import {
  CommentaryLabel,
  CommentarySection,
  SlotOverviewWrapper,
} from '../../search-result/index.sc';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import ChartToolTip from '../../chart-tool-tip';

const gridXTicksCount = 6;

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false
) => {
  // console.log(widgetMapping, dashboardType, widget.component);

  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    overView: true,
  };

  return (
    <GraphComponent
      data={widget.data}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  );
};

const Sentiments = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  handleOnClick = () => {},
  customClassName = '',
  helperText = '',
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const tooltipEnabled = true;
  const [chartToolTip, setChartToolTip] = useState();
  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const handleMouseEnter = (event, d, positiveFlag) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d?.data || d;
      setTooltipData({ data: { ...tData, positiveFlag }, rawData: d?.rawData });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (enableTooltip) {
        setEnableTooltip(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [enableTooltip]);

  const customHandleClick = (event, d) => {
    handleOnClick(event, d);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [helperText]);

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  return (
    <>
      <FullSlot className="graph-widget override-padding">
        <SlotDetailsMainWrp className={customClassName}>
          {/* <IconBox>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //   onClick={handleGraphEditClick}
            >
              <Edit2 />
            </Iconwpr>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //  onClick={handleClick}
            >
              <VerticleDots />
            </Iconwpr>
          </IconBox> */}
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotTitle ref={titleRef}>Sentiment By Themes</SlotTitle>
                <span
                  style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                  onMouseEnter={() => setChartToolTip(true)}
                  onMouseLeave={() => setChartToolTip(false)}
                >
                  <HelpIcon />
                </span>
              </SlotHeaderLeft>
              {chartToolTip && (
                <ChartToolTip
                  text={widget?.text || helperText}
                  componentLeft={iconPosition?.left}
                  componentTop={7}
                />
              )}
            </SlotHeader>
            <SlotBodyHeader>
              <SlotOverviewWrapper className="hide-download">
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
              {/* <SlotBodyHeaderRight>
                <LegendCon>
                  {widget?.data?.labels?.map((ele, i) => {
                    return (
                      <LegendItem key={i}>
                        <LegendBox legendColor={ele?.color}></LegendBox>
                        <LegendLabel>{ele?.label}</LegendLabel>
                      </LegendItem>
                    );
                  })}
                </LegendCon>
              </SlotBodyHeaderRight> */}
            </SlotBodyHeader>
            <SlotBody type={type} className="commentary">
              <SlotBodyMain>
                {loader ? (
                  <Loader />
                ) : (
                  widget &&
                  generateGraphComponent(
                    widget,
                    defaultConfig,
                    type,
                    dashboardType,
                    canvas,
                    resetSelection
                  )
                )}
                {enableTooltip && (
                  <PortalTooltip
                    isOpen={true}
                    pos={toolTipPos}
                    align={
                      toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                    }
                    vAlign={
                      toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                    }
                  >
                    <GraphTooltip
                      tooltipData={tooltipData}
                      type="two-d"
                      widget={widget}
                    />
                  </PortalTooltip>
                )}
              </SlotBodyMain>
            </SlotBody>
            <SlotFooter>
              <CommentarySection>
                <SlotBodyHeaderRight>
                  <LegendCon>
                    {widget?.data?.labels?.map((ele, i) => {
                      return (
                        <LegendItem key={i}>
                          <LegendBox legendColor={ele?.color}></LegendBox>
                          <LegendLabel>{ele?.label}</LegendLabel>
                        </LegendItem>
                      );
                    })}
                  </LegendCon>
                </SlotBodyHeaderRight>
                {/* <CommentaryLabel>
                  The N value for this calculation has been adjusted to include
                  comments with only the positive and negative sentiment.
                </CommentaryLabel> */}
              </CommentarySection>
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
    </>
  );
};

export default Sentiments;

Sentiments.defaultProps = {
  type: 'dashboard',
};

Sentiments.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
};
