import React from 'react';
import { ChartText, ChartToolTipWrp, TooltipIconWrp } from './index.sc';
import Proptypes from 'prop-types';
import Close from '../../assets/icons/Close';
import { mailSVG } from '../article-graphs';
import ResultsAIIinsightsDisclaimerIcon from '../../assets/icons/ResultsAIInsightsDisclaimerIcon';

const ChartToolTip = ({
  text,
  dashboardType,
  componentTop,
  componentLeft,
  crossIcon,
  setTooltipFlag,
  avatarTooltip = false,
  disclaimer,
}) => {
  return (
    <ChartToolTipWrp
      dashboardType={dashboardType}
      componentTop={componentTop}
      componentLeft={componentLeft}
      crossIcon={crossIcon}
      avatarTooltip={avatarTooltip}
    >
      {crossIcon && (
        <TooltipIconWrp>
          <div>{mailSVG}</div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setTooltipFlag(false)}
          >
            <Close width="1.25rem" height="1.25rem" />
          </div>
        </TooltipIconWrp>
      )}
      <ChartText crossIcon={crossIcon}>
        {text}
        {disclaimer && (
          <span>
            <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
              <ResultsAIIinsightsDisclaimerIcon color="#F2F4F8" />
            </div>
            This Platform uses AI-powered analytics to generate insights that
            are provided strictly for informational purposes only. Please review
            findings before making critical decisions.
          </span>
        )}
      </ChartText>
    </ChartToolTipWrp>
  );
};

export default ChartToolTip;

ChartToolTip.propTypes = {
  text: Proptypes.string,
  dashboardType: Proptypes.bool,
  componentTop: Proptypes.string,
  componentLeft: Proptypes.string,
  crossIcon: Proptypes.bool,
  avatarTooltip: Proptypes.bool,
  setTooltipFlag: Proptypes.func,
  disclaimer: Proptypes.bool,
};
