import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import {
  CampNoDatatxtWrp,
  FullSlot,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  SlotBody,
  SlotBodyHeaderRight,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  SlotOverviewWrapper,
  HalfSlot,
  SubTitleSmallWrp,
  Iconwpr,
  SlotHeaderRight,
  SlotLeftWrp,
  HalfSlotsWrp,
  SlotWrpSentimentAnalysis,
  SentimentCountTextWrp,
  BestBarGraphWrp,
  BestBarGraphTextWrp,
  BestBarGraphContentWrp,
  SentimentNegativeCountTextWrp,
  FullSlotWrap,
} from './index.sc';
import { PieGraph } from '../../../graphs';
import PortalTooltip from '../../portal-tooltip';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import ChartToolTip from '../../chart-tool-tip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import SocialTooltip from './tooltip';
import SimpleReusableDropDown from '../../simple-dropdown';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import BestBarGraph from '../../../graphs/BestBar/graphWrapper';
import SlotDetails from '../../search-result/slot-details';
import Edit2 from '../../../assets/icons/Edit2';
import { LegendSectionV2 } from '../../search-result/index.sc';
import GraphLegendV2 from '../../graph-legend-v2';
import CircularLoading from '../../../assets/icons/loading/circularLoading';
import DashboardPopup from '../../dasboard-popup';
import EditGraphPopup from '../../edit-graph-popup';
import ReachTooltip from '../../tooltip';
import { useLocation } from 'react-router-dom';
import { widgetPlaceHolders } from '../../../assets/img/widgetsImg';
import SlotPlaceHolder from '../../search-result/slot-details/SlotPlaceHolder';

const BrandSwitchDashboard = ({
  widget = {},
  type = 'dashboard',
  resetSelection = false,
  handleOnClick = () => {},
  helperText = '',
  selectedComponent,
  setSelectedComponent,
  graphDownloading,
  onDownloadChartData,
  downloadFunction,
  savedSelectedChart,
  handleGraphTitleUpdate,
  dashboardType,
  isNewsletter = false,
  savePopupActive = false,
}) => {
  const idx = 55;
  const j = idx;
  const tooltipEnabled = true;
  const titleRefs = useRef({});
  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  const { tabsData } = useSelector((state) => state.brandSwitch);
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();

  const [iconPosition, setIconPosition] = useState({});
  const [activeChart, setActiveChart] = useState(null);
  const [selectedChartId, setSelectedChartId] = useState('');
  const [selectedGraphEdit, setSelectedGraphEdit] = useState('');
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: '',
    chartType: widget?.graphType || '',
  });

  const location = useLocation();
  const newsletterDashboard =
    location?.pathname?.includes('news-letter') ||
    location?.pathname?.includes('sent-news-letter') ||
    location?.pathname?.includes('create-news-letter');

  const graphData = widget;

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData[selectedComponent]);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData[selectedComponent]);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData[selectedComponent]);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData[selectedComponent]);
      },
    },
  ];

  // sov chart data
  const brandSwitchSOVChart = {
    ...widget?.brand_switch_sov,
  };
  // top themes coverage chart data
  const brandSwitchTopThemesCoverageChart = {
    ...widget?.brand_switch_top_themes_coverage,
  };
  // Switch-in Switch-out Index chart data
  const brandSwitchSentimentIndexChart = {
    ...widget?.brand_switch_sentiment_analysis_count,
  };
  // Switch-in Switch-out Reasons chart data
  const brandSwitchSentimentReasonChart = {
    ...widget?.brand_switch_sentiment_analysis,
  };

  // get max value
  const maxValue = Math.max(
    brandSwitchSentimentReasonChart?.data?.sentimentNegativeChartMapData?.data
      ?.data?.[0]?.neg || 0,
    brandSwitchSentimentReasonChart?.data?.sentimentPositiveChartMapData?.data
      ?.data?.[0]?.pos || 0,
    brandSwitchSentimentReasonChart?.data?.sentimentPositiveChartMapData?.data
      ?.data?.[0]?.neu || 0
  );

  const calculateIconPosition = (id) => {
    if (titleRefs.current) {
      const titleWidth = titleRefs?.current[id]?.offsetWidth;

      setIconPosition((prevPositions) => ({
        ...prevPositions,
        [id]: titleWidth + 24, // Calculate icon position based on width
      }));
    }
  };

  const setTitleRef = (id, el) => {
    if (el) {
      titleRefs.current[id] = el;
    }
  };

  useEffect(() => {
    if (chartToolTip) {
      calculateIconPosition(chartToolTip); // Update width for active tooltip item
    }
  }, [chartToolTip, editChart2]);

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev === componentName ? false : componentName
    );
  };

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  const handleMouseEnter = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: {
          ...tData,
          value: tData?.articleCount || tData?.value,
        },
        rawData: d?.rawData,
      });
      setActiveChart(chartId);
    }
  };

  const handleMouseMove = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };

  const handleMouseLeave = (event, d, i, chartId) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
      setActiveChart(null);
    }
  };

  const defaultConfig = (chartId) => {
    return {
      handleMouseEnter: (event, d, i) => handleMouseEnter(event, d, i, chartId),
      handleMouseMove: (event, d, i) => handleMouseMove(event, d, i, chartId),
      handleMouseLeave: (event, d, i) => handleMouseLeave(event, d, i, chartId),
      handleOnClick: (event, d) => handleOnClick(event, d, chartId),
    };
  };

  const handleGraphEditClick = (widget) => {
    // e.preventDefault();
    setSelectedGraphEdit(widget?.data?.title);
    setSelectedChartId(widget?.customClassName);
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();
    // handleUpdatedChart(data);
    const chartData = savedSelectedChart?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      selectedChartId,
      data?.chartType || ''
    );
    handleGraphEditClick(e);
  };
  const handleEditChart = (data) => {
    setEditChart2({ ...editChart2, [selectedChartId]: { ...data } });
  };

  const renderEditIcon = (widget) => {
    if (widget?.show && dashboardType !== 'gridDashboard') {
      return (
        <Iconwpr
          width="1.5rem"
          height="1.5rem"
          onClick={() => handleGraphEditClick(widget)}
          className="hide-downloading"
        >
          <Edit2 />
        </Iconwpr>
      );
    }
    return null;
  };

  const renderDownloadOptions = (widget) => {
    return (
      <SlotHeaderRight>
        {dashboardType !== 'gridDashboard' && renderEditIcon(widget)}
        {dashboardType !== 'gridDashboard' && (
          <Iconwpr
            width={'1.5rem'}
            height={'1.5rem'}
            onClick={(e) => {
              handleOptionIcon(e, widget.customClassName);
            }}
            ref={downloadRef}
            className="hide-downloading"
          >
            <VerticleDots
              color={
                openActionDropdown === widget?.customClassName
                  ? '#675ef2'
                  : '#5C5E60'
              }
            />
            <SimpleReusableDropDown
              isOpen={openActionDropdown === widget?.customClassName}
              options={actionDropDownOptions}
              graphDownloading={graphDownloading}
              setIsOpen={setOpenActionDropDown}
            />
          </Iconwpr>
        )}
      </SlotHeaderRight>
    );
  };

  const sentimentAnalysisChartConfig = {
    ...defaultConfig(brandSwitchSentimentReasonChart?.customClassName),
    graphType: 'stack',
    yLabelAlignment: 200,
    yAxisType: 'text',
    xAxisType: 'number',
    xAxisTicksFormat: false,
    gridXTicks: 5,
    gutterSpace: 40,
    graphXYLabelFontSize: 11,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontColor: '#697077',
    wrapLength: newsletterDashboard ? 15 : 20,
    gridLineXStroke: '#d9dbdedd',
    gridLineStrokeWidth: 0,
    enableGridXLine: false,
    enableGridYLine: false,
    dashboardType: 'brandSwitch',
    type: 'dashboard',
    component: 'brand_switch_sentiment_analysis',
    summaryContainerHeight: 200,
    capitalizeLabel: true,
    greenGradientLight: '#24A148',
    greenGradientDark: '#24A148',
    redGradientLight: '#FA4E56',
    redGradientDark: '#FA4E56',
    grayGradientLight: '#FF832B',
    grayGradientDark: '#FF832B',
    greenDropShadowColor: '#24A148',
    redDropShadowColor: '#FA4E56',
    grayDropShadowColor: '#EB6200',
    barHeight: 24,
    articleSentiment: true,
    graphTopPadding: 45,
    maxValue,
    padding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 30,
    },
  };

  const brandName =
    brandSwitchSentimentReasonChart?.data?.sentimentNegativeChartMapData?.data
      ?.brandName;

  const formattedBrandName = brandName
    ? brandName.charAt(0).toUpperCase() + brandName.slice(1)
    : '';

  const legendData = [
    // {
    //   label: `Opportunities for ${formattedBrandName}`,
    //   color: '#FF832B',
    // },
    {
      label: `Positive Aspects of ${formattedBrandName}`,
      color: '#24A148',
    },
    {
      label: `Negative Aspects of ${formattedBrandName}`,
      color: '#DA1E28',
    },
  ];

  const ChartSlot = dashboardType === 'brandSwitch' ? HalfSlot : FullSlot;

  return (
    <>
      <FullSlotWrap>
        <HalfSlotsWrp dashboard={dashboardType === 'brandSwitch'}>
          {/* Switch-in Switch-out Index chart */}
          {!brandSwitchSentimentIndexChart?.show &&
            !brandSwitchSentimentIndexChart?.isLoading && (
              <HalfSlot>
                <>
                  <SlotHeaderLeft>
                    <SlotLeftWrp>
                      <SlotTitle
                        ref={(el) =>
                          setTitleRef(
                            brandSwitchSentimentIndexChart?.customClassName,
                            el
                          )
                        }
                      >
                        Switch-in Switch-out Index
                      </SlotTitle>
                    </SlotLeftWrp>
                  </SlotHeaderLeft>
                  <SlotPlaceHolder body={widgetPlaceHolders?.detailsGraph} />
                </>
              </HalfSlot>
            )}
          {(tabsData[0]?.industryTypeId !== null ||
            (Object?.keys(brandSwitchSentimentIndexChart)?.length > 0 &&
              dashboardType === 'newsletter')) && (
            <ChartSlot
              className="graph-widget"
              key={`widget-${idx}`}
              selected={false}
              id={`childDiv-${j}`}
              height="25.55rem"
            >
              <SlotDetails
                widget={brandSwitchSentimentIndexChart?.data}
                loader={brandSwitchSentimentIndexChart?.isLoading}
                legend={false}
                commentary={true}
                isSavePopup={false}
                accessTypeViewUser={false}
                handleOnClick={handleOnClick}
                handleGraphTitleUpdate={handleGraphTitleUpdate}
                editOption={true}
                actionOption={true}
                dashboardType={dashboardType}
                setSelectedComponent={setSelectedComponent}
                selectedComponent={selectedComponent}
                downloadFunction={downloadFunction}
                onDownloadChartData={onDownloadChartData}
                graphDownloading={false}
                widgetClassName={
                  brandSwitchSentimentIndexChart?.customClassName
                }
                insight={null}
                insightLoading={true}
                key={brandSwitchSentimentIndexChart?.customClassName}
                helperText={brandSwitchSentimentIndexChart?.text}
                resetSelection={resetSelection}
                isNewsletter={isNewsletter}
                editChart={{
                  chartName:
                    savedSelectedChart?.find(
                      (chart) =>
                        chart.chartId ===
                        brandSwitchSentimentIndexChart?.customClassName
                    )?.chartName ||
                    editChart2[brandSwitchSentimentIndexChart?.customClassName]
                      ?.chartName ||
                    'Switch-in Switch-out Index',
                }}
              />
            </ChartSlot>
          )}

          {/* SOV chart */}
          {Object?.keys(brandSwitchSOVChart)?.length > 0 && (
            <ChartSlot
              className="graph-widget override-padding"
              height="25.55rem"
            >
              <SlotDetailsMainWrp
                className={brandSwitchSOVChart.customClassName}
                ref={
                  selectedComponent === brandSwitchSOVChart.customClassName
                    ? containerRef
                    : null
                }
              >
                <SlotDetailsWrp>
                  <SlotHeader className="hide-download">
                    <SlotHeaderLeft>
                      <SlotLeftWrp>
                        <SlotTitle
                          ref={(el) =>
                            setTitleRef(
                              brandSwitchSOVChart?.customClassName,
                              el
                            )
                          }
                        >
                          {savedSelectedChart?.find(
                            (chart) =>
                              chart.chartId ===
                              brandSwitchSOVChart?.customClassName
                          )?.chartName ||
                            editChart2[brandSwitchSOVChart?.customClassName]
                              ?.chartName ||
                            'SOV'}
                          {/* {brandSwitchSOVChart?.data?.title || 'SOV'} */}
                        </SlotTitle>
                        {brandSwitchSOVChart.show && (
                          <span
                            style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                            onMouseEnter={() => {
                              setChartToolTip(
                                brandSwitchSOVChart?.customClassName
                              );
                            }}
                            onMouseLeave={() => {
                              setChartToolTip(false);
                            }}
                          >
                            {!isNewsletter && <HelpIcon />}
                          </span>
                        )}
                      </SlotLeftWrp>

                      <SlotOverviewWrapper>
                        {brandSwitchSOVChart.show && (
                          <SlotOverview
                            summary={brandSwitchSOVChart?.data?.data?.summary}
                          />
                        )}
                      </SlotOverviewWrapper>
                    </SlotHeaderLeft>
                    {dashboardType !== 'newsletter' &&
                      brandSwitchSOVChart?.show &&
                      renderDownloadOptions(brandSwitchSOVChart)}

                    {chartToolTip === brandSwitchSOVChart?.customClassName && (
                      <ChartToolTip
                        text={brandSwitchSOVChart?.text}
                        iconTop={-9}
                        componentTop={9}
                        componentLeft={iconPosition?.[chartToolTip]}
                      />
                    )}
                  </SlotHeader>
                  {!brandSwitchSOVChart.show && (
                    <SlotBody
                      type={type}
                      className="commentary"
                      style={{ marginTop: '2rem' }}
                    >
                      <SlotBodyMain>
                        {' '}
                        <CampNoDatatxtWrp>
                          <SlotPlaceHolder
                            body={widgetPlaceHolders?.detailsGraph}
                          />
                        </CampNoDatatxtWrp>
                      </SlotBodyMain>
                    </SlotBody>
                  )}
                  {brandSwitchSOVChart.show &&
                    brandSwitchSOVChart?.data?.data?.legends &&
                    brandSwitchSOVChart?.data?.data?.legends?.length <= 6 &&
                    brandSwitchSOVChart?.data?.graphType === 'pie' &&
                    brandSwitchSOVChart?.data?.shouldShowGraph && (
                      <LegendSectionV2
                        length={
                          brandSwitchSOVChart?.data?.data?.legends?.length
                        }
                        type={type}
                        newsSentimentChart={false}
                        dashboardType={dashboardType}
                        savePopupActive={savePopupActive}
                      >
                        <GraphLegendV2
                          dashboardType={dashboardType}
                          legendData={brandSwitchSOVChart?.data?.data?.data}
                          mediaType={null}
                        />
                      </LegendSectionV2>
                    )}
                  <SlotBody type={type} className="commentary">
                    <SlotBodyMain>
                      {brandSwitchSOVChart.isLoading ? (
                        <CircularLoading
                          size="0.25rem"
                          width="1.875rem"
                          height="1.875rem"
                        />
                      ) : !brandSwitchSOVChart.show ? null : brandSwitchSOVChart
                          ?.data?.data?.data?.length > 0 ? (
                        <PieGraph
                          data={brandSwitchSOVChart?.data?.data}
                          config={{
                            arcLabel: false,
                            graphType: 'pie',
                            enableTooltip: true,
                            padAngle: 0.03,
                            innerRadius: 0.01,
                            arcDividerStrokeColor: 'none',
                            dashboardType,
                            type,
                            component: brandSwitchSOVChart.customClassName,
                            summaryContainerHeight: 0,
                            ...defaultConfig(
                              brandSwitchSOVChart.customClassName
                            ),
                          }}
                          resetSelection={resetSelection}
                        />
                      ) : (
                        <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                      )}
                      {enableTooltip &&
                        activeChart === brandSwitchSOVChart.customClassName && (
                          <PortalTooltip
                            isOpen={true}
                            pos={toolTipPos}
                            align={
                              toolTipPos.left > window.innerWidth / 2
                                ? 'left'
                                : 'right'
                            }
                            vAlign={
                              toolTipPos.top > window.innerHeight / 2
                                ? 'top'
                                : 'bottom'
                            }
                          >
                            <SocialTooltip
                              tooltipData={tooltipData}
                              widget={brandSwitchSOVChart}
                            />
                          </PortalTooltip>
                        )}
                    </SlotBodyMain>
                  </SlotBody>
                  <SlotFooter>
                    <LegendCon></LegendCon>

                    <SlotBodyHeaderRight>
                      {' '}
                      <LegendCon>
                        {widget?.data?.map((ele, i) => {
                          return (
                            <LegendItem key={i}>
                              <LegendBox legendColor={ele.color}></LegendBox>
                              <LegendLabel>{ele?.label}</LegendLabel>
                            </LegendItem>
                          );
                        })}
                      </LegendCon>
                    </SlotBodyHeaderRight>
                  </SlotFooter>
                </SlotDetailsWrp>
              </SlotDetailsMainWrp>
            </ChartSlot>
          )}
        </HalfSlotsWrp>

        {/* Switch-in & Switch-out Reasons */}
        {(tabsData[0]?.industryTypeId !== null ||
          (Object?.keys(brandSwitchSentimentReasonChart)?.length > 0 &&
            dashboardType === 'newsletter')) && (
          <FullSlot
            height={dashboardType === 'newsletter' ? '26.25rem' : '40rem'}
            className="graph-widget"
            key={`widget-${idx}`}
            selected={false}
            id={`childDiv-${j}`}
            themeHeight={j === 5}
            peopleCoverage={
              brandSwitchSentimentReasonChart?.customClassName ===
              'brand_switch_sentiment_analysis'
            }
          >
            <SlotDetailsMainWrp
              className={brandSwitchSentimentReasonChart?.customClassName}
              ref={
                selectedComponent ===
                brandSwitchSentimentReasonChart.customClassName
                  ? containerRef
                  : null
              }
            >
              <SlotDetailsWrp hideGap={dashboardType === 'newsletter'}>
                <SlotHeader
                  className="hide-download"
                  isSentimentAnalysis={true}
                >
                  <SlotHeaderLeft>
                    <SlotLeftWrp>
                      <SlotTitle
                        ref={(el) =>
                          setTitleRef(
                            brandSwitchSentimentReasonChart?.customClassName,
                            el
                          )
                        }
                      >
                        {savedSelectedChart?.find(
                          (chart) =>
                            chart.chartId ===
                            brandSwitchSentimentReasonChart?.customClassName
                        )?.chartName ||
                          editChart2[
                            brandSwitchSentimentReasonChart?.customClassName
                          ]?.chartName ||
                          'Switch-in & Switch-out Reasons'}
                      </SlotTitle>
                      <>
                        {/* <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() => {
                      setChartToolTip(
                        brandSwitchSentimentReasonChart?.customClassName
                      );
                    }}
                    onMouseLeave={() => {
                      setChartToolTip(false);
                    }}
                  >
                    <HelpIcon />
                  </span>

                  {chartToolTip ===
                    brandSwitchSentimentReasonChart?.customClassName && (
                    <ChartToolTip
                      text={brandSwitchSentimentReasonChart?.text}
                      iconTop={-9}
                      componentTop={iconPosition?.[chartToolTip]?.width / 100}
                      componentLeft={iconPosition?.[chartToolTip]?.width}
                    />
                  )} */}
                        <ReachTooltip
                          content={brandSwitchSentimentReasonChart?.text}
                          direction="bottom"
                          bgColor="#000"
                        >
                          {!isNewsletter && <HelpIcon />}
                        </ReachTooltip>
                      </>
                    </SlotLeftWrp>

                    <SlotOverviewWrapper>
                      {brandSwitchSentimentReasonChart.show ? (
                        <SlotOverview
                          summary={
                            brandSwitchSentimentReasonChart?.data
                              ?.sentimentPositiveChartMapData?.data?.summary
                          }
                        />
                      ) : (
                        <SubTitleSmallWrp>--</SubTitleSmallWrp>
                      )}
                    </SlotOverviewWrapper>
                  </SlotHeaderLeft>
                  {dashboardType !== 'newsletter' &&
                    renderDownloadOptions(brandSwitchSentimentReasonChart)}
                </SlotHeader>

                {brandSwitchSentimentReasonChart?.isLoading ? (
                  <CircularLoading
                    size="0.25rem"
                    width="1.875rem"
                    height="1.875rem"
                  />
                ) : brandSwitchSentimentReasonChart?.data
                    ?.sentimentPositiveChartMapData?.data?.data.length == 0 &&
                  brandSwitchSentimentReasonChart?.data
                    ?.sentimentNegativeChartMapData?.data.data?.length == 0 ? (
                  <CampNoDatatxtWrp height={'26rem'}>No Data</CampNoDatatxtWrp>
                ) : (
                  <>
                    <BestBarGraphWrp>
                      <BestBarGraphContentWrp
                        savePopupActive={savePopupActive}
                        newsletter={dashboardType === 'newsletter'}
                      >
                        <SentimentCountTextWrp>
                          {
                            brandSwitchSentimentReasonChart?.data
                              ?.sentimentPositiveChartMapData?.data?.percentage
                          }
                          % Switch-In
                        </SentimentCountTextWrp>
                        <BestBarGraphTextWrp>
                          Probable reasons for Switching in to{' '}
                          {formattedBrandName}
                        </BestBarGraphTextWrp>
                      </BestBarGraphContentWrp>
                      <BestBarGraphContentWrp
                        savePopupActive={savePopupActive}
                        newsletter={dashboardType === 'newsletter'}
                      >
                        <SentimentNegativeCountTextWrp>
                          {
                            brandSwitchSentimentReasonChart?.data
                              ?.sentimentNegativeChartMapData?.data?.percentage
                          }
                          % Switch-Out
                        </SentimentNegativeCountTextWrp>
                        <BestBarGraphTextWrp>
                          Probable reasons for Switching out of{' '}
                          {formattedBrandName}
                        </BestBarGraphTextWrp>
                      </BestBarGraphContentWrp>
                    </BestBarGraphWrp>

                    <SlotWrpSentimentAnalysis
                      peopleCoverage={
                        brandSwitchSentimentReasonChart?.customClassName ===
                        'brand_switch_sentiment_analysis'
                      }
                    >
                      <BestBarGraph
                        config={sentimentAnalysisChartConfig}
                        data={
                          brandSwitchSentimentReasonChart?.data
                            ?.sentimentPositiveChartMapData?.data
                        }
                        handleOnClick={handleOnClick}
                        rerender={false}
                        resetSelection={resetSelection}
                      />
                      <BestBarGraph
                        config={sentimentAnalysisChartConfig}
                        data={
                          brandSwitchSentimentReasonChart?.data
                            ?.sentimentNegativeChartMapData?.data
                        }
                        handleOnClick={handleOnClick}
                        rerender={false}
                        resetSelection={resetSelection}
                      />
                    </SlotWrpSentimentAnalysis>
                  </>
                )}
                {enableTooltip &&
                  activeChart ===
                    brandSwitchSentimentReasonChart?.customClassName && (
                    <PortalTooltip
                      isOpen={true}
                      pos={toolTipPos}
                      align={
                        toolTipPos.left > window.innerWidth / 2
                          ? 'left'
                          : 'right'
                      }
                      vAlign={
                        toolTipPos.top > window.innerHeight / 2
                          ? 'top'
                          : 'bottom'
                      }
                    >
                      <SocialTooltip
                        tooltipData={tooltipData}
                        widget={brandSwitchSentimentReasonChart}
                      />
                    </PortalTooltip>
                  )}
                <SlotFooter>
                  <SlotBodyHeaderRight>
                    {brandSwitchSentimentReasonChart?.data
                      ?.sentimentPositiveChartMapData?.data?.data.length > 0 &&
                      !brandSwitchSentimentReasonChart?.isLoading && (
                        <LegendCon>
                          {legendData.map((ele, i) => (
                            <LegendItem key={i}>
                              <LegendBox legendColor={ele?.color} />
                              <LegendLabel textAlign={false}>
                                {ele?.label}
                              </LegendLabel>
                            </LegendItem>
                          ))}
                        </LegendCon>
                      )}
                  </SlotBodyHeaderRight>
                </SlotFooter>
              </SlotDetailsWrp>
            </SlotDetailsMainWrp>
          </FullSlot>
        )}
        {((!brandSwitchSentimentReasonChart?.show &&
          !brandSwitchSentimentReasonChart?.isLoading) || !brandSwitchSentimentReasonChart?.show) && (
            <FullSlot>
              <>
                <SlotHeaderLeft>
                  <SlotLeftWrp>
                    <SlotTitle
                      ref={(el) =>
                        setTitleRef(
                          brandSwitchSentimentReasonChart?.customClassName,
                          el
                        )
                      }
                    >
                      Switch-in & Switch-out Reasons
                    </SlotTitle>
                  </SlotLeftWrp>
                </SlotHeaderLeft>
                <SlotPlaceHolder body={widgetPlaceHolders?.detailsGraph} />
              </>
            </FullSlot>
          )}

        {/* Top themes coverage chart */}
        {Object?.keys(brandSwitchTopThemesCoverageChart)?.length > 0 && (
          <FullSlot
            className="graph-widget"
            height={dashboardType === 'newsletter' ? '27.55rem' : '32.5rem'}
            key={`widget-${idx}`}
            selected={false}
            id={`childDiv-${j}`}
            themeHeight={j === 5}
            peopleCoverage={
              brandSwitchTopThemesCoverageChart?.data?.component ===
              '"brand_switch_top_themes_coverage"'
            }
          >
            {!brandSwitchTopThemesCoverageChart?.show && (
              <>
                <SlotHeaderLeft style={{ marginLeft: '1.5rem' }}>
                  <SlotLeftWrp>
                    <SlotTitle
                      ref={(el) =>
                        setTitleRef(
                          brandSwitchTopThemesCoverageChart?.customClassName,
                          el
                        )
                      }
                    >
                      Top Themes Coverage
                      {/* {brandSwitchSOVChart?.data?.title || 'SOV'} */}
                    </SlotTitle>
                  </SlotLeftWrp>
                </SlotHeaderLeft>
                <SlotPlaceHolder body={widgetPlaceHolders?.detailsGraph} />
              </>
            )}

            {brandSwitchTopThemesCoverageChart?.show && (
              <div
                style={{
                  height: dashboardType === 'newsletter' ? '24rem' : '30rem',
                }}
              >
                <SlotDetails
                  isPopupOpen={true}
                  editOption={true}
                  actionOption={true}
                  onDownloadChartData={onDownloadChartData}
                  widget={brandSwitchTopThemesCoverageChart?.data}
                  loader={brandSwitchTopThemesCoverageChart?.isLoading}
                  isSavePopup={true}
                  handleOnClick={(x, y, z) =>
                    handleOnClick(x, y, 'brand_switch_top_themes_coverage')
                  }
                  handleGraphTitleUpdate={handleGraphTitleUpdate}
                  resetSelection={resetSelection}
                  dashboardType={dashboardType}
                  downloadFunction={downloadFunction}
                  setSelectedComponent={setSelectedComponent}
                  selectedComponent={selectedComponent}
                  widgetClassName={
                    brandSwitchTopThemesCoverageChart?.customClassName
                  }
                  key={brandSwitchTopThemesCoverageChart?.customClassName}
                  helperText={brandSwitchTopThemesCoverageChart?.text}
                  customGridDashboard={''}
                  isError={brandSwitchTopThemesCoverageChart?.isError}
                  refetch={brandSwitchTopThemesCoverageChart?.refetch}
                  isNewsletter={isNewsletter}
                  editChart={{
                    chartName:
                      savedSelectedChart?.find(
                        (chart) =>
                          chart.chartId ===
                          brandSwitchTopThemesCoverageChart?.customClassName
                      )?.chartName ||
                      editChart2[
                        brandSwitchTopThemesCoverageChart?.customClassName
                      ]?.chartName ||
                      'Top Themes Coverage',
                  }}
                />
              </div>
            )}
          </FullSlot>
        )}
      </FullSlotWrap>
      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={
              savedSelectedChart?.find(
                (chart) => chart.chartId === selectedChartId
              )?.chartName || selectedGraphEdit
            }
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
    </>
  );
};

export default BrandSwitchDashboard;

BrandSwitchDashboard.defaultProps = {
  type: 'dashboard',
};

BrandSwitchDashboard.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
  setArticleType: Proptypes.object,
  setClickedPosition: Proptypes.object,
  setSelectedComponent: Proptypes.func,
  selectedComponent: Proptypes.string,
  graphDownloading: Proptypes.bool,
  onDownloadChartData: Proptypes.func,
  downloadFunction: Proptypes.func,
  savedSelectedChart: Proptypes.object,
  handleGraphTitleUpdate: Proptypes.func,
  dashboardType: Proptypes.string,
  isNewsletter: Proptypes.bool,
  savePopupActive: Proptypes.bool,
};
