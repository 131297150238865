import * as d3 from 'd3';
import ColorParser from '../utils/colorParser';
import {
  outletBreakDown as colorBox,
  initialValues,
} from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';
import { hexToRgba } from '../utils/hexToRgba';
import { formatNumber } from '../utils/graphGrid';

export const outletRect = function outletRect() {
  let config = {
    ...initialValues,
    barHeight: 16,
    barPadding: 2,
  };

  const t = d3
    .transition()
    .delay(function (d, i) {
      return i * 3;
    })
    .duration(config.duration);
  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      const yWidth = config.yScale.bandwidth() - config.barPadding;
      config.barHeight =
        config.authorDataLength && data?.length < 3 && yWidth <= 4
          ? yWidth * 4
          : config.barHeight < yWidth
          ? config.barHeight
          : yWidth;
      selected
        .selectAll('.bar-group')
        .data(data)
        .join(
          (enter) => {
            enter.append('g').attr('class', 'bar-group');
          },
          (update) => update,
          (exit) => {
            exit.remove();
          }
        );

      function barHeight(eleRef) {
        eleRef
          .attr('height', (d) =>
            d.accValue === 0
              ? 0
              : config.graphType === 'group'
              ? config.barHeight / data.length
              : config.barHeight
          )
          .attr('y', (d, i) =>
            config.graphType === 'group'
              ? config.yScale(d.label) +
                (yWidth - config.barHeight) / 2 +
                d.labelIndex * (config.barHeight / data.length)
              : config.yScale(d.label) + (yWidth - config.barHeight) / 2
          );
      }
      function drawBar(eleRef) {
        eleRef
          .call(barHeight)
          .attr('x', (d) =>
            config.graphType === 'group'
              ? config.xScale(config.minX)
              : config.articleSentiment
              ? config.xScale(parseFloat(d.accValue - d.value)) +
                config.barPadding
              : config.xScale(parseFloat(d.accValue - d.value))
          )
          .attr('width', (d, i) => {
            const barHeight = config.articleSentiment
              ? Math.max(
                  0,
                  config.xScale(parseFloat(d.value)) - config.barPadding
                )
              : config.xScale(parseFloat(d.value));
            return barHeight;
          });
      }

      // Linear Gradient
      if (config?.colorGradientDark && config?.colorGradientLight) {
        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.colorGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.colorGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );
      }

      if (config?.articleSentiment) {
        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator-positive');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.greenGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.greenGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );

        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator-negative');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.redGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.redGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );

        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator-neutral');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.grayGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.grayGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );
      }

      const handleDropShadow = (d, i) => {
        if (config.articleSentiment) {
          const color = ColorParser(colorBox[i]);
          const rgbaColor = hexToRgba(color, 0.3);
          return `drop-shadow(-5px 0px 6px ${rgbaColor})`;
        }
      };

      selected
        .selectAll('.bar-group')
        .selectAll('rect')
        .data((d, i) => {
          d.map((entry) => {
            const temp = entry;
            temp.labelIndex = i;
            return temp;
          });
          return d;
        })
        .join(
          (enter) => {
            enter
              .append('rect')
              .attr('class', 'column-rect')
              .attr('data-gi', (d) => d.labelIndex)
              .style('fill', (d, i) => {
                const color = ColorParser(colorBox[i]);
                const alpha = Math.max(0, 1 - 0.1 * d?.labelIndex); // Calculate opacity based on index
                return hexToRgba(color, alpha);
              })
              .attr('rx', 4)
              .style('filter', (d, i) => handleDropShadow(d, i))
              // .style('filter',  'url(#drop-shadow)')
              .attr('x', 0)
              .attr('width', 0)
              .call(barHeight)
              .transition(t)
              .call(drawBar);
          },
          (update) => update.transition(t).call(drawBar),
          (exit) => {
            exit.transition(t).attr('height', 0).attr('width', 0).remove();
          }
        );

      // Add labels to each bar (update this code section)
      selected
        .selectAll('.bar-group')
        .selectAll('.bar-label')
        .data((d, i) => {
          d.map((entry) => {
            const temp = entry;
            temp.labelIndex = i;
            return temp;
          });
          return d;
        })
        .join(
          (enter) => {
            enter
              .append('text')
              .attr('class', 'bar-label')
              .attr('x', (d) => {
                const barWidth = config.xScale(parseFloat(d.value));
                return (
                  config.xScale(parseFloat(d.accValue - d.value)) + barWidth / 2
                );
              })
              .attr('y', (d) => {
                const barY =
                  config.graphType === 'group'
                    ? config.yScale(d.label) +
                      (yWidth - config.barHeight) / 2 +
                      d.labelIndex * (config.barHeight / data.length)
                    : config.yScale(d.label) + (yWidth - config.barHeight) / 2;
                return barY + config.barHeight / 2;
              })
              .attr('dy', '0.35em')
              .text((d) => {
                const fullLabel = `${d.labelText} ${formatNumber(d.value)}`;
                const barWidth = config.xScale(parseFloat(d.value));
                const textWidth = getTextWidth(fullLabel); // Custom function to get text width

                // Check if the text width exceeds the available space
                if (textWidth > barWidth) {
                  // If the available width is less than 40 pixels, skip the label
                  if (barWidth < 40) {
                    return '';
                  }
                  // If it exceeds the space but is still larger than 40, show ellipsis
                  return `${fullLabel.slice(0, Math.floor(barWidth / 16))}..`;
                }
                return fullLabel; // Return the full label if it fits
              })
              .style('fill', 'white')
              .style('font-size', '11px')
              .style('text-anchor', 'middle');
          },
          (update) => {
            update
              .attr('x', (d) => {
                const barWidth = config.xScale(parseFloat(d.value));
                return (
                  config.xScale(parseFloat(d.accValue - d.value)) + barWidth / 2
                );
              })
              .attr('y', (d) => {
                const barY =
                  config.graphType === 'group'
                    ? config.yScale(d.label) +
                      (yWidth - config.barHeight) / 2 +
                      d.labelIndex * (config.barHeight / data.length)
                    : config.yScale(d.label) + (yWidth - config.barHeight) / 2;
                return barY + config.barHeight / 2;
              })
              .text((d) => {
                const fullLabel = `${d.labelText} ${formatNumber(d.value)}`;
                const barWidth = config.xScale(parseFloat(d.value));
                const textWidth = getTextWidth(fullLabel); // Custom function to get text width

                // Check if the text width exceeds the available space
                if (textWidth > barWidth) {
                  // If the available width is less than 40 pixels, skip the label
                  if (barWidth < 40) {
                    return ''; // Skip label entirely if space is too small
                  }
                  // If it exceeds the space but is still larger than 40, show ellipsis
                  return `${fullLabel.slice(0, Math.floor(barWidth / 16))}..`;
                }
                return fullLabel; // Return the full label if it fits
              });
          },
          (exit) => exit.remove()
        );

      // Function to calculate text width (you can implement this according to your needs)
      function getTextWidth(text) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '12px Arial'; // Match font size and family used in your labels
        return context.measureText(text).width;
      }
      setUpEvents(config, selected, 'bar-label');
      setUpEvents(config, selected, 'column-rect');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
