import {
  brandCharts,
  peopleCharts,
  industryCharts,
  competitionCharts,
  campaignMonitorCharts,
  socialCampaignCharts,
  contentDashboardCharts,
  influencerDashboardCharts,
  storyAnalysisCharts,
  brandSwitchCharts,
  networkMapCharts,
  primpactCharts,
  primpactComparisonCharts,
} from '../../pages/new-dashboard/utils';
import { brand_mapping } from './brand-mapping';
import { people_mapping } from './people-mapping';
import { industry_mapping } from './industry-mapping';
import { competition_mapping } from './competition-mapping';
import { campaign_mapping } from './campaign-mapping';
import { message_congruence_mapping } from './message-congruence-mapping';
import { social_campaign_mapping } from './social-campaign-mapping';
import { content_dashboard_mapping } from './content-dashboard-mapping';
import { influencer_dashboard_mapping } from './influencer-dashboard-mapping';
import { story_analysis_dashboard_mapping } from './story-analysis-dashboard-mapping';
import { brand_switch_mapping_dashboard } from './brand-switch-dashboard-mapping';
import { networkMap_dashboard_mapping } from './networkMap-dashboard-mapping';
import { primapct_mapping } from './primpact-mapping';

export const getGraphData = (resData, graphType, chartName) => {
  let resp;
  if (brandCharts.find((x) => x?.chartId === graphType)) {
    resp = brand_mapping(resData, graphType, chartName);
    resp.dashboardType = 'brand';
    resp.customClassName = graphType;
  } else if (competitionCharts.find((x) => x?.chartId === graphType)) {
    resp = competition_mapping(resData, graphType, chartName);
    resp.dashboardType = 'brand';
    resp.customClassName = graphType;
  } else if (peopleCharts.find((x) => x?.chartId === graphType)) {
    resp = people_mapping(resData, graphType, chartName);
    resp.dashboardType = 'people';
    resp.customClassName = graphType;
  } else if (industryCharts.find((x) => x?.chartId === graphType)) {
    resp = industry_mapping(resData, graphType, chartName);
    resp.dashboardType = 'industry';
    resp.customClassName = graphType;
  } else if (campaignMonitorCharts.find((x) => x?.chartId === graphType)) {
    resp = campaign_mapping(resData, graphType, chartName);
    resp.dashboardType = 'campaign';
    resp.customClassName = graphType;
  } else if (graphType === 'congruence') {
    resp = message_congruence_mapping(resData, graphType, chartName);
    resp.customClassName = graphType;
    resp.dashboardType = 'congruence';
  } else if (socialCampaignCharts.find((x) => x?.chartId === graphType)) {
    resp = social_campaign_mapping(resData, graphType, chartName);
    resp = {
      dashboardType: 'socialCampaign',
      customClassName: graphType,
      [graphType]: {
        data: resp,
        show: true,
        customClassName: graphType,
      },
    };
  } else if (contentDashboardCharts.find((x) => x?.chartId === graphType)) {
    resp = content_dashboard_mapping(resData, graphType, chartName);
    if (graphType === 'advanced_content_engagement') {
      resp = {
        dashboardType: 'contentDashboard',
        customClassName: graphType,
        [graphType]: {
          data: { ...resp?.data, show: true, customClassName: graphType },
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    } else if (graphType === 'advanced_content_top_posts') {
      resp = {
        dashboardType: 'contentDashboard',
        customClassName: graphType,
        topEngagingPosts: {
          data: { ...resp, show: true },
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    } else {
      resp = {
        dashboardType: 'contentDashboard',
        customClassName: graphType,
        [graphType]: {
          data: {
            ...resp,
            show: true,
          },
          customClassName: graphType,
          component: graphType,
        },
      };
    }
  } else if (influencerDashboardCharts.find((x) => x?.chartId === graphType)) {
    resp = influencer_dashboard_mapping(resData, graphType, chartName);
    // if (graphType === 'influencer_wordcloud') {
    resp = {
      dashboardType: 'influencerDashboard',
      customClassName: graphType,
      [graphType]: {
        data: {
          ...resp,
          show: true,
          customClassName: graphType,
        },
        customClassName: graphType,
        component: graphType,
      },
    };
  } else if (storyAnalysisCharts.find((x) => x?.chartId === graphType)) {
    resp = story_analysis_dashboard_mapping(resData, graphType, chartName);
    resp = {
      dashboardType: 'storyAnalysis',
      customClassName: graphType,
      [graphType]: {
        data: {
          ...resp,
          show: true,
          customClassName: graphType,
        },
        customClassName: graphType,
        component: graphType,
      },
    };
  } else if (brandSwitchCharts.find((x) => x?.chartId === graphType)) {
    resp = brand_switch_mapping_dashboard(resData, graphType, chartName);
    if (graphType === 'brand_switch_sentiment_analysis_count') {
      resp = {
        dashboardType: 'brandSwitch',
        customClassName: graphType,
        [graphType]: {
          data: {
            ...resp?.sentimentCountChartMapData,
            customClassName: graphType,
          },
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    } else if (graphType === 'brand_switch_sentiment_analysis') {
      resp = {
        dashboardType: 'brandSwitch',
        customClassName: graphType,
        [graphType]: {
          data: {
            ...resp?.sentimentReasonsChartMapData,
            customClassName: graphType,
          },
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    } else {
      resp = {
        dashboardType: 'brandSwitch',
        customClassName: graphType,
        [graphType]: {
          data: {
            ...resp,
            customClassName: graphType,
          },
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    }
  } else if (networkMapCharts.find((x) => x?.chartId === graphType)) {
    resp = networkMap_dashboard_mapping(resData, graphType, chartName);
    // if (graphType === 'influencer_wordcloud') {
    resp = {
      dashboardType: 'networkMap',
      customClassName: graphType,
      networkClusterDataWidgetDetails: {
        data: {
          ...resp,
          show: true,
          customClassName: graphType,
        },
        customClassName: graphType,
        component: graphType,
      },
    };
  } else if (
    primpactCharts.find((x) => x?.chartId === graphType) ||
    primpactComparisonCharts.find((x) => x?.chartId === graphType)
  ) {
    resp = primapct_mapping(resData, graphType, chartName);
    resp.customClassName = graphType;
    resp.dashboardType = 'primpact';
  }
  return resp;
};
