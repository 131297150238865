import {
  coverageOverTimeChartDashboardMapData,
  mediaTypeBrandMapData,
  reachOverTimeChartDashboardMapData,
  resultOverTimeBrandMapData,
  sentimeChartDashobardMapData,
  sentimeOverTimeChartDashboardMapData,
  companiesMentioned,
  compMediaContributors,
  topPerformingPostsChartData,
} from '../../hooks/data/dashboardData';
import { calculatePercentage, addCountPrefix } from '../../constants/utils';
import { getFormattedDate } from '../../hooks/useCharts';
import { socialMediaTypes, traditionalMediaTypes } from '../../constants';
import { outletBreakDown } from '../../graphs/utils/graphConst';
import { format, parseISO } from 'date-fns';
import { colors, WordCloudOrange } from '../../hooks/data/colors';

function updateArrayToObject(arr, keyProperty, docCountProperty, labels) {
  const resultObject = {};

  labels.forEach((label) => {
    const value = label?.value;
    const docCount =
      arr.find((item) => item[keyProperty] === value)?.[docCountProperty] || 0;
    resultObject[value] = docCount;
  });

  return resultObject;
}

export function brand_mapping(response, graphType, chartName, filterBy) {
  if (graphType === 'sentiment_analysis') {
    if (response?.error) {
      return {};
    }
    const sentimentChartMapData = JSON.parse(
      JSON.stringify(sentimeChartDashobardMapData)
    );
    // Set Percentage here
    const percentage = calculatePercentage(response);
    const sentimeMentMapData = sentimentChartMapData?.data.data;
    const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
      return {
        ...x,
        value: percentage[x.label],
        sentiment_negative: 123,
      };
    });

    const totalArticlesMapData = sentimentChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    sentimentChartMapData.originalCount = response?.total_count;
    sentimentChartMapData.title = chartName;
    sentimentChartMapData.data.summary = totalArticlesMapData;
    sentimentChartMapData.data.data = updatedSentimentMapData || [];
    sentimentChartMapData.data.netSentiment = response?.net_sentiment || 0;
    sentimentChartMapData.shouldShowGraph = response?.total_count > 0;
    return sentimentChartMapData;
  } else if (graphType === 'sentiment_over_time') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(
      JSON.stringify(sentimeOverTimeChartDashboardMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Sentiment Over Time - Traditional';
    // }

    const dataFromRes = response?.data;
    const labels = mapData.data.labels;
    const udpatedDataFromRes = dataFromRes?.map((x) => {
      const formattedDate = getFormattedDate(x?.date ?? x.label);
      const data = updateArrayToObject(
        x?.sentiment_count,
        'key',
        'doc_count',
        labels
      );
      return {
        label: formattedDate,
        ...data,
        date: x?.date ?? x.label,
      };
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.originalCount = response?.total_count;
    mapData.data.summary = totalArticlesMapData;
    mapData.title = chartName;
    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;
    return mapData;
  } else if (graphType === 'sentiment_over_time_social') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(
      JSON.stringify(sentimeOverTimeChartDashboardMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Sentiment Over Time - Social';
    // }

    const dataFromRes = response?.data;
    const labels = mapData.data.labels;
    const udpatedDataFromRes = dataFromRes?.map((x) => {
      const formattedDate = getFormattedDate(x?.date ?? x.label);
      const data = updateArrayToObject(
        x?.sentiment_count,
        'key',
        'doc_count',
        labels
      );
      return {
        label: formattedDate,
        ...data,
        date: x?.date ?? x.label,
        mediaType: 'social',
      };
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.originalCount = response?.total_count;
    mapData.data.summary = totalArticlesMapData;
    mapData.title = chartName;
    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;
    return mapData;
  } else if (graphType === 'coverage_over_time') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(
      JSON.stringify(coverageOverTimeChartDashboardMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Traditional';
    // }

    // mapData.mediaTypes = payload?.media_types
    //   ? payload?.media_types?.filter((x) => traditional.includes(x))
    //   : [...traditional];
    mapData.mediaType = [...traditionalMediaTypes];

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.title = chartName;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          value: String(x?.value),
          date: x?.date ?? x.label,
        };
      }) || [];
    mapData.shouldShowGraph = response?.total_count;
    return mapData;
  } else if (graphType === 'coverage_over_time_social') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(
      JSON.stringify(coverageOverTimeChartDashboardMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Social';
    // }

    // mapData.mediaTypes = payload?.media_types
    //   ? payload?.media_types?.filter((x) => social.includes(x))
    //   : [...social];
    mapData.mediaType = [...socialMediaTypes];

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.title = chartName;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          value: String(x?.value),
          date: x?.date ?? x.label,
          mediaType: 'social',
        };
      }) || [];
    mapData.shouldShowGraph = response?.total_count;
    return mapData;
  } else if (graphType === 'reach_over_time') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(
      JSON.stringify(reachOverTimeChartDashboardMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Reach Over Time - Traditional';
    // }

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.title = chartName;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          // value: x?.doc_count,
          value: x?.value,
          date: x?.date ?? x.label,
        };
      }) || [];
    const noDataCheck = response.data.some((item) => {
      return Object.keys(item).some(
        (key) => key !== 'label' && item[key] !== '0'
      );
    });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    return mapData;
  } else if (graphType === 'reach_over_time_social') {
    if (response?.error) {
      return {};
    }
    console.log(response);
    const mapData = JSON.parse(
      JSON.stringify(reachOverTimeChartDashboardMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Reach Over Time - Social';
    mapData.mediaType = 'Social';
    // }

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.title = chartName;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          // value: x?.doc_count,
          value: x?.value,
          date: x?.date ?? x.label,
          mediaType: 'social',
        };
      }) || [];
    const noDataCheck = response?.data.some((item) => {
      return Object.keys(item).some(
        (key) => key !== 'label' && item[key] !== '0'
      );
    });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    return mapData;
  } else if (graphType === 'media_type') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(mediaTypeBrandMapData));

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count ? response?.total_count : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    const dataFromRes = response?.data;

    let totalOnlineCount = 0;
    let totalPrintCount = 0;
    let totalBlogsCount = 0;
    let totalForumsCount = 0;
    let totalReviewsCount = 0;
    let totalTwitterCount = 0;
    let totalRedditCount = 0;
    let totalYouTubeCount = 0;
    dataFromRes?.forEach((x) => {
      const onlineMedia = x?.media_type_count?.find(
        (media) => media.label === 'Online'
      );
      const PrintMedia = x?.media_type_count?.find(
        (media) => media.label === 'Print'
      );
      const forumMedia = x?.media_type_count?.find(
        (media) => media.label === 'Forums'
      );
      const blogMedia = x?.media_type_count?.find(
        (media) => media.label === 'Blogs'
      );
      const reviewMedia = x?.media_type_count?.find(
        (media) => media.label === 'Reviews'
      );
      const twitterMedia = x?.media_type?.find((media) => {
        return media?.label?.toLowerCase()?.includes('twitter');
      });

      const YouTubeMedia = x?.media_type?.find((media) => {
        return media?.label === 'YouTube';
      });

      const redditMedia = x?.media_type?.find((media) => {
        return media;
      });

      const onlineDocCount = onlineMedia ? onlineMedia.value : 0;
      const printDocCount = PrintMedia ? PrintMedia.value : 0;
      const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
      const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
      const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
      const twitterDocCount = twitterMedia ? twitterMedia.doc_count : 0;
      const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
      const YouTubeDocCount = YouTubeMedia ? YouTubeMedia.doc_count : 0;

      totalOnlineCount += onlineDocCount;
      totalPrintCount += printDocCount;
      totalBlogsCount += blogDocCount;
      totalReviewsCount += reviewDocCount;
      totalForumsCount += forumDocCount;
      totalTwitterCount += twitterDocCount;
      totalRedditCount += redditDocCount;
      totalYouTubeCount += YouTubeDocCount;
    });

    const udpatedDataFromRes = dataFromRes?.map((item) => {
      const formattedDate = getFormattedDate(item?.label ?? item?.date);

      return {
        date: item?.label ?? item?.date,
        label: formattedDate,

        print:
          item.media_type_count.find((type) => type.label === 'Print')?.value ||
          0,
        online:
          item.media_type_count.find((type) => type.label === 'Online')
            ?.value || 0,
        forum:
          item.media_type_count.find((type) => type.label === 'Forums')
            ?.value || 0,
        blog:
          item.media_type_count.find((type) => type.label === 'Blogs')?.value ||
          0,
        review:
          item.media_type_count.find((type) => type.label === 'Reviews')
            ?.value || 0,
        twitter:
          item.media_type_count.find((type) => type.label === 'X (Twitter)')
            ?.value || 0,
        reddit:
          item?.media_type_count.find((type) => type?.label === 'Reddit')
            ?.value || 0,
        YouTube:
          item?.media_type_count.find((type) => type?.label === 'YouTube')
            ?.value || 0,
      };
    });
    // .slice(0, 60);

    mapData.data.summary = {
      ...totalArticlesMapData,
      totalOnlineCount,
      totalPrintCount,
      totalBlogsCount,
      totalForumsCount,
      totalReviewsCount,
      totalTwitterCount,
      totalRedditCount,
      totalYouTubeCount,
      mediaType: response?.media_filter ?? null,
    };
    mapData.title = chartName;

    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } else if (graphType === 'brand_companies_mentioned') {
    if (response?.error) {
      return {};
    }
    const companies = JSON.parse(JSON.stringify(companiesMentioned));

    companies.data.data = response.data.map((item, i) => {
      const colorIndex = i % colors?.length;
      return {
        ...colors[colorIndex],
        label: item.label?.toUpperCase(),
        value: item.article_count,
        labelColor: colors[colorIndex]?.color,
      };
    });
    companies.data.summary.value = String(
      addCountPrefix(response?.total_count)
    );
    companies.originalCount = response?.total_count;
    companies.title = chartName;
    companies.shouldShowGraph = response?.data?.length > 0;
    if (response) {
      companies.originalData = response;
    }
    return companies;
  } else if (graphType === 'brand_top_performing_posts') {
    if (response?.error) {
      return {};
    }
    const topPostsChartMapData = JSON.parse(
      JSON.stringify(topPerformingPostsChartData)
    );

    const totalArticlesMapData = topPostsChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topPostsChartMapData.data.summary = totalArticlesMapData;
    topPostsChartMapData.originalCount = response?.total_count;
    const topAuthorRes = response?.data;

    const updatedTopPostsData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
      const formattedDate = x?.date
        ? format(parseISO(x.date), 'dd/MM/yyyy')
        : null;

      const truncatedTitle = x?.title ? `${x.title.slice(0, 20)}...` : '';

      return {
        article_id: x?.id,
        rank: i + 1,
        date: formattedDate,
        postContent: truncatedTitle,
        engagement: x?.engagement,
        reach: x?.reach,
      };
    });

    topPostsChartMapData.title = chartName;
    topPostsChartMapData.data.data = updatedTopPostsData || [];
    topPostsChartMapData.shouldShowGraph = topAuthorRes?.length > 0;

    return topPostsChartMapData;
  } else if (graphType === 'brand_engagement_metrics') {
    if (response?.error) {
      return {};
    }
    const data = JSON.parse(JSON.stringify(compMediaContributors));
    data.component = 'brand_engagement_metrics';
    data.title = 'Engagement Metrics';
    data.data.data = response?.data;

    // generated dynamic labels
    data.data.labels = [
      {
        label: 'Likes',
        value: 'likes',
        color: outletBreakDown[0],
      },
      {
        label: 'Shares',
        value: 'shares',
        color: outletBreakDown[1],
      },
      {
        label: 'Comments',
        value: 'comments',
        color: outletBreakDown[2],
      },
    ];
    data.title = chartName;
    // Update summary
    data.data.summary.value = String(addCountPrefix(response?.total_count));
    data.shouldShowGraph = response?.data?.length > 0;
    data.originalCount = response?.total_count;
    return data;
  } else if (graphType === 'brand_social_word_cloud') {
    if (response?.error) {
      return {};
    }
    const companies = JSON.parse(JSON.stringify(companiesMentioned));

    const filteredData = response?.data?.filter((item) => item.count > 0);

    companies.title = 'Social Word Cloud';

    if (!filteredData?.length) {
      companies.data.data = [];
      return companies;
    }

    // Group items by their count
    const countGroups = new Map();
    filteredData.forEach((item) => {
      if (!countGroups.has(item.article_count)) {
        countGroups.set(item.article_count, []);
      }
      countGroups.get(item.article_count).push(item);
    });

    // Convert to sorted array of [count, items] pairs
    const sortedCountGroups = Array.from(countGroups.entries()).sort(
      (a, b) => b[0] - a[0]
    );

    // Ensure at least 6 color groups
    const colorGroups = [];
    let currentGroup = [];
    let currentGroupCount = null;

    sortedCountGroups.forEach(([count, items]) => {
      if (currentGroupCount === null || count === currentGroupCount) {
        currentGroup.push(...items);
        currentGroupCount = count;
      } else {
        colorGroups.push([currentGroupCount, currentGroup]);
        currentGroup = items;
        currentGroupCount = count;
      }
    });

    // Add the last group
    if (currentGroup.length > 0) {
      colorGroups.push([currentGroupCount, currentGroup]);
    }

    // If we have fewer than 6 groups, split the largest groups
    while (colorGroups.length < 6) {
      const largestGroupIndex = colorGroups.reduce(
        (maxIndex, group, index, arr) =>
          group[1].length > arr[maxIndex][1].length ? index : maxIndex,
        0
      );

      const [count, items] = colorGroups[largestGroupIndex];
      const midPoint = Math.floor(items.length / 2);

      colorGroups.splice(
        largestGroupIndex,
        1,
        [count, items.slice(0, midPoint)],
        [count, items.slice(midPoint)]
      );
    }

    // Assign colors to groups
    const colorAssignments = new Map();
    colorGroups.forEach(([count, items], index) => {
      const assignedColor =
        WordCloudOrange[Math.min(index, WordCloudOrange.length - 1)];

      items.forEach((item) => {
        colorAssignments.set(item, assignedColor);
      });
    });
    // Map the original filtered data with color assignments
    companies.data.data = filteredData.map((item) => {
      const assignedColor = colorAssignments.get(item);

      return {
        ...assignedColor,
        label: item.label?.toUpperCase(),
        value: item.article_count,
        labelColor: assignedColor?.color,
        type: item?.type,
      };
    });

    companies.component = 'brand_social_word_cloud';
    companies.data.summary.value = String(
      addCountPrefix(response?.total_count)
    );
    companies.title = chartName;
    companies.shouldShowGraph = response?.data?.length > 0;
    companies.originalCount = response?.total_count;

    if (response) {
      companies.originalData = response;
    }

    return companies;
  } else if (graphType === 'brand_result_over_time') {
    if (response?.error) {
      return {};
    }
    const mapData = JSON.parse(JSON.stringify(resultOverTimeBrandMapData));

    // mapData.mediaTypes = payload?.media_types
    //   ? payload?.media_types?.filter((x) => traditional.includes(x))
    //   : [...traditional];

    mapData.mediaType = [...traditionalMediaTypes];

    // setting total count
    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count ? response?.total_count : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = response?.total_count;
    mapData.title = chartName;

    // setting data
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x.label);

        return {
          ...x,
          label: formattedDate,
          date: x.label,
          trendLabel:
            (filterBy?.value ?? 'Reach').charAt(0).toUpperCase() +
            (filterBy?.value ?? 'Reach').slice(1),

          trend:
            x.engagements || x.reach || x.impressions
              ? x.engagements || x.reach || x.impressions
              : 0,
        };
      }) || [];

    mapData.shouldShowGraph = response?.data?.length > 0;

    return mapData;
  }
}
