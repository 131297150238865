import React from 'react';

const AddUserIcon2 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_18592_49352"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_18592_49352)">
        <path
          d="M14.75 11V9.25H13V7.75H14.75V6H16.25V7.75H18V9.25H16.25V11H14.75ZM8 10C7.16667 10 6.45833 9.70833 5.875 9.125C5.29167 8.54167 5 7.83333 5 7C5 6.16667 5.29167 5.45833 5.875 4.875C6.45833 4.29167 7.16667 4 8 4C8.83333 4 9.54167 4.29167 10.125 4.875C10.7083 5.45833 11 6.16667 11 7C11 7.83333 10.7083 8.54167 10.125 9.125C9.54167 9.70833 8.83333 10 8 10ZM2 16V14.0833C2 13.7253 2.08681 13.3962 2.26042 13.096C2.43403 12.7959 2.67361 12.5556 2.97917 12.375C3.74306 11.9306 4.54861 11.5903 5.39583 11.3542C6.24306 11.1181 7.11111 11 8 11C8.88889 11 9.75694 11.1181 10.6042 11.3542C11.4514 11.5903 12.2569 11.9306 13.0208 12.375C13.3264 12.5556 13.566 12.7959 13.7396 13.096C13.9132 13.3962 14 13.7253 14 14.0833V16H2Z"
          fill="#4D5358"
        />
      </g>
    </svg>
  );
};

export default AddUserIcon2;
