import { widgetImg, widgetPlaceHolders } from '../assets/img/widgetsImg';
import GeoBreakDown from '../components/geographical_breakdown';
import HorizontalMultiBar from '../components/horizontal-bar';
import L1Carousel from '../components/l1-carousel';
import RecentSearches from '../components/recent-searches';
import SingleArticle from '../components/single-article';
import TopFiveSearches from '../components/top-five-searches';
import TopTrendingChart from '../components/top-trending-chart';
import UberStatsSentiment from '../components/uber-stats-sentiment';
import UberStatsVolume from '../components/uber-stats-volume';
import UberStats from '../components/user-stats';
import {
  BigBubble,
  CoolColumn,
  GreatGuage,
  LinearLine,
  PieGraph,
  Radar,
  WordCloud,
  RadialPieChart,
  BestBar,
  USMapChart,
  WorldMapGraph,
  CoolColumn3D,
  HotHeatMap,
  ConcentricPieChart,
  GaugeChart,
  LineBar,
  GroupedLineBar,
} from '../graphs';

import NetworkClusterWrapper from '../graphs/NetworkCluster/networkCluster';
import PRImpactHeatMap from '../graphs/PRHeatMap/graphWrapper';
import Treemap from '../graphs/TreeGraph/graphWrapper';
import { theme } from './theme';
import GroupedColumnBar from '../graphs/GroupedColumnBar/graphWrapper';
import BubbleChart from '../graphs/BubbleSentimentCluster/bubbleSentimentGraph';

const handleOnClick = (event, d, i) => {
  console.log('handleOnClick', event, d, i);
};

const handleMouseOver = (event, d, i) => {
  // console.log('handleMouseOver', event, d, i);
};

const handleMouseOut = (event, d, i) => {
  // console.log('handleMouseOut', event, d, i);
};

const handleMouseEnter = (event, d, i) => {
  // console.log('handleMouseEnter', event, d, i);
};

const handleMouseMove = (event, d, i) => {
  // console.log('handleMouseMove', event, d, i);
};

const handleMouseLeave = (event, d, i) => {
  // console.log('handleMouseLeave', event, d, i);
};

export const events = {
  handleOnClick,
  handleMouseOver,
  handleMouseOut,
  handleMouseEnter,
  handleMouseMove,
  handleMouseLeave,
};

export const gridLine = {
  enableGridXLine: false,
  enableGridYLine: true,
  gridLineStrokeWidth: 1,
  gridLineXStroke: '#d9dbde',
};

export const noGridLine = { enableGridXLine: false, enableGridYLine: true };

export const L1GridLineConfig = {
  gridYTicks: 6,
  hideYAxis: false,
  gridLineYStroke: '#d9dbdedd',
  gridLineStrokeWidth: 1,
  enableGridXLine: false,
  enableGridYLine: true,
};

export const L1GridLineBarConfig = {
  hideYAxis: true,
  gridLineXStroke: theme.light.graphColors.coolGray30,
  gridLineStrokeWidth: 1,
  enableGridXLine: true,
  enableGridYLine: false,
};

export const coolColumn3DConfig = {
  graphType: 'column',
  gridXYLabelFontSize: 12,
  enableTooltip: true,
  gridYTicks: 6,
  ...events,
};

const graphConfig = {
  bubble: {
    enableTooltip: true,
    graphType: 'bubble',
    ...events,
  },
  column: {
    graphType: 'column',
    gridXYLabelFontSize: 12,
    enableTooltip: true,
    ...events,
    yAxisType: 'number',
    yAxisTicksFormat: true,
    yLabelAlignment: 40,
    gridYTicks: 6,
    gridLineXStroke: '#d9dbdedd',
    gridLineStrokeWidth: 1,
    enableGridXLine: false,
    enableGridYLine: true,
    xAxisType: 'text',
    singleLineWrp: true,
    gridXTicks: 8,
  },
  column_fixed_width: {
    graphType: 'group',
    gutterSpace: 1,
    gridXTicks: 6,
    gridYTicks: 6,
    gridLineYStroke: '#DDE1E6',
    gridLineStrokeWidth: 1,
    enableGridYLine: true,
    enableTooltip: true,
    graphXYLabelFontSize: 11,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontColor: theme?.light?.graphColors?.coolGray60,
    ...events,
  },
  pr_heatmap_width: {
    graphType: 'group',
    gutterSpace: 1,
    gridXTicks: 6,
    gridYTicks: 6,
    gridLineYStroke: '#DDE1E6',
    gridLineStrokeWidth: 1,
    enableGridYLine: true,
    enableTooltip: true,
    graphXYLabelFontSize: 11,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontColor: theme?.light?.graphColors?.coolGray60,
    padding: { left: 0, top: 0, bottom: 0, right: 10 },
    ...events,
  },
  coolColumn3D: {
    graphType: 'column',
    gridXYLabelFontSize: 11,
    hideXAxis: true,
    hideYAxis: true,
    showOutletLabels: true,
    enableTooltip: true,
    gridYTicks: 6,
    yAxisType: 'number',
    // xLabelAlignment: 5,
    yAxisTicksFormat: true,
    enableRectLabels: true,
    colors: ['#675EF2', '#F54A80', '#FCAF41'],
    ...events,
  },
  coolColumn3DNoLabel: {
    graphType: 'column',
    gridXYLabelFontSize: 12,
    hideXAxis: false,
    enableTooltip: true,
    gridYTicks: 6,
    yAxisType: 'number',
    // xLabelAlignment: 5,
    yAxisTicksFormat: true,
    enableRectLabels: false,
    colors: ['#675EF2', '#F54A80', '#FCAF41'],
    ...events,
  },
  groupped_column: {
    graphType: 'group',
    gridYTicks: 6,
    gridLineYStroke: '#EBEBEB',
    gridLineStrokeWidth: 1,
    enableGridYLine: true,
    enableTooltip: true,
    yAxisType: 'number',
    yLabelAlignment: 50,
    yAxisTicksFormat: true,
    // columnWidth: 32,
    columnWidth: 16,
    graphXYLabelFontSize: 11,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 11,
    fontColor: theme.light.graphColors.coolGray60,
    ...events,
  },
  gauge: {
    startAngle: (-1 * Math.PI) / 2,
    endAngle: Math.PI / 2,
    enableArcBG: '#F0F2F5',
    arcDividerStrokeColor: 'none',
    innerRadius: 0.2, // 0.01 - 0.5
    enableNeedle: true,
  },
  gauge_meter: {
    startAngle: (-1 * Math.PI) / 2,
    endAngle: Math.PI / 2,
    enableArcBG: '#F0F2F5',
    arcDividerStrokeColor: 'none',
    innerRadius: 0.2, // 0.01 - 0.5
    enableNeedle: true,
  },
  gauge2d: {
    startAngle: Math.PI * -0.5,
    endAngle: Math.PI * 0.5,
    endRadius: 0,
    arcWidth: 20,
    arcPadding: 5,
    hideNeedle: false,
  },
  line: {
    // graphType: 'area',
    graphType: 'line',
    gridXYLabelFontSize: 12,
    enableCurve: true,
    enableGradient: true,
    enableAreaLine: true,
    // areaLineStroke: '#E20074',
    areaLineStroke: '#893FFC',
    enableTooltip: true,
    ...events,
    yLabelAlignment: 40,
    yAxisTicksFormat: true,
    yAxisType: 'number',
    gridYTicks: 6,
    gridXTicks: 8,
    graphAreaWMultiplayer: 1,
    xAxisType: 'text',
    singleLineWrp: true,
    ...gridLine,
  },
  stacked_line: {
    // graphType: 'sarea',
    graphType: 'area',
    yAxisType: 'number',
    yDomainMultiplayer: 0.001,
    gridYTicks: 5,
    yLabelAlignment: 40,
    yAxisTicksFormat: true,
    graphAreaWMultiplayer: 1,
    enableTooltipTrendPointer: true,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 11,
    fontColor: theme.light.graphColors.coolGray60,
    enableGradient: true,
    enableAreaLine: true,
    ...gridLine,
  },
  area: {
    graphType: 'area',
    gridXYLabelFontSize: 12,
    enableCurve: true,
    enableGradient: true,
    enableAreaLine: false,
    areaLineStroke: '#E20074',
    enableTooltip: true,
    ...events,
    yAxisType: 'number',
    yLabelAlignment: 50,
    yAxisTicksFormat: true,
    enableNeedle: false,
    gridXTicks: 6,
    gridYTicks: 6,
    graphAreaWMultiplayer: 0.95,
    ...gridLine,
  },
  result_over_time_area: {
    graphType: 'area',
    gridXYLabelFontSize: 11,
    fontWeight: 500,
    enableCurve: false,
    enableGradient: true,
    enableAreaLine: true,
    areaLineStroke: '#893FFC',
    enableTooltip: true,
    gridYTicks: 6,
    r: 5,
    multiLineDrillDown: true,
    yAxisType: 'numbernumber',
    yLabelAlignment: 30,
    yAxisTicksFormat: true,
    graphAreaWMultiplayer: 0.98,
    enablePeaks: true,
    ...events,
    ...gridLine,
    enableGridXLine: true,
    pointCircleStrokeWidth: 2,
    enableTooltipTrendPointer: true,
  },
  line_bar: {
    ...events,
    ...gridLine,
  },
  line2d: {
    graphType: 'line',
    gridXYLabelFontSize: 12,
    enableCurve: true,
    enableGradient: true,
    enableAreaLine: true,
    areaLineStroke: '#E20074',
    enableTooltip: true,
    graphAreaWMultiplayer: 0.95,
    ...events,
    ...gridLine,
  },
  donut: {
    startAngle: -1 * (Math.PI / 2),
    endAngle: Math.PI + Math.PI / 2,
    enableArcBG: '#F0F2F5',
    arcDividerStrokeColor: 'none',
    innerRadius: 0.15, // 0.01 - 0.5
    arcLabel: false,
    enablePolyline: false,
    padAngle: 0.03,
    enableCenterText: true,
    enableTooltip: true,
    graphType: 'donut',
    cornerRadius: 5,
    ...events,
  },
  concentric_donut: {
    startAngle: -1 * Math.PI,
    endAngle: Math.PI,
    endRadius: 0,
    arcWidth: 20,
    arcPadding: 5,
    hideNeedle: false,
  },
  pie: {
    arcLabel: false,
    graphType: 'pie',
    enableTooltip: true,
    padAngle: 0.03,
    innerRadius: 0.01, // 0.01 - 0.5
    arcDividerStrokeColor: 'none',
  },
  concentric_pie: {
    startAngle: -1 * Math.PI,
    endAngle: Math.PI,
    enableArcBG: '#F0F2F5',
    arcDividerStrokeColor: 'white',
    innerRadius: 0.6, // 0.01 - 0.5
    outerInnerRadius: 0.55,
    enablePolyline: false,
    arcLabel: true,
    center: true,
    isConcentric: false,
  },
  radar: {
    backgroundType: 'circle1',
    startAngle: -1 * Math.PI,
    endAngle: Math.PI,
    enableArcBG: '#F0F2F5',
    arcDividerStrokeColor: 'none',
    innerRadius: 0.2, // 0.01 - 0.5
    enablePolyline: true,
    arcLabel: true,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '13',
    fontColor: '#4D5358',
    dasharray: 2,
  },
  word_cloud: {},
  radial_pie: { arcLabel: true },
  bar: {
    yAxisType: 'text',
    yLabelAlignment: 60,
    graphType: 'bar',
  },
  bar2d: { graphType: 'group' },
  usmap: {
    enableTooltip: true,
    ...events,
    graphType: 'us_map',
    interpolateColors: ['#675EF2', '#D2CDFF'],
  },
  worldmap: {
    graphType: 'world_map',
    enableTooltip: true,
    ...events,
  },
  stackedBar: {
    graphType: 'stack',
    yLabelAlignment: 45,
    yAxisType: 'text',
    xAxisType: 'number',
    xAxisTicksFormat: true,
    gridXTicks: 5,
    gutterSpace: 25,
    graphXYLabelFontSize: 11,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontColor: theme?.light?.graphColors?.coolGray60,
  },
  butterfly: {},
  top_theme_config: {
    graphType: 'column',
    gridXYLabelFontSize: 12,
    hideYAxis: true,
    enableTooltip: true,
    ...events,
    gridXTicks: 6,
    yLabelAlignment: 5,
    singleLineWrp: true,
    maxData: 5,
  },
  top_author_config: {
    graphType: 'column',
    gridXYLabelFontSize: 12,
    hideYAxis: true,
    enableTooltip: true,
    ...events,
    enableNeedle: false,
    yLabelAlignment: 5,
    yAxisTicksFormat: true,
    yAxisType: 'number',
  },
  popular_topics_config: {
    graphType: 'column',
    gridXYLabelFontSize: 12,
    hideYAxis: true,
    enableTooltip: true,
    ...events,
    enableNeedle: false,
    yLabelAlignment: 5,
    gridXTicks: 4,
  },
};

export const graphTypes = {
  concentric_pie_chart: {
    label: 'Concentric Pie',
    value: 'concentric_pie',
    component: ConcentricPieChart,
    config: graphConfig.concentric_pie,
    supportType: ['1d'],
  },
  sentiment_gauge: {
    label: 'Gauge Chart',
    value: 'sentiment_gauge',
    component: GaugeChart,
    config: {},
    supportType: ['1d'],
  },
  line_bar: {
    label: 'Line Bar',
    value: 'line_bar',
    component: LineBar,
    config: graphConfig.line_bar,
    supportType: ['1d', '2d'],
  },
  grouped_column_bar: {
    label: 'Grouped Column Bar',
    value: 'grouped_column_bar',
    component: GroupedColumnBar,
    config: graphConfig.line_bar,
    supportType: ['1d', '2d'],
  },
  bubble: {
    label: 'Bubble',
    value: 'bubble',
    component: BigBubble,
    config: graphConfig.bubble,
    supportType: ['1d'],
  },
  column: {
    label: 'Column',
    value: 'column',
    component: CoolColumn,
    config: graphConfig.column,
    supportType: ['1d', '2d'],
  },
  result_over_time_column: {
    label: 'Column',
    value: 'result_over_time_column',
    component: CoolColumn,
    config: {
      ...graphConfig.column,
      gutterSpace: 25,
      hideYAxis: false,
      yLabelAlignment: 50,
    },
    supportType: ['1d', '2d'],
  },
  stacked_column: {
    label: 'Column',
    value: 'stacked_column',
    component: CoolColumn,
    config: {
      ...graphConfig.column,
      yLabelAlignment: 45,
      gridLineXStroke: '#d9dbdedd',
      gridLineStrokeWidth: 1,
      enableGridXLine: false,
      enableGridYLine: true,
    },
    supportType: ['1d', '2d'],
  },
  pr_impact_column: {
    label: 'Column',
    value: 'pr_impact_column',
    component: CoolColumn,
    config: {
      ...graphConfig.column_fixed_width,
      gridXTicks: 10,
      yLabelAlignment: 65,
    },
    supportType: ['1d', '2d'],
  },
  pr_impact_heatmap: {
    label: 'heat_map',
    value: 'pr_impact_heatmap',
    component: PRImpactHeatMap,
    config: {
      ...graphConfig.pr_heatmap_width,
      gridXTicks: 10,
      yLabelAlignment: 65,
    },
    supportType: ['1d', '2d'],
  },
  adv_heatmap: {
    label: 'adv_heat_map',
    value: 'adv_heatmap',
    component: HotHeatMap,
    config: {
      xAxisType: 'text',
      yLabelAlignment: 80,
      hideYAxisLine: true,
      hideXAxisLine: true,
      hideYAxis: false,
      enableGridXLine: false,
      enableGridYLine: false,
    },
    supportType: ['1d', '2d'],
  },
  groupped_column: {
    label: 'Groupped Column',
    value: 'groupped_column',
    component: CoolColumn,
    config: {
      ...graphConfig.groupped_column,
      gutterSpace: 50,
      yLabelAlignment: 40,
    },
    supportType: ['2d'],
  },
  advanced_author_groupped_column: {
    label: 'Groupped Column',
    value: 'advanced_author_groupped_column',
    component: CoolColumn,
    config: {
      ...graphConfig.groupped_column,
      gutterSpace: 20,
      yLabelAlignment: 40,
    },
    supportType: ['2d'],
  },
  people_grouped_column: {
    label: 'Groupped Column',
    value: 'people_grouped_column',
    component: CoolColumn,
    config: {
      ...graphConfig.groupped_column,
      yLabelAlignment: 40,
      yAxisTicksFormat: true,
      yAxisType: 'number',
      gutterSpace: 70,
    },
    supportType: ['2d'],
  },
  sentiment_groupped_column: {
    label: 'Groupped Column',
    value: 'sentiment_groupped_column',
    component: CoolColumn,
    config: {
      ...graphConfig.column,
      valueInPercent: true,
      yAxisType: 'percentage',
      // gutterSpace: window.innerWidth / 7.5,
      columnWidth: 120,
    },
    supportType: ['2d'],
  },
  column_fixed_width: {
    label: 'Fixed Width Column',
    value: 'column_fixed_width',
    component: CoolColumn,
    config: graphConfig.column_fixed_width,
    supportType: ['1d', '2d'],
  },
  cool_column_3d: {
    label: '3D Data',
    value: 'cool_column_3d',
    component: CoolColumn3D,
    config: graphConfig.coolColumn3D,
    supportType: ['3d'],
  },
  cool_column_3d_NL: {
    label: '3D Data',
    value: 'cool_column_3d_NL',
    component: CoolColumn3D,
    config: graphConfig.coolColumn3DNoLabel,
    supportType: ['3d'],
  },

  gauge: {
    label: 'Gauge',
    value: 'gauge',
    component: GreatGuage,
    config: graphConfig.guage,
    supportType: ['1d'],
  },
  gauge_meter: {
    label: 'Gauge Meter',
    value: 'gauge_meter',
    component: PieGraph,
    config: graphConfig.gauge_meter,
    supportType: ['1d'],
  },
  line: {
    label: 'Line',
    value: 'line',
    component: LinearLine,
    config: graphConfig.line,
    supportType: ['1d', '2d'],
  },
  stacked_line: {
    label: 'Stacked Line',
    value: 'stacked_line',
    component: LinearLine,
    config: graphConfig.stacked_line,
    supportType: ['1d', '2d'],
  },
  pie: {
    label: 'Pie',
    value: 'pie',
    component: PieGraph,
    config: graphConfig.pie,
    supportType: ['1d'],
  },
  donut: {
    label: 'Donut',
    value: 'donut',
    component: PieGraph,
    config: graphConfig.donut,
    supportType: ['1d'],
  },
  concentric_donut: {
    label: 'Concentric Donut',
    value: 'concentric_donut',
    component: GreatGuage,
    config: graphConfig.concentric_donut,
    supportType: ['2d'],
  },
  radar: {
    label: 'Radar',
    value: 'radar',
    component: Radar,
    config: graphConfig.radar,
    supportType: ['1d'],
  },
  word_cloud: {
    label: 'Word Cloud',
    value: 'word_cloud',
    component: WordCloud,
    config: graphConfig.wordcloud,
    supportType: ['1d'],
  },
  radial_pie: {
    label: 'Radial Pie',
    value: 'radial_pie',
    component: RadialPieChart,
    config: graphConfig.radial_pie,
    supportType: ['1d'],
  },
  butterfly: {
    label: 'Butterfly',
    value: 'butterfly',
    component: HorizontalMultiBar,
    config: graphConfig.butterfly,
    supportType: ['2d'],
  },
  sentiment_by_theme_butterfly: {
    label: 'Butterfly',
    value: 'sentiment_by_theme_butterfly',
    component: HorizontalMultiBar,
    config: { ...graphConfig.butterfly, barGap: '0' },
    supportType: ['2d'],
  },
  concentric_pie: {
    label: 'Concenteric Pie',
    value: 'concentric_pie',
    component: PieGraph,
    config: graphConfig.concentric_pie,
    supportType: ['1d'],
  },
  bar: {
    label: 'Bar',
    value: 'bar',
    component: BestBar,
    config: {
      ...graphConfig.bar,
      yLabelAlignment: 120,
      yAxisTicksFormat: true,
      yAxisType: 'text',
      gridLineXStroke: '#d9dbde',
      gridLineStrokeWidth: 1,
      enableGridXLine: true,
      enableGridYLine: false,
      wrapLength: 20,
    },
    supportType: ['1d', '2d'],
  },
  author_impact_bar: {
    label: 'Bar',
    value: 'author_impact_bar',
    component: BestBar,
    config: {
      ...graphConfig.bar,
      yLabelAlignment: 120,
      yAxisTicksFormat: true,
      yAxisType: 'text',
      hideXAxis: true,
      gutterSpace: 35,
      gridLineXStroke: '#d9dbde',
      gridLineStrokeWidth: 1,
      enableGridXLine: true,
      enableGridYLine: false,
      wrapLength: 20,
    },
    supportType: ['1d', '2d'],
  },
  top_source_bar_tree_map: {
    label: 'TreeMap',
    value: 'top_sources_bar',
    component: Treemap,
    config: {},
    supportType: [],
  },
  top_sources_bar: {
    label: 'Bar',
    value: 'top_sources_bar',
    component: BestBar,
    config: {
      ...graphConfig.bar,
      yLabelAlignment: 130,
      yAxisTicksFormat: true,
      yAxisType: 'text',
      xAxisType: 'number',
      xAxisTicksFormat: true,
      wrapLength: 25,
      gridLineXStroke: '#d9dbde',
      gridLineStrokeWidth: 1,
      enableGridXLine: true,
      enableGridYLine: false,
    },
    supportType: ['1d', '2d'],
  },
  top_author_bar: {
    label: 'Bar',
    value: 'top_author_bar',
    component: BestBar,
    config: {
      ...graphConfig.bar,
      yLabelAlignment: 130,
      yAxisTicksFormat: true,
      yAxisType: 'text',
      xAxisType: 'number',
      xAxisTicksFormat: true,
      wrapLength: 20,
      gridLineXStroke: '#d9dbde',
      gridLineStrokeWidth: 1,
      enableGridXLine: true,
      enableGridYLine: false,
      graphXYLabelFontSize: 11,
      fontFamily: 'Inter',
      fontWeight: 500,
      hideYAxisLine: true,
    },
    supportType: ['1d', '2d'],
  },
  us_map: {
    label: 'US Map',
    value: 'us_map',
    component: USMapChart,
    config: graphConfig.usmap,
    supportType: ['1d'],
  },
  world_map: {
    label: 'World Map',
    value: 'world_map',
    component: WorldMapGraph,
    config: graphConfig.worldmap,
    supportType: ['1d'],
  },
  grouped_bar: {
    label: 'Grouped bar',
    value: 'grouped_bar',
    component: BestBar,
    config: graphConfig.bar2d,
    supportType: ['2d'],
  },
  stacked_bar: {
    label: 'Stacked bar',
    value: 'stacked_bar',
    component: BestBar,
    maxData: 6,
    config: {
      ...graphConfig.stackedBar,
      yLabelAlignment: 130,
      gutterSpace: 40,
      wrapLength: 20,
      gridLineXStroke: '#d9dbdedd',
      gridLineStrokeWidth: 1,
      enableGridXLine: true,
      enableGridYLine: false,
    },
    supportType: ['2d'],
  },
  uber_stats: {
    label: 'Stats',
    value: 'uber_stats',
    component: UberStats,
    config: graphConfig.stackedBar,
    supportType: ['2d'],
  },
  uber_stats_volume: {
    label: 'Stats',
    value: 'uber_stats_volume',
    component: UberStatsVolume,
    config: graphConfig.stackedBar,
    supportType: ['2d'],
  },
  uber_stats_sentiment: {
    label: 'Stats',
    value: 'uber_stats_sentiment',
    component: UberStatsSentiment,
    config: graphConfig.stackedBar,
    supportType: ['2d'],
  },
  // profile_line , profile_radar
  profile_line: {
    label: 'Line',
    value: 'profile_line',
    component: LinearLine,
    config: graphConfig.area,
    supportType: ['1d', '2d'],
  },
  profile_radar: {
    label: 'Radar',
    value: 'profile_radar',
    component: Radar,
    config: graphConfig.radar,
    supportType: ['1d'],
  },
  area: {
    label: 'Area',
    value: 'area',
    component: LinearLine,
    config: graphConfig.result_over_time_area,
    supportType: ['1d', '2d'],
  },

  top_trending: {
    label: 'Top Trending',
    value: 'top_trending',
    component: TopTrendingChart,
    config: {},
  },
  network_radial_tree: {
    label: 'Network map',
    value: 'networkmap_cluster',
    component: NetworkClusterWrapper,
    config: {},
  },
  bubble_cluster: {
    label: 'Influencer Deep-Dive',
    value: 'influencer_sentiment_cluster',
    config: {},
    component: BubbleChart,
  },
};

export const overviewWidgets = {
  result_over_time: {
    label: 'Results Over Time',
    slotType: 'full',
    allowedGraphTypes: [graphTypes.groupped_column, graphTypes.area],
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.bar, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.resultOvertime,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  media_type: {
    label: 'Media type',
    // allowedGraphTypes: [graphTypes.donut, graphTypes.pie],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: {
          ...graphConfig.pie,
          enableNeedle: false,
          arcLabel: false,
        },
      },
    },
    img: widgetImg.mediaTypePie,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  sov: {
    label: 'SOV',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.mediaTypePie,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  word_cloud: {
    label: 'Word Cloud',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: {
          ...graphConfig.word_cloud,
          yLabelAlignment: 5,
          maxData: 5,
          minFontSize: 7,
          maxFontSize: 20,
          padding: { left: 0, top: 0, bottom: 0, right: 10 },
        },
      },
    },
    img: widgetImg.wordCloud,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  top_source: {
    label: 'Popular Websites',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          yAxisType: 'text',
          yLabelAlignment: 5,
          graphType: 'bar',
          gridXTicks: 4,
          enableNeedle: false,
          xAxisType: 'number',
          xAxisTicksFormat: true,
          ...L1GridLineBarConfig,
          ...events,
        },
      },
    },
    img: widgetImg.topSources,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  top_themes: {
    label: 'Popular Topics',
    allowedGraphTypes: [],
    // slotType: 'half',
    slotType: 'full',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.top_theme_config,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.topTheme,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  outlet_breakdown: {
    label: 'Media Outlet Breakdown',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          ...graphConfig.column,
          enableNeedle: false,
          arcLabel: false,
          gutterSpace: 15,
          yLabelAlignment: 0,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.outletBreakDown,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  top_author: {
    label: 'Top Authors',
    // allowedGraphTypes: [graphTypes.column, graphTypes.bar],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: { ...graphConfig.top_author_config, ...L1GridLineConfig },
      },
    },
    img: widgetImg.topSources,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  geographical_breakdown: {
    label: 'Geographical Breakdown',
    allowedGraphTypes: [],
    slotType: 'half',

    bentoView: {
      l1: {
        component: USMapChart,
        config: graphConfig.usmap,
        // component: L1Carousel,
        // graphComponent: GeoBreakDown,
        // config: {
        //   ...graphConfig.uber_stats_volume,
        //   enableNeedle: false,
        //   maxData: 6,
        // },
      },
    },
    img: widgetImg.geographicalBreakdown,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  sentiment: {
    label: 'Sentiment',
    // allowedGraphTypes: [graphTypes.pie, graphTypes.donut],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        // graphComponent: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
};

export const brandWidgets = {
  volume_analysis: {
    type: 'brand',
    label: 'Key Metrics Overview',
    allowedGraphTypes: [],
    slotType: 'half',
    description:
      'A breakdown of results in your search(es) by total results, total Reach and total AVE',
    bentoView: {
      l1: {
        component: L1Carousel,
        graphComponent: GeoBreakDown,
        config: { ...graphConfig.uber_stats_volume, enableNeedle: false },
      },
    },
    img: widgetImg.volumeAnalysis,
    placeholder: widgetPlaceHolders.detailsGraph,
  },
  sentiment_analysis: {
    type: 'brand',
    label: 'Brand Sentiment',
    allowedGraphTypes: [graphTypes.pie, graphTypes.sentiment_gauge],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        // graphComponent: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'A breakdown of results in your search(es) by overall article sentiment, categorized as positive, negative, or neutral.',
    tooltipEnabled: true,
  },
  sentiment_over_time: {
    type: 'brand',
    label: 'Sentiment Over Time - Traditional',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          enableNeedle: false,
          gridXTicks: 4,
          gridYTicks: 6,
          yAxisType: 'number',
          yLabelAlignment: 10,
          yAxisTicksFormat: true,
          hideYAxis: true,
          lineStrokeWidth: 1.5,
          // graphAreaWMultiplayer: 1,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.stackedBar,
    placeholder: widgetPlaceHolders.areaGraph,
    description:
      'Tracks and analyzes the evolving tones of results over different time intervals, showing changes in sentiment trends.',
    tooltipEnabled: true,
  },
  sentiment_over_time_social: {
    type: 'brand',
    label: 'Sentiment Over Time - Social',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          enableNeedle: false,
          gridXTicks: 4,
          gridYTicks: 6,
          yAxisType: 'number',
          yLabelAlignment: 10,
          yAxisTicksFormat: true,
          hideYAxis: true,
          lineStrokeWidth: 1.5,
          // graphAreaWMultiplayer: 1,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.stackedBar,
    placeholder: widgetPlaceHolders.areaGraph,
    description:
      'Tracks and analyzes the evolving tones of results over different time intervals, showing changes in sentiment trends.',
    tooltipEnabled: true,
  },
  coverage_over_time: {
    type: 'brand',
    label: 'Results Over Time - Traditional',
    // allowedGraphTypes: [graphTypes.line, graphTypes.column],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridYTicks: 6,
          gridXTicks: 4,
          hideYAxis: true,
          yLabelAlignment: 10,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends in the amount of media coverage.',
    tooltipEnabled: true,
  },
  coverage_over_time_social: {
    type: 'brand',
    label: 'Results Over Time - Social',
    // allowedGraphTypes: [graphTypes.line, graphTypes.column],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridYTicks: 6,
          gridXTicks: 4,
          hideYAxis: true,
          yLabelAlignment: 10,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends in the amount of media coverage.',
    tooltipEnabled: true,
  },
  reach_over_time: {
    type: 'brand',
    label: 'Reach Over Time - Traditional',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.column,
          enableNeedle: false,
          hideYAxis: true,
          gridXTicks: 6,
          gridYTicks: 6,
          gutterSpace: 15,
          yLabelAlignment: 10,
          maxData: 5,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.resultOvertime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'Tracks how a metric, like audience size or engagement, changes over time',
    tooltipEnabled: true,
  },
  reach_over_time_social: {
    type: 'brand',
    label: 'Reach Over Time - Social',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.column,
          enableNeedle: false,
          hideYAxis: true,
          gridXTicks: 6,
          gridYTicks: 6,
          gutterSpace: 15,
          yLabelAlignment: 10,
          maxData: 5,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.resultOvertime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'Tracks how a metric, like audience size or engagement, changes over time',
    tooltipEnabled: true,
  },
  media_type: {
    type: 'brand',
    label: 'Data Sources',
    // allowedGraphTypes: [graphTypes.groupped_column, graphTypes.line],
    slotType: 'half',
    bentoView: {
      l1: {
        component: L1Carousel,
        graphComponent: PieGraph,
        config: {
          ...graphConfig.pie,
          enableNeedle: false,
          arcLabel: false,
        },
      },
    },
    img: widgetImg.mediaTypeBar,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A breakdown of results in your search(es) by media type, including Online, Print, and Broadcast, showing the distribution of coverage across different media formats.',
    tooltipEnabled: true,
  },
  brand_companies_mentioned: {
    type: 'brand',
    label: 'Brand Mentions',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: { ...graphConfig.word_cloud, enableNeedle: false },
      },
    },
    img: widgetImg.wordCloud,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by companies mentioned, showing which companies are frequently discussed.',
    tooltipEnabled: true,
  },
  // brand_top_performing_posts: {
  //   type: 'brand',
  //   label: 'Top Performing Posts',
  //   allowedGraphTypes: [],
  //   slotType: 'half',
  //   bentoView: {
  //     l1: {
  //       component: WordCloud,
  //       config: { ...graphConfig.word_cloud, enableNeedle: false },
  //     },
  //   },
  //   img: widgetImg.journalists,
  //   placeholder: widgetPlaceHolders.detailsGraph,
  //   description: 'Tracks how a metric, like post content or engagement, reach.',
  //   tooltipEnabled: true,
  // },
  // brand_engagement_metrics: {
  //   type: 'brand',
  //   label: 'Engagement Metrics',
  //   allowedGraphTypes: [],
  //   slotType: 'half',
  //   bentoView: {
  //     l1: {
  //       component: WordCloud,
  //       config: {
  //         ...graphConfig.sentiment_groupped_column,
  //         enableNeedle: false,
  //       },
  //     },
  //   },
  //   img: widgetImg.coolColumn,
  //   placeholder: widgetPlaceHolders.barGraph,
  //   description:
  //     'A breakdown of results in your search(es) by companies mentioned, showing which companies are frequently discussed.',
  //   tooltipEnabled: true,
  // },
  brand_social_word_cloud: {
    type: 'brand',
    label: 'Social Word Cloud',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: { ...graphConfig.word_cloud, enableNeedle: false },
      },
    },
    img: widgetImg.wordCloud,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by companies mentioned, showing which companies are frequently discussed.',
    tooltipEnabled: true,
  },
  brand_result_over_time: {
    type: 'brand',
    label: 'Results Over Time',
    // allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LineBar,
        config: {
          ...graphConfig.line_bar,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends in the amount of media coverage.',
    tooltipEnabled: true,
  },
  // brand_seasonal_trends: {
  //   type: 'brand',
  //   label: 'Seasonal Trends',
  //   allowedGraphTypes: [],
  //   slotType: 'half',
  //   bentoView: {
  //     l1: {
  //       component: LinearLine,
  //       config: {
  //         ...graphConfig.line,
  //         gridXTicks: 6,
  //         gridYTicks: 6,
  //         hideYAxis: true,
  //         lineStrokeWidth: 1.5,
  //         ...noGridLine,
  //         ...L1GridLineConfig,
  //       },
  //     },
  //   },
  //   img: widgetImg.coverageOverTime,
  //   placeholder: widgetPlaceHolders.lineGraph,
  //   description:
  //     'Tracks and analyzes the evolving tones of results over different time intervals, showing changes in sentiment trends.',
  //   tooltipEnabled: true,
  // },
  competition_companies_mentioned: {
    type: 'competition',
    label: 'Brand Mentions',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: { ...graphConfig.word_cloud, enableNeedle: false },
      },
    },
    img: widgetImg.wordCloud,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by companies mentioned, showing which companies are frequently discussed.',
    tooltipEnabled: true,
  },
  sov: {
    type: 'competition',
    label: 'SOV',
    // allowedGraphTypes: [graphTypes.pie, graphTypes.donut],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: {
          ...graphConfig.donut,
          enableNeedle: false,
          innerRadius: 0.3,
          arcLabel: false,
        },
      },
    },
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A comparative breakdown of results in your respective searches, showing the proportion of coverage each search term receives relative to the total.',
    tooltipEnabled: true,
  },
  article_sentiment: {
    type: 'competition',
    label: 'Brand Sentiment',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          ...graphConfig.stackedBar,
          gridXTicks: 6,
          gridYTicks: 6,
          yLabelAlignment: 5,
          gutterSpace: 15,
          wrapLength: 4,
          hideYAxis: true,
          ...L1GridLineBarConfig,
        },
      },
    },
    img: widgetImg.articleSentiment,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'Analyzes the tone or attitude expressed within written articles, blogs, or news pieces, categorizing them as positive, negative, or neutral.',
    tooltipEnabled: true,
  },
  competitive_coverage_over_time: {
    type: 'competition',
    label: 'Results Over Time - Traditional',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          gridYTicks: 6,
          hideYAxis: true,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends in the amount of media coverage.',
    tooltipEnabled: true,
  },
  competitive_coverage_over_time_social: {
    type: 'competition',
    label: 'Results Over Time - Social',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          gridYTicks: 6,
          hideYAxis: true,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends in the amount of media coverage.',
    tooltipEnabled: true,
  },
  competitive_reach_over_time: {
    type: 'competition',
    label: 'Reach Over Time - Traditional',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          gridYTicks: 6,
          hideYAxis: true,
          lineStrokeWidth: 1.5,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.reachOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'Tracks how a metric, like audience size or engagement, changes over time',
    tooltipEnabled: true,
  },
  competitive_reach_over_time_social: {
    type: 'competition',
    label: 'Reach Over Time - Social',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          gridYTicks: 6,
          hideYAxis: true,
          lineStrokeWidth: 1.5,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.reachOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'Tracks how a metric, like audience size or engagement, changes over time',
    tooltipEnabled: true,
  },
  coverage_by_journalist: {
    type: 'competition',
    label: 'Coverage by Media Contributors',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.groupped_column,
          hideYAxis: true,
          gridXTicks: 6,
          gridYTicks: 6,
          gutterSpace: 15,
          yLabelAlignment: 10,
          columnWidth: 24,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.mediaTypeBar,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of news articles in your search(es) by top publications, indicating visibilty created by each publication for your brand(s).',
    tooltipEnabled: true,
  },
  coverage_by_source: {
    type: 'competition',
    label: 'Brand Coverage by Popular Websites',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn3D,
        config: {
          ...graphConfig.coolColumn3D,
          hideXAxis: true,
          xLabelAlignment: 0,
          gutterSpace: 15,
          hideYAxis: true,
          yLabelAlignment: 15,
          enableRectLabels: false,
          gridXTicks: 6,
        },
      },
    },
    placeholder: widgetPlaceHolders.barGraph,
    img: widgetImg.outletBreakDown,
    description:
      'A breakdown of news articles in your search(es) by journalist or author ,identifying the contributors with the most coverage',
    tooltipEnabled: true,
  },
  breakdown_by_media_type: {
    type: 'competition',
    label: 'Data Sources Breakdown',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn3D,
        config: {
          ...graphConfig.coolColumn3D,
          hideXAxis: true,
          xLabelAlignment: 0,
          gutterSpace: 15,
          hideYAxis: true,
          yLabelAlignment: 15,
          enableRectLabels: false,
        },
      },
    },
    img: widgetImg.outletBreakDown,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A visual representation of the distribution of content across different media formats, such as Web, Trade, Magazine, Wire, Newspaper, and more, indicating the diversity of media channels.',
    tooltipEnabled: true,
  },
  competition_companies_mentioned: {
    type: 'competition',
    label: 'Brand Mentions (Comparison)',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: { ...graphConfig.word_cloud, enableNeedle: false },
      },
    },
    img: widgetImg.wordCloud,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by companies mentioned, showing which companies are frequently discussed.',
    tooltipEnabled: true,
  },
  competition_media_contributors: {
    type: 'competition',
    label: 'Coverage by Media Contributors (Influencers)',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: {
          ...graphConfig.sentiment_groupped_column,
          enableNeedle: false,
        },
      },
    },
    img: widgetImg.coolColumn,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A breakdown of results in your search(es) by companies mentioned, showing which companies are frequently discussed.',
    tooltipEnabled: true,
  },
  competition_coverage_by_websites: {
    type: 'competition',
    label: 'Brand Coverage by Social Channels',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: {
          ...graphConfig.sentiment_groupped_column,
          enableNeedle: false,
        },
      },
    },
    img: widgetImg.coolColumn,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A breakdown of results in your search(es) by companies mentioned, showing which companies are frequently discussed.',
    tooltipEnabled: true,
  },
};

export const industryWidgets = {
  industry_volume_analysis: {
    type: 'industry',
    label: 'Key Metrics Overview',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: L1Carousel,
        graphComponent: GeoBreakDown,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.volumeAnalysis,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'A breakdown of results in your search(es) by total results, total Reach and total AVE',
    tooltipEnabled: true,
  },
  industry_sentiment_analysis: {
    type: 'industry',
    label: 'Sentiment Analysis',
    // allowedGraphTypes: [graphTypes.pie, graphTypes.donut],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        graphComponent: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'A breakdown of results in your search(es) by overall article sentiment, categorized as positive, negative, or neutral.',
    tooltipEnabled: true,
  },
  industry_coverage_by_journalist: {
    type: 'industry',
    label: 'Coverage by Media Contributors',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.column,
          yLabelAlignment: 0,
          arcLabel: false,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageByJournalist,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of news articles in your search(es) by journalist or author, identifying the contributors with the most coverage.',
    tooltipEnabled: true,
  },
  industry_coverage_by_source: {
    type: 'industry',
    label: 'Coverage by Data Sources Over Time',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.column,
          gutterSpace: 15,
          yLabelAlignment: 5,
          ...L1GridLineConfig,
        },
      },
    },
    placeholder: widgetPlaceHolders.barGraph,
    img: widgetImg.topSourceBySentiment,
    description:
      'A breakdown of news articles in your search(es) by  number of articles published by certain sources.',
    tooltipEnabled: true,
  },
  industry_companies_mentioned: {
    type: 'industry',
    label: 'Brand Mentioned',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: { ...graphConfig.word_cloud, enableNeedle: false },
      },
    },
    img: widgetImg.wordCloud,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by brand mentioned, showing which brands are frequently discussed.',
    tooltipEnabled: true,
  },
  industry_coverage_over_time: {
    type: 'industry',
    label: 'Results Over Time - Traditional',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          gridYTicks: 6,
          hideYAxis: true,
          yLabelAlignment: 10,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends and changes in media presence.',
    tooltipEnabled: true,
  },
  industry_coverage_over_time_social: {
    type: 'industry',
    label: 'Results Over Time - Social',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          gridYTicks: 6,
          hideYAxis: true,
          yLabelAlignment: 10,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.lineGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends and changes in media presence.',
    tooltipEnabled: true,
  },
  industry_coverage_by_top_publications: {
    type: 'industry',
    label: 'Coverage by Popular Websites',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          ...graphConfig.bar,
          gridXTicks: 5,
          enableNeedle: false,
          hideYAxis: true,
          yLabelAlignment: 150,
          ...L1GridLineBarConfig,
        },
      },
    },
    img: widgetImg.topSources,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A breakdown of results in your search(es) by source or publication, highlighting the most influential media outlets.',
    tooltipEnabled: true,
  },
  industry_top_influencers: {
    type: 'industry',
    label: 'Top Influencers',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          ...graphConfig.bar,
          gridXTicks: 5,
          enableNeedle: false,
          hideYAxis: true,
          yLabelAlignment: 150,
          ...L1GridLineBarConfig,
        },
      },
    },
    img: widgetImg.topSources,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A breakdown of results in your search(es) by source or publication, highlighting the most influential media outlets.',
    tooltipEnabled: true,
  },
};

export const people = {
  people_volume_analysis: {
    type: 'people',
    label: 'Key Metrics Overview',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: L1Carousel,
        graphComponent: GeoBreakDown,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    placeholder: widgetPlaceHolders.detailsGraph,
    img: widgetImg.volumeAnalysis,
    description:
      'A breakdown of results in your search(es) by total results, total Reach and total AVE',
    tooltipEnabled: true,
  },
  people_coverage_over_time: {
    type: 'people',
    label: 'Results Over Time - Traditional',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          hideYAxis: true,
          yLabelAlignment: 10,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends and changes in media presence.',
    tooltipEnabled: true,
  },

  people_coverage_over_time_social: {
    type: 'people',
    label: 'Results Over Time - Social',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: LinearLine,
        config: {
          ...graphConfig.line,
          gridXTicks: 6,
          hideYAxis: true,
          yLabelAlignment: 10,
          lineStrokeWidth: 1.5,
          ...noGridLine,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.coverageOverTime,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'A breakdown of results in your search(es) by volume coverage over time, highlighting trends and changes in media presence.',
    tooltipEnabled: true,
  },
  people_top_journalist_by_sentiment: {
    type: 'people',
    label: 'Media Contributors by Sentiment - Traditional',
    allowedGraphTypes: [],
    maxData: 6,
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          ...graphConfig.stackedBar,
          enableNeedle: false,
          yLabelAlignment: 5,
          wrapLength: 4,
          gutterSpace: 15,
          hideYAxis: true,
          ...L1GridLineBarConfig,
        },
      },
    },
    img: widgetImg.articleSentiment,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A breakdown of results in your search(es) by  Journalist/Author and their corresponding article sentiment, showing which journalist is contributing the most postive, negative or neutal content',
    tooltipEnabled: true,
  },
  people_top_journalist_by_sentiment_social: {
    type: 'people',
    label: 'Media Contributors by Sentiment - Social',
    allowedGraphTypes: [],
    maxData: 6,
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          ...graphConfig.stackedBar,
          enableNeedle: false,
          yLabelAlignment: 5,
          wrapLength: 4,
          gutterSpace: 15,
          hideYAxis: true,
          ...L1GridLineBarConfig,
        },
      },
    },
    img: widgetImg.articleSentiment,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'A breakdown of results in your search(es) by  Journalist/Author and their corresponding article sentiment, showing which journalist is contributing the most postive, negative or neutal content',
    tooltipEnabled: true,
  },
  people_top_source_by_sentiment: {
    type: 'people',
    label: 'Data Sources by Sentiment',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.column,
          enableNeedle: false,
          arcLabel: false,
          gutterSpace: 15,
          yLabelAlignment: 0,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.topSourceBySentiment,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'Indicates the sources with the highest sentiment scores, highlighting those whose content tends to be most positively or negatively perceived within a given dataset.',
    tooltipEnabled: true,
  },
  people_popular_topics: {
    type: 'people',
    label: 'Word Cloud',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.popular_topics_config,
          enableNeedle: false,
          hideYAxis: true,
          singleLineWrp: true,
          maxData: 5,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.topTheme,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'Topics that are the most discussed or widely mentioned subjects within a specific context, providing insights into current trends and interests.',
    tooltipEnabled: true,
  },
  people_media_type: {
    type: 'people',
    label: 'Coverage by Data Sources Over Time',
    allowedGraphTypes: [],
    slotType: 'half',
    description:
      'A breakdown of results in your search(es) by media type, including Online, Print, and Broadcast, showing the distribution of coverage across different media formats.',
    tooltipEnabled: true,

    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.groupped_column,
          enableNeedle: false,
          yLabelAlignment: 10,
          yAxisTicksFormat: true,
          yAxisType: 'number',
          gutterSpace: 15,
          hideYAxis: true,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.mediaTypeBar,
    placeholder: widgetPlaceHolders.barGraph,
  },
  people_coverage_on_key_topics: {
    type: 'people',
    label: 'Coverage on Key Topics',
    allowedGraphTypes: [],
    slotType: 'half',
    description: 'Coverage on Key Topics',
    tooltipEnabled: true,

    bentoView: {
      l1: {
        component: Radar,
        config: {
          ...graphConfig.groupped_column,
          enableNeedle: false,
          yLabelAlignment: 10,
          yAxisTicksFormat: true,
          yAxisType: 'number',
          gutterSpace: 15,
          hideYAxis: true,
        },
      },
      img: widgetImg.mediaTypeBar,
      placeholder: widgetPlaceHolders.barGraph,
    },
  },

  people_popular_topic_themes: {
    type: 'people',
    label: 'Top Themes',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.popular_topics_config,
          enableNeedle: false,
          hideYAxis: true,
          singleLineWrp: true,
          maxData: 5,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.topTheme,
    placeholder: widgetPlaceHolders.barGraph,
    description:
      'Identifies the most prominent or recurring subjects with in your search results, providing insights into key topics and trends',
    tooltipEnabled: true,
  },
};

export const advancedDashboardWidgets = {
  campaigns_impact: {
    label: 'Campaign Monitor',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.campaignMonitor,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
  },
  author_imapct: {
    label: 'Author Imapct',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.authorImpact,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
  },
  sentiment_by_themes: {
    label: 'Sentiment by Themes',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.sentiments,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
  },
  message_congruence_by_Media: {
    label: 'Message Congruence by Media',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.messageCongruence,
  },
  message_congruence_by_total_mentions: {
    label: 'Message Congruence by total mentions',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.messageCongruence,
  },
  pr_impact_scale: {
    label: 'PR Impact Scale',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: {
          ...graphConfig.pie,
          enableNeedle: false,
        },
      },
    },
    img: widgetImg.prImpact,
  },
};

const contentDashboardWidget = {
  top_author: {
    label: 'Content Type',
    chartType: 'content_type',
    allowedGraphTypes: [],
    slotType: 'half',
  },
  promoted_organic: {
    label: 'Promoted/Organic',
    chartType: 'promoted_organic',
    allowedGraphTypes: [],
    slotType: 'half',
  },
  advanced_content_engagement: {
    label: 'Engagement by Time and Weekday',
    chartType: 'engagement_time_weekday',
    allowedGraphTypes: [],
    slotType: 'full',
  },
  topEngagingPosts: {
    label: 'Top Engaging Posts',
    chartType: 'top_engaging_posts',
    allowedGraphTypes: [],
    slotType: 'full',
  },
};

const influencerDashboardWidget = {
  influencer_wordcloud: {
    label: 'Influencer WordCloud',
    chartType: 'influencer_wordcloud',
    allowedGraphTypes: [],
    slotType: 'half',
  },
  section_trends: {
    label: 'Trends',
    chartType: 'section_trends',
    allowedGraphTypes: [],
    slotType: 'full',
  },
  influencer_geographical: {
    label: 'Influencer by Geography',
    chartType: 'influencer_geographical',
    allowedGraphTypes: [],
    slotType: 'half',
  },
  key_influencer: {
    label: 'Key Influencer',
    chartType: 'key_influencer',
    allowedGraphTypes: [],
    slotType: 'half',
  },
  influencer_cluster_chart: {
    label: 'influencer-sentiment-cluster',
    chartType: 'influencer_cluster_chart',
    allowedGraphTypes: [],
    slotType: 'full',
  },
};

export const profileWidgets = {
  volume_analysis: {
    label: 'Key Metrics Overview',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.messageCongruence,
  },
  sentiment_analysis: {
    label: 'Sentiment Analysis',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.sentiments,
  },
  sentiment_over_time: {
    label: 'Sentiment Over Time',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.reach,
  },
  coverage_over_time: {
    label: 'Results Over Time ',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.reach,
  },
  media_type: {
    label: 'Data Sources',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.mediaType,
  },
  reach_over_time: {
    label: 'Reach Over Time',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false },
      },
    },
    img: widgetImg.reach,
  },
};

export const storyAnalysisWidgets = {
  story_analysis_results_over_time: {
    label: 'Results Over Time',
    slotType: 'full',
    allowedGraphTypes: [graphTypes.groupped_column, graphTypes.area],
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.bar, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.resultOvertime,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_summary: {
    label: 'Summary',
    slotType: 'half',
    allowedGraphTypes: [graphTypes.groupped_column, graphTypes.area],
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.bar, enableNeedle: false, arcLabel: false },
      },
    },
    // img: widgetImg.resultOvertime,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_top_trending: {
    label: 'Top Trending',
    slotType: 'half',
    allowedGraphTypes: [graphTypes.groupped_column, graphTypes.area],
    bentoView: {
      l1: {
        component: PieGraph,
        config: { ...graphConfig.bar, enableNeedle: false, arcLabel: false },
      },
    },
    // img: widgetImg.resultOvertime,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_media_channels: {
    label: 'Media type',
    // allowedGraphTypes: [graphTypes.donut, graphTypes.pie],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        config: {
          ...graphConfig.pie,
          enableNeedle: false,
          arcLabel: false,
        },
      },
    },
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  // story_analysis_sentiment: {
  //   label: 'SOV',
  //   allowedGraphTypes: [],
  //   slotType: 'half',
  //   bentoView: {
  //     l1: {
  //       component: PieGraph,
  //       config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
  //     },
  //   },
  //   img: widgetImg.mediaTypePie,
  //   description:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
  //   tooltipEnabled: true,
  // },
  story_analysis_word_could: {
    label: 'Word Cloud',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: WordCloud,
        config: {
          ...graphConfig.word_cloud,
          yLabelAlignment: 5,
          maxData: 5,
          minFontSize: 7,
          maxFontSize: 20,
          padding: { left: 0, top: 0, bottom: 0, right: 10 },
        },
      },
    },
    img: widgetImg.wordCloud,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_top_sources: {
    label: 'Top Sources',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          yAxisType: 'text',
          yLabelAlignment: 5,
          graphType: 'bar',
          gridXTicks: 4,
          enableNeedle: false,
          xAxisType: 'number',
          xAxisTicksFormat: true,
          ...L1GridLineBarConfig,
          ...events,
        },
      },
    },
    img: widgetImg.topSources,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },

  story_analysis_outlet_breakdown: {
    label: 'Outlet Breakdown',
    allowedGraphTypes: [],
    slotType: 'half',
    bentoView: {
      l1: {
        component: BestBar,
        config: {
          ...graphConfig.column,
          enableNeedle: false,
          arcLabel: false,
          gutterSpace: 15,
          yLabelAlignment: 0,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.outletBreakDown,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_geographical_breakdown: {
    label: 'Geographical Breakdown',
    allowedGraphTypes: [],
    slotType: 'half',

    bentoView: {
      l1: {
        component: USMapChart,
        config: graphConfig.usmap,
        // component: L1Carousel,
        // graphComponent: GeoBreakDown,
        // config: {
        //   ...graphConfig.uber_stats_volume,
        //   enableNeedle: false,
        //   maxData: 6,
        // },
      },
    },
    img: widgetImg.geographicalBreakdown,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_sentiment: {
    label: 'Sentiment',
    // allowedGraphTypes: [graphTypes.pie, graphTypes.donut],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        // graphComponent: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_top_influencer: {
    label: 'Top Influencers',
    // allowedGraphTypes: [graphTypes.pie, graphTypes.donut],
    slotType: 'half',
    bentoView: {
      l1: {
        component: PieGraph,
        // graphComponent: PieGraph,
        config: { ...graphConfig.pie, enableNeedle: false, arcLabel: false },
      },
    },
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
  story_analysis_top_themes: {
    label: 'Top Themes',
    allowedGraphTypes: [],
    // slotType: 'half',
    slotType: 'full',
    bentoView: {
      l1: {
        component: CoolColumn,
        config: {
          ...graphConfig.top_theme_config,
          ...L1GridLineConfig,
        },
      },
    },
    img: widgetImg.topTheme,
    placeholder: widgetPlaceHolders.detailsGraph,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut ultrices quam.',
    tooltipEnabled: true,
  },
};

export const standardDashboards = [
  {
    label: 'Brand',
    value: 'brand',
    widgets: brandWidgets,
  },
  {
    label: 'Industry',
    value: 'industry',
    widgets: industryWidgets,
  },
  {
    label: 'People',
    value: 'people',
    widgets: people,
  },
];

export const dashboards = [
  {
    label: 'Overview',
    value: 'overview',
    widgets: overviewWidgets,
  },
  ...standardDashboards,
  {
    label: 'Advance Dashboard',
    value: 'advance_dashboard',
    widgets: advancedDashboardWidgets,
  },
  // {
  //   label: 'Content Dashboard',
  //   value: 'contentDashboard',
  //   widgets: contentDashboardWidget,
  // },
  // {
  //   label: 'Story Analysis',
  //   value: 'storyAnalysis',
  //   widgets: storyAnalysisWidgets,
  // },
  // {
  //   label: 'Brand Switch',
  //   value: 'brandSwitch',
  //   widgets: brandSwitchWidgets,
  // },
  {
    label: 'Profile',
    value: 'profile',
    widgets: profileWidgets,
  },
  // {
  //   label: 'Influencer Dashboard',
  //   value: 'influencerDashboard',
  //   widgets: influencerDashboardWidget,
  // },
];

export const brandSwitchWidgets = {
  brand_switch_sov: {
    type: 'brandSwitch',
    label: 'SOV',
    allowedGraphTypes: [],
    slotType: 'half',
    img: widgetImg.mediaTypePie,
    placeholder: widgetPlaceHolders.detailsGraph,
    tooltipEnabled: true,
  },
  brand_switch_top_themes_coverage: {
    type: 'brandSwitch',
    label: 'Top Themes Coverage',
    allowedGraphTypes: [],
    slotType: 'full',
    tooltipEnabled: true,
  },
  brand_switch_sentiment_analysis: {
    type: 'brandSwitch',
    label: 'Switch-in & Switch-out Reasons',
    allowedGraphTypes: [],
    slotType: 'full',
    tooltipEnabled: true,
  },
  brand_switch_sentiment_analysis_count: {
    type: 'brandSwitch',
    label: 'Switch-in Switch-out Index',
    allowedGraphTypes: [graphTypes.sentiment_gauge],
    slotType: 'half',
    tooltipEnabled: true,
  },
};

const widgetObj = {};
dashboards.forEach((ele) => {
  widgetObj[ele.value] = ele.widgets;
});
widgetObj.custom = { ...people, ...industryWidgets, ...brandWidgets };

export const widgetMapping = {
  ...widgetObj,
};

export const getDashboardWidgets = (dashboardKey) => {
  const dashBoardObj = dashboards.filter((ele) => ele.value === dashboardKey);
  const dashBoardWidgets = dashBoardObj.length
    ? Object.values(dashBoardObj[0].widgets)
    : [];
  return dashBoardWidgets;
};

export const workspaceConfig = {
  recent: {
    component: RecentSearches,
  },
  top_articles: {
    component: TopFiveSearches,
  },
  // single_article: {
  //   component: SingleArticle,
  // },
};
