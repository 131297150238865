import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContentDashboardContainer } from './index.sc';
import { SlotWrp } from '../social-campaign/index.sc';
import SlotDetails from '../../search-result/slot-details';
import TopInfluencer from '../../top-influencer';
import { FullSlot, HalfSlot } from '../../search-result/index.sc';
const InfluencerDashboards = ({
  columnCount,
  widget,
  dashboardType,
  handleOnClick = () => {},
  setArticlePosition = () => {},
  resetSelection,
  setArticleType = () => {},
  setSelectedComponent = () => {},
  selectedComponent = '',
  onDownloadChartData,
  downloadFunction,
  savedSelectedChart,
  handleGraphTitleUpdate,
  isNewsletter = false,
}) => {
  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  return (
    <ContentDashboardContainer>
      <SlotWrp dashboard={true}>
        {widget &&
          Object?.keys(widget)?.map((contentKey, id) => {
            const SlotComponent =
              (widget?.[contentKey]?.data?.slotType === 'half' ||
                contentKey === 'influencer_wordcloud' ||
                contentKey === 'influencer_geographical') &&
              dashboardType !== 'newsletter'
                ? HalfSlot
                : FullSlot;
            let savedChartConfig = {};
            const chartData = savedSelectedChart?.find(
              (chart) =>
                chart?.chartId === widget?.[contentKey]?.data?.customClassName
            );

            if (chartData) {
              savedChartConfig = {
                chartName: chartData?.chartName,
                chartType: chartData?.chartType
                  ? chartData?.chartType
                  : widget?.[contentKey]?.data?.graphType,
              };
            } else {
              savedChartConfig = {
                chartName: widget?.[contentKey]?.data?.title,
                chartType: widget?.[contentKey]?.data?.graphType,
              };
            }

            return (
              widget?.[contentKey]?.data?.show && (
                <SlotComponent
                  className="graph-widget"
                  key={`widget-${id}`}
                  dashboardType={
                    contentKey !== 'key_influencer' ||
                    contentKey !== 'influencer_section_influencer'
                  }
                  height={
                    contentKey === 'influencer_cluster_chart'
                      ? '36rem'
                      : dashboardType === 'newsletter'
                      ? '26rem'
                      : undefined
                  }
                >
                  {contentKey !== 'key_influencer' &&
                  contentKey !== 'influencer_section_influencer' ? (
                    <SlotDetails
                      widget={widget?.[contentKey]?.data}
                      loader={widget?.[contentKey]?.data?.isLoading}
                      dashboardType={dashboardType}
                      legend={
                        widget?.[contentKey]?.data?.customClassName ===
                          'advanced_content_promoted_organic' ||
                        widget?.[contentKey]?.data?.customClassName ===
                          'advanced_content_engagement'
                      }
                      handleOnClick={handleOnClick}
                      resetSelection={resetSelection}
                      actionOption={true}
                      editOption={true}
                      setSelectedComponent={setSelectedComponent}
                      selectedComponent={selectedComponent}
                      onDownloadChartData={onDownloadChartData}
                      downloadFunction={downloadFunction}
                      widgetClassName={
                        widget?.[contentKey]?.data?.customClassName
                      }
                      editChart={savedChartConfig}
                      handleGraphTitleUpdate={handleGraphTitleUpdate}
                      helperText={widget?.[contentKey]?.data?.text}
                      isNewsletter={isNewsletter}
                    />
                  ) : (
                    <TopInfluencer
                      columnCount={columnCount}
                      widget={widget?.[contentKey]?.data}
                      loader={widget?.[contentKey]?.data?.isLoading}
                      legend={false}
                      handleDrillDown={setArticleType}
                      resetSelection={resetSelection}
                      setArticlePosition={setArticlePosition}
                      onDownloadChartData={onDownloadChartData}
                      setSelectedComponent={setSelectedComponent}
                      downloadFunction={downloadFunction}
                      widgetClassName={
                        widget?.[contentKey]?.data?.customClassName
                      }
                      actionOption={true}
                      editOption={true}
                      editChart={savedChartConfig}
                      handleGraphTitleUpdate={handleGraphTitleUpdate}
                      helperText={widget?.[contentKey]?.data?.text}
                      dashboardType={dashboardType}
                      isNewsletter={isNewsletter}
                    />
                  )}
                </SlotComponent>
              )
            );
          })}
      </SlotWrp>
    </ContentDashboardContainer>
  );
};

export default InfluencerDashboards;

InfluencerDashboards.propTypes = {
  widget: PropTypes.object,
  dashboardType: PropTypes?.string,
  handleOnClick: PropTypes?.func,
  setArticlePosition: PropTypes?.func,
  resetSelection: PropTypes?.bool,
  setArticleType: PropTypes?.func,
  setSelectedComponent: PropTypes?.func,
  selectedComponent: PropTypes?.string,
  onDownloadChartData: PropTypes?.func,
  downloadFunction: PropTypes?.func,
  savedSelectedChart: PropTypes?.object,
  handleGraphTitleUpdate: PropTypes?.func,
  isNewsletter: PropTypes.bool,
  columnCount: PropTypes.number,
};
