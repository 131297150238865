import * as d3 from 'd3';
import { setUpEvents } from '../utils/graphEvents';
import { hexToRgba } from '../utils/hexToRgba';

export const lineBarRects = function lineBarRects() {
  let config = {};

  function graph(selected) {
    selected.each(function (data) {
      if (!data) return;

      const barWidth = config.xScale.bandwidth();
      const t = d3
        .transition()
        .delay((d, i) => i * 3)
        .duration(config.duration);

      // Add a defs element to the selected element
      selected
        .append('defs')
        .append('linearGradient')
        .attr('id', 'gradient')
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '80%')
        .attr('y2', '0%')
        .selectAll('stop')
        .data([
          { offset: '0%', color: '#BD96FE' },
          { offset: '100%', color: '#893FFC' },
        ])
        .enter()
        .append('stop')
        .attr('offset', function (d) {
          return d.offset;
        })
        .attr('stop-color', function (d) {
          return d.color;
        });

      selected
        .selectAll('.bar-rect')
        .data(data)
        .join(
          (enter) => {
            enter
              .append('rect')
              .attr('class', 'bar-rect')
              .attr('x', (d) => config.xScale(d.label))
              .attr('y', (d) => config.yScale(d.value))
              .attr('width', barWidth)
              .attr('height', (d) => config.yScale(d.value))
              .attr('fill', 'url(#gradient)')
              .attr('filter', () => {
                const color = '#BD96FE';
                const rgbaColor = hexToRgba(color, 0.6);
                return `drop-shadow(0px 10px 12px ${rgbaColor})`;
              })
              .attr('rx', 4)
              .attr('y', (d) => config.yScale(d.value))
              .attr(
                'height',
                (d) => config.graphAreaH - config.yScale(d.value)
              );
          },
          (update) => update,
          (exit) => {
            exit.transition(t).attr('height', 0);
          }
        )
        .attr('x', (d) => config.xScale(d.label))
        .attr('width', barWidth)
        .attr('y', (d) => config.graphAreaH - config.yScale(d.value))
        .attr('height', (d) => config.yScale(d.value));

      setUpEvents(config, selected, 'bar-rect');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) return config;
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};

export const lineBarLine = function lineBarLine() {
  let config = { enableTooltipTrendPointer: true };

  function graph(selected) {
    selected.each(function (data) {
      const barWidth = config.xScale.bandwidth();
      const t = d3
        .transition()
        .delay((d, i) => i * 3)
        .duration(config.duration);

      // Line path
      const line = d3
        .line()
        .x((d) => config.x(d))
        .y((d) => config.yRight(d));

      selected
        .selectAll('.line-path')
        .data([data])
        .join(
          (enter) => {
            enter
              .append('path')
              .attr('class', 'line-path')
              .attr('d', line)
              .attr('fill', 'none')
              .attr('stroke', '#EE5396')
              .attr('stroke-width', 2.5);
          },
          (update) => update,
          (exit) => exit.remove()
        );

      // Line dots
      selected
        .selectAll('.line-dot')
        .data(data)
        .join(
          (enter) => {
            enter
              .append('circle')
              .attr('class', 'line-dot')
              .attr('cx', (d) => config.x(d))
              .attr('cy', config.graphAreaH)
              .attr('r', 0)
              .attr('fill', '#EE5396')
              .attr('cy', (d) => config.yRight(d));
          },
          (update) => update,
          (exit) => {
            exit.transition(t).attr('r', 0);
          }
        )
        .attr('cx', (d) => config.x(d) + barWidth / 2)
        .attr('cy', (d) => config.yRight(d));

      // Hover functionality
      if (config.enableTooltipTrendPointer) {
        let hoverGroup = selected.select('.hover-group');
        if (hoverGroup.empty()) {
          hoverGroup = selected.append('g').attr('class', 'hover-group');
        }

        let verticalLine = hoverGroup.select('.vertical-line');
        if (verticalLine.empty()) {
          verticalLine = hoverGroup
            .append('line')
            .attr('class', 'vertical-line')
            .attr('y1', 0)
            .attr('y2', config.graphAreaH)
            .style('stroke', '#A9ADBE')
            .style('stroke-width', 1)
            .style('stroke-dasharray', '3,4')
            .style('opacity', 0);
        }

        let hoverDot = hoverGroup.select('.hover-dot');
        if (hoverDot.empty()) {
          hoverDot = hoverGroup
            .append('circle')
            .attr('class', 'hover-dot')
            .attr('r', 3.5)
            .style('fill', 'white')
            .style('stroke', '#EE5396')
            .style('stroke-width', 2)
            .style('opacity', 0);
        }

        selected
          .selectAll('.hover-rect')
          .data(data)
          .join('rect')
          .attr('class', (d, i) => `hover-rect hover-rect-${i}`) // Add unique class
          .attr('x', (d) => config.xScale(d.label))
          .attr('y', 0)
          .attr('width', barWidth)
          .attr('height', config.graphAreaH)
          .attr('fill', 'transparent')
          .on('mousemove.tooltipPointer', function (event, d) {
            const barType = d.key || 'default'; // Assuming data has 'key' property for bar type
            verticalLine
              .attr('x1', config.x(d))
              .attr('x2', config.x(d))
              .style('opacity', 1);

            hoverDot
              .attr('cx', config.x(d))
              .attr('cy', config.yRight(d))
              .style('opacity', 1);
          })
          .on('mouseout.tooltipPointer', function () {
            verticalLine.style('opacity', 0);
            hoverDot.style('opacity', 0);
          });
      }

      setUpEvents(config, selected, 'hover-rect');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) return config;
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
