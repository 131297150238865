import React from 'react';
import * as d3 from 'd3';
import RootGraph from '../utils/rootGraph';
import { initialValues } from '../utils/graphConst';
import { getGraphUtils } from '../utils/graphUtils';
import { lineBarRects, lineBarLine } from './lineBar';
import { formatNumber } from '../utils/graphGrid';
import { parse, format, isValid } from 'date-fns';
import {
  purpleColorGradients,
  magentaColorGradients,
  cyanColorGradients,
  coolGrayColorGradients,
} from '../../constants/graph-colors';

// Define gradients
const gradients = [
  {
    id: 'gradient1',
    start: purpleColorGradients.purple40,
    end: purpleColorGradients.purple60,
  },
  {
    id: 'gradient2',
    start: magentaColorGradients.magenta40,
    end: magentaColorGradients.magenta60,
  },
  {
    id: 'gradient3',
    start: cyanColorGradients.cyan30,
    end: cyanColorGradients.cyan50,
  },
];
class LineBarGraph extends RootGraph {
  constructor(element) {
    super(element);
    this.data = null;
    this.summary = null;
    this.graphData = null;
  }

  setData(inData) {
    const data = inData;
    this.data = JSON.parse(JSON.stringify(data.data));
    this.summary = data.summary;
    this.graphData = [this.data];
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  clear() {
    if (this.$graphGrp) {
      this.$graphGrp.selectAll('*').remove();
    }
  }

  drawGraph() {
    super.drawGraph();

    if (!this.graphData || !this.graphData[0]) return;

    const data = this.graphData[0];

    let config = {
      ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'linebar',
      summary: this.summary,
      ...this.config,
      gridXTicks: 8,
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 20,
      },
    };

    const { graphAreaH, graphAreaW } = getGraphUtils(
      config,
      this.graphData.flat(Infinity)
    );

    const xScale = d3
      .scaleBand()
      .range([0, graphAreaW])
      .domain(data.map((d) => d.label))
      .padding(0.4);

    // Adjusting yScale to accommodate negative values
    const yScale = d3
      .scaleLinear()
      .range([graphAreaH, config.graphTopPadding])
      .domain([
        d3.min(data, (d) => Math.min(d.value1, d.value2, d.value3)), // Set the minimum value for negative handling
        d3.max(data, (d) => Math.max(d.value1, d.value2, d.value3)) * 1.2, // Set the maximum value with buffer
      ]);

    // Adjusting yScaleRight to accommodate negative values (for trend)
    const yScaleRight = d3
      .scaleLinear()
      .range([graphAreaH, config.graphTopPadding])
      .domain([
        d3.min(data, (d) => Math.min(d.trend)), // Set the minimum value for trend
        d3.max(data, (d) => d.trend) * 1.2, // Set the maximum trend value with buffer
      ]);

    const subXScale = d3
      .scaleBand()
      .domain(['bar1', 'bar2', 'bar3'])
      .range([0, xScale.bandwidth()])
      .padding(0.1);

    config = {
      ...config,
      xScale,
      yScale,
      yScaleRight,
      graphAreaH,
      graphAreaW,
      x: (d) => xScale(d.label) + xScale.bandwidth() / 2,
      y: (d) => yScale(d.value),
      yRight: (d) => yScaleRight(d.trend),
      subXScale,
    };

    // Centering adjustments
    const svgWidth = this.width || 800;
    const svgHeight = this.height || 600;
    const centerX = (svgWidth - config.graphAreaW) / 2;
    const centerY = (svgHeight - config.graphAreaH) / 2;

    // Ensure graph group is centered
    this.$graphGrp.attr('transform', `translate(${centerX}, ${centerY})`);

    // Bars (grouped)
    const groupedBarData = data.map((d) => ({
      label: d.label,
      bars: [
        { key: 'bar1', value: d.value1 },
        { key: 'bar2', value: d.value2 },
        { key: 'bar3', value: d.value3 },
      ],
    }));

    const defs = this.$graphGrp.append('defs');

    gradients.forEach((gradient) => {
      const linearGradient = defs
        .append('linearGradient')
        .attr('id', gradient.id)
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', '0%')
        .attr('y2', '100%');

      linearGradient
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', gradient.start);

      linearGradient
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', gradient.end);
    });

    this.$graphGrp
      .append('g')
      .attr('class', 'linebar-rect-group')
      .selectAll('.bar-group')
      .data(groupedBarData)
      .enter()
      .append('g')
      .attr('class', 'bar-group')
      .attr('transform', (d) => `translate(${xScale(d.label)}, 0)`)
      .selectAll('rect')
      .data((d) => d.bars)
      .enter()
      .append('rect')
      .attr('class', (d) => `bar-rect ${d.key}`) // Assign unique class (e.g., 'bar-rect bar1')
      .attr('x', (d) => subXScale(d.key))
      .attr('y', (d) => yScale(d.value))
      .attr('width', subXScale.bandwidth())
      .attr('height', (d) => graphAreaH - yScale(d.value))
      .attr('fill', (d, i) => `url(#${gradients[i % gradients.length].id})`)
      .on('click', function (event, d) {
        d3.selectAll('.bar-rect').classed('selected', false);
        d3.select(this).classed('selected', true);
      });

    // Trend line for negative values
    const hasValidTrendData = data.some((d) => d.trend !== 0);
    if (hasValidTrendData) {
      this.$graphGrp
        .append('g')
        .attr('class', 'linebar-line-group')
        .datum(data)
        .call(lineBarLine().config(config)); // Use the right scale for trend values
    }

    // X-Axis
    this.$graphGrp
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${config.graphAreaH})`)
      .call(d3.axisBottom(config.xScale).tickSize(0))
      .selectAll('.tick text') // Target the text of each tick
      .style('font-size', '14px') // Set font size
      .style('font-weight', '600') // Set font weight
      .style('margin-top', '5px')
      .style('fill', coolGrayColorGradients.coolGray60);

    // Y-Axis
    this.$graphGrp
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(config.yScale).ticks(5).tickSize(0))
      .selectAll('text')
      .style('font-size', '12px')
      .style('font-weight', '600')
      .attr('fill', coolGrayColorGradients.coolGray60);

    this.$graphGrp.select('.y-axis path').style('stroke', 'none');

    // Y-Axis (Right)
    this.$graphGrp
      .append('g')
      .attr('class', 'y-axis-right')
      .attr('transform', `translate(${config.graphAreaW}, 0)`)
      .call(d3.axisRight(config.yScaleRight).ticks(5).tickSize(0));
  }

  onResetFunc() {
    if (this.$graphGrp) {
      const barSelected = this.$graphGrp.selectAll('.bar-rect');
      const lineSelected = this.$graphGrp.selectAll('.line-path');
      barSelected.classed('selected', false);
      barSelected.classed('unselected', false);
      lineSelected.classed('selected', false);
      lineSelected.classed('unselected', false);
    }
  }
}

export default LineBarGraph;
