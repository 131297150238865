import {
  topInfluencerChartMapData as TempTopInfluencerChartMapData,
  sentimentAnalysis,
  coverageByJournalist,
  coverageBySource,
  companiesMentioned,
  coverageOverTime,
  coverageByPublication,
} from '../../hooks/data/IndustryData';
import { blueColorGradients } from '../../constants/graph-colors';
import {
  calculatePercentage,
  addCountPrefix,
  toCamelCase,
  colorCodeObjects,
  capitalizeWords,
} from '../../constants/utils';
import { getFormattedDate } from '../../hooks/useCharts';
import { colors } from '../../hooks/data/colors';
import { traditionalMediaTypes, socialMediaTypes } from '../../constants/index';
import twitterLogo from '../../assets/img/twitterLogo.png';
import redditLogo from '../../assets/img/reddit.png';

export function industry_mapping(resData, graphType, chartName) {
  if (graphType === 'industry_sentiment_analysis') {
    const sentimentChartMapData = JSON.parse(JSON.stringify(sentimentAnalysis));
    // Set Percentage here

    const percentage = calculatePercentage(resData?.data);
    const sentimeMentMapData = sentimentChartMapData?.data.data;
    const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
      return {
        ...x,
        value: percentage[x.label],
      };
    });

    const totalArticlesMapData = sentimentChartMapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(resData?.data?.total_count)
    );
    sentimentChartMapData.data.summary = totalArticlesMapData;
    sentimentChartMapData.data.data = updatedSentimentMapData || [];
    sentimentChartMapData.shouldShowGraph = resData?.data?.total_count;
    return sentimentChartMapData;
  } else if (graphType === 'industry_coverage_by_journalist') {
    const journalistCoverage = JSON.parse(JSON.stringify(coverageByJournalist));
    journalistCoverage.data.data = resData?.data?.data
      ?.slice(0, 10)
      ?.filter((x) => Number(x?.value) !== 0)
      ?.map((item) => {
        const label = toCamelCase(item.label);
        return {
          author_id: item.author_id,
          label: capitalizeWords(label),
          value: item.value,
          labelColor: blueColorGradients.blue60,
        };
      });
    journalistCoverage.data.summary.value = String(
      addCountPrefix(resData?.data?.total_count)
    );
    journalistCoverage.title = chartName;
    journalistCoverage.shouldShowGraph = resData?.data?.total_count > 0;
    return journalistCoverage;
  } else if (graphType === 'industry_coverage_by_source') {
    const coverageBySourceData = JSON.parse(JSON.stringify(coverageBySource));
    coverageBySourceData.title = chartName;

    let totalOnlineCount = 0;
    let totalPrintCount = 0;
    let totalBlogsCount = 0;
    let totalForumsCount = 0;
    let totalReviewsCount = 0;
    let totalTwitterCount = 0;
    let totalRedditCount = 0;
    let totalYouTubeCount = 0;
    resData?.data?.data?.forEach((x) => {
      const onlineMedia = x?.media_type_count?.find(
        (media) => media.label === 'Online'
      );
      const PrintMedia = x?.media_type_count?.find(
        (media) => media.label === 'Print'
      );
      const forumMedia = x?.media_type_count?.find(
        (media) => media.label === 'Forums'
      );
      const blogMedia = x?.media_type_count?.find(
        (media) => media.label === 'Blogs'
      );
      const reviewMedia = x?.media_type_count?.find(
        (media) => media.label === 'Reviews'
      );
      const twitterMedia = x?.media_type_count?.find(
        (media) => media.label === 'X (Twitter)'
      );
      const redditMedia = x?.media_type_count?.find(
        (media) => media.label === 'Reddit'
      );
      const YouTubeMedia = x?.media_type_count?.find(
        (media) => media.label === 'YouTube'
      );
      const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
      const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
      const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
      const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
      const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
      const twitterDocCount = twitterMedia ? twitterMedia?.doc_count : 0;
      const redditDocCount = redditMedia ? redditMedia?.doc_count : 0;
      const YouTubeDocCount = YouTubeMedia ? YouTubeMedia?.doc_count : 0;
      totalOnlineCount += onlineDocCount;
      totalPrintCount += printDocCount;
      totalBlogsCount += blogDocCount;
      totalReviewsCount += reviewDocCount;
      totalForumsCount += forumDocCount;
      totalTwitterCount += twitterDocCount;
      totalRedditCount += redditDocCount;
      totalYouTubeCount += YouTubeDocCount;
    });

    coverageBySourceData.data.data = resData?.data?.data?.map((item) => {
      const formattedDate = getFormattedDate(item?.date ?? item.label);
      const onlineValue = item.media_type_count.find(
        (media) => media.label === 'Online'
      )?.value;
      const printValue = item.media_type_count.find(
        (media) => media.label === 'Print'
      )?.value;
      const forumValue = item.media_type_count.find(
        (media) => media.label === 'Forums'
      )?.value;
      const blogValue = item.media_type_count.find(
        (media) => media.label === 'Blogs'
      )?.value;
      const reviewValue = item.media_type_count.find(
        (media) => media.label === 'Reviews'
      )?.value;

      const twitterValue = item.media_type_count.find(
        (media) => media.label === 'X (Twitter)'
      )?.value;

      const redditValue = item.media_type_count.find(
        (media) => media.label === 'Reddit'
      )?.value;

      const YouTubeValue = item.media_type_count.find(
        (media) => media.label === 'YouTube'
      )?.value;

      return {
        date: item?.label ?? item?.date,
        label: formattedDate,
        print: printValue ? printValue.toString() : '0',
        online: onlineValue ? onlineValue.toString() : '0',
        blog: blogValue ? blogValue.toString() : '0',
        forum: forumValue ? forumValue.toString() : '0',
        review: reviewValue ? reviewValue.toString() : '0',
        twitter: twitterValue ? twitterValue?.toString() : '0',
        reddit: redditValue ? redditValue?.toString() : '0',
        YouTube: YouTubeValue ? YouTubeValue?.toString() : '0',
      };
    });

    // Modify summary value based on the total count received from API
    coverageBySourceData.data.summary.value = String(
      addCountPrefix(resData?.data?.total_count)
    );

    coverageBySourceData.data.summary = {
      ...coverageBySourceData.data.summary,
      totalOnlineCount,
      totalPrintCount,
      totalBlogsCount,
      totalForumsCount,
      totalReviewsCount,
      totalTwitterCount,
      totalRedditCount,
      totalYouTubeCount,
      mediaType: resData?.data?.media_filter ?? null,
    };

    coverageBySourceData.shouldShowGraph = resData?.data?.total_count > 0;
    return coverageBySourceData;
  } else if (graphType === 'industry_companies_mentioned') {
    const companies = JSON.parse(JSON.stringify(companiesMentioned));
    companies.data.data = resData?.data?.data?.map((item, i) => {
      const colorIndex = i % colors?.length;
      return {
        ...colors[colorIndex],
        label: item.label?.toUpperCase(),
        value: item.article_count,
        labelColor: colors[colorIndex]?.color,
      };
    });
    companies.title = chartName;
    companies.data.summary.value = String(
      addCountPrefix(resData?.data?.total_count)
    );
    companies.shouldShowGraph = resData?.data?.data?.length > 0;
    return companies;
  } else if (graphType === 'industry_coverage_over_time') {
    const mapData = JSON.parse(JSON.stringify(coverageOverTime));

    mapData.mediaTypes = [...traditionalMediaTypes];

    mapData.title = chartName || 'Results Over Time - Traditional';

    // mapData.mediaTypes = payload?.media_types
    //   ? payload?.media_types?.filter((x) => traditional?.includes(x))
    //   : [...traditional];

    const coverageMapData = mapData.data.summary;
    const totalCount = resData?.data?.total_count
      ? resData?.data?.total_count
      : resData?.data && resData?.data?.data
      ? resData?.data?.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    coverageMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = coverageMapData;
    mapData.data.data =
      resData?.data?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          value: String(x?.value),
          date: x?.label ?? x?.date,
        };
      }) || [];
    mapData.shouldShowGraph = resData?.data?.total_count > 0;
    return mapData;
  } else if (graphType === 'industry_coverage_over_time_social') {
    const mapData = JSON.parse(JSON.stringify(coverageOverTime));

    mapData.title = chartName || 'Results Over Time - Social';

    mapData.mediaTypes = [...socialMediaTypes];

    const coverageMapData = mapData.data.summary;
    const totalCount = resData?.data?.total_count
      ? resData?.data?.total_count
      : resData?.data && resData?.data?.data
      ? resData?.data?.data?.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    coverageMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = coverageMapData;
    mapData.data.data =
      resData?.data?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          value: String(x?.value),
          date: x?.label ?? x?.date,
        };
      }) || [];
    mapData.shouldShowGraph = resData?.data?.total_count > 0;
    return mapData;
  } else if (graphType === 'industry_top_influencers') {
    const topInfluencerChartMapData = JSON.parse(
      JSON.stringify(TempTopInfluencerChartMapData)
    );

    const totalArticlesMapData = topInfluencerChartMapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(resData?.data?.total_count)
    );
    topInfluencerChartMapData.data.summary = totalArticlesMapData;

    const topAuthorRes = resData?.data?.data;

    const updatedTopInfluencerData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
      return {
        author_id: x?.author_id,
        value: x?.doc_count,
        authorName: x?.author,
        mentions: x?.doc_count,
        reach: x?.reach,
        source: x?.source || 'Social',
        logoUrl: '',
      };
    });

    topInfluencerChartMapData.title = chartName;
    topInfluencerChartMapData.data.data = updatedTopInfluencerData || [];
    topInfluencerChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
    return topInfluencerChartMapData;
  } else if (graphType === 'industry_coverage_by_top_publications') {
    const publications = JSON.parse(JSON.stringify(coverageByPublication));
    const slicedData = resData?.data?.data?.slice(0, 10); // Take only the first ten items

    const updatedTopPublicationsData = slicedData?.map((item) => ({
      label: capitalizeWords(item.source_name),
      value: item.value,
      color: '#675EF2',
      labelColor: blueColorGradients.blue60,
    }));
    publications.title = chartName;
    publications.data.data = colorCodeObjects(updatedTopPublicationsData) || [];
    publications.data.summary.value = String(
      addCountPrefix(resData?.data?.total_count)
    );
    publications.shouldShowGraph = resData?.data?.total_count > 0;
    return publications;
  }
}
