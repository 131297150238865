import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LegendBox,
  LegendContainer,
  LegendLabel,
  LegendWrp,
  LegendBoxWpr,
} from './index.sc';
import { addCountPrefix } from '../../constants/utils';
const GraphLegendV2 = ({ legendData = [], mediaType, dashboardType }) => {
  const [screenWidth, setScreenWidth] = useState();

  const getTotalValue = (legendData) => {
    return legendData.reduce((total, legend) => {
      if (typeof legend.value === 'number') {
        return total + legend.value;
      } else {
        return total;
      }
    }, 0);
  };

  useEffect(() => {
    // Set a CSS variable for window width dynamically
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize(); // Set initial width
    window.addEventListener('resize', handleResize); // Update on resize

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function filterData(data, mediaTypes) {
    return data.filter((item) => {
      // Convert item label to lowercase for case-insensitive comparison
      const label = item.label.toLowerCase();

      // If mediaType is 'all', include all items
      if (Array.isArray(mediaTypes) && mediaTypes.includes('all')) {
        return true;
      }

      // Check if mediaTypes is an array and if it includes the specific media type
      if (Array.isArray(mediaTypes)) {
        // Filter only the specified media types
        if (
          [
            'online',
            'print',
            'blogs',
            'forums',
            'reviews',
            'reddit',
            'x (twitter)',
            'youtube',
          ].includes(label)
        ) {
          const correctLabel =
            label === 'x (twitter)'
              ? 'twitter'
              : label === 'youtube'
              ? 'YouTube'
              : label;
          return mediaTypes.includes(correctLabel);
        }
      }

      // For other labels, do not filter out
      return true;
    });
  }

  // this function is used to format the percentage value
  const formatPercentage = (value) => {
    return parseFloat(value.toFixed(2));
  };

  return (
    <LegendWrp>
      {(() => {
        const totalValue = getTotalValue(legendData);
        const sortedLegendData = filterData(legendData, mediaType).sort(
          (a, b) => b.value - a.value
        );

        const visibleLegends =
          dashboardType === 'overview'
            ? sortedLegendData.slice(0, 5)
            : sortedLegendData.slice(0);

        const legendsToDisplay = [...visibleLegends];

        // remove media types if required (this will remove the media type from legends)
        const excludedMediaTypes = ['YouTube', 'X (Twitter)', 'Reddit'];

        // Pre-calculate percentages
        const filteredLegends = legendsToDisplay.filter(
          (legend) => !excludedMediaTypes.includes(legend.label)
        );

        const calculatePercentages = (legends, totalValue) => {
          // Calculate raw percentages
          const calculatedLegends = legends.map((legend) => {
            const rawPercentage = (legend.value / totalValue) * 100;
            return {
              ...legend,
              percentage: formatPercentage(rawPercentage),
            };
          });

          // Calculate the total percentage after formatting
          const totalPercentage = calculatedLegends.reduce(
            (sum, legend) => sum + legend.percentage,
            0
          );
          const difference = 100 - totalPercentage;
          // Adjust percentages to ensure the total is 100%
          if (difference !== 0) {
            // Find the item with the highest percentage (so the adjustment is less noticeable)
            let maxIndex = 0;
            calculatedLegends.forEach((legend, index) => {
              if (legend.percentage > calculatedLegends[maxIndex].percentage) {
                maxIndex = index;
              }
            });

            // Adjust the highest percentage legend to absorb the rounding difference
            calculatedLegends[maxIndex].percentage = parseFloat(
              (calculatedLegends[maxIndex].percentage + difference).toFixed(2)
            );
          }

          return calculatedLegends.map((legend) => ({
            ...legend,
            formattedPercentage: legend.percentage,
            formattedValue: addCountPrefix(legend.value),
          }));
        };

        // call the function to return formatted legends
        const legendsWithCalculations = calculatePercentages(
          filteredLegends,
          totalValue
        );

        return legendsWithCalculations.map((legend, i) => {
          return (
            <LegendContainer key={i}>
              <LegendBoxWpr>
                {legend?.color && (
                  <LegendBox bgColor={legend?.color}></LegendBox>
                )}
                <LegendLabel
                  className="pie-donut-legend"
                  overLap={true}
                  screenWidth={screenWidth}
                >
                  {legend?.label}
                </LegendLabel>
              </LegendBoxWpr>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '8rem',
                  gap: '0.75rem',
                }}
              >
                <LegendLabel
                  style={{
                    width: '2.5rem',
                    textAlign: 'right',
                    marginRight: '0.25rem',
                  }}
                >{`${legend.formattedPercentage}%`}</LegendLabel>
                <LegendLabel
                  style={{
                    width: '2.5rem',
                  }}
                >
                  {legend.formattedValue}
                </LegendLabel>
              </div>
            </LegendContainer>
          );
        });
      })()}
    </LegendWrp>
  );
};

export default GraphLegendV2;

GraphLegendV2.propTypes = {
  legendData: PropTypes.arrayOf(PropTypes.object),
  mediaType: PropTypes.string,
  dashboardType: PropTypes.string,
};
