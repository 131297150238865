/* eslint-disable array-callback-return */
import { useQuery } from '@tanstack/react-query';
import { axiosPost, get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import {
  SOVMapData,
  articleSentimentMapData,
  coverageOverTimeChartDashboardMapData,
  coverageOverTimeCompetitionMapData,
  journalistMapBreakdown,
  mediaBreakDownMapData,
  mediaTypeBrandMapData,
  reachOverTimeChartDashboardMapData,
  reachOverTimeCompMapData,
  resultOverTimeBrandMapData,
  sentimeChartDashobardMapData,
  sentimeOverTimeChartDashboardMapData,
  sourcesMapData,
  volumeAnalysisDashboardMapData,
  companiesMentioned,
  competitionCompaniesMentioned,
  compMediaContributors,
  compPopularWebsites,
  topPerformingPostsChartData,
  seasonalTrendsMapData,
} from './data/dashboardData';
import {
  addCountPrefix,
  calculatePercentage,
  calculatePercentageIncrease,
  getSelectedTypesForSocialGraph,
  getSelectedTypesForTraditionalGraph,
  removeQuotesFromObjectKeys,
  // toCamelCase,
} from '../constants/utils';
// import { format, parse, parseISO } from 'date-fns';
import { convertValuesToInt } from '../constants/dashboards/dashboardUtils';
import { getFormattedDate } from './useCharts';
import { encloseWordsInDoubleQuotes } from './usePeopleCharts';
import { outletBreakDown, sovColorBox } from '../graphs/utils/graphConst';
import { theme } from '../constants/theme';
import { purpleColorGradients } from '../constants/graph-colors';
import { colors, labelColorMap, WordCloudOrange } from './data/colors';
import { format, parseISO } from 'date-fns';
import { brandChartsQuery } from '../utils/apiQueryFunctionChecker';

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

/**
 * Extracts relevant payload based on selected brands from dropdown values
 * @param {Array} payload - Original payload array
 * @param {Object} dropdownValue - Object with brandData and competitionData arrays
 * @returns {Array} Filtered payload or original payload if no selections
 */
export const extractRelevantPayload = (payload, dropdownValue) => {
  // Return original payload if inputs are invalid
  if (!Array.isArray(payload) || !payload.length) {
    return payload;
  }

  if (
    !dropdownValue ||
    (!dropdownValue.brandData?.length && !dropdownValue.competitionData?.length)
  ) {
    return payload;
  }

  // Combine selected brands
  const selectedBrands = [
    ...(dropdownValue.brandData || []),
    ...(dropdownValue.competitionData || []),
  ];

  // Filter payload to keep only selected brands
  return payload.filter((item) => {
    const brandName = Object.keys(item)[0];
    return selectedBrands.includes(brandName);
  });
};

const getSelectedTypes = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    const isSocial = filters?.media_types?.some((x) => social?.includes(x));
    const isTraditional = filters?.media_types?.some((x) =>
      traditional?.includes(x)
    );
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const socialCardMapping = [
  'Social Mentions',
  // 'Social Impressions',
  'Social Reach',
  // 'Total Engagement',
];

const traditionalMapping = ['Total Results', 'Traditional Reach', 'Total AVE'];

const all = [
  'Total Results',
  'Traditional Reach',
  'Total AVE',
  'Social Mentions',
  // 'Social Impressions',
  'Social Reach',
  // 'Total Engagement',
];

const mediaMappings = {
  social: socialCardMapping,
  all,
  traditional: traditionalMapping,
};

const getTitle = (tile, selectedMedia) => {
  if (tile?.label === 'Social Unique Users') {
    return 'Unique Users';
  } else if (
    (tile?.label === 'Total Articles' || tile?.label === 'Social Mentions') &&
    selectedMedia === 'all'
  ) {
    return tile?.label;
  } else if (
    (tile?.label === 'Total Articles' || tile?.label === 'Social Mentions') &&
    selectedMedia !== 'all'
  ) {
    return 'Social Mentions';
  } else if (
    tile?.label === 'Traditional Reach' &&
    selectedMedia === 'traditional'
  ) {
    return 'Total Reach';
  }
  return tile?.label;
};

function generateLabels(payload, socialMediaTypes) {
  // Filter media types
  const filteredMediaTypes = payload?.filter((type) =>
    socialMediaTypes.includes(type)
  );

  // Create labels
  return filteredMediaTypes
    .map((type) => ({
      label: type,
      value: type,
      color: labelColorMap[type]?.color || null,
    }))
    .filter((label) => label.color !== null);
}

const getDashboardVolumeAnalysisData = async (payload, brand) => {
  const title = 'Key Metrics Overview';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    const { data: response } = await get(
      `${API}/dashboard-brand/volume-analysis?${queryString}`,
      {}
    );

    const selectedMedia = getSelectedTypes(payload);

    const volumAnalysisMapData = JSON.parse(
      JSON.stringify(volumeAnalysisDashboardMapData)
    );

    const totalArticlesMapData = volumAnalysisMapData.data.summary;
    totalArticlesMapData.value = String(
      addCountPrefix(
        addCountPrefix(response?.[0] ? response?.[0]?.current_count : 0)
      )
    );

    const colorData = volumAnalysisMapData?.data?.data;

    const filteredData = response
      ?.filter((tile) => {
        const cards = mediaMappings[selectedMedia];
        if (!cards?.includes(tile?.label)) {
          return false;
        }
        return true;
      })
      ?.map((x) => ({
        ...x,
        order: mediaMappings[selectedMedia]?.findIndex(
          (card) => card === x?.title
        ),
      }))
      ?.sort((a, b) => a.order - b.order);

    const data = filteredData
      ?.map((x, i) => {
        const { isIncreased, percentageIncrease: change } =
          calculatePercentageIncrease(x?.current_count, x?.previous_count);
        const colorIndex = i % colorData?.length;
        const cards = mediaMappings[selectedMedia];
        const order = cards.findIndex((card) => card === x?.label);
        return {
          label: getTitle(x, selectedMedia),
          value: x?.current_count,
          change,
          isIncreased,
          color: colorData[colorIndex]?.color,
          previousCount: x?.previous_count,
          order,
        };
      })
      ?.sort((a, b) => a.order - b.order);

    volumAnalysisMapData.data.data = data;
    volumAnalysisMapData.shouldShowGraph = true;
    if (response) {
      volumAnalysisMapData.originalData = response;
    }
    return volumAnalysisMapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useDashboardAnalisysData = (filters, brand, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard_volume-analysis', filters, brand],
    queryFn: () => getDashboardVolumeAnalysisData(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getSentimentChartData = async (payload, brand) => {
  const title = 'Brand Sentiment';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/sentiment-analysis?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const sentimentChartMapData = JSON.parse(
      JSON.stringify(sentimeChartDashobardMapData)
    );
    // Set Percentage here
    const percentage = calculatePercentage(response);
    const sentimeMentMapData = sentimentChartMapData?.data.data;
    const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
      return {
        ...x,
        value: percentage[x.label],
        sentiment_negative: 123,
      };
    });

    const totalArticlesMapData = sentimentChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    sentimentChartMapData.originalCount = response?.total_count;
    sentimentChartMapData.data.summary = totalArticlesMapData;
    sentimentChartMapData.data.data = updatedSentimentMapData || [];
    sentimentChartMapData.data.netSentiment = response?.net_sentiment || 0;
    sentimentChartMapData.shouldShowGraph = response?.total_count > 0;
    if (response) {
      sentimentChartMapData.originalData = response;
    }
    return sentimentChartMapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useDashboardSentimentChartData = (filters, brand, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand_sentiment-analysis', filters, brand],
    queryFn: () => getSentimentChartData(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

function updateArrayToObject(arr, keyProperty, docCountProperty, labels) {
  const resultObject = {};

  labels.forEach((label) => {
    const value = label?.value;
    const docCount =
      arr.find((item) => item[keyProperty] === value)?.[docCountProperty] || 0;
    resultObject[value] = docCount;
  });

  return resultObject;
}

export const getSentimentOvertimeChartData = async (payload, brand) => {
  const title = 'Sentiment Over Time - Traditional';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/sentiments-over-time?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(
      JSON.stringify(sentimeOverTimeChartDashboardMapData)
    );

    const selectedMedia = getSelectedTypes(payload);
    if (selectedMedia === 'all') {
      mapData.title = 'Sentiment Over Time - Traditional';
    }

    const dataFromRes = response?.data;
    const labels = mapData.data.labels;
    const udpatedDataFromRes = dataFromRes?.map((x) => {
      const formattedDate = getFormattedDate(x?.date ?? x.label);
      const data = updateArrayToObject(
        x?.sentiment_count,
        'key',
        'doc_count',
        labels
      );
      return {
        label: formattedDate,
        ...data,
        date: x?.date ?? x.label,
      };
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.originalCount = response?.total_count;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useDashboardSentimentOverTimeChartData = (
  filters,
  brand,
  isEnabled
) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand_sentiment-over-time', filters, brand],
    queryFn: () => getSentimentOvertimeChartData(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getSentimentOverSocialMediaChartData = async (payload, brand) => {
  const title = 'Sentiment Over Time - Social';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/social-sentiments-over-time?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(
      JSON.stringify(sentimeOverTimeChartDashboardMapData)
    );

    const selectedMedia = getSelectedTypes(payload);
    if (selectedMedia === 'all') {
      mapData.title = 'Sentiment Over Time - Social';
    }

    const dataFromRes = response?.data;
    const labels = mapData.data.labels;
    const udpatedDataFromRes = dataFromRes?.map((x) => {
      const formattedDate = getFormattedDate(x?.date ?? x.label);
      const data = updateArrayToObject(
        x?.sentiment_count,
        'key',
        'doc_count',
        labels
      );
      return {
        label: formattedDate,
        ...data,
        date: x?.date ?? x.label,
        mediaType: 'social',
      };
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.originalCount = response?.total_count;
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useDashboardSentimentOverTimeSocialChartData = (
  filters,
  brand,
  isEnabled
) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: [
      'dashboard-brand_sentiment-over-time-social-media',
      filters,
      brand,
    ],
    queryFn: () => getSentimentOverSocialMediaChartData(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getCoverageOvertimeData = async (payload, brand) => {
  const title = 'Results Over Time - Traditional';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/coverage-over-time?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(
      JSON.stringify(coverageOverTimeChartDashboardMapData)
    );

    const selectedMedia = getSelectedTypes(payload);
    if (selectedMedia === 'all') {
      mapData.title = 'Results Over Time - Traditional';
    }

    mapData.mediaTypes = payload?.media_types
      ? payload?.media_types?.filter((x) => traditional.includes(x))
      : [...traditional];

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          value: String(x?.value),
          date: x?.date ?? x.label,
        };
      }) || [];
    mapData.shouldShowGraph = response?.total_count;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useCoverageOvertimeBrand = (filters, brand, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand_coverage-over-time', filters, brand],
    queryFn: () => getCoverageOvertimeData(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getCoverageOvertimeSocialData = async (payload, brand) => {
  const title = 'Results Over Time - Social';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/social-coverage-over-time?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(
      JSON.stringify(coverageOverTimeChartDashboardMapData)
    );

    const selectedMedia = getSelectedTypes(payload);
    if (selectedMedia === 'all') {
      mapData.title = 'Results Over Time - Social';
    }

    mapData.mediaTypes = payload?.media_types
      ? payload?.media_types?.filter((x) => social.includes(x))
      : [...social];

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          value: String(x?.value),
          date: x?.date ?? x.label,
          mediaType: 'social',
        };
      }) || [];
    mapData.shouldShowGraph = response?.total_count;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useCoverageOvertimeSocialBrand = (filters, brand, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand_social-coverage-over-time', filters, brand],
    queryFn: () => getCoverageOvertimeSocialData(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getReachOverTimeBrand = async (payload, brand) => {
  const title = 'Reach Over Time - Traditional';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
      brand_keywords: brand,
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/reach-over-time?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(
      JSON.stringify(reachOverTimeChartDashboardMapData)
    );

    const selectedMedia = getSelectedTypes(payload);
    if (selectedMedia === 'all') {
      mapData.title = 'Reach Over Time - Traditional';
    }

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          // value: x?.doc_count,
          value: x?.value,
          date: x?.date ?? x.label,
        };
      }) || [];
    const noDataCheck = response.data.some((item) => {
      return Object.keys(item).some(
        (key) => key !== 'label' && item[key] !== '0'
      );
    });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useReachOverTimeBrand = (filters, brand, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand_reach-over-time', filters, brand],
    queryFn: () => getReachOverTimeBrand(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getReachOverTimeBrandSocial = async (payload, brand) => {
  const title = 'Reach Over Time - Social';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/social-reach-over-time?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(
      JSON.stringify(reachOverTimeChartDashboardMapData)
    );

    const selectedMedia = getSelectedTypes(payload);
    if (selectedMedia === 'all') {
      mapData.title = 'Reach Over Time - Social';
      mapData.mediaType = 'Social';
    }

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count
      ? response?.total_count
      : response && response.data
      ? response.data.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.value || 0),
          0
        )
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);
        return {
          ...x,
          label: formattedDate,
          // value: x?.doc_count,
          value: x?.value,
          date: x?.date ?? x.label,
          mediaType: 'social',
        };
      }) || [];
    const noDataCheck = response.data.some((item) => {
      return Object.keys(item).some(
        (key) => key !== 'label' && item[key] !== '0'
      );
    });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useReachOverTimeBrandSocial = (filters, brand, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand_social-reach-over-time', filters, brand],
    queryFn: () => getReachOverTimeBrandSocial(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

const sovProperties = {
  volume: 'doc_count',
  impression: 'impressions',
  traditional_reach: 'reach',
  social_reach: 'reach',
  engagement: 'engagement',
};

export const getSOVData = async (payload, filter) => {
  const brands = payload?.map((brandObj) => {
    const brandKey = Object.keys(brandObj)[0];
    return {
      [brandKey]: {
        ...brandObj[brandKey],
        filter_by: filter?.value || 'volume',
      },
    };
  });

  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-sov`,
    brands,
    {}
  );

  if (!response) {
    response = {};
  }
  const payloadBrandKeys = payload
    ?.map((item) => Object.keys(item)?.map((brandKey) => brandKey))
    ?.flat(Infinity);

  const mapData = JSON.parse(JSON.stringify(SOVMapData));

  const totalArticlesMapData = mapData.data.summary;

  // const totalCount = response?.total_count
  //   ? response?.total_count
  //   : response && response.data
  //   ? response.data.reduce(
  //       (accumulator, currentValue) =>
  //         accumulator + (currentValue?.doc_count || 0),

  //       0
  //     )
  //   : 0;

  const totalCount = response?.overall_total_count;

  totalArticlesMapData.value = String(addCountPrefix(totalCount));

  mapData.data.summary = totalArticlesMapData;

  // mapData.data.data =
  //   response?.data?.map((x, i) => {
  //     return {
  //       ...x,

  //       value: x?.[sovProperties[filter?.value || 'volume']],

  //       label: x.label.replace(/^"(.*)"$/, '$1'),
  //       filter: filter?.value,
  //       graph: 'sov',
  //     };
  //   }) || []; // old version code

  mapData.originalCount = totalCount;
  mapData.data.data =
    payloadBrandKeys
      ?.map((keyName) => {
        const sovData = response?.brands
          ?.map((brandLabel, i) => {
            const brandKeyData = brandLabel?.[keyName]?.data?.[0];

            if (!brandKeyData) return null;

            return {
              ...brandKeyData,

              value: brandKeyData?.[sovProperties[filter?.value ?? 'volume']],

              label: brandKeyData?.label.replace(/^"(.*)"$/, '$1'),
              filter: 'volume' || filter?.value,
              graph: 'sov',
            };
          })
          ?.filter(Boolean);

        return sovData;
      })
      ?.flat() || [];

  mapData.data.legends = [
    // ...brand.map((k) => k.toLowerCase()),
    // ...comp.map((k) => k.toLowerCase()),
    // ...brand.map((k) => k),
    // ...comp.map((k) => k),
    ...payloadBrandKeys,
  ]?.map((x) => {
    return {
      label: x,
      // value: String(x).toLowerCase(),
      value: String(x).toLowerCase(),
    };
  });
  // Create a map to store the index of each legend

  const legendIndexMap = {};

  mapData?.data?.legends?.forEach((legend, index) => {
    legendIndexMap[legend?.label] = index;
  });

  // Sort the list data using the custom comparison

  mapData?.data?.data?.sort(
    (a, b) => (legendIndexMap[a?.label] || 0) - (legendIndexMap[b?.label] || 0)
  );

  mapData.data.data = mapData.data.data.map((item, i) => {
    return {
      ...item,
      color: sovColorBox[i],
    };
  });

  // mapData.shouldShowGraph = response?.total_count;
  mapData.shouldShowGraph =
    response?.data?.some((item) => item?.doc_count !== 0) ||
    (response?.overall_total_count && response?.overall_total_count > 0);
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useSOVData = (filters, filter, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-brand_competition-sov', filters, filter],
    queryFn: () => getSOVData(filters, filter),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export function transformData(inputData) {
  // Step 1: Create total count object
  const totalCounts = inputData.reduce((acc, brandObj) => {
    const brandName =
      Object.keys(brandObj)[0] !== 'recent_search_id'
        ? Object.keys(brandObj)[0]
        : Object.keys(brandObj)[1];
    const totalCount = brandObj[brandName].total_count;
    acc[brandName] = totalCount;
    return acc;
  }, {});

  const brandKey =
    Object.keys(inputData[0])[1] !== 'recent_search_id'
      ? Object.keys(inputData[0])[1]
      : Object.keys(inputData[0])[0];
  // Step 2: Create date-wise values array
  const dates = inputData[0][brandKey].data.map((entry) => entry.label);

  const dateWiseData = dates.map((date) => {
    const values = inputData.map((brandObj) => {
      const brandName =
        Object.keys(brandObj)[0] !== 'recent_search_id'
          ? Object.keys(brandObj)[0]
          : Object.keys(brandObj)[1];

      const brandData = brandObj[brandName].data.find((d) => d.label === date);
      return {
        label: brandName,
        value: brandData ? parseFloat(brandData?.[brandName] || 0) : 0,
      };
    });
    return {
      date,
      values,
    };
  });

  return { totalCounts, dateWiseData };
}

export const getCoverageOvertimeCompetitionData = async (
  payload
  // brand,
  // comp
) => {
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-coverage-over-time`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const { totalCounts, dateWiseData } = transformData(response?.brands);

  const labels = Object.keys(totalCounts)?.map((key, index) => ({
    label: key,
    value: key?.split(' ').join('_'),
    // color: peopleReachOverTimeColors[index],
  }));

  const mapData = JSON.parse(
    JSON.stringify(coverageOverTimeCompetitionMapData)
  );

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Traditional';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => traditional.includes(x))
    : [...traditional];

  const totalArticlesMapData = mapData.data.summary;
  // const totalCount = response?.total_count ? response?.total_count : 0;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  // mapData.data.data =
  //   convertValuesToInt(response?.data, ['label', 'date'])?.map((x) => {
  //     const formattedDate = getFormattedDate(String(x?.date ?? x.label));
  //     const refinedXData = removeQuotesFromObjectKeys(x);
  //     return {
  //       ...refinedXData,
  //       ...x,
  //       label: formattedDate,
  //       date: x?.date ?? x.label,
  //     };
  //   }) || []; // The following code is for the chip keywords flow.

  mapData.data.data = dateWiseData?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);
    function arrayToObject(arr) {
      return arr.reduce((acc, { label, value }) => {
        const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
        acc[formattedLabel] = value;
        return acc;
      }, {});
    }
    return {
      ...x,
      label: formattedDate,
      date: x?.date,
      ...arrayToObject(x?.values),
    };
  });
  mapData.originalCount = totalCount;

  mapData.data.labels = labels;
  // mapData.data.labels = [
  //   // ...comp.map((k) => k.toLowerCase()),
  //   // ...brand.map((k) => k.toLowerCase()),
  //   // ...brand.map((k) => k),
  //   // ...comp.map((k) => k),
  //   payloadBrandKeys,
  // ]?.map((x) => {
  //   return {
  //     label: x,
  //     // value: String(x).toLowerCase(),
  //     value: String(x),
  //   };
  // });
  const noDataCheck =
    response?.overall_total_count && response?.overall_total_count > 0;
  // const noDataCheck = response.data.some((item) => {
  //   return Object.keys(item).some(
  //     (key) => key !== 'label' && item[key] !== '0'
  //   );
  // });
  // mapData.shouldShowGraph = response?.total_count;
  mapData.shouldShowGraph = noDataCheck;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useCoverageOvertimeCompetition = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-coverage-over-time',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getCoverageOvertimeCompetitionData(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getCoverageOvertimeCompetitionSocialData = async (
  payload
  // brand,
  // comp
) => {
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-social-coverage-over-time`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const { totalCounts, dateWiseData } = transformData(response?.brands);

  const labels = Object.keys(totalCounts)?.map((key, index) => ({
    label: key,
    value: key?.split(' ').join('_'),
    // color: peopleReachOverTimeColors[index],
  }));

  const mapData = JSON.parse(
    JSON.stringify(coverageOverTimeCompetitionMapData)
  );

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Social';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => social.includes(x))
    : [...social];

  const totalArticlesMapData = mapData.data.summary;
  // const totalCount = response?.total_count ? response?.total_count : 0;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  // mapData.data.data =
  //   convertValuesToInt(response?.data, ['label', 'date'])?.map((x) => {
  //     const formattedDate = getFormattedDate(String(x?.date ?? x.label));
  //     const refinedXData = removeQuotesFromObjectKeys(x);
  //     return {
  //       ...refinedXData,
  //       ...x,
  //       label: formattedDate,
  //       date: x?.date ?? x.label,
  //     };
  //   }) || []; // The following code is for the chip keywords flow.

  mapData.data.data = dateWiseData?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);
    function arrayToObject(arr) {
      return arr.reduce((acc, { label, value }) => {
        const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
        acc[formattedLabel] = value;
        return acc;
      }, {});
    }
    return {
      ...x,
      label: formattedDate,
      date: x?.date,
      ...arrayToObject(x?.values),
    };
  });
  mapData.originalCount = totalCount;

  mapData.data.labels = labels;
  // mapData.data.labels = [
  //   // ...comp.map((k) => k.toLowerCase()),
  //   // ...brand.map((k) => k.toLowerCase()),
  //   // ...brand.map((k) => k),
  //   // ...comp.map((k) => k),
  // ]?.map((x) => {
  //   return {
  //     label: x,
  //     // value: String(x).toLowerCase(),
  //     value: String(x),
  //   };
  // });
  const noDataCheck =
    response?.overall_total_count && response?.overall_total_count > 0;
  // const noDataCheck = response.data.some((item) => {
  //   return Object.keys(item).some(
  //     (key) => key !== 'label' && item[key] !== '0'
  //   );
  // });
  // mapData.shouldShowGraph = response?.total_count;
  mapData.shouldShowGraph = noDataCheck;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useCoverageOvertimeSocialCompetition = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-coverage-over-time-soical',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getCoverageOvertimeCompetitionSocialData(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getMediaType = async (payload, brand) => {
  const title = 'Data Sources';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/media-type?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(JSON.stringify(mediaTypeBrandMapData));

    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count ? response?.total_count : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    const dataFromRes = response?.data;

    let totalOnlineCount = 0;
    let totalPrintCount = 0;
    let totalBlogsCount = 0;
    let totalForumsCount = 0;
    let totalReviewsCount = 0;
    let totalTwitterCount = 0;
    let totalRedditCount = 0;
    let totalYouTubeCount = 0;
    dataFromRes?.forEach((x) => {
      const onlineMedia = x?.media_type_count?.find(
        (media) => media.label === 'Online'
      );
      const PrintMedia = x?.media_type_count?.find(
        (media) => media.label === 'Print'
      );
      const forumMedia = x?.media_type_count?.find(
        (media) => media.label === 'Forums'
      );
      const blogMedia = x?.media_type_count?.find(
        (media) => media.label === 'Blogs'
      );
      const reviewMedia = x?.media_type_count?.find(
        (media) => media.label === 'Reviews'
      );
      const twitterMedia = x?.media_type?.find((media) => {
        return media?.label?.toLowerCase()?.includes('twitter');
      });

      const YouTubeMedia = x?.media_type?.find((media) => {
        return media?.label === 'YouTube';
      });

      const redditMedia = x?.media_type?.find((media) => {
        return media;
      });

      const onlineDocCount = onlineMedia ? onlineMedia.value : 0;
      const printDocCount = PrintMedia ? PrintMedia.value : 0;
      const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
      const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
      const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
      const twitterDocCount = twitterMedia ? twitterMedia.doc_count : 0;
      const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
      const YouTubeDocCount = YouTubeMedia ? YouTubeMedia.doc_count : 0;

      totalOnlineCount += onlineDocCount;
      totalPrintCount += printDocCount;
      totalBlogsCount += blogDocCount;
      totalReviewsCount += reviewDocCount;
      totalForumsCount += forumDocCount;
      totalTwitterCount += twitterDocCount;
      totalRedditCount += redditDocCount;
      totalYouTubeCount += YouTubeDocCount;
    });

    const udpatedDataFromRes = dataFromRes?.map((item) => {
      const formattedDate = getFormattedDate(item?.label ?? item?.date);

      return {
        date: item?.label ?? item?.date,
        label: formattedDate,

        print:
          item.media_type_count.find((type) => type.label === 'Print')?.value ||
          0,
        online:
          item.media_type_count.find((type) => type.label === 'Online')
            ?.value || 0,
        forum:
          item.media_type_count.find((type) => type.label === 'Forums')
            ?.value || 0,
        blog:
          item.media_type_count.find((type) => type.label === 'Blogs')?.value ||
          0,
        review:
          item.media_type_count.find((type) => type.label === 'Reviews')
            ?.value || 0,
        twitter:
          item.media_type_count.find((type) => type.label === 'X (Twitter)')
            ?.value || 0,
        reddit:
          item?.media_type_count.find((type) => type?.label === 'Reddit')
            ?.value || 0,
        YouTube:
          item?.media_type_count.find((type) => type?.label === 'YouTube')
            ?.value || 0,
      };
    });
    // .slice(0, 60);

    mapData.data.summary = {
      ...totalArticlesMapData,
      totalOnlineCount,
      totalPrintCount,
      totalBlogsCount,
      totalForumsCount,
      totalReviewsCount,
      totalTwitterCount,
      totalRedditCount,
      totalYouTubeCount,
      mediaType: response?.media_filter ?? null,
    };

    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useMediaTypeBrand = (filters, brand, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand_media-type', filters, brand],
    queryFn: () => getMediaType(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export function fillMissingProperties(
  dataArray,
  labelsArray,
  payloadBrandKeys
) {
  // return payloadBrandKeys
  //   ?.map((keyName) => {
  //     return dataArray?.brands
  //       ?.map((brandLabel, i) => {
  //         const brandKeyData = brandLabel?.[keyName]?.data?.[0];

  //         if (!brandKeyData) return null;
  //         const newObj = { ...brandKeyData };

  //         newObj.label = newObj.label.replace(/^"(.*)"$/, '$1');

  //         labelsArray.forEach((labelObj) => {
  //           const { value } = labelObj;

  //           if (!newObj[value]) {
  //             newObj[value] = '0';
  //           }
  //         });

  //         return newObj;
  //       })
  //       ?.filter(Boolean);
  //   })
  //   ?.flat();

  return dataArray?.brands?.map((dataObj) => {
    const newObj = { ...dataObj };

    newObj.label = newObj.label.replace(/^"(.*)"$/, '$1');

    labelsArray.forEach((labelObj) => {
      const { value } = labelObj;

      if (!newObj[value]) {
        newObj[value] = '0';
      }
    });

    return newObj;
  });
}

export const getArticleSentiment = async (
  payload
  //  brand, comp
) => {
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-sentiment`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const payloadBrandKeys = payload
    ?.map((item) => Object.keys(item)?.map((brandKey) => brandKey))
    ?.flat(Infinity);
  const mapData = JSON.parse(JSON.stringify(articleSentimentMapData));
  // const totalKeywords = [...brand, ...keywords];
  const totalArticlesMapData = mapData.data.summary;
  // const totalCount = response?.total_count ? response?.total_count : 0;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  mapData.originalCount = totalCount;
  const labels = mapData?.data?.labels;
  mapData.data.data = fillMissingProperties(response, labels, payloadBrandKeys);
  // mapData?.data?.data?.sort(
  //   (a, b) =>
  //     (totalKeywords?.indexOf(a?.label) || 0) -
  //     (totalKeywords?.indexOf(b?.label) || 0)
  // );

  mapData.data.data = mapData.data.data.map((item, i) => {
    return {
      ...item,
      color: sovColorBox[i],
    };
  });
  const noDataCheck =
    response?.overall_total_count && response?.overall_total_count > 0;
  // response.data.some((item) => {
  //   return Object.keys(item).some(
  //     (key) => key !== 'label' && item[key] !== '0'
  //   );
  // }) ||
  // mapData.shouldShowGraph = response?.total_count;
  mapData.shouldShowGraph = noDataCheck;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useArticleSentimentComp = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-sentiment',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getArticleSentiment(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getReachOverTimeCompData = async (
  payload
  // brand, comp
) => {
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-reach-over-time`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const { totalCounts, dateWiseData } = transformData(response?.brands);
  const labels = Object.keys(totalCounts)?.map((key, index) => ({
    label: key,
    value: key?.split(' ').join('_'),
    // color: peopleReachOverTimeColors[index],
  }));

  const mapData = JSON.parse(JSON.stringify(reachOverTimeCompMapData));

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Reach Over Time - Traditional';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => traditional.includes(x))
    : [...traditional];

  // setting total count
  const totalArticlesMapData = mapData.data.summary;
  // const totalCount = response?.total_count ? response?.total_count : 0;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  mapData.originalCount = totalCount;
  // setting data
  // mapData.data.data =
  //   response?.data?.map((x) => {
  //     const formattedDate = getFormattedDate(x?.date ?? x.label);
  //     const refinedXData = removeQuotesFromObjectKeys(x);
  //     return {
  //       ...refinedXData,
  //       ...x,
  //       label: formattedDate,
  //       date: x?.date ?? x.label,
  //     };
  //   }) || []; The following code is for the chip keywords flow.

  mapData.data.data = dateWiseData?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);
    function arrayToObject(arr) {
      return arr.reduce((acc, { label, value }) => {
        const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
        acc[formattedLabel] = value;
        return acc;
      }, {});
    }
    return {
      ...x,
      label: formattedDate,
      date: x?.date,
      ...arrayToObject(x?.values),
    };
  });
  mapData.data.labels = labels;

  // setting labels for graph
  // mapData.data.labels = [
  //   // ...comp.map((k) => k.toLowerCase()),
  //   // ...brand.map((k) => k.toLowerCase()),
  //   // ...brand.map((k) => k),
  //   // ...comp.map((k) => k),
  // ]?.map((x) => {
  //   return {
  //     label: x,
  //     // value: String(x).toLowerCase(),
  //     value: String(x),
  //   };
  // });
  const noDataCheck =
    response?.overall_total_count && response?.overall_total_count > 0;
  // const noDataCheck = response.data.some((item) => {
  //   return Object.keys(item).some(
  //     (key) => key !== 'label' && item[key] !== '0.0' && item[key] !== '0'
  //   );
  // });
  // mapData.shouldShowGraph = response?.total_count;
  mapData.shouldShowGraph = noDataCheck;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useReachOverTimeComp = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-reach-over-time',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getReachOverTimeCompData(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getReachOverTimeSocialCompData = async (payload, brand, comp) => {
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-social-reach-over-time`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const { totalCounts, dateWiseData } = transformData(response?.brands);
  const labels = Object.keys(totalCounts)?.map((key, index) => ({
    label: key,
    value: key?.split(' ').join('_'),
    // color: peopleReachOverTimeColors[index],
  }));

  const mapData = JSON.parse(JSON.stringify(reachOverTimeCompMapData));

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Reach Over Time - Social';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => social.includes(x))
    : [...social];

  // setting total count
  const totalArticlesMapData = mapData.data.summary;
  // const totalCount = response?.total_count ? response?.total_count : 0;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  mapData.originalCount = totalCount;
  // setting data
  // mapData.data.data =
  //   response?.data?.map((x) => {
  //     const formattedDate = getFormattedDate(x?.date ?? x.label);
  //     const refinedXData = removeQuotesFromObjectKeys(x);
  //     return {
  //       ...refinedXData,
  //       ...x,
  //       label: formattedDate,
  //       date: x?.date ?? x.label,
  //     };
  // }) || []; // This following code is for the chip keywords flow.
  // setting labels for graph

  mapData.data.data = dateWiseData?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);
    function arrayToObject(arr) {
      return arr.reduce((acc, { label, value }) => {
        const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
        acc[formattedLabel] = value;
        return acc;
      }, {});
    }
    return {
      ...x,
      label: formattedDate,
      date: x?.date,
      ...arrayToObject(x?.values),
    };
  });
  mapData.data.labels = labels;

  // mapData.data.labels = [
  //   // ...comp.map((k) => k.toLowerCase()),
  //   // ...brand.map((k) => k.toLowerCase()),
  //   ...brand.map((k) => k),
  //   ...comp.map((k) => k),
  // ]?.map((x) => {
  //   return {
  //     label: x,
  //     // value: String(x).toLowerCase(),
  //     value: String(x),
  //   };
  // });
  const noDataCheck =
    response?.overall_total_count && response?.overall_total_count > 0;
  // const noDataCheck = response.data.some((item) => {
  //   return Object.keys(item).some(
  //     (key) => key !== 'label' && item[key] !== '0.0' && item[key] !== '0'
  //   );
  // });
  // mapData.shouldShowGraph = response?.total_count;
  mapData.shouldShowGraph = noDataCheck;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useReachOverSocialTimeComp = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-reach-over-time-social',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getReachOverTimeSocialCompData(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getJournalistCoverageData = async (payload, brand, comp) => {
  // const keywords = [...comp];
  const keywords = [];

  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-coverage-by-journalist`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const payloadBrandKeys = payload
    ?.map((item) => Object.keys(item)?.map((brandKey) => brandKey))
    ?.flat(Infinity);

  const mapData = JSON.parse(JSON.stringify(journalistMapBreakdown));

  // const totalArticlesMapData = mapData.summary;
  // totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  // mapData.summary = totalArticlesMapData;

  const totalArticlesMapData = mapData.data.summary;
  // const totalCount = response?.total_count ? response?.total_count : 0;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  mapData.originalCount = totalCount;
  const updatedData = [];

  const comparisonData = {};

  response?.brands?.map((brandData) => {
    const brandKey =
      Object?.keys(brandData)?.[0] !== 'recent_search_id'
        ? Object?.keys(brandData)?.[0]
        : Object?.keys(brandData)?.[1];

    comparisonData[brandKey] =
      brandData?.[brandKey]?.data[Object.keys(brandData?.[brandKey]?.data)[0]];
  });

  Object.keys(comparisonData)?.forEach((keyword, index) => {
    if (keyword && index === 0 && comparisonData[keyword]) {
      const combinedObject = {};

      const sortedData = comparisonData[keyword]
        ?.slice(0, 10)
        ?.sort((a, b) => b.count - a.count)
        ?.filter((item) => item?.count > 0);

      if (sortedData.length === 0) {
        combinedObject.noData = 0;
      } else {
        for (const item of sortedData) {
          const authorName = item.label;
          const count = item.count;
          combinedObject[
            `${authorName?.replace(
              /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
              ''
            )}_author_id`
          ] = item.author_id;
          combinedObject[authorName] = count;
        }
      }
      updatedData.push({
        label: keyword,
        color: theme?.light?.graphColors?.purple60,
        lightColor: theme?.light?.graphColors?.purple40,
        ...combinedObject,

        // key: data?.
      });
    } else if (keyword && index === 1) {
      const combinedObject = {};

      const sortedData = comparisonData[keyword]
        ?.slice(0, 10)
        ?.sort((a, b) => b.count - a.count);
      if (sortedData.length === 0) {
        combinedObject.noData = 0;
      } else {
        for (const item of sortedData) {
          const authorName = item.label;
          const count = item.count;
          combinedObject[
            `${authorName?.replace(
              /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
              ''
            )}_author_id`
          ] = item.author_id;
          combinedObject[authorName] = count;
        }
      }
      updatedData.push({
        label: keyword,
        color: theme?.light?.graphColors?.magenta60,
        lightColor: theme?.light?.graphColors?.magenta40,
        ...combinedObject,
      });
    } else if (keyword && index === 2 && comparisonData[keyword]) {
      const combinedObject = {};

      const sortedData = comparisonData[keyword]
        ?.slice(0, 10)
        ?.sort((a, b) => b.count - a.count)
        ?.filter((item) => item?.count > 0);
      if (sortedData.length === 0) {
        combinedObject.noData = 0;
      } else {
        for (const item of sortedData) {
          const authorName = item.label;
          const count = item.count;
          combinedObject[
            `${authorName?.replace(
              /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
              ''
            )}_author_id`
          ] = item.author_id;
          combinedObject[authorName] = count;
        }
      }
      updatedData.push({
        label: keyword,
        color: theme?.light?.graphColors?.cyan50,
        lightColor: theme?.light?.graphColors?.cyan30,
        ...combinedObject,

        // key: data?.
      });
    } else if (keyword && index === 3 && comparisonData[keyword]) {
      const combinedObject = {};

      const sortedData = comparisonData[keyword]
        ?.slice(0, 10)
        ?.sort((a, b) => b.count - a.count);
      if (sortedData.length === 0) {
        combinedObject.noData = 0;
      } else {
        for (const item of sortedData) {
          const authorName = item.label;
          const count = item.count;
          combinedObject[
            `${authorName?.replace(
              /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
              ''
            )}_author_id`
          ] = item.author_id;
          combinedObject[authorName] = count;
        }
      }
      updatedData.push({
        label: keyword,
        color: theme?.light?.graphColors?.orange50,
        lightColor: theme?.light?.graphColors?.orange30,
        ...combinedObject,

        // key: data?.
      });
    } else if (keyword && index === 4 && comparisonData[keyword]) {
      const combinedObject = {};

      const sortedData = comparisonData[keyword]
        ?.slice(0, 10)
        ?.sort((a, b) => b.count - a.count);
      if (sortedData.length === 0) {
        combinedObject.noData = 0;
      } else {
        for (const item of sortedData) {
          const authorName = item.label;
          const count = item.count;
          combinedObject[
            `${authorName?.replace(
              /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
              ''
            )}_author_id`
          ] = item.author_id;
          combinedObject[authorName] = count;
        }
      }
      updatedData.push({
        label: keyword,
        color: theme?.light?.graphColors?.teal50,
        lightColor: theme?.light?.graphColors?.teal30,
        ...combinedObject,

        // key: data?.
      });
    } else if (keyword && index === 5 && comparisonData[keyword]) {
      const combinedObject = {};

      const sortedData = comparisonData[keyword]
        ?.slice(0, 10)
        ?.sort((a, b) => b.count - a.count);
      if (sortedData.length === 0) {
        combinedObject.noData = 0;
      } else {
        for (const item of sortedData) {
          const authorName = item.label;
          const count = item.count;
          combinedObject[
            `${authorName?.replace(
              /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
              ''
            )}_author_id`
          ] = item.author_id;
          combinedObject[authorName] = count;
        }
      }
      updatedData.push({
        label: keyword,
        color: theme?.light?.graphColors?.yellow40,
        lightColor: theme?.light?.graphColors?.yellow30,
        ...combinedObject,

        // key: data?.
      });
    }
  });
  mapData.data.data = updatedData;

  const flatMappedData = updatedData.map((dataItem) => {
    return Object.keys(dataItem)
      .filter(
        (key) =>
          key !== 'label' &&
          key !== 'color' &&
          key !== 'lightColor' &&
          key !== 'author_id'
      )
      .map((key) => {
        return {
          label: key,
          value: String(key),
        };
      });
  });

  // const totalKeywords = [...brand, ...comp];
  // Create a Set to track unique entries
  const uniqueEntriesSet = new Set();
  const uniqueEntries = flatMappedData.filter((entry) => {
    const entryKey = `${entry.label}-${entry.value}`;
    if (uniqueEntriesSet.has(entryKey)) {
      return false;
    }
    uniqueEntriesSet.add(entryKey);
    return true;
  });

  mapData.data.legends = [
    // ...brand.map((k) => k.toLowerCase()),
    // ...comp.map((k) => k.toLowerCase()),
    // ...brand.map((k) => k),
    // ...comp.map((k) => k),
    ...payloadBrandKeys,
  ]?.map((x) => {
    return {
      label: x,
      // value: String(x).toLowerCase(),
      value: String(x).toLowerCase(),
    };
  });

  mapData.data.labels = uniqueEntries;
  mapData?.data?.data?.sort(
    (a, b) =>
      (payloadBrandKeys?.indexOf(a?.label) || 0) -
      (payloadBrandKeys?.indexOf(b?.label) || 0)
  );
  // mapData.shouldShowGraph =
  //   response?.total_count ||
  //   response?.data[keywords[0]].some((item) => item.value?.length > 0);
  const noDataCheck =
    response?.overall_total_count && response?.overall_total_count > 0;
  // const noDataCheck = Object.keys(response.data).some(
  //   (key) => response?.data[key]?.length > 0
  // );
  mapData.shouldShowGraph = noDataCheck;
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useJournalistCoverageComp = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-coverage-by-journalist',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getJournalistCoverageData(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getMediaBreakDownMedia = async (
  payload
  // brand, comp
) => {
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-breakdown-by-mediatype`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }
  const payloadBrandKeys = payload
    ?.map((item) => Object.keys(item)?.map((brandKey) => brandKey))
    ?.flat(Infinity);

  const mapData = JSON.parse(JSON.stringify(mediaBreakDownMapData));
  // setting total count
  const totalArticlesMapData = mapData.data.summary;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  mapData.originalCount = totalCount;
  const categories = [
    'Online',
    'Print',
    'Forums',
    'Reviews',
    'Blogs',
    // 'Broadcast',
    'X (Twitter)',
    'Reddit',
    'YouTube',
  ];

  const comparisonData = [];
  response?.brands?.map((brandData) => {
    const brandKey =
      Object?.keys(brandData)[0] !== 'recent_search_d'
        ? Object?.keys(brandData)[0]
        : Object?.keys(brandData)[1];
    comparisonData.push(brandData?.[brandKey]?.data?.[0]);
  });

  // setting data
  mapData.data.data = mapSampleDataToData(comparisonData, categories);
  // setting labels for graph
  mapData.data.labels = [
    // ...comp.map((k) => k.toLowerCase()),
    // ...brand.map((k) => k.toLowerCase()),
    // ...brand.map((k) => k),
    // ...comp.map((k) => k),
    ...payloadBrandKeys,
  ]?.map((x) => {
    const valueStr = x.replace(/["]+/g, '');

    return {
      label: x,
      // value: String(x).toLowerCase(),
      value: String(valueStr),
    };
  });

  mapData.shouldShowGraph =
    response?.overall_total_count && response?.overall_total_count > 0;
  // mapData.shouldShowGraph = response?.data.some((item, i) => {
  //   return item?.value?.length > 0 && !Array.isArray(item?.value[i]?.value);
  // });
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export function mapSampleDataToData(sampleData, categories) {
  const findValue = (array, category) => {
    // return array.find((x) => String(x?.label).includes(category))?.value || 0;
    //  As the backend providing object if there is no data instead of array
    if (!Array.isArray(array)) return 0;
    const foundValue = array.find((x) => String(x?.label).includes(category));
    if (foundValue) {
      return Array.isArray(foundValue.value)
        ? foundValue.value.reduce(
            (sum, subItem) => sum + (subItem.value || 0),
            0
          )
        : foundValue.value || 0;
    }
    return 0;
  };

  const resultData = categories.map((category) => {
    const result = { label: category };

    sampleData.forEach((data) => {
      result[data.label.replace(/^"(.*)"$/, '$1')] = findValue(
        data?.value,
        category
      );
    });
    return result;
  });

  return resultData;
}

export const useMediaBreakDownMediaType = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-breakdown-by-mediatype',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getMediaBreakDownMedia(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};
export function convertEmptyObjectToArray(data, brandCompetitionKeywords) {
  if (data && typeof data === 'object' && !Array.isArray(data)) {
    Object.keys(data).forEach((key) => {
      if (
        typeof data[key] === 'object' &&
        !Array.isArray(data[key]) &&
        Object.keys(data[key]).length === 0
      ) {
        data[key] = []; // Replace empty object with an empty array
      }
    });

    brandCompetitionKeywords.forEach((keyword) => {
      if (!Object.hasOwn(data, keyword)) {
        data[keyword] = []; // Add missing keyword with an empty array
      }
    });
  }
  return data;
}
export const getSourcesComp = async (payload, brand, comp) => {
  const keywords = [];

  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-coverage-by-source`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const payloadBrandKeys = payload
    ?.map((item) => Object.keys(item)?.map((brandKey) => brandKey))
    ?.flat(Infinity);

  const mapData = JSON.parse(JSON.stringify(sourcesMapData));
  // setting total count
  const totalArticlesMapData = mapData.data.summary;
  const totalCount = response?.overall_total_count
    ? response?.overall_total_count
    : 0;
  totalArticlesMapData.value = String(addCountPrefix(totalCount));
  mapData.data.summary = totalArticlesMapData;
  mapData.originalCount = totalCount;
  response.data = convertEmptyObjectToArray(response?.data, [
    brand,
    ...keywords,
  ]);
  const updatedData = [];

  const comparisonData = {};

  response?.brands?.map((brandData) => {
    const brandKey =
      Object?.keys(brandData)?.[0] !== 'recent_search_id'
        ? Object?.keys(brandData)?.[0]
        : Object?.keys(brandData)?.[1];

    // Fix: Handle empty data object by creating an empty array for the brand
    if (brandData?.[brandKey]?.data?.[brandKey]) {
      comparisonData[brandKey] = brandData?.[brandKey]?.data?.[brandKey];
    } else {
      comparisonData[brandKey] = [];
    }
  });

  Object.keys(comparisonData)?.forEach((keyword, index) => {
    if (keyword) {
      const combinedObject = {};
      const sourceMap = {}; // To store the source values mapped to source_name

      const sortedData = comparisonData[keyword]
        ?.slice(0, 10)
        ?.sort((a, b) => b.count - a.count);

      if (!sortedData || sortedData.length === 0) {
        combinedObject.noData = 0;
      } else {
        for (const item of sortedData) {
          // Use source_name as the key instead of source
          const source = item.source;
          const source_name = item.source_name || item.source; // Use source_name if available, otherwise fall back to source
          const count = item.count;

          combinedObject[source_name] = count;
          sourceMap[source_name] = source; // Store the mapping
        }
      }

      // Define colors based on index
      let color, lightColor;
      switch (index) {
        case 0:
          color = theme?.light?.graphColors?.purple60;
          lightColor = theme?.light?.graphColors?.purple40;
          break;
        case 1:
          color = theme?.light?.graphColors?.magenta60;
          lightColor = theme?.light?.graphColors?.magenta40;
          break;
        case 2:
          color = theme?.light?.graphColors?.cyan50;
          lightColor = theme?.light?.graphColors?.cyan30;
          break;
        case 3:
          color = theme?.light?.graphColors?.orange50;
          lightColor = theme?.light?.graphColors?.orange30;
          break;
        case 4:
          color = theme?.light?.graphColors?.teal50;
          lightColor = theme?.light?.graphColors?.teal30;
          break;
        case 5:
          color = theme?.light?.graphColors?.yellow40;
          lightColor = theme?.light?.graphColors?.yellow30;
          break;
        default:
          color = theme?.light?.graphColors?.purple60;
          lightColor = theme?.light?.graphColors?.purple40;
      }

      updatedData.push({
        label: keyword,
        color,
        lightColor,
        ...combinedObject,
        sourceMap, // Add the sourceMap to the data item
      });
    }
  });

  mapData.data.data = updatedData;

  mapData.data.legends = [...payloadBrandKeys]?.map((x) => {
    return {
      label: x,
      value: String(x).toLowerCase(),
    };
  });

  const flatMappedData = updatedData.flatMap((dataItem) => {
    // Get the sourceMap from the data item
    const sourceMap = dataItem.sourceMap || {};

    return Object.keys(dataItem)
      .filter(
        (key) =>
          key !== 'label' &&
          key !== 'color' &&
          key !== 'lightColor' &&
          key !== 'sourceMap' &&
          key !== 'noData' // Also exclude noData
      )
      .map((key) => ({
        label: key, // This is the source_name
        value: String(key),
        source: sourceMap[key] || key, // This is the original source value
      }));
  });

  // Create a Set to track unique entries
  const uniqueEntriesSet = new Set();
  const uniqueEntries = flatMappedData.filter((entry) => {
    const entryKey = `${entry.label}-${entry.value}`;
    if (uniqueEntriesSet.has(entryKey)) {
      return false;
    }
    uniqueEntriesSet.add(entryKey);
    return true;
  });

  mapData?.data?.data?.sort(
    (a, b) =>
      (payloadBrandKeys?.indexOf(a?.label) || 0) -
      (payloadBrandKeys?.indexOf(b?.label) || 0)
  );

  mapData.data.labels = uniqueEntries;

  const noDataCheck =
    response?.overall_total_count && response?.overall_total_count > 0;

  mapData.shouldShowGraph = noDataCheck;
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useSourcesCompData = (
  filters,
  //  brand, competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-brand_competition-coverage-by-source',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getSourcesComp(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getCompaniesMentioned = async (payload, brand, comp) => {
  const dashboard_name = 'brandComparison';
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/companies-mentioned-comparison?dashboard_name=${dashboard_name}`,
    payload,
    {}
  );

  if (!response) {
    response = {};
  }

  const companies = JSON.parse(JSON.stringify(competitionCompaniesMentioned));

  const comparisonData = response?.brands
    ?.map((brandData) => {
      const brandKey =
        Object?.keys(brandData)[0] !== 'recent_search_d'
          ? Object?.keys(brandData)[0]
          : Object?.keys(brandData)[1];
      return brandData?.[brandKey]?.data;
    })
    ?.flat();

  companies.data.data = comparisonData.map((item, i) => {
    const colorIndex = i % colors?.length;
    return {
      ...colors[colorIndex],
      label: item.label?.toUpperCase(),
      value: item.article_count,
      labelColor: colors[colorIndex]?.color,
    };
  });
  companies.data.summary.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  companies.originalCount = response?.overall_total_count;
  companies.shouldShowGraph = comparisonData?.length > 0;
  if (response) {
    companies.originalData = response;
  }
  return companies;
};

export const useCompaniesMentioned = (
  filters,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-competition-companies-mentioned',
      filters,
      // brand,
      // competition,
    ],
    queryFn: () => getCompaniesMentioned(filters),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

export const getBrandCompaniesMentioned = async (payload, brand) => {
  const title = 'Brand Mentions';
  try {
    // const keywords = [...comp];
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
      // competition_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;

    let { data: response } = await get(
      `${API}/dashboard-brand/companies-mentioned-analysis?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const companies = JSON.parse(JSON.stringify(companiesMentioned));

    companies.data.data = response.data.map((item, i) => {
      const colorIndex = i % colors?.length;
      return {
        ...colors[colorIndex],
        label: item.label?.toUpperCase(),
        value: item.article_count,
        labelColor: colors[colorIndex]?.color,
      };
    });
    companies.data.summary.value = String(
      addCountPrefix(response?.total_count)
    );
    companies.originalCount = response?.total_count;
    companies.shouldShowGraph = response?.data?.length > 0;
    if (response) {
      companies.originalData = response;
    }
    return companies;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useBrandCompaniesMentioned = (filters, brand, isEnabled) => {
  filters = {
    ...filters,
    brand_keywords: brand,
    dashboard_name: 'brand',
  };
  return useQuery({
    queryKey: ['dashboard-brand-companies-mentioned', filters, brand],
    queryFn: () => getBrandCompaniesMentioned(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

const getMediaContributorsData = async (
  payload,
  dropdownValue
  //  brand, comp
) => {
  const filteredPayload = extractRelevantPayload(payload, dropdownValue);
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-coverage-by-media-contributors-influencers`,
    filteredPayload,
    {}
  );

  //   const { mediaTypes } = getSelectedTypesForSocialGraph(payload);

  //   const keywords = [...comp];
  //   const queryString = `${objectToQueryString({
  //     ...payload,
  //     brand_keywords: encloseWordsInDoubleQuotes(brand),
  //     competition_keywords: encloseWordsInDoubleQuotes(keywords),
  //     media_types: mediaTypes,
  //   })}`;
  //   let { data: response } = await get(
  //     `${API}/dashboard-brand/competition-coverage-by-media-contributors-influencers?${queryString}`,
  //   {}
  // );
  if (!response) {
    response = {};
  }

  const data = JSON.parse(JSON.stringify(compMediaContributors));
  const payloadBrandKeys = payload
    ?.map((item) => Object.keys(item)?.map((brandKey) => brandKey))
    ?.flat(Infinity);
  // const queryKeywords = [...brand, ...comp];
  const dynamicLabels = [
    ...new Set(payloadBrandKeys?.map((label) => label)),
    // ...new Set(queryKeywords?.map((label) => label?.toLowerCase())),
  ];

  // Generate labels with colors
  const labels = dynamicLabels.map((label, index) => {
    return {
      label,
      value: label,
      color: outletBreakDown[index % outletBreakDown.length],
    };
  });

  const comparisonData = response?.brands
    ?.map((brandData) => {
      const brandKey =
        Object?.keys(brandData)[0] !== 'recent_search_d'
          ? Object?.keys(brandData)[0]
          : Object?.keys(brandData)[1];
      return brandData?.[brandKey]?.data;
    })
    ?.flat();

  function getRepeatedValues() {
    // If there's only one brand or no repeated authors, return data directly
    if (!comparisonData || comparisonData.length === 0) {
      return [];
    }

    const countMap = {};

    // Count occurrences of each element
    comparisonData.forEach((item) => {
      const value = item?.author_id;
      countMap[value] = (countMap[value] || 0) + 1;
    });

    // Check if any authors are repeated
    const hasRepeatedAuthors = Object.values(countMap).some(
      (count) => count >= 1
    );

    // If no repeated authors, return sorted original data
    if (!hasRepeatedAuthors) {
      return comparisonData
        .sort((a, b) => b.total_articles - a.total_articles)
        .map((item) => ({
          id: item.id,
          name: item.name,
          author_id: item.author_id,
          keywords_breakdown: item.keywords_breakdown,
          total_articles: item.total_articles,
        }));
    }

    // Original logic for repeated authors
    const filteredComparisonData = comparisonData.filter(
      (item) => countMap[item?.author_id] >= 1
    );

    const sortedData = filteredComparisonData.sort(
      (a, b) => countMap[b?.author_id] - countMap[a?.author_id]
    );

    const grouped = {};

    // Iterate over each item in the array
    sortedData.forEach((item) => {
      const { id, author_id, total_articles, name, keywords_breakdown } = item;

      // If this id combination does not exist in the grouped object, create an entry for it
      if (!grouped[author_id]) {
        grouped[author_id] = {
          id,
          name,
          author_id,
          keywords_breakdown: [],
          total_articles: 0,
        };
      }

      // Merge the value arrays
      grouped[author_id].keywords_breakdown = [
        ...grouped[author_id].keywords_breakdown,
        ...keywords_breakdown,
      ];
      // Sum the total_articles values
      grouped[author_id].total_articles += total_articles;
    });

    // Return the transformed array sorted by total_articles
    return Object.values(grouped).sort(
      (a, b) => b.total_articles - a.total_articles
    );
  }

  // Map the data dynamically for each author
  data.data.data = getRepeatedValues()
    ?.slice(0, 10)
    ?.map((author) => {
      // Initialize keyword values to 0 for each keyword in dynamicLabels
      const keywordCounts = dynamicLabels.reduce((acc, keyword) => {
        acc[keyword] = 0;
        return acc;
      }, {});

      // Update counts based on the author's keywords_breakdown
      author.keywords_breakdown.forEach((keywordData) => {
        keywordCounts[keywordData.keyword] = keywordData.articles_count;
      });

      return {
        author_id: author.author_id,
        label: author.name,
        total_articles: author.total_articles,
        keyword_breakdown: author.keywords_breakdown,
        ...keywordCounts,
      };
    });

  // generated dynamic labels
  data.data.labels = labels;

  // Update summary
  data.data.summary.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  data.originalCount = response?.overall_total_count;

  data.shouldShowGraph =
    response?.overall_total_count && response?.overall_total_count > 0;
  // data.shouldShowGraph = response?.data?.length > 0;

  if (response) {
    data.originalData = response;
  }

  return data;
};

export const useCompetitionMediaContributors = (
  filters,
  dropdownValue,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-competition-media-contributor',
      filters,
      dropdownValue,
      // competition,
    ],
    queryFn: () => getMediaContributorsData(filters, dropdownValue),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

const getCoverageByWebsites = async (payload, dropdownValue) => {
  const filteredPayload = extractRelevantPayload(payload, dropdownValue);
  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  // })}`;
  let { data: response } = await axiosPost(
    `${API}/dashboard-brand/competition-coverage-by-popular-website`,
    filteredPayload,
    {}
  );
  // const { mediaTypes } = getSelectedTypesForSocialGraph(payload);

  // const keywords = [...comp];
  // const queryString = `${objectToQueryString({
  //   ...payload,
  //   brand_keywords: encloseWordsInDoubleQuotes(brand),
  //   competition_keywords: encloseWordsInDoubleQuotes(keywords),
  //   media_types: mediaTypes,
  // })}`;
  // let { data: response } = await get(
  //   `${API}/dashboard-brand/competition-coverage-by-popular-website?${queryString}`,
  //   {}
  // );
  if (!response) {
    response = {};
  }

  const data = JSON.parse(JSON.stringify(compPopularWebsites));

  // const queryKeywords = [...brand, ...comp];
  const payloadBrandKeys = payload
    ?.map((item) => Object.keys(item)?.map((brandKey) => brandKey))
    ?.flat(Infinity);

  const dynamicLabels = [
    ...new Set(payloadBrandKeys.map((label) => label)),
    // ...new Set(queryKeywords.map((label) => label.toLowerCase())),
  ];

  // Generate labels with colors
  const labels = dynamicLabels.map((label, index) => {
    return {
      label,
      value: label,
      color: outletBreakDown[index % outletBreakDown.length],
    };
  });

  const comparisonData = response?.brands
    ?.map((brandData) => {
      const brandKey =
        Object?.keys(brandData)[0] !== 'recent_search_d'
          ? Object?.keys(brandData)[0]
          : Object?.keys(brandData)[1];
      return brandData?.[brandKey]?.data;
    })
    ?.flat();

  function getRepeatedValues() {
    // If there's only one brand, return its data directly
    if (response?.brands?.length === 1) {
      const brandData = response.brands[0];
      const brandKey = Object.keys(brandData).find(
        (key) => key !== 'recent_search_id'
      );
      return brandData[brandKey].data
        .sort((a, b) => b.count - a.count) // Sort by count in descending order
        .map((item) => ({
          source_name: item.source_name,
          source: item.source,
          keywords_breakdown: item.keywords_breakdown,
          count: item.count,
        }));
    }

    // Original logic for multiple brands
    const countMap = {};
    comparisonData.forEach((item) => {
      const value = item?.source;
      countMap[value] = (countMap[value] || 0) + 1;
    });

    const filteredComparisonData = comparisonData.filter(
      (item) => countMap[item?.source] >= 1
    );

    const sortedData = filteredComparisonData.sort(
      (a, b) => countMap[b?.source] - countMap[a?.source]
    );

    const grouped = {};
    sortedData.forEach((item) => {
      const { count, source, keywords_breakdown, source_name } = item;
      if (!grouped[source]) {
        grouped[source] = {
          source_name,
          source,
          keywords_breakdown: [],
          count: 0,
        };
      }
      grouped[source].keywords_breakdown = [
        ...grouped[source].keywords_breakdown,
        ...keywords_breakdown,
      ];
      grouped[source].count += count;
    });

    return Object.values(grouped);
  }

  // Map the data dynamically for each source
  data.data.data = getRepeatedValues()
    ?.slice(0, 10)
    .map((author) => {
      // Initialize keyword values to 0 for each keyword in dynamicLabels
      const keywordCounts = dynamicLabels.reduce((acc, keyword) => {
        acc[keyword] = 0;
        return acc;
      }, {});

      // Update counts based on the author's keywords_breakdown
      author.keywords_breakdown.forEach((keywordData) => {
        keywordCounts[keywordData.keyword] = keywordData.articles_count;
      });

      return {
        source: author.source,
        label: author?.source_name,
        total_articles: author.count,
        keyword_breakdown: author.keywords_breakdown,
        ...keywordCounts,
      };
    });

  // generated dynamic labels
  data.data.labels = labels;
  // Update summary
  data.data.summary.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  data.shouldShowGraph = response?.data?.length > 0;
  data.originalCount = response?.overall_total_count;
  data.shouldShowGraph =
    response?.overall_total_count && response?.overall_total_count > 0;
  if (response) {
    data.originalData = response;
  }
  return data;
};

export const useCompetitionCoverageByWebsites = (
  filters,
  dropdownValue,
  // brand,
  // competition,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-competition-popular-website',
      filters,
      dropdownValue,
      // brand,
      // competition,
    ],
    queryFn: () => getCoverageByWebsites(filters, dropdownValue),
    refetchOnWindowFocus: false,
    enabled: !isEnabled,
  });
};

const getTopPerformingPosts = async (payload, brand) => {
  const title = 'Top Performing Posts';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
      brand_keywords: brand,
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/social-top-performing-posts?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const topPostsChartMapData = JSON.parse(
      JSON.stringify(topPerformingPostsChartData)
    );

    const totalArticlesMapData = topPostsChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topPostsChartMapData.data.summary = totalArticlesMapData;
    topPostsChartMapData.originalCount = response?.total_count;
    const topAuthorRes = response?.data;

    const updatedTopPostsData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
      const formattedDate = x?.date
        ? format(parseISO(x.date), 'dd/MM/yyyy')
        : null;

      const truncatedTitle = x?.title ? `${x.title.slice(0, 20)}...` : '';

      return {
        article_id: x?.id,
        rank: i + 1,
        date: formattedDate,
        postContent: truncatedTitle,
        engagement: x?.engagement,
        reach: x?.reach,
      };
    });

    topPostsChartMapData.data.data = updatedTopPostsData || [];
    topPostsChartMapData.shouldShowGraph = topAuthorRes?.length > 0;

    if (response) {
      topPostsChartMapData.originalData = response;
    }

    return topPostsChartMapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useTopPerformingPosts = (filters, brand, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-brand_top-performing', filters, brand],
    queryFn: () => getTopPerformingPosts(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getBrandEngagements = async (payload, brand) => {
  const title = 'Engagement Metrics';
  try {
    // const keywords = [...comp];
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
      brand_keywords: brand,
    })}`;

    let { data: response } = await get(
      `${API}/dashboard-brand/engagement-metrics?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }
    const data = JSON.parse(JSON.stringify(compMediaContributors));
    data.component = 'brand_engagement_metrics';
    data.title = 'Engagement Metrics';
    data.data.data = response?.data;

    // generated dynamic labels
    data.data.labels = [
      {
        label: 'Likes',
        value: 'likes',
        color: outletBreakDown[0],
      },
      {
        label: 'Shares',
        value: 'shares',
        color: outletBreakDown[1],
      },
      {
        label: 'Comments',
        value: 'comments',
        color: outletBreakDown[2],
      },
    ];

    // Update summary
    data.data.summary.value = String(addCountPrefix(response?.total_count));
    data.shouldShowGraph = response?.data?.length > 0;
    data.originalCount = response?.total_count;
    return data;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useBrandEngagements = (filters, brand, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-dashboard-brand-engagements', filters, brand],
    queryFn: () => getBrandEngagements(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getBrandSocialWordCloud = async (payload, brand) => {
  const title = 'Brand Mentions';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
    })}`;

    let { data: response } = await get(
      `${API}/dashboard-brand/social-wordcloud?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const companies = JSON.parse(JSON.stringify(companiesMentioned));

    const filteredData = response?.data?.filter(
      (item) => item.article_count > 0
    );

    companies.title = 'Social Word Cloud';

    if (!filteredData?.length) {
      companies.data.data = [];
      return companies;
    }

    // Group items by their count
    const countGroups = new Map();
    filteredData.forEach((item) => {
      if (!countGroups.has(item.article_count)) {
        countGroups.set(item.article_count, []);
      }
      countGroups.get(item.article_count).push(item);
    });

    // Convert to sorted array of [count, items] pairs
    const sortedCountGroups = Array.from(countGroups.entries()).sort(
      (a, b) => b[0] - a[0]
    );

    // Ensure at least 6 color groups
    const colorGroups = [];
    let currentGroup = [];
    let currentGroupCount = null;

    sortedCountGroups.forEach(([count, items]) => {
      if (currentGroupCount === null || count === currentGroupCount) {
        currentGroup.push(...items);
        currentGroupCount = count;
      } else {
        colorGroups.push([currentGroupCount, currentGroup]);
        currentGroup = items;
        currentGroupCount = count;
      }
    });

    // Add the last group
    if (currentGroup.length > 0) {
      colorGroups.push([currentGroupCount, currentGroup]);
    }

    // If we have fewer than 6 groups, split the largest groups
    while (colorGroups.length < 6) {
      const largestGroupIndex = colorGroups.reduce(
        (maxIndex, group, index, arr) =>
          group[1].length > arr[maxIndex][1].length ? index : maxIndex,
        0
      );

      const [count, items] = colorGroups[largestGroupIndex];
      const midPoint = Math.floor(items.length / 2);

      colorGroups.splice(
        largestGroupIndex,
        1,
        [count, items.slice(0, midPoint)],
        [count, items.slice(midPoint)]
      );
    }

    // Assign colors to groups
    const colorAssignments = new Map();
    colorGroups.forEach(([count, items], index) => {
      const assignedColor =
        WordCloudOrange[Math.min(index, WordCloudOrange.length - 1)];

      items.forEach((item) => {
        colorAssignments.set(item, assignedColor);
      });
    });
    // Map the original filtered data with color assignments
    companies.data.data = filteredData.map((item) => {
      const assignedColor = colorAssignments.get(item);

      return {
        ...assignedColor,
        label: item.label?.toUpperCase(),
        value: item.article_count,
        labelColor: assignedColor?.color,
        type: item?.type,
      };
    });

    companies.component = 'brand_social_word_cloud';
    companies.data.summary.value = String(
      addCountPrefix(response?.total_count)
    );
    companies.shouldShowGraph = response?.data?.length > 0;
    companies.originalCount = response?.total_count;

    if (response) {
      companies.originalData = response;
    }

    return companies;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useBrandSocialWordCloud = (filters, brand, isEnabled) => {
  filters = {
    ...filters,
    brand_keywords: brand,
    dashboard_name: 'brand',
  };
  return useQuery({
    queryKey: ['dashboard-brand-social-wordcloud', filters, brand],
    queryFn: () => getBrandSocialWordCloud(filters, brand),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getResultOverTimeBrandData = async (payload, brand, filterBy) => {
  const title = 'Result Over Time';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
      // brand_keywords: encloseWordsInDoubleQuotes(brand),
      filter_by: filterBy?.value ?? 'reach',
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-brand/results-over-time?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(JSON.stringify(resultOverTimeBrandMapData));

    mapData.mediaTypes = payload?.media_types
      ? payload?.media_types?.filter((x) => traditional.includes(x))
      : [...traditional];

    // setting total count
    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count ? response?.total_count : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = response?.total_count;

    // setting data
    mapData.data.data =
      response?.data?.map((x) => {
        const formattedDate = getFormattedDate(x.label);

        return {
          ...x,
          label: formattedDate,
          date: x.label,
          trendLabel:
            (filterBy?.value ?? 'Reach').charAt(0).toUpperCase() +
            (filterBy?.value ?? 'Reach').slice(1),

          trend:
            x.engagements || x.reach || x.impressions
              ? x.engagements || x.reach || x.impressions
              : 0,
        };
      }) || [];

    mapData.shouldShowGraph = response?.data?.length > 0;
    if (response) {
      mapData.originalData = response;
    }

    return mapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useResultOverTimeBrand = (filters, brand, filterBy, isEnabled) => {
  filters = { ...filters, brand_keywords: brand };
  return useQuery({
    queryKey: ['dashboard-brand-result-over-time', filters, brand, filterBy],
    queryFn: () => getResultOverTimeBrandData(filters, brand, filterBy),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled && brandChartsQuery(filters),
  });
};

export const getSeasonalTrendsChartData = async (payload, brand, filterBy) => {
  const queryString = `${objectToQueryString({
    ...payload,
    // brand_keywords: encloseWordsInDoubleQuotes(brand),
    brand_keywords: brand,
  })}`;
  let { data: response } = await get(
    `${API}//dashboard-brand/social-seasonal-trends?${queryString}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const mapData = JSON.parse(JSON.stringify(seasonalTrendsMapData));

  const dataFromRes = response?.data;
  const labels = payload.media_types
    ? generateLabels(payload.media_types, social)
    : mapData.data.labels;

  const udpatedDataFromRes = dataFromRes?.map((x) => {
    const formattedDate = getFormattedDate(x?.date ?? x.label);

    const data = updateArrayToObject(
      x?.media_count || [],
      'type',
      filterBy?.value === 'mention' ? 'doc_count' : 'total_engagement',
      labels
    );

    return {
      label: formattedDate,
      ...data,
      date: x?.date ?? x.label,
      timeline: x.label,
    };
  });

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.originalCount = response?.total_count;
  mapData.data.data = udpatedDataFromRes || [];
  mapData.shouldShowGraph = response?.total_count;
  mapData.data.labels = labels;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useDashboardSeasonalTrendsChartData = (
  filters,
  brand,
  filterBy,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-brand_seasonal_trends', filters, brand, filterBy],
    queryFn: () => getSeasonalTrendsChartData(filters, brand, filterBy),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};
