import styled from 'styled-components';

export const SentimentModalWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const SentimentModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SentimentModalHeading = styled.div`
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const SentimentModalIconWrp = styled.div`
  cursor: pointer;
`;

export const SentimentModalListWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const SentimentModalTitle = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const SentimentModalList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const SentimentModalButtonWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;
