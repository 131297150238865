import styled from 'styled-components';

export const GraphWrp = styled.div`
  padding: 1rem 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background};
  /* height: ${({ graphType }) =>
    graphType === 'chart2' ? '31rem' : '100%'}; */
`;

export const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
  // padding: 4rem 1.625rem;
  cursor: pointer;
`;

export const ImageTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: #161a34;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GraphImage = styled.img`
  width: 100%;
  height: 95%;
  margin: 1rem 0;
`;

export const NewsGraphWrp = styled.div`
  margin-left: ${({ width }) => width && '8rem'};
  width: 100%;
  /* width: calc(100% - 1.25rem); */
  height: 100%;
`;

export const SlotDetailsAddWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SlotDetailsWrap = styled.div`
  height: ${({ networkHeight }) => networkHeight || '29.55rem'};
  width: 100%;
  padding: 1rem 0;
  border: 1px solid #dde1e6;
  border-radius: 0.75rem;
  margin-top: 1rem;
  ${({ resolution }) => resolution && `zoom: ${resolution};`}
`;

export const AddedNewContent = styled.div``;
