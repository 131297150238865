import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import Close from '../../assets/icons/Close';
import ArticleImageNAImg from '../../assets/img/NA for no image.svg';
import { trackEvent } from '../../utils/mixPanel';
import { Link } from 'react-router-dom';
import DefaultProfileImage from '../../assets/icons/defaultSMProfilePic.svg';
import { coolGrayColorGradients } from '../../constants/graph-colors';
import TwitterIcon from '../../assets/icons/twitter.svg';
import DefaultSMIcon from '../../assets/icons/defaultSMIcon.svg';
import RedditIcon from '../../assets/icons/reddit.svg';
import { format, isValid, parse } from 'date-fns';
import { capitalizeFirstWord } from '../../utils';
import Loader from '../loader';
import VerticalLoading from '../../assets/icons/loading/verticalLoading';
import SvgIcon from '../circularSvgIcon';
import Markdown from 'react-markdown';
import AuthenticateIcon from '../../assets/icons/AuthenticateIcon';
import FakeArticleIcon from '../../assets/icons/FakeArticleIcon';
import FakeNewsIcon from '../../assets/icons/FakeNewsIcon';
import { useFakeAnalysisArticle } from '../../hooks/useSearch';
import { error } from '../../service';
const socialMediaIcons = [
  'X (Twitter)',
  'Reddit',
  'Facebook',
  'Trust Radius',
  'Android Authority',
  'Stack Overflow',
  'Forums',
  'Blogs',
  'Reviews',
  'YouTube',
];
export const ArticleLinkSection = styled.div`
  display: flex;
  gap: 0.6rem;
  padding: 0 0 0.8rem 0;
`;
const ArticleBody = styled.div`
  font-size: 0.8rem;
  color: ${coolGrayColorGradients.coolGray70};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1875rem;
  letter-spacing: -0.24px;
  ${({ isStoryPopup }) =>
    isStoryPopup &&
    `
  -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  `}
`;
const ArticlePressTitle = styled.h1`
  font-weight: 600;
  display: -webkit-box;
  font-size: 1rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.secondaryText};
  font-family: Inter;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.32px;
  text-decoration: none;
  margin: 0;
`;
const ArticleTitle = styled(Link)`
  font-weight: 600;
  display: -webkit-box;
  font-size: ${({ mainTitle }) =>
    mainTitle ? '11px' : '1rem'}; /* Conditional font size */

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.32px;
  text-decoration: none;
  color: black;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
  }
`;
const ArticleTitletWrp = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const ArticleTextImgWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

const ArticleWrpL = styled.div`
  margin-left: auto;
  width: 6.8125rem;
  height: 6.8125rem;
  ${({ articleView }) =>
    articleView &&
    css`
      width: 7rem;
      height: 7rem;
    `}
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  animation: ${fadeIn} 0.2s ease-out;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 32px 34px -10px #00000029;
  width: 640px;
  height: ${({ isExpanded }) => (isExpanded ? '620px' : '380px')};
  padding: 20px;
  position: relative;
  animation: ${zoomIn} 0.2s ease-out;
  transition: height 0.3s ease;
`;

const Title = styled.h2`
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  color: #000;
`;

const Message = styled.p`
  color: #343a3f;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

const ActionButton = styled.button`
  width: 100%;
  background-color: #5f39f8;
  color: white;
  font-weight: 500;
  padding: 14px 16px 14px 16px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;
`;

const ContainerWrp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Subtitle = styled.p`
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0%;
  color: #666;
  margin: 8px 0 16px 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const IconWrapper = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
`;
const ArticleIcon = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
`;
const BadgeIcon = styled.img`
  position: absolute;
  top: -0.2rem;
  left: -0.2rem;
  width: 1rem;
  height: 1rem;
`;
const ArticleDefaultIcon = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #c3cce8; */
  background: linear-gradient(180deg, #b5c1e5 0%, #a8afce 100%);
  color: white;
  border-radius: 6px;
  text-transform: uppercase;
`;
const ArticleLinkWrp = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
  width: 100%;
  flex-wrap: wrap;
`;
const BoxArticlewpr = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const Articlekeytxt = styled.div`
  text-decoration: none;
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.closeButton};
  font-weight: 500;
  font-size: 11px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
  font-family: Inter;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.24px;
`;
const ArticleAuthor = styled.div`
  text-decoration: none;
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.closeButton};
  font-weight: 500;
  font-size: 11px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
  font-family: Inter;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.24px;
`;

const ResultsSection = styled.div`
  margin-top: 24px;
  overflow-y: auto;
  height: 290px;
  border-top: 1px solid #e2e2e2;
  flex: 1;
`;
const ArticleResult = styled.div`
  padding: 16px 16px 1px 16px;
  border-bottom: 1px solid #e2e2e2;

  &:last-child {
    border-bottom: none;
  }
`;

const PredictionLabel = styled.div`
  color: ${(props) =>
    props.prediction === 'Authentic' ? '#198038' : '#DA1E28'};
  font-family: Inter;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -1%;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
`;
const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 32px;
`;

const Reason = styled(Markdown)`
  font-size: 13px;
  color: #4b5563;
  line-height: 1.5;
`;
const PredictionLabelContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;
const LoaderContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 12px;
  border-top: 1px solid #e2e2e2;
  padding-top: 20px;
`;
const VerifyingText = styled.p`
  font-family: Inter;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -1%;
  color: #878d96;
  margin-left: 8px;
`;

const DisclaimerText = styled.p`
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  color: #878d96;
  text-align: left;
  margin: 16px 0 0 0;
  padding-top: 16px;
  border-top: 1px solid #e2e2e2;
`;
const handleImageError = (event) => {
  event.target.src = DefaultProfileImage; // Default image
};
const getMainTitle = (ele) => {
  if (
    ele?.newsType === 'Online' ||
    ele?.newsType === 'Print' ||
    ele?.mediaType === 'Online' ||
    ele?.mediaType === 'Print'
  ) {
    const title = ele?.publication
      ? `${ele.publication.charAt(0).toUpperCase()}${ele.publication.slice(1)}`
      : ele?.source
      ? `${ele.source.charAt(0).toUpperCase()}${ele.source.slice(1)}`
      : '';

    return title && ele.author ? `${title},` : title;
  }
  return ele.author
    ? `${ele?.author.charAt(0).toUpperCase()}${ele?.author.slice(1)}${
        getSubtitle(ele) ? ',' : ''
      }`
    : ele?.authors_byline
    ? `${ele?.authors_byline
        .charAt(0)
        .toUpperCase()}${ele?.authors_byline.slice(1)}${
        getSubtitle(ele) ? ',' : ''
      }`
    : '';
};
const getSubtitle = (ele) => {
  if (
    ele?.newsType === 'Online' ||
    ele?.newsType === 'Print' ||
    ele?.mediaType === 'Online' ||
    ele?.mediaType === 'Print'
  ) {
    return ele.author
      ? `${ele?.author.charAt(0).toUpperCase()}${ele?.author.slice(1)}${
          ele.publication !== 'X (Twitter)' && ele?.publication !== 'reddit'
            ? ''
            : ''
        }`
      : ele?.authors_byline
      ? `${ele?.authors_byline
          .charAt(0)
          .toUpperCase()}${ele?.authors_byline.slice(1)}${
          ele.source !== 'X (Twitter)' && ele?.source !== 'reddit' ? '' : ''
        }`
      : '';
  }
  if (
    (ele?.newsType === 'X (Twitter)' || ele?.mediaType === 'X (Twitter)') &&
    ele?.username
  ) {
    return `@${ele?.username}`;
  }
  return '';
};

export const Bottomkeys = [
  // { label: 'Link', value: 'link' },
  { label: 'Publication', value: 'publication_url' },
  {
    label: '12 Jun 2023',
    value: 'date',
    func: (ele) => {
      const parsedDate =
        (ele?.date || ele?.pubDate)?.split(' ')?.length >= 2
          ? parse(
              (ele?.date || ele?.pubDate)?.split(' ')[0],
              'yyyy-MM-dd',
              new Date()
            )
          : parse(
              (ele?.date || ele?.pubDate)?.split('T')[0],
              'yyyy-MM-dd',
              new Date()
            );
      const formattedDate = format(parsedDate, 'MMM dd, yyyy');
      return formattedDate;
    },
  },
  { label: 'Online News', value: 'newsType' },
  {
    label: 'New York, US',
    value: 'place',
    func: (ele) => {
      return ele.place?.toUpperCase();
    },
  },
];
const getOnlyValues = (Bottomkeys, ele) => {
  return Bottomkeys.map((item, i) => {
    let storyKeys;

    return (item?.func && item.func(ele)) || ele[storyKeys ?? item.value];
  })?.filter((x) => !!x && x !== 'NA');
};
const FakeNewsPopup = ({ onClose, article }) => {
  const fakenewsref = useRef();
  const { mutateAsync: handleFakeNewsasync } = useFakeAnalysisArticle();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [apiError, setApiError] = useState(false);
  useEffect(() => {
    const verifyNews = async () => {
      try {
        const response = await handleFakeNewsasync({
          article_ids: [article.articleId],
        });
        if (!response.isSuccessful) {
          setResults({ articles: [] });
          setApiError(true);
        } else {
          setResults(response?.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error verifying news:', error);
        setIsLoading(false);
      }
    };

    verifyNews();
  }, [article]);
  const handleClickOutside = (event) => {
    if (fakenewsref.current && !fakenewsref.current.contains(event.target)) {
      onClose();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const RenderImage = ({ url }) => {
    // eslint-disable-next-line no-unused-vars
    const [_isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const handleImageLoad = () => setIsLoading(false);
    const handleImageError = () => {
      setIsLoading(false);
      setHasError(true);
    };
    return (
      <img
        src={`${hasError ? ArticleImageNAImg : url}`}
        alt="article thumbnail"
        onLoad={handleImageLoad}
        onError={handleImageError}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        loading="lazy"
      />
    );
  };

  RenderImage.propTypes = {
    url: PropTypes.string,
  };
  const ele = article;
  return (
    <Overlay>
      <PopupContainer
        isExpanded={!isLoading && results?.articles.length > 0}
        ref={fakenewsref}
      >
        <span
          style={{
            height: '190px',
            borderBottom: '1px solid #e2e2e2',
          }}
        >
          <ContainerWrp>
            <TitleContainer>
              <Title>Verify Content</Title>
              <Subtitle>Verify the authenticity of the content</Subtitle>
            </TitleContainer>
            <div onClick={onClose} style={{ cursor: 'pointer' }}>
              <Close color="#878D96" />
            </div>
          </ContainerWrp>
          <ArticleLinkSection>
            {(ele?.icon || ele?.profile_photo) && (
              <IconWrapper>
                <ArticleIcon
                  src={ele.icon || ele?.profile_photo}
                  onError={handleImageError}
                />
                {(ele?.publication === 'reddit' ||
                  ele.mediaType === 'Reddit') && <BadgeIcon src={RedditIcon} />}
                {(ele.publication === 'X (Twitter)' ||
                  ele.mediaType === 'X (Twitter)') && (
                  <BadgeIcon src={TwitterIcon} />
                )}
                {(ele.publication === undefined || ele.publication === null) &&
                  (ele.mediaType === null || ele.mediaType === undefined) && (
                    <BadgeIcon src={DefaultSMIcon} />
                  )}
              </IconWrapper>
            )}
            {!ele?.icon &&
              !socialMediaIcons?.includes(ele?.newsType ?? ele?.mediaType) &&
              !ele?.profile_photo && (
                <ArticleDefaultIcon>
                  {ele?.publication?.[0] ?? ele?.source?.[0]}
                </ArticleDefaultIcon>
              )}
            {!ele?.icon &&
              // socialMediaIcons?.includes(ele?.publication) &&
              socialMediaIcons?.includes(ele?.newsType || ele?.mediaType) &&
              !ele?.profile_photo && (
                <IconWrapper>
                  <ArticleIcon src={DefaultProfileImage} />
                  {(ele?.newsType === 'Reddit' ||
                    ele.mediaType === 'Reddit') && (
                    <BadgeIcon src={RedditIcon} />
                  )}
                  {(ele.newsType === 'X (Twitter)' ||
                    ele.mediaType === 'X (Twitter)') && (
                    <BadgeIcon src={TwitterIcon} />
                  )}
                  {ele?.newsType !== 'Reddit' &&
                    ele.newsType !== 'X (Twitter)' &&
                    ele.mediaType !== 'Reddit' &&
                    ele.mediaType !== 'X (Twitter)' && (
                      <BadgeIcon src={DefaultSMIcon} />
                    )}
                </IconWrapper>
              )}
            <div>
              <ArticleLinkWrp>
                <BoxArticlewpr>
                  <ArticleAuthor
                    style={{
                      fontSize: '12px',
                      color: 'black',
                      fontWeight: '600',
                    }}
                  >
                    {ele?.title === '' && (ele?.link || ele?.url) ? (
                      <ArticleTitle
                        to={ele?.link || ele?.url}
                        mainTitle={true}
                        target="_blank"
                        rel={ele.title}
                        onClick={() => {
                          trackEvent('Result - External Link Redirection', {
                            result_id: ele?.articleId,
                          });
                        }}
                      >
                        {' '}
                        {getMainTitle(ele)}
                      </ArticleTitle>
                    ) : (
                      getMainTitle(ele)
                    )}
                  </ArticleAuthor>
                </BoxArticlewpr>

                <BoxArticlewpr>
                  <ArticleAuthor>{getSubtitle(ele)}</ArticleAuthor>
                </BoxArticlewpr>
              </ArticleLinkWrp>
              <ArticleLinkWrp>
                {getOnlyValues(
                  Bottomkeys.filter((item) => item.value !== 'link'),
                  ele
                )?.map((item, i, array) => (
                  <BoxArticlewpr key={i}>
                    <Articlekeytxt>
                      {item[0] ? capitalizeFirstWord(item) : item}
                      {i !== array.length - 1 && (item ? ',' : '')}
                    </Articlekeytxt>
                  </BoxArticlewpr>
                ))}
              </ArticleLinkWrp>
            </div>
          </ArticleLinkSection>
          <ArticleTextImgWrp>
            {' '}
            <ArticleTitletWrp>
              {(ele?.link || ele?.url) && (
                <ArticleTitle
                  to={ele?.link || ele?.url}
                  target="_blank"
                  rel={ele.title}
                  onClick={() => {
                    trackEvent('Result - External Link Redirection', {
                      result_id: ele?.articleId,
                    });
                  }}
                >
                  {ele?.title}
                </ArticleTitle>
              )}
              {!ele?.link && !ele?.url && (
                <ArticlePressTitle></ArticlePressTitle>
              )}{' '}
              <ArticleBody>{ele.content}</ArticleBody>
            </ArticleTitletWrp>
            <ArticleWrpL>
              <RenderImage url={ele?.image ?? ele?.image_url} />
            </ArticleWrpL>
          </ArticleTextImgWrp>
        </span>
        {isLoading ? (
          <LoaderContainer>
            <SvgIcon
              size="2.5rem"
              style={{
                position: 'relative',
                zIndex: 10,
              }}
            />
            <VerifyingText>Verifying ...</VerifyingText>
          </LoaderContainer>
        ) : apiError ? (
          <LoaderContainer>
            <VerifyingText>
              News verification failed. Please try again later.
            </VerifyingText>
          </LoaderContainer>
        ) : (
          results && (
            <>
              <ResultsSection>
                {results.articles.map((article) => (
                  <ArticleResult key={article.articleId}>
                    <PredictionLabelContainer>
                      <StyledIcon>
                        {article.prediction === 'Authentic' ? (
                          <AuthenticateIcon />
                        ) : (
                          <FakeNewsIcon />
                        )}
                      </StyledIcon>
                      <PredictionLabel prediction={article.prediction}>
                        {article.prediction === 'Authentic'
                          ? 'Identified as Authentic'
                          : 'Identified as Fake'}
                      </PredictionLabel>
                    </PredictionLabelContainer>
                    <Reason>{article.reason}</Reason>
                  </ArticleResult>
                ))}
              </ResultsSection>
              <DisclaimerText
                style={{
                  color: coolGrayColorGradients.coolGray70,
                  fontStyle: 'italic',
                }}
              >
                This identification/classification is AI generated and could be
                wrong in few cases.
              </DisclaimerText>
            </>
          )
        )}
      </PopupContainer>
    </Overlay>
  );
};

export default FakeNewsPopup;

FakeNewsPopup.propTypes = {
  onClose: PropTypes.func,
  article: PropTypes.any,
};
