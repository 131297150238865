import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  networkPinData: [],
};

const networkMapDashboard = createSlice({
  name: 'networkMap',
  initialState,
  reducers: {
    setNetworkMapData: (state, action) => {
      state.networkPinData.push(action.payload);
    },
    updateNetworkPinData: (state, action) => {
      state.networkPinData.push(action.payload);
    },
    removeNetworkPinData: (state, action) => {
      const tabIndex = action.payload;
      state.networkPinData.splice(tabIndex, 1);
    },
    resetNetworkData: () => {
      return initialState;
    },
  },
});

export const {
  setNetworkMapData,
  updateNetworkPinData,
  removeNetworkPinData,
  resetNetworkData,
  //   setComparisonFilter,
} = networkMapDashboard.actions;
export default networkMapDashboard.reducer;
