import React, { useEffect, useRef, useState } from 'react';
import {
  NetworkChartIconWrp,
  NetworkChartResults,
  NetworkChartTitle,
  NetworkCloseWrp,
  NetworkClusterHeaderWrp,
  NetworkClusterWrp,
  NetworkContentWrp,
  NetworkDiscussionChartWrp,
  NetworkDiscussionContentWrp,
  NetworkDiscussionTitleWrp,
  NetWorkHeaderWrp,
  NetworkImg,
  NetworkLoader,
  NetworkMapTabs,
  NetworkMapWTabWrap,
  NetworkMedia,
  NetworkMediaDetailsWrp,
  NetworkMediaHandle,
  NetworkMediaImage,
  NetworkMediaTitle,
  NetworkMediaWrap,
  NetworkProfileDetailsWrp,
  NetworkProfileIconWrp,
  NetworkProfileWrp,
  NetworkResultWrp,
  NetworkSentiment,
  NetworkSentimentChartWrp,
  NetworkSentimentLegends,
  NetworkSentimentTitle,
  NetworkSentimentWrp,
  NetWorkSubDetails,
  NetworkTabButton,
  NetworkTabs,
  NetworkTabsWrap,
  NetWorkTitle,
  NetWorkTitleIconWrp,
  NetWorkTitleWrp,
  NetworkWidgetComponent,
  WrapIcon,
} from './index.sc';
import ResultsInFigure from '../results-in-figure';
import PropTypes from 'prop-types';
// import networkProfile from '../../assets/img/networkProfile.png';

import DefaultProfile from '../../assets/img/defaultProfile.svg';
import backgroundImage from '../../assets/img/NetWorkBackground.png';
import PinWidget from '../../assets/icons/PinWidget';
import { VerticleDots } from '../../assets/icons/VerticleDots';
// import { BestBar } from '../../graphs';
// import { theme } from '../../constants/theme';
import GraphLegend from '../graph-legend';
import twitterLogo from '../../assets/img/twitterLogo.png';
import RadialTreeChart from '../../graphs/RadialTree/radialTreeChart';
import HelpIcon from '../../assets/icons/HelpIcon';
import {
  updateNetworkPinData,
  removeNetworkPinData,
} from '../../redux/slices/networkMapDashboardSlice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import SimpleReusableDropDown from '../simple-dropdown';
import Loader from '../loader';
import { addCountPrefix } from '../../constants/utils';
import X from '../../assets/icons/X';

const NetworkClusterPopup = ({
  popupData,
  widgetComponent = false,
  setNetWorkPopup = () => {},
  networkId = '',
  authorLoader = false,
  errorNoData = false,
  children,
}) => {
  const [dropDown, setDropDown] = React.useState(false);
  const [resultInFigData, setResultInFigData] = React.useState([]);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const onTabChange = (id) => {
    setActiveTab(id);
  };

  const radialRef = useRef(null);

  // const resultData = [
  //   {
  //     change: -75,
  //     data: 130,
  //     isIncreased: false,
  //     previousCount: 525,
  //     title: 'Total Posts',
  //   },
  //   {
  //     change: -75,
  //     data: 58302891,
  //     isIncreased: false,
  //     previousCount: 525,
  //     title: 'Total Engagement',
  //   },
  // ];

  const sentimentData = {
    data: [
      {
        label: 'screenrant.com',
        pos: 1,
        neg: 3,
        neu: 6,
      },
    ],
    totalSentiment: 10,
    labels: [
      {
        label: 'Positive',
        value: 1,

        color: '#3FBB62',
      },
      {
        label: 'Negative',
        value: 3,
        color: '#FE757B',
      },
      {
        label: 'Neutral',
        value: 6,
        color: '#BDC3CB',
      },
    ],
  };

  const mediaData = [
    {
      mediaImage: twitterLogo,
      title: '302.5K Followers',
      account: '@thetiarawills',
    },
    {
      mediaImage: twitterLogo,
      title: '302.5K Followers',
      account: '@thetiarawills',
    },
    {
      mediaImage: twitterLogo,
      title: '302.5K Followers',
      account: '@thetiarawills',
    },
  ];

  const dropDownOptions = [
    // {
    //   label: 'Pin to Dashboard',
    //   value: 'pin',
    //   clickFunction: () => {},
    // },
    {
      label: 'Remove from Dashboard',
      value: 'remove',
      clickFunction: (removedata) => {
        dispatch(removeNetworkPinData(networkId));
        toast.success('Removed to dashboard successfully');
      },
    },
  ];

  const sentimentValue = (data) => {
    return (data?.value / sentimentData?.totalSentiment) * 100;
  };

  useEffect(() => {
    if (popupData?.tweet_count) {
      setResultInFigData([
        {
          change: 0,
          data: popupData?.tweet_count,
          isIncreased: false,
          previousCount: 0,
          title: 'Total Posts',
        },
        {
          change: 0,
          data: popupData?.tweet_count * popupData?.followers_count,
          isIncreased: false,
          previousCount: 0,
          title: 'Total Engagement',
        },
      ]);
    }
  }, [popupData]);

  return (
    <NetworkClusterWrp widgetComponent={widgetComponent}>
      {authorLoader || errorNoData ? (
        <NetworkLoader>
          {errorNoData ? (
            'Failed to fetch author details. Please try again later.'
          ) : (
            <Loader />
          )}
        </NetworkLoader>
      ) : (
        <>
          <NetworkMapTabs>
            <NetworkMapWTabWrap>
              <NetworkTabButton
                active={activeTab === 0}
                onClick={() => onTabChange(0)}
              >
                Influencer Profile
              </NetworkTabButton>
              <NetworkTabButton
                active={activeTab === 1}
                onClick={() => onTabChange(1)}
              >
                Posts
              </NetworkTabButton>
            </NetworkMapWTabWrap>
            <NetworkCloseWrp onClick={() => setNetWorkPopup(false)}>
              <X size={16} />
            </NetworkCloseWrp>
          </NetworkMapTabs>
          {activeTab === 0 ? (
            <>
              <NetworkWidgetComponent widgetComponent={widgetComponent}>
                <NetworkClusterHeaderWrp
                  bgImg={backgroundImage || popupData?.profile_banner_url}
                  widgetComponent={widgetComponent}
                >
                  <NetWorkHeaderWrp>
                    <NetworkProfileWrp>
                      <NetworkImg
                        src={
                          popupData?.profile_image_url
                            ? popupData?.profile_image_url
                            : DefaultProfile
                        }
                      />
                    </NetworkProfileWrp>
                    <NetworkProfileDetailsWrp>
                      <NetWorkTitleIconWrp>
                        <NetWorkTitleWrp>
                          <NetWorkTitle>{popupData?.name}</NetWorkTitle>
                          <NetWorkSubDetails>
                            {/* Designation - Beauty Influencer/Esthetician */}
                            {popupData?.description}
                          </NetWorkSubDetails>
                        </NetWorkTitleWrp>
                        {/* <NetworkProfileIconWrp>
                          {!widgetComponent && (
                            <WrapIcon
                              onClick={() => {
                                setNetWorkPopup(false);
                                dispatch(updateNetworkPinData(popupData));
                                toast.success(
                                  'Pinned to dashboard successfully'
                                );
                              }}
                              title="Pin Dashboard"
                            >
                              <PinWidget />
                            </WrapIcon>
                          )}
                          {widgetComponent && (
                            <WrapIcon
                              onClick={() => {
                                setDropDown(
                                  dropDown === networkId ? false : networkId
                                );
                              }}
                            >
                              <VerticleDots />
                              <SimpleReusableDropDown
                                isOpen={dropDown === networkId}
                                options={dropDownOptions}
                                // graphDownloading={true}
                                setIsOpen={setDropDown}
                              />
                            </WrapIcon>
                          )}
                        </NetworkProfileIconWrp> */}
                      </NetWorkTitleIconWrp>
                      <NetWorkTitleWrp>
                        <NetWorkSubDetails>Focus/Interests</NetWorkSubDetails>
                        {popupData?.area_of_interest?.length > 0 && (
                          <NetworkTabsWrap>
                            {popupData?.area_of_interest?.map(
                              (interestItem) => (
                                <NetworkTabs key={interestItem}>
                                  {interestItem}
                                </NetworkTabs>
                              )
                            )}
                          </NetworkTabsWrap>
                        )}
                      </NetWorkTitleWrp>
                      {/* <NetWorkTitleWrp>
                        <NetWorkSubDetails>
                          Industry Connections
                        </NetWorkSubDetails>
                        {popupData?.interest_connections?.length > 0 && (
                          <NetworkTabsWrap>
                            {popupData?.interest_connections?.map(
                              (interestItem) => (
                                <NetworkTabs key={interestItem}>
                                  {interestItem}
                                </NetworkTabs>
                              )
                            )}
                          </NetworkTabsWrap>
                        )}
                      </NetWorkTitleWrp> */}
                    </NetworkProfileDetailsWrp>
                  </NetWorkHeaderWrp>
                </NetworkClusterHeaderWrp>
                <NetworkResultWrp>
                  <ResultsInFigure
                    tileDetails={resultInFigData}
                    selectedMedia={'social'}
                    eventCycle={false}
                    networkMap={true}
                  />
                </NetworkResultWrp>
                {/* {!widgetComponent && (
              <NetworkSentimentWrp>
                <BestBar
          data={sentimentData}
          config={{
            yLabelAlignment: 130,
            yAxisTicksFormat: false,
            yAxisType: 'text',
            xAxisType: 'number',
            xAxisTicksFormat: false,
            wrapLength: 20,
            gridLineXStroke: '#d9dbde',
            gridLineStrokeWidth: 1,
            enableGridXLine: false,
            enableGridYLine: false,
            graphXYLabelFontSize: 11,
            fontFamily: 'Inter',
            fontWeight: 500,
            greenGradientLight: theme?.light?.graphColors?.green40,
            greenGradientDark: theme?.light?.graphColors?.green50,
            redGradientLight: theme?.light?.graphColors?.red40,
            redGradientDark: theme?.light?.graphColors?.red50,
            grayGradientLight: theme?.light?.graphColors?.coolGray30,
            grayGradientDark: theme?.light?.graphColors?.coolGray40,
            greenDropShadowColor: theme?.light?.graphColors?.green50,
            redDropShadowColor: theme?.light?.graphColors?.red50,
            grayDropShadowColor: '#A8ADBD',
            barHeight: 24,
            articleSentiment: true,
            showAllLabels: false,
            hideXAxis: true,
            hideYAxis: true,
            graphTopPadding: 0,
            authorDataLength: 10,
            // ...defaultConfig(topAuthors.customClassName),
          }}
          resetSelection={{}}
          rerender={{}}
          handleOnClick={() => {}}
        />
                <NetworkSentimentTitle>Sentiment</NetworkSentimentTitle>
                <NetworkSentimentChartWrp>
                  {sentimentData?.labels?.map((barData, id) => (
                    <NetworkSentiment
                      key={id}
                      barWidth={sentimentValue(barData)}
                      color={barData?.color}
                    ></NetworkSentiment>
                  ))}
                </NetworkSentimentChartWrp>
                <NetworkSentimentLegends>
                  <GraphLegend legendData={sentimentData?.labels} />
                </NetworkSentimentLegends>
              </NetworkSentimentWrp>
            )} */}
                <NetworkMediaWrap>
                  {/* {mediaData?.map((item, id) => ( */}
                  <NetworkMedia>
                    <NetworkMediaImage
                      // src={item?.mediaImage}
                      // alt={item?.account}
                      src={twitterLogo}
                      alt="Twitter Logo"
                    />
                    <NetworkMediaDetailsWrp>
                      <NetworkMediaTitle>
                        {addCountPrefix(popupData?.followers_count)} Followers
                      </NetworkMediaTitle>
                      <NetworkMediaHandle>
                        {popupData?.username}
                      </NetworkMediaHandle>
                    </NetworkMediaDetailsWrp>
                  </NetworkMedia>
                  {/* ))} */}
                </NetworkMediaWrap>
                <NetworkContentWrp>
                  <NetworkMediaTitle>
                    Biographical Information
                  </NetworkMediaTitle>
                  {popupData?.bio_summary && (
                    <NetworkMediaHandle>
                      {popupData?.bio_summary}
                    </NetworkMediaHandle>
                  )}
                </NetworkContentWrp>
              </NetworkWidgetComponent>
              {/* <NetworkWidgetComponent widgetComponent={widgetComponent}>
            <NetworkDiscussionChartWrp widgetComponent={widgetComponent}>
              <NetworkDiscussionContentWrp>
                <NetworkDiscussionTitleWrp>
                  <NetworkChartTitle>
                    Discussion & Connections
                    <span
                      style={{
                        marginLeft: '1rem',
                      }}
                    >
                      <HelpIcon />
                    </span>
                  </NetworkChartTitle>
                  <NetworkChartResults>800 Connections</NetworkChartResults>
                </NetworkDiscussionTitleWrp>
                <NetworkChartIconWrp>
                  <VerticleDots />
                </NetworkChartIconWrp>
              </NetworkDiscussionContentWrp>
              <RadialTreeChart
                ref={radialRef}
                width={radialRef?.current?.clientWidth}
                height={radialRef?.current?.clientHeight}
              />
            </NetworkDiscussionChartWrp>
            {widgetComponent && (
              <NetworkSentimentWrp>
                <BestBar
          data={sentimentData}
          config={{
            yLabelAlignment: 130,
            yAxisTicksFormat: false,
            yAxisType: 'text',
            xAxisType: 'number',
            xAxisTicksFormat: false,
            wrapLength: 20,
            gridLineXStroke: '#d9dbde',
            gridLineStrokeWidth: 1,
            enableGridXLine: false,
            enableGridYLine: false,
            graphXYLabelFontSize: 11,
            fontFamily: 'Inter',
            fontWeight: 500,
            greenGradientLight: theme?.light?.graphColors?.green40,
            greenGradientDark: theme?.light?.graphColors?.green50,
            redGradientLight: theme?.light?.graphColors?.red40,
            redGradientDark: theme?.light?.graphColors?.red50,
            grayGradientLight: theme?.light?.graphColors?.coolGray30,
            grayGradientDark: theme?.light?.graphColors?.coolGray40,
            greenDropShadowColor: theme?.light?.graphColors?.green50,
            redDropShadowColor: theme?.light?.graphColors?.red50,
            grayDropShadowColor: '#A8ADBD',
            barHeight: 24,
            articleSentiment: true,
            showAllLabels: false,
            hideXAxis: true,
            hideYAxis: true,
            graphTopPadding: 0,
            authorDataLength: 10,
            // ...defaultConfig(topAuthors.customClassName),
          }}
          resetSelection={{}}
          rerender={{}}
          handleOnClick={() => {}}
        />
                <NetworkSentimentTitle>Sentiment</NetworkSentimentTitle>
                <NetworkSentimentChartWrp>
                  {sentimentData?.labels?.map((barData, id) => (
                    <NetworkSentiment
                      key={id}
                      barWidth={sentimentValue(barData)}
                      color={barData?.color}
                    ></NetworkSentiment>
                  ))}
                </NetworkSentimentChartWrp>
                <NetworkSentimentLegends>
                  <GraphLegend legendData={sentimentData?.labels} />
                </NetworkSentimentLegends>
              </NetworkSentimentWrp>
            )}
          </NetworkWidgetComponent> */}
            </>
          ) : (
            // Article component
            children
          )}
        </>
      )}
    </NetworkClusterWrp>
  );
};

export default NetworkClusterPopup;

NetworkClusterPopup.propTypes = {
  popupData: PropTypes.object,
  widgetComponent: PropTypes.bool,
  setNetWorkPopup: PropTypes.func,
  networkId: PropTypes.number,
  authorLoader: PropTypes.bool,
  errorNoData: PropTypes.bool,
  children: PropTypes.node,
};
