import { calculatePercentage, getSelectedTypes } from '..';
import {
  blueColorGradients,
  coolGrayColorGradients,
  purpleColorGradients,
} from '../../constants/graph-colors';
import { addCountPrefix, colorCodeObjects } from '../../constants/utils';
import { concentricPie } from '../../graphs/utils/graphConst';
import {
  resultOveTime,
  topThemeChartMapData as TempTopThemeChartMapData,
} from '../../hooks/data/advancedSocialCampaign';
import {
  mediaTypeChartMapData,
  sentimentChartMapData,
  wordCloudChartMapData as TempWordCloudChartMapData,
  topInfluencerChartMapData as TempTopInfluencerChartMapData,
  topSourceChartMapData as TempTopSourceChartMapData,
  outletBreakdownMapData as TempOutletBreakdownMapData,
  geographicalWorldMapData,
  OverAllSummaryData,
  TopTrendingData,
} from '../../hooks/data/chartData';
import { colors } from '../../hooks/data/colors';
import { getFormattedDate } from '../../hooks/useCharts';
import twitterLogo from '../../assets/img/twitterLogo.png';
import redditLogo from '../../assets/img/reddit.png';

export function story_analysis_dashboard_mapping(
  response,
  graphType,
  chartName
) {
  console.log({ graphType });
  if (graphType === 'story_analysis_results_over_time') {
    if (response?.error) {
      return {};
    }
    // if (!response) {
    //   response = [];
    // }

    const mapData = JSON.parse(JSON.stringify(resultOveTime));

    const totalArticlesMapData = mapData.data.summary;

    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = response?.data?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);
      return {
        ...x,
        label: formattedDate,
        gucci: x.doc_count,
        date: x?.date,
        color: purpleColorGradients?.purple60,
        timeline: x.date,
      };
    });

    mapData.data.predictiveData = response?.predictive_extend?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);

      return {
        ...x,
        label: formattedDate,
        gucci: x.doc_count,
        date: x?.date,
        color: purpleColorGradients?.purple60,
        timeline: x.date,
        predictiveLine: !Object?.keys(x)?.some((mediaKey) =>
          mediaKey?.includes('media_type')
        ),
      };
    });
    mapData.graphType = 'stacked_line';
    mapData.data.summary.value = String(addCountPrefix(response?.total_count));
    mapData.shouldShowGraph = response?.data?.some(
      (items) => items.value !== 0
    );
    mapData.slotType = 'full';
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } else if (graphType === 'story_analysis_sentiment') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(JSON.stringify(sentimentChartMapData));

    // Set Percentage here
    const percentage = calculatePercentage(response);
    const sentimeMentMapData = mapData?.data.data;
    const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
      return {
        ...x,
        value: percentage[x.label],
        labelColor: x?.color,
      };
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = updatedSentimentMapData || [];
    mapData.slotType = 'half';
    mapData.shouldShowGraph = true;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } else if (graphType === 'story_analysis_media_channels') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(JSON.stringify(mediaTypeChartMapData));

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.data.summary = totalArticlesMapData;

    const mediaData = mapData?.data?.data;

    const mediaTypesFromRes = response?.media_types;
    const updatedMediaCountData = mediaData?.map((x) => {
      const mdDataForMap = mediaTypesFromRes.find((mdData) =>
        mdData?.type?.toLowerCase().includes(x.label?.toLowerCase())
      );
      if (mdDataForMap) {
        return {
          ...x,
          value: mdDataForMap?.count,
          labelColor: x?.color,
        };
      }
      return {
        ...x,
        value: 0,
        labelColor: x?.color,
      };
    });
    mapData.data.data = updatedMediaCountData || [];
    mapData.shouldShowGraph = response?.total_count > 0;
    mapData.title = 'Media Channel';
    mapData.slotType = 'half';
    if (response) {
      mapData.originalData = response;
    }

    return mapData;
  } else if (graphType === 'story_analysis_top_themes') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }
    const topThemeChartMapData = JSON.parse(
      JSON.stringify(TempTopThemeChartMapData)
    );

    const totalArticlesMapData = topThemeChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topThemeChartMapData.data.summary = totalArticlesMapData;

    const topThemeRes = response?.data;

    const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
      const colorIndex = i % concentricPie.length;
      return {
        ...x,
        value: x?.article_count,
        secondaryValue: x.secondary_theme,
        label: x?.primary_theme,
        thresholdValue: x?.article_count,
        keyword: x?.keyword,
        labelColor: concentricPie[colorIndex],
      };
    });
    topThemeChartMapData.data.data = updatedTopSourcedata || [];
    topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
    topThemeChartMapData.originalData = response;
    topThemeChartMapData.slotType = 'half';
    if (response) {
      topThemeChartMapData.originalData = response;
    }

    return topThemeChartMapData;
  } else if (graphType === 'story_analysis_word_could') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const wordCloudChartMapData = JSON.parse(
      JSON.stringify(TempWordCloudChartMapData)
    );

    const totalArticlesMapData = wordCloudChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    wordCloudChartMapData.data.summary = totalArticlesMapData;

    const worldCloudFromRes = response?.data;

    const updatedWordCloudData = worldCloudFromRes?.map((x, i) => {
      const colorIndex = i % colors?.length;
      return {
        ...colors[colorIndex],
        value: x?.article_count,
        thresholdValue: x?.count,
        label: x?.label,
        labelColor: colors[colorIndex]?.color,
      };
    });

    wordCloudChartMapData.data.data = updatedWordCloudData || [];
    wordCloudChartMapData.shouldShowGraph = response?.data?.length > 0;
    wordCloudChartMapData.slotType = 'half';

    if (response) {
      wordCloudChartMapData.originalData = response;
    }

    return wordCloudChartMapData;
  } else if (graphType === 'story_analysis_top_influencer') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const topInfluencerChartMapData = JSON.parse(
      JSON.stringify(TempTopInfluencerChartMapData)
    );

    const totalArticlesMapData = topInfluencerChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topInfluencerChartMapData.data.summary = totalArticlesMapData;

    const topAuthorRes = response?.authors;

    const updatedTopInfluencerData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
      return {
        author_id: x?.author_id,
        value: x?.count,
        authorName: x?.author,
        mentions: x?.count,
        reach: x?.reach,
        source: x?.source,
        logoUrl: '',
      };
    });

    topInfluencerChartMapData.data.data = updatedTopInfluencerData || [];
    topInfluencerChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
    topInfluencerChartMapData.slotType = 'half';

    if (response) {
      topInfluencerChartMapData.originalData = response;
    }

    return topInfluencerChartMapData;
  } else if (graphType === 'story_analysis_top_sources') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const topSourceChartMapData = JSON.parse(
      JSON.stringify(TempTopSourceChartMapData)
    );

    const totalArticlesMapData = topSourceChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topSourceChartMapData.data.summary = totalArticlesMapData;

    const topSourceRes = response?.sources;

    const updatedTopSourceData = topSourceRes?.slice(0, 10)?.map((x, i) => {
      return {
        value: x?.count,
        label: x?.source,
        color: '#675EF2',
        labelColor: blueColorGradients.blue60,
      };
    });

    topSourceChartMapData.title = 'Top Sources';

    topSourceChartMapData.data.data =
      colorCodeObjects(updatedTopSourceData) || [];
    topSourceChartMapData.shouldShowGraph = topSourceRes?.length > 0;

    topSourceChartMapData.slotType = 'half';

    if (response) {
      topSourceChartMapData.originalData = response;
    }

    return topSourceChartMapData;
  } else if (graphType === 'story_analysis_outlet_breakdown') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }
    const outletBreakdownMapData = JSON.parse(
      JSON.stringify(TempOutletBreakdownMapData)
    );
    const selectedMedia = 'all';
    //    getSelectedTypes(payload);

    // const summaryData = outletBreakdownMapData?.data?.summary?.data;
    const mediaTypesSummary = response?.data;

    const totalCount = mediaTypesSummary?.reduce(
      (sum, obj) => sum + obj.doc_count,
      0
    );

    let results;

    if (selectedMedia === 'all') {
      const social = { label: 'Social' };
      const traditional = { label: 'Traditional' };

      mediaTypesSummary.forEach((item) => {
        if (item.media_type === 'Online' || item.media_type === 'Print') {
          // Sum the values for "Traditional"
          const totalDocCount = item.submedia_types.reduce(
            (sum, subItem) => sum + subItem.doc_count,
            0
          );
          traditional[item.media_type] =
            (traditional[item.media_type] || 0) + totalDocCount;
        } else {
          // Sum the values for "Social"
          const totalDocCount = item.submedia_types.reduce(
            (sum, subItem) => sum + subItem.doc_count,
            0
          );
          social[item.media_type] =
            (social[item.media_type] || 0) + totalDocCount;
        }
      });

      // Sort each group's properties by value from largest to smallest
      const sortedSocial = { label: 'Social' };
      const sortedTraditional = { label: 'Traditional' };

      Object.entries(social)
        .filter(([key]) => key !== 'label')
        .sort(([, a], [, b]) => b - a)
        .forEach(([key, value]) => {
          sortedSocial[key] = value;
        });

      Object.entries(traditional)
        .filter(([key]) => key !== 'label')
        .sort(([, a], [, b]) => b - a)
        .forEach(([key, value]) => {
          sortedTraditional[key] = value;
        });

      // Final result with sorted entries
      const finalResult = [sortedTraditional, sortedSocial];
      results = finalResult;
    } else {
      // For other cases, return the normal transformed data with submedia_types
      const transformedData = mediaTypesSummary.map((item) => {
        const transformedItem = { label: item.media_type };
        item.submedia_types.forEach((subItem) => {
          transformedItem[subItem.key] = subItem.doc_count;
        });
        return transformedItem;
      });

      results = transformedData;
    }
    outletBreakdownMapData.data.summary.value = String(
      addCountPrefix(totalCount || 0)
    );
    outletBreakdownMapData.data.data = results || [];
    outletBreakdownMapData.shouldShowGraph = mediaTypesSummary?.length > 0;
    outletBreakdownMapData.slotType = 'half';

    if (response) {
      outletBreakdownMapData.originalData = response;
    }

    return outletBreakdownMapData;
  } else if (graphType === 'story_analysis_geographical_breakdown') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const geographicalMapData = JSON.parse(
      JSON.stringify(geographicalWorldMapData)
    );

    const totalArticlesMapData = geographicalMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    geographicalMapData.data.summary = totalArticlesMapData;

    const geographicalRes = response?.data;

    const updatedGeographicalRes = geographicalRes?.map((state) => {
      return {
        label: state.country,
        value: state.current_count,
        labelColor:
          state.current_count > 0
            ? purpleColorGradients.purple60
            : coolGrayColorGradients.coolGray20,
      };
    });

    geographicalMapData.data.data = updatedGeographicalRes;
    geographicalMapData.shouldShowGraph = geographicalRes?.length > 0;
    geographicalMapData.slotType = 'half';

    if (response) {
      geographicalMapData.originalData = response;
    }

    return geographicalMapData;
  } else if (graphType === 'story_analysis_summary') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = [];
    }

    const mapData = OverAllSummaryData;

    mapData.data.data = response?.data;
    mapData.shouldShowGraph = response?.data?.summary !== '';
    mapData.slotType = 'half';
    if (response) {
      mapData.originalData = response;
    }

    return mapData;
  } else {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = [];
    }

    const mapData = TopTrendingData;
    mapData.data.summary.value = String(
      addCountPrefix(
        response?.data?.media_types?.reduce(
          (acc, item) => acc + item.doc_count,
          0
        ) || 0
      )
    );

    mapData.data.data = response?.data?.data;
    mapData.shouldShowGraph = response?.data?.data?.length > 0;
    mapData.slotType = 'half';

    if (response) {
      mapData.originalData = response;
    }

    return mapData;
  }
}
