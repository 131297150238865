import {
  coolGrayColorGradients,
  cyanColorGradients,
  greenColorGradients,
  magentaColorGradients,
  orangeColorGradients,
  purpleColorGradients,
  redColorGradients,
  tealColorGradients,
} from '../../constants/graph-colors';
import { addCountPrefix } from '../../constants/utils';
import { geographicalWorldMapData } from '../../hooks/data/chartData';
import { WordCloudColors } from '../../hooks/data/colors';
import { resultOverTimeBrandMapData } from '../../hooks/data/dashboardData';
import {
  influencerSentimentClusterChartMapData,
  sectionInfluencersChartMapData,
} from '../../hooks/data/InfluencerData';
import {
  peoplePopularTopics,
  peopleReachOverTimeColors,
} from '../../hooks/data/peopleData';
import { getDateRangeLabel } from '../../hooks/useCharts';

export function influencer_dashboard_mapping(response, graphType, chartName) {
  if (graphType === 'influencer_wordcloud') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = [];
    }
    const mapData = JSON.parse(JSON.stringify(peoplePopularTopics));
    const counts = {
      PERSONS: 0,
      COMPANIES: 0,
      LOCATIONS: 0,
      HASHTAGS: 0,
      OTHERS: 0,
    };
    const legendsConfig = {
      PERSONS: { label: 'People', color: magentaColorGradients.magenta60 },
      COMPANIES: {
        label: 'Organizations',
        color: purpleColorGradients.purple60,
      },
      LOCATIONS: { label: 'Locations', color: orangeColorGradients.orange60 },
      HASHTAGS: { label: 'Hashtags', color: cyanColorGradients.cyan50 },
      OTHERS: { label: 'Others', color: tealColorGradients.teal50 },
    };
    mapData.data.data = response?.data
      ?.filter((x) => {
        return x?.type !== 'LOCATIONS';
      })
      ?.map((item, i) => {
        if (item?.type in counts) {
          counts[item.type] += 1;
        }
        return {
          label: item.label,
          value: item.article_count,
          type: item?.type,
          labelColor: WordCloudColors[item?.type],
          color: WordCloudColors[item?.type],
        };
      });
    const legends = Object.keys(counts)
      .filter((key) => counts[key] > 0)
      .map((key) => legendsConfig[key]);
    mapData.data.legends = legends;
    mapData.title = chartName || 'Word Cloud';
    mapData.slotType = 'half';
    mapData.data.summary.value = String(addCountPrefix(response?.total_count));
    mapData.shouldShowGraph = response?.data?.length > 0;
    if (response) {
      mapData.originalData = response;
    }

    return mapData;
  } else if (graphType === 'influencer_geographical') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const geographicalMapData = JSON.parse(
      // JSON.stringify(TempGeoGraphicalMapData)
      JSON.stringify(geographicalWorldMapData)
    );

    const totalArticlesMapData = geographicalMapData.data.summary;
    geographicalMapData.data.summary = totalArticlesMapData;

    const geographicalRes = response?.data;
    let count = 0;

    const updatedGeographicalRes = geographicalRes?.map((state) => {
      count += state.articles_count;
      return {
        label: state.country,
        value: state.unique_influencer_count,
        articleCount: state.articles_count,
        labelColor:
          state.current_count > 0
            ? purpleColorGradients.purple60
            : coolGrayColorGradients.coolGray20,
      };
    });

    totalArticlesMapData.value = String(addCountPrefix(count));
    geographicalMapData.title = chartName || 'Influencer by Geography';

    geographicalMapData.data.data = updatedGeographicalRes;
    geographicalMapData.shouldShowGraph = geographicalRes?.length > 0;
    return geographicalMapData;
  } else if (graphType === 'influencer_section_influencer') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const topEngagingPostsChartData = JSON.parse(
      JSON.stringify(sectionInfluencersChartMapData)
    );

    const totalArticlesMapData = topEngagingPostsChartData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topEngagingPostsChartData.data.summary = totalArticlesMapData;

    const topEngageRes = response?.data;

    const updatedTopInfluencerData = topEngageRes?.slice(0, 10)?.map((x, i) => {
      return {
        rank: i + 1,
        country: x?.country,
        postContributor: x?.name,
        followers: x?.followers,
        totalPost: x?.count,
        reach: x?.reach,
        impressions: x?.impressions,
        engagements: x?.engagements,
        net_sentiments: x?.net_sentiment,
        value: x?.id,
      };
    });

    topEngagingPostsChartData.data.data = updatedTopInfluencerData || [];
    topEngagingPostsChartData.title = chartName || 'Key Influencers';

    topEngagingPostsChartData.slotType = 'full';
    topEngagingPostsChartData.shouldShowGraph = topEngageRes?.length > 0;
    return topEngagingPostsChartData;
  } else if (graphType === 'influencer_section_trends') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }
    const mapData = JSON.parse(JSON.stringify(resultOverTimeBrandMapData));

    // setting total count
    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.total_count ? response?.total_count : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;

    const labels = {};
    // setting data
    mapData.data.data =
      response?.data?.slice(0, 10).map((x) => {
        return {
          label: x?.label,
          // date: x.label || 0,
          trendLabel: 'Sentiment',
          trend: x?.total_sentiment || 0,
          value1: x?.datewise_data[0]?.count || 0,
          value2: x?.datewise_data[1]?.count || 0,
          value3: x?.datewise_data[2]?.count || 0,
          id: x?.author_id,
          bars: x?.datewise_data?.map((date, i) => {
            const label = getDateRangeLabel(date?.start_date, date?.end_date);
            labels[label] = peopleReachOverTimeColors[i];
            return {
              label,
              start_date: date?.start_date,
              end_date: date?.end_date,
              value: date?.count,
              circleColor:
                date?.net_sentiment >= 0
                  ? greenColorGradients.green30
                  : redColorGradients.red30,
              sentiment: date?.net_sentiment >= 0 ? 'POS' : 'NEG',
              borderColor:
                date?.net_sentiment >= 0
                  ? greenColorGradients.green50
                  : redColorGradients.red50,
              color: peopleReachOverTimeColors[i],
              authorId: x?.author_id,
            };
          }),
        };
      }) || [];
    mapData.data.legends = Object.keys(labels).map((key) => ({
      label: key,
      color: labels[key],
    }));
    mapData.data.circleLegends = [
      {
        label: 'Positive',
        borderColor: greenColorGradients.green50,
        circleColor: greenColorGradients.green30,
      },
      {
        label: 'Negative',
        borderColor: redColorGradients.red50,
        circleColor: redColorGradients.red30,
      },
    ];
    mapData.title = chartName || 'Trends';

    mapData.shouldShowGraph = response?.data?.length > 0;
    mapData.component = 'section_trends';
    mapData.graphType = 'grouped_column_bar';
    if (response) {
      mapData.originalData = response;
    }

    return mapData;
  } else if (graphType === 'influencer_cluster_chart') {
    if (response?.error) {
      return {};
    }
    if (!response) {
      response = {};
    }

    const mapData = JSON.parse(
      JSON.stringify(influencerSentimentClusterChartMapData)
    );

    mapData.data.data = response?.data;
    mapData.data.summary.value = String(addCountPrefix(response?.total_count));

    return mapData;
  }
}
