import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import SlotDetails from '../../components/search-result/slot-details';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import { getGraphData } from '../../utils/graph-widget/index';
import { useGetChartTemplate } from '../../hooks/useSaveNewsLetter';
import TopInfluencer from '../../components/top-influencer';
import JournalistAndSourceGraph from '../../components/advanced-dashboard/author-impact';
import PopularTopics from '../../pages/new-dashboard/popular-topics';
import CampaignMonitor from '../../components/advanced-dashboard/campaign-monitor-2';
import PeopleCampaign from '../../components/advanced-dashboard/campaign-monitor';
import Congruence from '../../components/advanced-dashboard/congruence';
import SocialCampaignMonitor from '../../components/advanced-dashboard/social-campaign';
import ContentDashboards from '../../components/advanced-dashboard/content-dashboard';
import NetworkMapDashboard from '../../components/advanced-dashboard/network-map-dashboard';
import InfluencerDashboards from '../../components/advanced-dashboard/influencer-dashboard';
import StoryAnalysisDashboard from '../../components/advanced-dashboard/story-analysis';
import BrandSwitchDashboard from '../../components/advanced-dashboard/brand-switch';
import PRImpact from '../../components/advanced-dashboard/pr-impact';
import { industryCharts } from '../new-dashboard/utils';

export const renderGraph = (data, graphType, savePopupActive = false) => {
  const columnCount = 6; // renders only 6 column in table
  const footerMarginTop = graphType === 'outlet_breakdown' ? '2rem' : '0rem';
  if (
    graphType === 'industry_top_influencers' ||
    graphType === 'people_top_score_comparison' ||
    graphType === 'brand_top_performing_posts'
  ) {
    return (
      <TopInfluencer
        widget={data}
        widgetClassName={data?.customClassName}
        key={data?.customClassName}
        helperText={data?.text}
        dashboardType={'industry'}
        isNewsletter={true}
        columnCount={columnCount}
      />
    );
  }

  if (
    graphType === 'coverage_by_source' ||
    graphType === 'coverage_by_journalist'
  ) {
    return (
      <JournalistAndSourceGraph
        widget={data}
        widgetClassName={data?.customClassName}
        editChart={{ chartName: data?.title }}
        helperText={data?.text}
        legend={true}
        widgetClassNameTwo={data?.customClassNameTwo}
        dashboardType={'brand'}
        isNewsletter={true}
      />
    );
  }

  if (graphType === 'people-impact') {
    return (
      <PeopleCampaign
        widget={data}
        customClassName={data?.customClassName}
        dashboardType={'people'}
        helperText={data?.text}
        title={data?.title}
        isNewsletter={true}
      />
    );
  }

  if (graphType === 'people_popular_topic_themes') {
    let widgetData = data;
    if (graphType === 'people_popular_topic_themes') {
      widgetData = {
        data,
        customClassName: 'people_popular_topic_themes',
        title: 'Popular Topics',
      };
    }
    return (
      <PopularTopics
        dashboardType={'grid-dashboard'}
        widget={widgetData}
        customClassName={data?.customClassName}
        widgetClassName={data?.customClassName}
        selected={1}
        idx={0}
        isNewsletter={true}
      />
    );
  }

  if (data?.dashboardType === 'campaign') {
    return (
      <CampaignMonitor
        widget={data}
        isNewsletter={true}
        dashboardType="newsletter"
      />
    );
  }

  if (graphType === 'congruence') {
    return (
      <Congruence
        widget={data}
        dashboardType={data?.dashboardType}
        customClassName={graphType}
        isNewsletter={true}
      />
    );
  }

  if (data?.dashboardType === 'socialCampaign') {
    return (
      <SocialCampaignMonitor
        widget={data}
        isNewsletter={true}
        dashboardType="newsletter"
      />
    );
  }

  if (data?.dashboardType === 'contentDashboard') {
    return (
      <ContentDashboards
        widget={data}
        dashboardType={'contentDashboard'}
        isNewsletter={true}
      />
    );
  }

  if (data?.dashboardType === 'influencerDashboard') {
    return (
      <InfluencerDashboards
        columnCount={columnCount}
        widget={data}
        isNewsletter={true}
        dashboardType="newsletter"
      />
    );
  }
  if (data?.dashboardType === 'storyAnalysis') {
    return (
      <StoryAnalysisDashboard
        widget={data}
        isNewsletter={true}
        dashboardType="newsletter"
      />
    );
  }
  if (data?.dashboardType === 'brandSwitch') {
    return (
      <BrandSwitchDashboard
        widget={data}
        isNewsletter={true}
        savePopupActive={savePopupActive}
        dashboardType="newsletter"
      />
    );
  }
  if (data?.dashboardType === 'networkMap') {
    return (
      <NetworkMapDashboard
        widget={data}
        isNewsletter={true}
        dashboardType="newsletter"
      />
    );
  }
  if (data?.dashboardType === 'primpact') {
    return (
      <PRImpact
        widget={data}
        isNewsletter={true}
        dashboardType="newsletter"
        tab_id={data?.tab_id}
      />
    );
  }
  return (
    <SlotDetails
      widget={data}
      savePopupActive={savePopupActive}
      footerMarginTop={footerMarginTop}
      legend={true}
      newsSentimentChart={graphType === 'sentiment_analysis'}
      donutWidth={graphType === 'sentiment_analysis'}
      dashboardType={
        typeof graphType === 'string' &&
        graphType.includes('sentiment_analysis')
          ? 'overview'
          : typeof data?.graphType === 'string' &&
            data.graphType.includes('pie')
          ? 'newsletter'
          : 'grid-dashboard'
      }
      isNewsletter={true}
      // themeComponent={data.component === 'top_themes' ?? false}
    />
  );
};

const DashboardGraphs = () => {
  const [data, setData] = useState(null);

  const { graphType, searchId, dashboardId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const accessToken = query.get('accessToken');
  const tabIdx = query.get('tabIdx');

  const { mutateAsync: getChartDataFunc } = useGetChartTemplate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getchartData = async () => {
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };

    let payload = {
      searchid: parseInt(searchId),
      dashboard_id: parseInt(dashboardId),
      chart_type: graphType,
    };

    if (tabIdx) {
      payload = {
        ...payload,
        tabIdx: parseInt(tabIdx) === 99 ? null : parseInt(tabIdx),
      };
    }

    const response = await getChartDataFunc({ payload, headers });

    // Check if graphType exists in industryCharts
    const isMatchingChart = industryCharts.some(
      (chart) => chart.chartId === graphType
    );
    const resData = isMatchingChart ? response?.data : response?.data?.data;
    const chartName = response?.data?.chart_name;
    const mapedData = getGraphData(resData, graphType, chartName);
    if (tabIdx) {
      setData({ ...mapedData, tab_id: parseInt(tabIdx, 10) });
    } else {
      setData(mapedData);
    }
  };

  useEffect(() => {
    getchartData();
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  const styles =
    graphType === 'outlet_breakdown'
      ? {
          maxWidth: '800px',
          maxHeight: '500px',
          margin: 'auto',
          marginTop: '50px',
          marginBottom: '50px',
        }
      : {
          maxWidth: '800px',
          height: '500px',
          margin: 'auto',
          marginTop: '30px',
          marginBottom: '50px',
        };

  return data ? (
    <div style={styles} id="graph-loaded" className="graph-rendered">
      {renderGraph(data, graphType)}
    </div>
  ) : (
    <CircularLoading size="0.25rem" width="1.875rem" height="1.875rem" />
  );
};

export default DashboardGraphs;
