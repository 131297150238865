import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CampNoDatatxtWrp,
  FullSlot,
  // IconBox,
  Iconwpr,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  SlotBody,
  SlotBodyHeader,
  SlotBodyHeaderRight,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  // SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotHeaderRight,
  SlotTitle,
  SlotOverviewWrapper,
} from './index.sc';
import Edit2 from '../../../assets/icons/Edit2';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
// import PortalTooltip from '../../portal-tooltip';
// import GraphTooltip from '../../graph-tooltip';
// import { graphTypes, widgetMapping } from '../../../constants/widgets';
import { ScatterPlot3D } from '../../../graphs';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import {
  CommentaryLabel,
  CommentarySection,
  // CommentaryLabel,
  // CommentarySection,
} from '../../search-result/index.sc';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import { gridLine } from '../../../constants/widgets';
import ChartToolTip from '../../chart-tool-tip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import { colorBox } from '../../../graphs/utils/graphConst';
import { useSelector } from 'react-redux';
import SimpleReusableDropDown from '../../simple-dropdown';
import EditGraphPopup from '../../edit-graph-popup';
import DashboardPopup from '../../dasboard-popup';
import { useParams } from 'react-router-dom';
import { AiButton } from '../../nav-section/index.sc';

const CampaignMonitor = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  handleOnClick = () => {},
  customClassName,
  helperText = '',
  title = 'Campaign Monitor',
  editChart,
  downloadFunction,
  graphDownloading,
  onDownloadChartData,
  selectedComponent,
  setSelectedComponent,
  handleUpdatedChart,
  handleGraphTitleUpdate,
  chartNames,
  isNewsletter = false,
}) => {
  const { dashboardId } = useParams();
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget?.graphType || '',
  });

  const getSelector = (state) => {
    if (dashboardType === 'primpact') {
      return state.prImpact;
    } else if (dashboardType === 'people') {
      return state.people;
    } else if (dashboardType === 'campaign') {
      return state.campaignMonitor;
    } else {
      return state.advancedDashboardTab;
    }
  };
  const { activeTab } = useSelector(getSelector);

  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const tooltipEnabled = true;

  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  const graphData = widget;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
  ];

  const handleOptionIcon = (e, componentName) => {
    // e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev !== componentName ? componentName : false
    );
  };
  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [widget?.data?.data?.title]);

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: {
          ...tData,
          value: tData?.articleCount,
        },
        rawData: d?.rawData,
      });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (enableTooltip) {
        setEnableTooltip(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [enableTooltip]);

  const customHandleClick = (event, d) => {
    handleOnClick(event, d);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  if (dashboardType === 'grid-dashboard') {
    delete defaultConfig.handleOnClick;
  }

  const legendData = [
    {
      label: 'X-Axis : Net Sentiment',
      color: '#fff',
    },
    {
      label: 'Y-Axis : Reach',
      color: '#fff',
    },
    {
      label: 'Size of Bubbles : Number of Results',
      color: '#fff',
    },
  ];

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [helperText]);

  const handleGraphEditClick = (e) => {
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();

    handleUpdatedChart(data);
    const chartData = chartNames?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      customClassName,
      data?.chartType
    );
    handleGraphEditClick(e);
  };

  const handleEditChart = (data) => {
    setEditChart2(data);
  };

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  return (
    <>
      <FullSlot className="graph-widget override-padding" bottom={false}>
        <SlotDetailsMainWrp className={customClassName} ref={containerRef}>
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotTitle ref={titleRef}>
                  {dashboardId && !isNewsletter
                    ? editChart?.chartName
                    : editChart2?.chartName || 'Campaign Monitor'}
                </SlotTitle>
                {activeTab !== 999 && !isNewsletter && (
                  <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() => setChartToolTip(true)}
                    onMouseLeave={() => setChartToolTip(false)}
                  >
                    {!isNewsletter && <HelpIcon />}
                  </span>
                )}
                <SlotOverviewWrapper>
                  <SlotOverview summary={widget?.summary} />
                </SlotOverviewWrapper>

                {chartToolTip && (
                  <ChartToolTip
                    text={helperText}
                    iconTop={-9}
                    componentTop={7}
                    componentLeft={iconPosition?.left}
                  />
                )}
              </SlotHeaderLeft>

              {!isNewsletter && (
                <SlotHeaderRight>
                  <Iconwpr
                    width="1.5rem"
                    height="1.5rem"
                    onClick={() => handleGraphEditClick(widget)}
                    className="hide-downloading"
                  >
                    <Edit2 />
                  </Iconwpr>
                  <Iconwpr
                    width={'1.5rem'}
                    height={'1.5rem'}
                    onClick={(e) => {
                      handleOptionIcon(e, widget.customClassName);
                    }}
                    ref={downloadRef}
                    className="hide-downloading"
                  >
                    <VerticleDots
                      color={
                        openActionDropdown === selectedComponent
                          ? '#675ef2'
                          : '#5C5E60'
                      }
                    />
                    <SimpleReusableDropDown
                      isOpen={openActionDropdown === selectedComponent}
                      options={actionDropDownOptions}
                      graphDownloading={graphDownloading}
                      setIsOpen={setOpenActionDropDown}
                    />
                  </Iconwpr>
                </SlotHeaderRight>
              )}
            </SlotHeader>
            {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
            <SlotBody type={type} className="commentary">
              <SlotBodyMain>
                {loader ? (
                  <Loader />
                ) : widget?.shouldShowGraph ? (
                  <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                ) : (
                  <ScatterPlot3D
                    data={widget}
                    config={{
                      xAxisType: 'number',
                      yLabelAlignment: 30,
                      gridXTicks: 12,
                      gridYTicks: 6,
                      overTimeChart: true,
                      hideYAxisLine: true,
                      valueInPercent: true,
                      ...gridLine,
                      ...defaultConfig,
                    }}
                    resetSelection={resetSelection}
                  />
                )}
                {enableTooltip && (
                  <PortalTooltip
                    isOpen={true}
                    pos={toolTipPos}
                    align={
                      toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                    }
                    vAlign={
                      toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                    }
                  >
                    <GraphTooltip
                      tooltipData={tooltipData}
                      type="two-d"
                      widget={widget}
                    />
                  </PortalTooltip>
                )}
              </SlotBodyMain>
            </SlotBody>
            <SlotFooter>
              <LegendCon>
                {legendData.map((ele, i) => {
                  return (
                    <LegendItem key={i}>
                      {/* <LegendBox legendColor={ele?.color}></LegendBox> */}
                      <LegendLabel>{ele?.label}</LegendLabel>
                    </LegendItem>
                  );
                })}
              </LegendCon>

              <SlotBodyHeaderRight>
                {' '}
                <LegendCon>
                  {widget?.data?.map((ele, i) => {
                    return (
                      <LegendItem key={i}>
                        <LegendBox legendColor={ele.color}></LegendBox>
                        <LegendLabel textWrap={true} title={ele?.label}>
                          {ele?.label}
                        </LegendLabel>
                      </LegendItem>
                    );
                  })}
                </LegendCon>
              </SlotBodyHeaderRight>
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>

        <DashboardPopup
          open={popupIsOpen}
          toggler={handleGraphEditClick}
          width={'40%'}
          popContent={
            <EditGraphPopup
              popupIsOpen={popupIsOpen}
              handleEditClick={handleUpdateGraph}
              handleClose={handleGraphEditClick}
              widgetTitle={editChart?.chartName}
              handleEdit={handleEditChart}
              widget={graphData}
            />
          }
        />
      </FullSlot>
    </>
  );
};

export default CampaignMonitor;

CampaignMonitor.defaultProps = {
  type: 'dashboard',
};

CampaignMonitor.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
  title: Proptypes.string,
  graphDownloading: Proptypes.string,
  editChart: Proptypes.object,
  downloadFunction: Proptypes.func,
  onDownloadChartData: Proptypes.func,
  selectedComponent: Proptypes.string,
  setSelectedComponent: Proptypes.func,
  handleGraphTitleUpdate: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  chartNames: Proptypes.object,
  isNewsletter: Proptypes.bool,
};
