import { prImpactMapData } from '../../hooks/data/advancedDashboardData';
import { addCountPrefix } from '../../constants/utils';
import { format, parseISO } from 'date-fns';
import { theme } from '../../constants/theme';

export function primapct_mapping(response, graphType, chartName) {
  if (graphType === 'primpact' || graphType === 'comparison_primpact') {
    if (response?.error) {
      return {};
    }
    const mapData = {};
    const updatedData = [];

    response?.forEach((impactData) => {
      const data = JSON.parse(JSON.stringify(prImpactMapData));
      data.customClassName = 'primpact';
      data.title = chartName || '';

      data.gauge.summary.value =
        parseInt(impactData?.gauge) < 0 ? 0 : parseInt(impactData?.gauge);

      data.totalCount = String(addCountPrefix(impactData?.total_count));

      data.gauge.summary.rawValue = Number(impactData.gauge).toFixed(2);

      data.column.data.data = impactData?.data?.map((x) => {
        const dateString = x?.label;
        const date = parseISO(dateString);
        const formattedDate = format(date, 'dd MMM yy');
        const getColor = (count) => {
          if (count >= 1 && count < 15) {
            return theme?.light?.graphColors?.orange40 || '#ED3F47';
          } else if (count >= 15 && count <= 39) {
            return theme?.light?.graphColors?.green30 || '#FF8C00';
          } else if (count > 39 && count <= 65) {
            return theme?.light?.graphColors?.green40 || '#FF8C00';
          } else if (count < 1) {
            return theme?.light?.graphColors?.red50 || '#FF8C00';
          }
          return theme?.light?.graphColors?.green50 || '#00CE75';
        };

        const getRatingScale = (count) => {
          if (count >= 1 && count < 15) {
            return 'Poor';
          } else if (count >= 15 && count <= 39) {
            return 'Good';
          } else if (count > 39 && count <= 65) {
            return 'Very Good';
          } else if (count < 1) {
            return 'Very Poor';
          }
          return 'Excellent';
        };
        return {
          ...x,
          label: formattedDate,
          value: x.pr_impact,
          color: getColor(x.pr_impact),
          count: x.doc_count,
          date: x?.label,
          rating_scale: getRatingScale(x.pr_impact),
        };
      });
      data.brandKeyword = impactData?.brand_name;

      updatedData.push({
        ...data,
      });
    });

    // TODO:
    mapData.prBrandKeywords = response?.map((res) => res?.brand_name);
    mapData.data = updatedData;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  }
}
