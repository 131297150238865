import styled from 'styled-components';

export const ContentDashboardContainer = styled.div``;

export const SlotWrp = styled.div`
  position: relative;
  margin: 0rem 0rem 2rem 0rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  }
`;
