import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 400px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ClientList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ClientItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  gap: 10px;
  transition: background 0.2s ease, padding-left 0.2s ease;
  position: relative;
  max-height: 56px;
  border-radius: 12px;
  padding-left: 8px;
  background: ${({ active }) => (active ? '#ece4ff' : 'transparent')};

  &:hover {
    background: #f6f2ff;
    padding-left: 12px;
  }
`;

export const ClientImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  z-index: ${({ noIndex = false }) => noIndex && 0};
`;

export const ClientName = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const ArrowIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0; /* Hidden by default */
  transition: opacity 0.2s ease, transform 0.2s ease;

  svg {
    fill: #5f39f8;
  }

  ${ClientItem}:hover & {
    opacity: 1; /* Show on hover */
    transform: translate(5px, -50%); /* Slight movement for the hover effect */
  }
`;
