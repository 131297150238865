import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TranslationSetting from '../translation-setting';

// Helper function to get the display text for the selected language
const getSelectedLanguageText = (
  selectedLanguage,
  languages,
  articleId,
  defaultLanguage,
  isAutoTranslate
) => {
  const selectedLangData = selectedLanguage[articleId];
  const defaultLangName = languages[defaultLanguage] || defaultLanguage;

  if (!selectedLangData) {
    if (isAutoTranslate) {
      return `${languages.en || 'English'} (Translated)`;
    }
    return `${defaultLangName} (Original)`;
  }

  const { language: selectedLangCode, isOriginal } = selectedLangData;
  const selectedLangName = languages[selectedLangCode] || selectedLangCode;

  if (selectedLangCode === 'More Options') return 'More Options';
  if (isOriginal) return `${selectedLangName} (Original)`;
  return `${selectedLangName} (Translated)`;
};

// Helper function to get the display text for each option
const getOptionText = (
  item,
  languages,
  index,
  isAutoTranslate,
  defaultLanguage
) => {
  if (item === 'More Options') return 'More Options';

  // If auto-translate is on, prioritize English translation
  if (isAutoTranslate && item === 'en') {
    return `${languages[item]} (Translated)`;
  }

  // If it's the default language, mark as original
  if (item === defaultLanguage) {
    return `${languages[item]} (Original)`;
  }

  // For other cases, use existing logic
  return index === 0
    ? `${languages[item]} (Original)`
    : `${languages[item]} (Translation)`;
};

const LanguageSelector = ({
  ele,
  selectedLanguageOption,
  languages,
  toggleDropdown,
  handleSelect,
  handlePopupModal,
  dropdownStates,
  showTranslationPopup,
  setShowTranslationPopup,
  onApply,
  isAutoTranslate,
}) => {
  const dropdownRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Only close if translation popup is not open
      if (!showTranslationPopup) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          selectRef.current &&
          !selectRef.current.contains(event.target)
        ) {
          // Close dropdown for this specific article
          toggleDropdown(ele?.articleId, false);
        }
      }
    };

    // Add event listener when dropdown is open
    if (dropdownStates[ele?.articleId]) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [dropdownStates, ele?.articleId, toggleDropdown, showTranslationPopup]);

  return (
    <>
      <LanguageWrapper>
        <InnerWrapper>
          <Label>Language :</Label>
          <Select
            ref={selectRef}
            onClick={() => toggleDropdown(ele?.articleId)}
          >
            <OptionText>
              {getSelectedLanguageText(
                selectedLanguageOption,
                languages,
                ele.articleId,
                ele.language,
                isAutoTranslate
              )}
            </OptionText>
            <ArrowIcon />
            {dropdownStates[ele?.articleId] && (
              <Dropdown ref={dropdownRef}>
                <OptionsList>
                  {[ele.language, 'en', 'More Options'].map((item, index) => (
                    <Option
                      key={index}
                      isSelected={
                        !selectedLanguageOption[ele.articleId]
                          ? isAutoTranslate
                            ? item === 'en'
                            : item === ele.language
                          : selectedLanguageOption[ele.articleId]?.language ===
                            item
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item === 'More Options') {
                          handlePopupModal(ele.articleId);
                          toggleDropdown(ele?.articleId, false);
                        } else {
                          handleSelect(
                            ele.articleId,
                            item,
                            item === ele.language
                          );
                        }
                      }}
                    >
                      <OptionText>
                        {getOptionText(
                          item,
                          languages,
                          index,
                          isAutoTranslate,
                          ele.language
                        )}
                      </OptionText>
                      {(!selectedLanguageOption[ele.articleId]
                        ? isAutoTranslate
                          ? item === 'en'
                          : item === ele.language
                        : selectedLanguageOption[ele.articleId]?.language ===
                          item) && <CheckIcon />}
                    </Option>
                  ))}
                </OptionsList>
              </Dropdown>
            )}
          </Select>
        </InnerWrapper>
      </LanguageWrapper>
      {showTranslationPopup && (
        <TranslationSetting
          onClose={() => setShowTranslationPopup(false)}
          onApply={onApply}
          initialState={isAutoTranslate}
        />
      )}
    </>
  );
};

// Styled Components
const LanguageWrapper = styled.div`
  position: relative;
  display: flex;
  background: #ffffff;
  align-items: center;
  gap: 8px;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.div`
  color: #4d5358;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  font-family: Inter, sans-serif;
`;

const Select = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 0px;
  color: #5f39f8;
  gap: 4px;
`;

const Dropdown = styled.div`
  position: absolute;
  bottom: calc(100% + 4px);
  left: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.12);
  padding: 4px;
  z-index: 1;
`;

const OptionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  padding: 8px 6px;
  gap: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  border-radius: 6px;
  color: ${({ isSelected }) => (isSelected ? '#5F39F8' : '#4D5358')};
  background: ${({ isSelected }) => (isSelected ? '#F6F2FF' : '#FFFFFF')};
  transition: all 0.2s ease;
`;

const OptionText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
`;

// SVG Icons
const CheckIcon = () => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
    <path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill="#5F39F8" />
  </svg>
);

const ArrowIcon = () => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none">
    <path d="M4 6L0.535898 0H7.4641L4 6Z" fill="#585858" />
  </svg>
);

LanguageSelector.propTypes = {
  ele: PropTypes.object,
  selectedLanguageOption: PropTypes.object,
  languages: PropTypes.object,
  toggleDropdown: PropTypes.func,
  handleSelect: PropTypes.func,
  handlePopupModal: PropTypes.func,
  dropdownStates: PropTypes.object,
  showTranslationPopup: PropTypes.bool,
  setShowTranslationPopup: PropTypes.func,
  onApply: PropTypes.func,
  isAutoTranslate: PropTypes.bool,
};

export default LanguageSelector;
