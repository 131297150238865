import React from 'react';
const AuthenticateIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="7" fill="#DEFBE6" />
    <mask
      id="mask0_22428_45631"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="24"
      height="24"
    >
      <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_22428_45631)">
      <path
        d="M19.3 14.25L22.825 10.7L22.125 10L19.3 12.825L17.875 11.4L17.175 12.125L19.3 14.25ZM7 24V22H19V24H7ZM20 17C18.6167 17 17.4375 16.5125 16.4625 15.5375C15.4875 14.5625 15 13.3833 15 12C15 10.6167 15.4875 9.4375 16.4625 8.4625C17.4375 7.4875 18.6167 7 20 7C21.3833 7 22.5625 7.4875 23.5375 8.4625C24.5125 9.4375 25 10.6167 25 12C25 13.3833 24.5125 14.5625 23.5375 15.5375C22.5625 16.5125 21.3833 17 20 17ZM7 16V14H13.3C13.4167 14.3667 13.55 14.7167 13.7 15.05C13.85 15.3833 14.0333 15.7 14.25 16H7ZM7 20V18H16.4C16.7833 18.2333 17.1917 18.4292 17.625 18.5875C18.0583 18.7458 18.5167 18.8583 19 18.925V20H7Z"
        fill="#198038"
      />
    </g>
  </svg>
);

export default AuthenticateIcon;
